import * as Yup from 'yup';
import { FormFields } from './Username.fields';

export const userNameSchema = () => Yup.object().shape({
  [FormFields.username.fieldName]: Yup.string()
    .ensure()
    .required('Enter admin username'),
});

export default userNameSchema;
