export const ACTIONS = {
  CLEAR_PERSON_ASSOCIATIONS_MODIFY_STATUS: 'CLEAR_PERSON_ASSOCIATIONS_MODIFY_STATUS',
  REQUEST_PERSON_ASSOCIATIONS: 'REQUEST_PERSON_ASSOCIATIONS ',
  RECEIVE_PERSON_ASSOCIATIONS: 'RECEIVE_PERSON_ASSOCIATIONS',
  REQUEST_PERSON_ASSOCIATIONS_ERROR: 'REQUEST_PERSON_ASSOCIATIONS_ERROR',
  REQUEST_UPDATE_PERSON_ASSOCIATIONS: 'REQUEST_UPDATE_PERSON_ASSOCIATIONS ',
  RECEIVE_UPDATE_PERSON_ASSOCIATIONS: 'RECEIVE_UPDATE_PERSON_ASSOCIATIONS',
  REQUEST_UPDATE_PERSON_ASSOCIATIONS_ERROR: 'REQUEST_UPDATE_PERSON_ASSOCIATIONS_ERROR',
};

export default ACTIONS;
