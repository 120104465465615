export const ACTIONS = {
  REQUEST_HUMANTASK: 'REQUEST_HUMANTASK',
  RECEIVE_HUMANTASK: 'RECEIVE_HUMANTASK',
  RECEIVE_HUMANTASK_ERROR: 'RECEIVE_HUMANTASK_ERROR',
  COMPLETE_HUMANTASK: 'COMPLETE_HUMANTASK',
  COMPLETED_HUMANTASK: 'COMPLETED_HUMANTASK',
  COMPLETED_HUMANTASK_ERROR: 'COMPLETED_HUMANTASK_ERROR',
  REQUEST_WORKFLOW: 'REQUEST_WORKFLOW',
  RECEIVE_WORKFLOW: 'RECEIVE_WORKFLOW',
  REFRESH_WORKFLOW: 'REFRESH_WORKFLOW',
  RECEIVE_WORKFLOW_ERROR: 'RECEIVE_WORKFLOW_ERROR',
  REQUEST_START_WORKFLOW: 'REQUEST_START_WORKFLOW',
  RECEIVE_START_WORKFLOW: 'RECEIVE_START_WORKFLOW',
  REQUEST_START_WORKFLOW_ERROR: 'REQUEST_START_WORKFLOW',
  REQUEST_START_COMPLETE_WF_TASK: 'REQUEST_START_COMPLETE_WF_TASK',
  RECEIVE_COMPLETE_WF_TASK: 'RECEIVE_COMPLETE_WF_TASK',
  REQUEST_COMPLETE_WF_TASK_ERROR: 'REQUEST_COMPLETE_WF_TASK_ERROR',
  REQUEST_POSTSUBMIT_TASKS: 'REQUEST_POSTSUBMIT_TASKS',
  RECEIVE_POSTSUBMIT_TASKS: 'RECEIVE_POSTSUBMIT_TASKS',
  REQUEST_POSTSUBMIT_TASKS_ERROR: 'REQUEST_POSTSUBMIT_TASKS_ERROR',
  // RECEIVE_APP_ADMISSION_STATE: 'RECEIVE_APP_ADMISSION_STATE',
  // REQUEST_PERSONTASK: 'REQUEST_PERSONTASK',
  // RECEIVE_PERSONTASK: 'RECEIVE_PERSONTASK',
  // RECEIVE_PERSONTASK_ERROR: 'RECEIVE_PERSONTASK_ERROR',
  REQUEST_RERUN_WF_TASK: 'REQUEST_RERUN_WF_TASK',
  RECEIVE_RERUN_WF_TASK: 'RECEIVE_RERUN_WF_TASK',
  REQUEST_RERUN_WF_TASK_ERROR: 'REQUEST_RERUN_WF_TASK_ERROR',
};

export const WF_TASK_NAMES = {
  ACCOUNT_CREATION: 'accountCreation',
  CONTACT_INFO: 'contactInfo',
  TELL_US_MORE: 'tellUsMore',
  MILITARY: 'military',
  PROGRAM_SELECTION: 'programSelection',
  LICENSE_INFO: 'licenseInfo',
  SECONDARY: 'secondary',
  HIGHER: 'higher',
  EXPULSION_INFO: 'expulsionInfo',
  CREDITS: 'credits',
  WORK: 'work',
  FINANCIAL: 'financial',
  FINANCIAL_MILITARY: 'financialMilitary',
  FINANCIAL_GRANTS: 'financialGrants',
  FINANCIAL_BENEFITS: 'financialBenefits', // Removed for ADMISSION v5 workflow
  FINANCIAL_EMPLOYER_BENEFITS: 'financialEmployerBenefits',
  FINANCIAL_DISCOUNTS: 'financialDiscounts',
  FINANCIAL_SCHOLARSHIPS: 'financialScholarships',
  FINANCIAL_TRIBAL_BENEFITS: 'financialTribalBenefits',
  FINANCIAL_PERSONAL: 'financialPersonal',
  FINANCIAL_LOANS: 'financialLoans',
  FINANCIAL_SUMMARY: 'financialSummary',
  FINANCIAL_SUMMARY_DOWNLOAD: 'financialSummaryDownload',
  MANAGE_FUNDS_CREDIT_BALANCE: 'manageFundsCreditBalance',
  REVIEW: 'review',
  REVIEW_ACKNOWLEDGE: 'reviewAcknowledge',
  REVIEW_SIGN_SUBMIT: 'reviewSignSubmit',
};

// eslint-disable-next-line max-len
export const getWorkflowKeyByValue = value => Object.keys(WF_TASK_NAMES).find(key => WF_TASK_NAMES?.[key] === value) || value;
