/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import styles from './styles';
import { phoneNumber10Digit, UOPX_PHONE_NUMBER } from '../../constants';
import { handleERCallClick, handleEREmailClick } from '../../../../store/helpers/commonHelper';

const useStyles = styles;
function ConfirmDialog(props) {
  const classes = useStyles();
  const {
    open, setOpen, title, body, body2,
    onNoClick, onYesClick, dialogId,
    listItems, bodyEnd, actions, cancelAsClose,
    needAlert, showOneButton, personId, erInfo,
    onNoClickLink,
    escToDismiss, // Can ESC key be used to closed the dialog without selecting yes or no
    onDismiss,
  } = props;

  const { erName, erPhone, erEmail } = erInfo || {};
  const hasERContact = body2.indexOf('your enrollment representative');
  const hasErName = hasERContact > -1 && erName ? body2.indexOf(erName) : -1;
  const phoneNum = erPhone || UOPX_PHONE_NUMBER;
  const phoneIndex = hasERContact > -1 ? body2.indexOf(phoneNum) : -1;
  const msgBeforePhoneFinal = hasERContact > -1 && phoneIndex > -1 ? body2.substr(0, phoneIndex) : '';
  const emailIndex = hasERContact > -1 && hasErName > -1 && erEmail ? body2.indexOf(erEmail) : -1;
  const msgAfterPhone = hasERContact > -1 && hasErName === -1 && phoneIndex > -1 ? body2.substr(phoneIndex + phoneNum.length) : '';
  const msgAfterEmail = hasERContact > -1 && hasErName > -1 && emailIndex > -1 ? body2.substr(emailIndex + erEmail.length) : '';
  const msgAfterFinal = msgAfterEmail || msgAfterPhone;

  const msgEmail = ' or ';

  const handleAction1 = () => {
    if (cancelAsClose) {
      setOpen(false);
    } else {
      onNoClick();
    }
  };

  const handleERPhoneCallClick = () => {
    handleERCallClick('Dashboard - Track your status', phoneNum, personId);
  };

  const handleEmailClick = () => {
    handleEREmailClick('Dashboard - Track your status', erEmail, personId);
  };

  const handleEscKeyDown = (e) => {
    if (e.key === 'Escape') {
      setOpen(false);
      onDismiss(); // Call dismiss handler
    }
  };

  useEffect(() => {
    if (open && escToDismiss) {
      document.addEventListener('keydown', handleEscKeyDown);
    } else {
      document.removeEventListener('keydown', handleEscKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleEscKeyDown);
    };
  }, [open, escToDismiss]);

  return (
    <Dialog
      className="Dialog"
      open={open}
      aria-labelledby={`${dialogId}Title`}
      id={dialogId}
    >
      {needAlert && (
        <p className="block-msg-nobackground warning-msg-nobackground">IMPORTANT</p>
      )}

      <DialogTitle id={`${dialogId}Title`}>{title}</DialogTitle>
      {body ? (
        <DialogContent className="tight">
          <DialogContentText className={classes.text}>
            {body}
            {(listItems && listItems.length > 1) ? (
              <ul className={clsx(classes.list, 'unordered-list main')}>
                {listItems.map((item, i) => <li key={i.toString()}><span>{item}</span></li>)}
              </ul>
            ) : null}
            {hasERContact === -1 && body2 && !needAlert && (
              <>
                <br />
                <br />
                {body2}
              </>
            )}
            {hasERContact > -1 && body2 && !needAlert && (
              <>
                <br />
                <br />
                {phoneNum && (
                  <div className={classes.statusMessage}>
                    {msgBeforePhoneFinal}
                    {' '}
                    <a
                      href={`tel:+1${phoneNumber10Digit(phoneNum)}`}
                      className="textLink"
                      onClick={handleERPhoneCallClick}
                    >
                      {phoneNum}
                    </a>
                    {' '}
                    {emailIndex > -1 && (
                      <span>
                        {msgEmail}
                        <a
                          href={`mailto:${erEmail}`}
                          target="_blank"
                          className="textLink"
                          rel="noreferrer"
                          title={erEmail}
                          onClick={handleEmailClick}
                        >
                          {erEmail}
                        </a>
                      </span>
                    )}
                    {msgAfterFinal && (
                      <span>
                        {msgAfterFinal}
                      </span>
                    )}
                  </div>
                )}
              </>
            )}
            {hasERContact === -1 && body2 && needAlert && (
              <>
                <br />
                <br />
                <p className="block-msg warning-msg-noicon">{body2}</p>
              </>
            )}
            {hasERContact > -1 && body2 && needAlert && (
              <>
                <br />
                <br />
                <p className="block-msg warning-msg-noicon">
                  {phoneNum && (
                    <div className={classes.erMessage}>
                      {msgBeforePhoneFinal}
                      {' '}
                      <a
                        href={`tel:+1${phoneNumber10Digit(phoneNum)}`}
                        className="textLink"
                        onClick={handleERPhoneCallClick}
                      >
                        {phoneNum}
                      </a>
                      {' '}
                      {emailIndex > -1 && (
                        <span>
                          {msgEmail}
                          <a
                            href={`mailto:${erEmail}`}
                            target="_blank"
                            className="textLink"
                            rel="noreferrer"
                            title={erEmail}
                            onClick={handleEmailClick}
                          >
                            {erEmail}
                          </a>
                        </span>
                      )}
                      {msgAfterFinal && (
                        <span>
                          {msgAfterFinal}
                        </span>
                      )}
                    </div>
                  )}
                </p>
              </>
            )}

            {bodyEnd || null}
          </DialogContentText>
        </DialogContent>
      ) : null}
      <DialogActions className={classes.dialogActions}>
        {showOneButton && (
          <Button
            onClick={onYesClick}
            color="primary"
            className="mainBtn confirmBtn"
            autoFocus
            disableFocusRipple
            disableRipple
          >
            {actions[0]}
          </Button>
        )}
        {!showOneButton && (actions && actions.length === 2) && (
          <>
            {(onNoClickLink !== '') ? (
              <>
                <Link
                  to={onNoClickLink}
                  className="linkForButton linkNarrow linkSecondary"
                >
                  {actions[0]}
                </Link>
                <Button
                  onClick={onYesClick}
                  color="primary"
                  className="mainBtn confirmBtn"
                  autoFocus
                  disableFocusRipple
                  disableRipple
                >
                  {actions[1]}
                </Button>
              </>
            ) : (
              <>
                <Button
                  // onClick={() => setOpen(false)}
                  onClick={handleAction1}
                  color="secondary"
                  className="mainBtn confirmBtn"
                  disableFocusRipple
                  disableRipple
                >
                  {actions[0]}
                </Button>
                <Button
                  onClick={onYesClick}
                  color="primary"
                  className="mainBtn confirmBtn"
                  autoFocus
                  disableFocusRipple
                  disableRipple
                >
                  {actions[1]}
                </Button>
              </>
            )}
          </>
        )}
        {!showOneButton && (!actions || actions.length === 0) && (
          <>
            <Button
              onClick={onYesClick}
              color="secondary"
              className="mainBtn confirmBtn"
              disableFocusRipple
              disableRipple
            >
              Yes
            </Button>
            <Button
              // onClick={() => setOpen(false)}
              onClick={handleAction1}
              color="primary"
              className="mainBtn confirmBtn"
              autoFocus
              disableFocusRipple
              disableRipple
            >
              No
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.defaultProps = {
  body: '',
  body2: '',
  title: '',
  listItems: [],
  bodyEnd: '',
  actions: [],
  dialogId: '',
  cancelAsClose: true,
  needAlert: false,
  showOneButton: false,
  onNoClickLink: '',
  personId: '',
  erInfo: {},
  escToDismiss: false,
  onDismiss: () => {},
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  body: PropTypes.string,
  body2: PropTypes.string,
  setOpen: PropTypes.func.isRequired,
  onNoClick: PropTypes.func.isRequired,
  onYesClick: PropTypes.func.isRequired,
  onNoClickLink: PropTypes.string,
  title: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.any),
  bodyEnd: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.any),
  dialogId: PropTypes.string,
  cancelAsClose: PropTypes.bool,
  needAlert: PropTypes.bool,
  showOneButton: PropTypes.bool,
  personId: PropTypes.string,
  erInfo: PropTypes.objectOf(PropTypes.any),
  escToDismiss: PropTypes.bool,
  onDismiss: PropTypes.func,
};

export default ConfirmDialog;
