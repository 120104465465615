export const PROGRAM_LEVEL_MAP = {
  A: 'Associates',
  UG: 'Undergraduate',
  GR: 'Graduate',
  DO: 'Doctoral',
};

export const pageHeader = 'Military and veterans education benefits';

export default { PROGRAM_LEVEL_MAP, pageHeader };
