import React from 'react';
import ServiceLayer from '../../services';

// Higher order component used to inject in services to a given component
// How to use:
// Use the HOC to wrap your component
// Example: const WrappedComponentName = withService(ComponentName);
// Then use the WrappedComponentName in place of ComponentName and pass any props as normal
// In your component there should now be a Services prop available

const withService = BaseComponent => props => <BaseComponent Services={ServiceLayer} {...props} />;

export default withService;
