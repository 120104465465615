import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LinkLongArrow from '../link-long-arrow/LinkLongArrow';
import STATUS from '../../../../../store/globalStatuses';
import SkipStepModal from '../../../../../components/modal/skipStepModal/SkipStepModal';
import {
  MAIN_STEP_INDEXES,
  SECONDARY_STEP_INDEXES,
  copyNavigationObject,
  nextPageLinkOverride,
  skipNavPage,
} from '../../../../../store/helpers/routingHelper';
import {
  triggerTealiumSkipPageEvent,
} from '../../../../vendor/tealium/TealiumUpdater';
import {
  convertCYOPPageIdToTealiumPageName,
} from '../../../financial/helper';
import styles from './styles';

function SkipButton(props) {
  const {
    label,
    to,
    handleSkipClick,
    setSubmittingPage,
    hasPageBeenSubmitted,
    preferenceValues,
    putChoosePath,
    pageId,
    personId,
    preferencesModifyStatus,
    application,
    updateApplication,
    navigation,
    updateAppNavigation,
    ...propsToPass
  } = props;

  const classes = styles();
  const [redirect, setRedirect] = useState(false);
  const [displaySkipButtonModal, setSkipButtonModal] = useState(false);
  const [skipToLink, setSkipToLink] = useState(to);

  const updatePreferenceSkipStep = () => {
    const updatedPreferenceValues = {
      militarySelection: preferenceValues.militarySelected,
      federalGrantsSelection: preferenceValues.federalGrantsSelected,
      employerBenefitsSelection: preferenceValues.employerBenefitsSelected,
      employerDiscountsSelection: preferenceValues.employerDiscountsSelected,
      scholarshipsSelection: preferenceValues.scholarshipsSelected,
      tribalBenefitsSelection: preferenceValues.tribalBenefitsSelected,
      noneApplySelection: preferenceValues.noneApplySelected,
      showLivingExpenses: preferenceValues.showLivingExpenses,
    };

    const updatedApplication = {
      ...application,
      navigation: copyNavigationObject(application.navigation),
    };

    let navUpdate = {};

    switch (pageId) {
      case 'pgFinanceMilitaryPrimary':
        updatedPreferenceValues.militarySelection = false;
        updatedApplication.navigation.pages[
          MAIN_STEP_INDEXES.FINANCIAL_PLAN
        ].secondarySteps[
          SECONDARY_STEP_INDEXES.FINANCIAL_MILITARY
        ].enabled = false;
        if (navigation.id) {
          navUpdate = skipNavPage(navigation, MAIN_STEP_INDEXES.FINANCIAL_PLAN, SECONDARY_STEP_INDEXES.FINANCIAL_MILITARY);
        }
        break;
      case 'pgFinanceGrants':
        updatedPreferenceValues.federalGrantsSelection = false;
        updatedApplication.navigation.pages[
          MAIN_STEP_INDEXES.FINANCIAL_PLAN
        ].secondarySteps[
          SECONDARY_STEP_INDEXES.FINANCIAL_GRANTS
        ].enabled = false;
        if (navigation.id) {
          navUpdate = skipNavPage(navigation, MAIN_STEP_INDEXES.FINANCIAL_PLAN, SECONDARY_STEP_INDEXES.FINANCIAL_GRANTS);
        }
        break;
      case 'pgFinanceEmployerBenefits':
        updatedPreferenceValues.employerBenefitsSelection = false;
        updatedApplication.navigation.pages[
          MAIN_STEP_INDEXES.FINANCIAL_PLAN
        ].secondarySteps[
          SECONDARY_STEP_INDEXES.FINANCIAL_EMPLOYER
        ].enabled = false;
        if (navigation.id) {
          navUpdate = skipNavPage(navigation, MAIN_STEP_INDEXES.FINANCIAL_PLAN, SECONDARY_STEP_INDEXES.FINANCIAL_EMPLOYER);
        }
        break;
      case 'pgFinanceAssociations':
        updatedPreferenceValues.employerDiscountsSelection = false;
        updatedApplication.navigation.pages[
          MAIN_STEP_INDEXES.FINANCIAL_PLAN
        ].secondarySteps[
          SECONDARY_STEP_INDEXES.FINANCIAL_DISCOUNTS
        ].enabled = false;
        if (navigation.id) {
          navUpdate = skipNavPage(navigation, MAIN_STEP_INDEXES.FINANCIAL_PLAN, SECONDARY_STEP_INDEXES.FINANCIAL_DISCOUNTS);
        }
        break;
      case 'pgFinanceScholarships':
        updatedPreferenceValues.scholarshipsSelection = false;
        updatedApplication.navigation.pages[
          MAIN_STEP_INDEXES.FINANCIAL_PLAN
        ].secondarySteps[
          SECONDARY_STEP_INDEXES.FINANCIAL_SCHOLARSHIPS
        ].enabled = false;
        if (navigation.id) {
          navUpdate = skipNavPage(navigation, MAIN_STEP_INDEXES.FINANCIAL_PLAN, SECONDARY_STEP_INDEXES.FINANCIAL_SCHOLARSHIPS);
        }
        break;
      case 'pgFinanceTribal':
        updatedPreferenceValues.tribalBenefitsSelection = false;
        updatedApplication.navigation.pages[
          MAIN_STEP_INDEXES.FINANCIAL_PLAN
        ].secondarySteps[
          SECONDARY_STEP_INDEXES.FINANCIAL_TRIBAL
        ].enabled = false;
        if (navigation.id) {
          navUpdate = skipNavPage(navigation, MAIN_STEP_INDEXES.FINANCIAL_PLAN, SECONDARY_STEP_INDEXES.FINANCIAL_TRIBAL);
        }
        break;
      default:
    }
    updatedPreferenceValues.noneApplySelection = !Object.values((() => {
      const pageSupressionValues = {
        ...updatedPreferenceValues,
      };
      delete pageSupressionValues.showLivingExpenses;
      return pageSupressionValues;
    })()).some(value => value === true);

    const skipToCurPage = window.location.href.includes(to);

    let nextPage = to;
    if (skipToCurPage) {
      nextPage = nextPageLinkOverride(navUpdate);
    }
    if (nextPage.includes('admissions/review')) {
      nextPage = '/admissions/financial/summary';
    }
    setSkipToLink(nextPage);
    triggerTealiumSkipPageEvent(convertCYOPPageIdToTealiumPageName(pageId), personId);
    handleSkipClick();
    putChoosePath(personId, updatedPreferenceValues);
    if (navigation.id) {
      updateAppNavigation(navUpdate);
    }
    updateApplication(updatedApplication);
  };

  const handleSkipButtonOpen = () => {
    setSkipButtonModal(true);
  };

  return (
    <>
      <span className={classes.skipBtn}>
        <LinkLongArrow
          id="skip_btn"
          color="secondary"
          {...propsToPass}
          ariaLabel="Skip this financial step"
          label="Skip this step"
          handleClick={() => {
            if (hasPageBeenSubmitted) {
              handleSkipButtonOpen();
            } else {
              updatePreferenceSkipStep();
              setSubmittingPage(true);
              setRedirect(true);
              window.scrollTo(0, 0);
            }
          }}
        />
      </span>
      {redirect && preferencesModifyStatus === STATUS.UPDATED ? (() => {
        setSubmittingPage(false);
        return <Redirect push to={skipToLink} />;
      })() : ''}
      <SkipStepModal
        isVisible={displaySkipButtonModal}
        setIsVisible={setSkipButtonModal}
        setSubmittingPage={setSubmittingPage}
        updatePreferenceSkipStep={updatePreferenceSkipStep}
        setRedirect={setRedirect}
        to={skipToLink}
      />
    </>
  );
}

SkipButton.defaultProps = {
  navigation: {},
};

SkipButton.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  handleSkipClick: PropTypes.func.isRequired,
  setSubmittingPage: PropTypes.func.isRequired,
  hasPageBeenSubmitted: PropTypes.bool.isRequired,
  preferenceValues: PropTypes.shape({
    militarySelected: PropTypes.bool.isRequired,
    federalGrantsSelected: PropTypes.bool.isRequired,
    employerBenefitsSelected: PropTypes.bool.isRequired,
    employerDiscountsSelected: PropTypes.bool.isRequired,
    scholarshipsSelected: PropTypes.bool.isRequired,
    tribalBenefitsSelected: PropTypes.bool.isRequired,
    noneApplySelected: PropTypes.bool.isRequired,
    showLivingExpenses: PropTypes.bool.isRequired,
  }).isRequired,
  putChoosePath: PropTypes.func.isRequired,
  pageId: PropTypes.string.isRequired,
  personId: PropTypes.string.isRequired,
  preferencesModifyStatus: PropTypes.string.isRequired,
  updatePreferenceSkipStep: PropTypes.func.isRequired,
  application: PropTypes.shape({
    navigation: PropTypes.shape({
      pages: PropTypes.arrayOf({}).isRequired,
    }).isRequired,
  }).isRequired,
  updateApplication: PropTypes.func.isRequired,
  navigation: PropTypes.shape({
    id: PropTypes.string,
    pages: PropTypes.arrayOf({}),
  }),
  updateAppNavigation: PropTypes.func.isRequired,
};

export default SkipButton;
