import { ACTIONS } from '../types';
import STATUS from '../../../globalStatuses';

export const stateReductions = {
  [ACTIONS.REQUEST_STATE]: state => ({
    ...state,
    statesStatus: {
      ...state.statesStatus,
      getStatus: STATUS.FETCHING,
      errorData: null,
    },
  }),
  [ACTIONS.RECEIVE_STATE]: (state, action) => ({
    ...state,
    states: {
      statesUS: action.payload.statesUS,
    },
    statesStatus: {
      ...state.statesStatus,
      getStatus: STATUS.FETCHED,
    },
  }),
  [ACTIONS.RECEIVE_STATE_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    statesStatus: {
      ...state.statesStatus,
      getStatus: STATUS.ERROR,
      errorData: action.error,
    },
  }),
};

export default stateReductions;
