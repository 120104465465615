import retryCallIfNeeded from '../../../restCalls';

const baseEndpoint = '/api/student/v1';

export default function StudentService(client) {
  const getStudentProgramMembershipsDetails = personId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpoint}/program/membershipsdetails?$filter=personId eq ${personId} and primary eq true`));

  const getStudentStatuses = personId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpoint}/statuses?$filter=personId eq ${personId}`));

  return {
    getStudentProgramMembershipsDetails,
    getStudentStatuses,
  };
}
