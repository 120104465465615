import { ACTIONS } from './types';
import STATUS from '../../../globalStatuses';

function creditsInfo(
  state = {
    creditsInfo: {},
  },
  action,
) {
  switch (action.type) {
    case ACTIONS.REQUEST_PERSON_EXAM_INFO:
      return {
        ...state,
        creditsInfoStatus: {
          ...state.creditsInfoStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
        creditsInfo: null,
      };
    case ACTIONS.RECEIVE_PERSON_EXAM_INFO:
      return {
        ...state,
        creditsInfo: action.creditsInfo,
        creditsInfoStatus: {
          ...state.creditsInfoStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_PERSON_EXAM_INFO_ERROR:
      return {
        ...state,
        error: action.error,
        creditsInfoStatus: {
          ...state.creditsInfoStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    case ACTIONS.REQUEST_SAVE_PERSON_EXAM_INFO:
      return {
        ...state,
        creditsInfoStatus: {
          ...state.creditsInfoStatus,
          getStatus: STATUS.UNFETCHED,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_SAVE_PERSON_EXAM_INFO:
      return {
        ...state,
        creditsInfo: action.creditsInfo,
        creditsInfoStatus: {
          ...state.creditsInfoStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_SAVE_PERSON_EXAM_INFO_ERROR:
      return {
        ...state,
        error: action.error,
        creditsInfoStatus: {
          ...state.creditsInfoStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };


    case ACTIONS.REQUEST_DELETE_PERSON_EXAM_INFO:
      return {
        ...state,
        creditsInfoStatus: {
          ...state.creditsInfoStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_DELETE_PERSON_EXAM_INFO:
      return {
        ...state,
        creditsInfo: action.creditsInfo,
        creditsInfoStatus: {
          ...state.creditsInfoStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_DELETE_PERSON_EXAM_INFO_ERROR:
      return {
        ...state,
        error: action.error,
        creditsInfoStatus: {
          ...state.creditsInfoStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.CLEAR_EXAM_CREDITS_MODIFY_STATUS:
      return {
        ...state,
        creditsInfoStatus: {
          ...state.creditsInfoStatus,
          modifyStatus: STATUS.UNFETCHED,
        },
      };
    default:
      return state;
  }
}
export default creditsInfo;
