/* eslint-disable linebreak-style */
import {
  REQUEST_SAVED_PROGRAMS,
  RECEIVE_SAVED_PROGRAMS,
  REQUEST_SAVED_PROGRAMS_ERROR,
} from './types';
import STATUS from '../../globalStatuses';
import { errorToMessage } from '../../../errorMessaging';

function leads(
  state = { leads: {} },
  action,
) {
  switch (action.type) {
    case REQUEST_SAVED_PROGRAMS:
      return {
        ...state,
        savedProgramsStatus: {
          ...state.savedProgramsStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_SAVED_PROGRAMS:
      return {
        ...state,
        savedPrograms: action.programs,
        savedProgramsStatus: {
          ...state.savedProgramsStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_SAVED_PROGRAMS_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        savedProgramsStatus: {
          ...state.savedProgramsStatus,
          getStatus: action.error?.response?.status === 404 ? STATUS.FETCHED : STATUS.ERROR,
          errorData: action.error,
        },
      };
    default:
      return state;
  }
}

export default leads;
