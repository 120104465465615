const STATUS = {
  ERROR: 'ERROR',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  MISSING: 'MISSING',
  UNFETCHED: 'UNFETCHED',
  INVALIDATED: 'INVALIDATED',
  UPDATING: 'UPDATING',
  UPDATED: 'UPDATED',
  COMPLETED: 'COMPLETED',
};

export default STATUS;
