/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Typography, Grid, Link,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  ReactComponent as MobileIcon,
} from '../../modules/admissions/components/icons/mobile-phone96x96.svg';
import {
  ReactComponent as IdentificationIcon,
} from '../../modules/admissions/components/icons/id-identification96x96.svg';
import {
  ReactComponent as CheckIcon,
} from '../../modules/admissions/components/icons/check_active96x96.svg';
import {
  ReactComponent as IdVerifySuccessIcon,
} from '../../modules/admissions/components/icons/id-verify-success.svg';
import styles from './styles';
import IdentityVerifyRetry from '../identity-verify-retry/IdentityVerifyRetry';
import { IdVerifySuccess } from './constants';

export default function HowIdentityVerifyWorks({
  title,
  stepOneHeader,
  paragraphOne,
  stepTwoHeader,
  paragraphTwo,
  stepFourHeader,
  paragraphFour,
  numsOfRetryLeft,
  showRetryMessage,
  children,
  videoChatEnabled,
  successfulPrePostSign,
}) {
  const classes = styles();

  const ViewAcceptableIDs = event => {
    event.preventDefault();
    const IDfaqElment = document.getElementById('panel2b-header');
    const IDfaqTop = document.getElementById('FAQverifyId');
    if (IDfaqElment && IDfaqTop) {
      if (IDfaqElment.getAttribute('aria-expanded') === 'false') {
        IDfaqElment.click();
      }
      IDfaqTop.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className={clsx(classes.root, 'howIdentityVerifyWorks')}>
      {!videoChatEnabled && showRetryMessage ? (
        <IdentityVerifyRetry
          numsOfRetryLeft={numsOfRetryLeft}
          videoChatEnabled={videoChatEnabled}
        />
      ) : null}
      {!showRetryMessage ? (
        <>
          {successfulPrePostSign ? (
            <>
              <Typography variant="h2" className={classes.title}>
                {title}
              </Typography>
              <ol className={classes.orderedList}>
                <li>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.listItem}
                  >
                    <Grid item aria-hidden="true">
                      <MobileIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="h3" className={classes.header}>
                        {stepOneHeader}
                      </Typography>
                      <Typography className={classes.paragraph}>
                        {paragraphOne}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
                <li>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.listItem}
                  >
                    <Grid item aria-hidden="true">
                      <IdentificationIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="h3" className={classes.header}>
                        {stepTwoHeader}
                      </Typography>
                      <Typography className={classes.paragraph}>
                        {paragraphTwo}
                      </Typography>
                      <div className={classes.linkContainer}>
                        <Link
                          href="#FAQverifyId"
                          className={classes.link}
                          onClick={ViewAcceptableIDs}
                        >
                          View acceptable IDs below
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                </li>
                <li>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.listItem}
                  >
                    <Grid item aria-hidden="true">
                      <CheckIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="h3" className={classes.header}>
                        {stepFourHeader}
                      </Typography>
                      <Typography className={classes.paragraph}>
                        {paragraphFour}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              </ol>
            </>
          ) : (
            <div className={classes.verifySuccessContainer}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <IdVerifySuccessIcon />
                </Grid>
                <Grid item>
                  <Typography variant="h2" className={classes.headerSuccess}>
                    {IdVerifySuccess.header}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.paragraph}>
                    {IdVerifySuccess.main}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )}
        </>
      ) : null}
      <div className={classes.childContainer}>{children}</div>
    </div>
  );
}

HowIdentityVerifyWorks.defaultProps = {
  children: null,
  successfulPrePostSign: true,
};

HowIdentityVerifyWorks.propTypes = {
  title: PropTypes.string.isRequired,
  stepOneHeader: PropTypes.string.isRequired,
  paragraphOne: PropTypes.string.isRequired,
  stepTwoHeader: PropTypes.string.isRequired,
  paragraphTwo: PropTypes.string.isRequired,
  stepFourHeader: PropTypes.string.isRequired,
  paragraphFour: PropTypes.string.isRequired,
  numsOfRetryLeft: PropTypes.number.isRequired,
  showRetryMessage: PropTypes.bool.isRequired,
  children: PropTypes.element,
  videoChatEnabled: PropTypes.bool.isRequired,
  successfulPrePostSign: PropTypes.bool,
};
