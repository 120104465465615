import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  arrowRightIcon: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1),
  },
  skipBtn: {
    display: 'block',
    marginTop: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
      marginTop: 0,
      marginLeft: theme.spacing(5),
    },
  },
}));
