import axios from 'axios';
import idHeaders from '../interceptors/idHeaders';
import retryCallIfNeeded from '../../restCalls';

const segmentBase = '/application/segment';

const accountVerfication = '/account-verification-status';
const accountVerficationEvents = '/account-verification-status-events';

export default function SegmentService() {
  axios.interceptors.request.use(idHeaders);
  const getAccountVerificationStatus = async personId => retryCallIfNeeded(axios, () => axios
    .get(`${segmentBase}${accountVerfication}?personId=${personId}`));

  const getAccountVerificationStatusEvents = async personId => retryCallIfNeeded(axios, () => axios
    .get(`${segmentBase}${accountVerficationEvents}?personId=${personId}`));

  const postAccountVerificationStatus = async (personId, username, idStatus, source) => retryCallIfNeeded(axios, () => axios
    .post(
      `${segmentBase}${accountVerfication}?personId=${personId}`,
      {
        personId, username, idStatus, source,
      },
    ));
  return {
    getAccountVerificationStatus,
    getAccountVerificationStatusEvents,
    postAccountVerificationStatus,
  };
}
