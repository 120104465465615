import retryCallIfNeeded from '../../../restCalls';

const baseEndpoint = '/api/applications/v1';
const baseRulesEndpoint = '/api/applications/rules/v1';
const applicationProxy = '/application/new/app';
const navigationProxy = '/application/new/nav';

export default function ApplicationService(client) {
  const getApplicationByPersonIdAsync = personId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpoint}/applications?index=personId&value=${personId}`));
  const getApplicationByApplicationIdAsync = applicationId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpoint}/applications/${applicationId}`));
  const postApplicationAsync = payload => retryCallIfNeeded(client, () => client
    .post(`${baseEndpoint}/applications`, payload));
  const updateApplicationAsync = async (application, applicationETag) => {
    const url = `${baseEndpoint}/applications/${application.id}`;
    const config = {
      headers: {
        'If-Match': applicationETag,
      },
    };
    return client.put(url, application, config);
  };
  const getNavigationByApplicationIdAsync = applicationId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpoint}/applications/${applicationId}/navigation`));
  const postNavigationAsync = (applicationId, payload) => retryCallIfNeeded(client, () => client
    .post(`${baseEndpoint}/applications/${applicationId}/navigation`, payload));
  const updateNavigationAsync = async (navigation, applicationId) => {
    const url = `${baseEndpoint}/applications/${applicationId}/navigation`;
    return client.put(url, navigation);
  };
  const getApplicationType = payload => retryCallIfNeeded(client, () => client.post(`${baseRulesEndpoint}/stateless/ruleset/applicationType`, payload));
  const putApplywebAppEnrollmentStatusAsync = async (
    applyWebAppId,
    enrollmentStatus,
  ) => retryCallIfNeeded(client, () => client
    .put(`${baseEndpoint}/application/${applyWebAppId}/enrollmentStatus?enrollmentStatusName=${enrollmentStatus}`, null));
  const uploadDocuments = payload => retryCallIfNeeded(client, () => client
    .post(`${baseEndpoint}/applications/uploadDocument`, payload));
  const uploadTranscriptDocument = payload => retryCallIfNeeded(client, () => client
    .post(`${baseEndpoint}/applications/uploadTranscriptDocument`, payload));
  const getProgramWorkExperienceRequirement = payload => retryCallIfNeeded(client, () => client.post(`${baseRulesEndpoint}/stateless/ruleset/programWorkExpRequirment`, payload));
  const getAttachmentByApplicationIdAsync = applicationId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpoint}/applications/${applicationId}/attachment`));
  const getApplicationsByIp = ipAddress => retryCallIfNeeded(client, () => client
    .get(`${baseEndpoint}/applications?index=additionalAttributes.ipAddress&value=${ipAddress}&limit=300`));

  const getEncodedApplicationByApplicationIdAsync = applicationId => retryCallIfNeeded(client, () => client
    .get(`${applicationProxy}/${applicationId}`));
  const postEncodedApplicationAsync = payload => retryCallIfNeeded(client, () => client
    .post(`${applicationProxy}`, payload));
  const updateEncodedApplicationAsync = async payload => client.put(`${applicationProxy}/${payload.i}`, payload);

  const getEncodedNavigationByApplicationIdAsync = applicationId => retryCallIfNeeded(client, () => client
    .get(`${navigationProxy}/${applicationId}`));
  const postEncodedNavigationAsync = payload => retryCallIfNeeded(client, () => client
    .post(`${navigationProxy}/${payload.i}`, payload));
  const updateEncodedNavigationAsync = async payload => client.put(`${navigationProxy}/${payload.i}`, payload);

  return {
    getApplicationByPersonIdAsync,
    postApplicationAsync,
    getApplicationByApplicationIdAsync,
    updateApplicationAsync,
    getNavigationByApplicationIdAsync,
    updateNavigationAsync,
    postNavigationAsync,
    getApplicationType,
    putApplywebAppEnrollmentStatusAsync,
    uploadDocuments,
    uploadTranscriptDocument,
    getProgramWorkExperienceRequirement,
    getAttachmentByApplicationIdAsync,
    getApplicationsByIp,
    getEncodedApplicationByApplicationIdAsync,
    postEncodedApplicationAsync,
    updateEncodedApplicationAsync,
    getEncodedNavigationByApplicationIdAsync,
    postEncodedNavigationAsync,
    updateEncodedNavigationAsync,
  };
}
