import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoginInfo from '../login/LoginInfo';
import DashBoard from './DashBoard';

const AdminHome = ({ Services }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginStatus, setLoginStatus] = useState('');
  const resetStatus = () => {
    setIsLoggedIn(false);
    setLoginStatus('');
  };
  const loginAdmin = data => {
    resetStatus();
    Services.adminService.adminLogin(data)
      .then(response => {
        setIsLoggedIn(true);
        setLoginStatus('success');
      }).catch(error => {
        setLoginStatus('failed');
      });
  };
  return (

    isLoggedIn ? <DashBoard /> : (
      <LoginInfo
        submitAction={loginAdmin}
        status={loginStatus}
      />
    )

  );
};
AdminHome.propTypes = {
  Services: PropTypes.shape({
    adminService: PropTypes.shape({
      adminLogin: PropTypes.func.isRequired,
    }),
  }).isRequired,
};
export default AdminHome;
