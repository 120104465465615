import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useWatch } from 'react-hook-form';
import {
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  FormControl,
  Typography,
} from '@material-ui/core';

const LegendHeading = (title, titleHeading) => {
  if (!title) {
    return null;
  }
  let HeadingTag;
  switch (titleHeading) {
    case 'h2':
      HeadingTag = 'h2';
      break;
    case 'h3':
      HeadingTag = 'h3';
      break;
    case 'h4':
      HeadingTag = 'h4';
      break;
    case 'h5':
      HeadingTag = 'h5';
      break;
    case 'h6':
      HeadingTag = 'h6';
      break;
    case 'p':
      HeadingTag = 'p';
      break;
    default:
      HeadingTag = 'h2';
      break;
  }

  return <HeadingTag>{title}</HeadingTag>;
};

const CustomRadioGroup = ({
  ariaDescribedBy,
  ariaLabelledBy,
  id,
  name,
  title,
  titleHeading,
  description,
  control,
  className,
  onChangeCallback,
  items,
  disabled,
  legendText,
  legendClass,
  children,
}) => {
  const formControlName = useWatch({
    name,
    control,
  });

  return (
    <fieldset id={`${id}-radio-group`} className="radio-group-fieldset">
      {children && (
        <legend>{children}</legend>
      )}
      {!children && title && (
        <legend>
          {LegendHeading(title, titleHeading)}
        </legend>
      )}
      {!children && description && (
        <Typography className="desc-radio-group">{description}</Typography>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl className="radio-group" data-error={invalid}>
            <legend className={legendClass}>
              {`${legendText}`}
            </legend>
            <RadioGroup
              {...field}
              id={`${id}-input`}
              aria-labelledby={ariaLabelledBy}
              aria-describedby={`${id}-helper-text ${ariaDescribedBy}`}
              row
              onChange={event => {
                field.onChange(event);
                onChangeCallback(event.target.value);
              }}
              className={className}
              data-radio-value={formControlName}
            >
              {items.map(item => (
                <FormControlLabel
                  value={item.value}
                  key={item.value}
                  control={(
                    <Radio
                      id={`${id}-${item.label.replace(' ', '').toLowerCase()}`}
                      color="default"
                      disableRipple
                    />
                )}
                  label={item.label}
                  disabled={disabled}
                  data-radio={item.label}
                />
              ))}
            </RadioGroup>
            {invalid && (
              <FormHelperText id={`${id}-helper-text`} error={invalid}>
                {error?.message}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </fieldset>
  );
};

CustomRadioGroup.propTypes = {
  ariaDescribedBy: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  titleHeading: PropTypes.string,
  description: PropTypes.string,
  control: PropTypes.shape({}).isRequired,
  required: PropTypes.bool,
  className: PropTypes.string,
  onChangeCallback: PropTypes.func,
  legendText: PropTypes.string,
  legendClass: PropTypes.string,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.element,
};

CustomRadioGroup.defaultProps = {
  title: '',
  titleHeading: 'h2',
  description: '',
  ariaDescribedBy: '',
  ariaLabelledBy: '',
  required: false,
  className: '',
  legendText: '',
  legendClass: '',
  disabled: false,
  items: [],
  onChangeCallback: () => {},
  children: null,
};

export default CustomRadioGroup;
