// https://github.com/tc39/proposal-uuid#faq
import { v4 as uuidv4 } from 'uuid';
import defaultFeatureFlags from '../../store/defaultFeatureFlags';
import featureFlagsNames from '../../store/featureFlags';
import { baseSagasEndpoint } from '../sagas/v1/task';
import clientHelper from '../../store/helpers/clientHelper';

const xCorrelationIdName = 'X-Correlation-Id';
const xRequestIdName = 'X-Request-Id';
const sagasCanaryHeader = 'out-of-the-blue';
const syntheticHeader = 'x-uopx-synthetic';
const fullstoryUrlHeader = 'X-FullStory-URL';
const xCorrelationId = sessionStorage.getItem(xCorrelationIdName) || sessionStorage.getItem('mcId') || uuidv4();
sessionStorage.setItem(xCorrelationIdName, xCorrelationId);

export default config => {
  const configUpdate = config;
  configUpdate.headers[xCorrelationIdName] = xCorrelationId;
  configUpdate.headers[xRequestIdName] = uuidv4();
  configUpdate.headers[fullstoryUrlHeader] = clientHelper.getFullStoryUrl();

  if ((defaultFeatureFlags[featureFlagsNames.ENABLE_SAGAS_CANARY_DEPLOYMENT]
    || sessionStorage.getItem('sagasCanaryDeployment') === 'true')
    && config.url.includes(baseSagasEndpoint)) {
    configUpdate.headers[sagasCanaryHeader] = '1';
  }
  if (sessionStorage.getItem('synthetic') === 'true') {
    configUpdate.headers[syntheticHeader] = 'true';
  }

  return configUpdate;
};
