import { ACTIONS } from '../types';
import STATUS from '../../../globalStatuses';

export const degreeTypeReductions = {
  [ACTIONS.REQUEST_DEGREETYPE]: state => ({
    ...state,
    degreeTypesStatus: {
      ...state.degreeTypesStatus,
      getStatus: STATUS.FETCHING,
      errorData: null,
    },
  }),
  [ACTIONS.RECEIVE_DEGREETYPE]: (state, action) => ({
    ...state,
    degreeTypes: action.payload.degreeTypes,
    degreeTypesStatus: {
      ...state.degreeTypesStatus,
      getStatus: STATUS.FETCHED,
    },
  }),
  [ACTIONS.RECEIVE_DEGREETYPE_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    degreeTypesStatus: {
      ...state.degreeTypesStatus,
      getStatus: STATUS.ERROR,
      errorData: action.error,
    },
  }),
};

export default degreeTypeReductions;
