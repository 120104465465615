import { ACTIONS } from '../types';
import STATUS from '../../../globalStatuses';

export const administrativeTerritoryReductions = {
  [ACTIONS.REQUEST_ADMINISTRATIVE_TERRITORIES]: state => ({
    ...state,
    administrativeTerritoriesStatus: {
      ...state.administrativeTerritoriesStatus,
      getStatus: STATUS.FETCHING,
      errorData: null,
    },
  }),
  [ACTIONS.RECEIVE_ADMINISTRATIVE_TERRITORIES]: (state, action) => ({
    ...state,
    administrativeTerritories: {
      administrativeTerritoriesUS: action.payload.administrativeTerritoriesUS,
    },
    administrativeTerritoriesStatus: {
      ...state.administrativeTerritoriesStatus,
      getStatus: STATUS.FETCHED,
    },
  }),
  [ACTIONS.RECEIVE_ADMINISTRATIVE_TERRITORIES_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    administrativeTerritoriesStatus: {
      ...state.administrativeTerritoriesStatus,
      getStatus: STATUS.ERROR,
      errorData: action.error,
    },
  }),
};

export default administrativeTerritoryReductions;
