import { errorToMessage } from '../../../../errorMessaging';
import STATUS from '../../../globalStatuses';
import { ACTIONS } from './types';

function associations(
  state = {
    associations: {},
  },
  action,
) {
  switch (action.type) {
    case ACTIONS.CLEAR_PERSON_ASSOCIATIONS_MODIFY_STATUS:
      return {
        ...state,
        personAssociationsStatus: {
          ...state.personAssociationsStatus,
          modifyStatus: STATUS.UNFETCHED,
        },
      };
    // GET Person Associations
    case ACTIONS.REQUEST_PERSON_ASSOCIATIONS:
      return {
        ...state,
        personAssociationsStatus: {
          ...state.personAssociationsStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_PERSON_ASSOCIATIONS:
      return {
        ...state,
        associations: action.associations,
        personAssociationsStatus: {
          ...state.personAssociationsStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_PERSON_ASSOCIATIONS_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personAssociationsStatus: {
          ...state.personAssociationsStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // Update Person Associations
    case ACTIONS.REQUEST_UPDATE_PERSON_ASSOCIATIONS:
      return {
        ...state,
        personAssociationsStatus: {
          ...state.personAssociationsStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_UPDATE_PERSON_ASSOCIATIONS:
      return {
        ...state,
        associations: action.associations,
        personAssociationsStatus: {
          ...state.personAssociationsStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_UPDATE_PERSON_ASSOCIATIONS_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personAssociationsStatus: {
          ...state.personAssociationsStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    default:
      return state;
  }
}

export default associations;
