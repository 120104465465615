import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import makeStyles from './styles';

export default function SkipStepModal({
  isVisible,
  setIsVisible,
  to,
  setSubmittingPage,
  updatePreferenceSkipStep,
  setRedirect,
}) {
  const classes = makeStyles();

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="edit-application-dialog-title"
        open={isVisible}
      >
        <DialogTitle id="edit-application-dialog-title">
          Do you want to skip this page?
        </DialogTitle>
        <MuiDialogContent>
          <Typography className={classes.messageText}>
            If you choose to skip this page, you will lose any information you have entered and this
            section will be removed from your Financial Plan estimate. To re-enter your info, you
            will need to return to the first page of the financial section and re-select the option.
          </Typography>
        </MuiDialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            id="skipSetpButtonCancel"
            color="secondary"
            aria-label="No, cancel"
            className="mainBtn confirmBtn"
            onClick={handleClose}
            disableFocusRipple
            disableRipple
          >
            No, cancel
          </Button>
          <Button
            id="skipSetpButton"
            color="primary"
            aria-label="Yes, skip this page"
            component="a"
            role="link"
            className="mainBtn confirmBtn"
            disabled={false}
            onClick={() => {
              updatePreferenceSkipStep();
              setSubmittingPage(true);
              setRedirect(true);
              window.scrollTo(0, 0);
              handleClose();
            }}
            to={to}
            setSubmittingPage={setSubmittingPage}
            autoFocus
            disableFocusRipple
            disableRipple
          >
            Yes, skip this page
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

SkipStepModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
  setSubmittingPage: PropTypes.bool.isRequired,
  updatePreferenceSkipStep: PropTypes.func.isRequired,
  setRedirect: PropTypes.bool.isRequired,
};
