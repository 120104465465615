import { ACTIONS } from '../types';
import STATUS from '../../../globalStatuses';

export const testProgressReductions = {
  [ACTIONS.REQUEST_TESTPROGTYPE]: state => ({
    ...state,
    testProgTypesStatus: {
      ...state.testProgTypesStatus,
      getStatus: STATUS.FETCHING,
      errorData: null,
    },
  }),
  [ACTIONS.RECEIVE_TESTPROGTYPE]: (state, action) => ({
    ...state,
    testProgTypes: action.payload.testProgTypes,
    testProgTypesStatus: {
      ...state.testProgTypesStatus,
      getStatus: STATUS.FETCHED,
    },
  }),
  [ACTIONS.RECEIVE_TESTPROGTYPE_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    testProgTypesStatus: {
      ...state.testProgTypesStatus,
      getStatus: STATUS.ERROR,
      errorData: action.error,
    },
  }),
};

export default testProgressReductions;
