export const ACTIONS = {
  // SSN
  REQUEST_SSN: 'REQUEST_SSN',
  RECEIVE_SSN: 'RECEIVE_SSN',
  REQUEST_SSN_ERROR: 'REQUEST_SSN_ERROR',
  REQUEST_POST_SSN: 'REQUEST_POST_SSN',
  RECEIVE_POST_SSN: 'RECEIVE_POST_SSN',
  REQUEST_POST_SSN_ERROR: 'REQUEST_POST_SSN_ERROR',
  REQUEST_PUT_SSN: 'REQUEST_PUT_SSN',
  RECEIVE_PUT_SSN: 'RECEIVE_PUT_SSN',
  REQUEST_PUT_SSN_ERROR: 'REQUEST_PUT_SSN_ERROR',
  REQUEST_DELETE_SSN: 'REQUEST_DELETE_SSN',
  RECEIVE_DELETE_SSN: 'RECEIVE_DELETE_SSN',
  REQUEST_DELETE_SSN_ERROR: 'REQUEST_DELETE_SSN_ERROR',
  CLEAR_SSN_MODIFY_STATUS: 'CLEAR_SSN_MODIFY_STATUS',
  RE_OPT: 'RE_OPT',

  // CREDIT BALANCE
  REQUEST_CREDIT_BALANCE: 'REQUEST_CREDIT_BALANCE',
  RECEIVE_CREDIT_BALANCE: 'RECEIVE_CREDIT_BALANCE',
  REQUEST_CREDIT_BALANCE_ERROR: 'REQUEST_CREDIT_BALANCE_ERROR',
  REQUEST_POST_CREDIT_BALANCE: 'REQUEST_POST_CREDIT_BALANCE',
  RECEIVE_POST_CREDIT_BALANCE: 'RECEIVE_POST_CREDIT_BALANCE',
  REQUEST_POST_CREDIT_BALANCE_ERROR: 'REQUEST_POST_CREDIT_BALANCE_ERROR',
  REQUEST_PUT_CREDIT_BALANCE: 'REQUEST_PUT_CREDIT_BALANCE',
  RECEIVE_PUT_CREDIT_BALANCE: 'RECEIVE_PUT_CREDIT_BALANCE',
  REQUEST_PUT_CREDIT_BALANCE_ERROR: 'REQUEST_PUT_CREDIT_BALANCE_ERROR',
  REQUEST_DELETE_CREDIT_BALANCE: 'REQUEST_DELETE_CREDIT_BALANCE',
  RECEIVE_DELETE_CREDIT_BALANCE: 'RECEIVE_DELETE_CREDIT_BALANCE',
  REQUEST_DELETE_CREDIT_BALANCE_ERROR: 'REQUEST_DELETE_CREDIT_BALANCE_ERROR',
  CLEAR_CREDIT_BALANCE_MODIFY_STATUS: 'CLEAR_CREDIT_BALANCE_MODIFY_STATUS',
};

export default ACTIONS;
