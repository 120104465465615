export const titleCaseWord = input => {
  if (input === null || input === undefined) {
    return input;
  }
  let strInput = input.toString();
  strInput = strInput[0].toUpperCase() + strInput.slice(1);
  return strInput;
};

export default titleCaseWord;
