import { errorToMessage } from '../../../../errorMessaging';
import STATUS from '../../../globalStatuses';
import { ACTIONS } from './types';

function calculatorSummary(
  state = {
    results: {
      detail: {
        value: {
          costOfAttendance: {},
          discounts: {},
          deductions: {},
          benefits: {},
          contributions: {},
          loanRepayment: {},
          yearOneEstimates: {},
        },
      },
    },
    calculatorSummaryStatus: {
      getStatus: STATUS.UNFETCHED,
      modifyStatus: STATUS.UNFETCHED,
      errorData: null,
    },
  },
  action,
) {
  switch (action.type) {
    // INITIAL GET CALCULATOR SUMMARY
    case ACTIONS.REQUEST_INITIAL_POST_CALCULATOR_SUMMARY:
      return {
        ...state,
        calculatorSummaryStatus: {
          ...state.calculatorSummaryStatus,
          initialLoadStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_INITIAL_POST_CALCULATOR_SUMMARY:
      return {
        ...state,
        results: action.resultsFields,
        calculatorSummaryStatus: {
          ...state.calculatorSummaryStatus,
          initialLoadStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_INITIAL_POST_CALCULATOR_SUMMARY_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        calculatorSummaryStatus: {
          ...state.calculatorSummaryStatus,
          initialLoadStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // POST CALCULATOR SUMMARY
    case ACTIONS.REQUEST_POST_CALCULATOR_SUMMARY:
      return {
        ...state,
        calculatorSummaryStatus: {
          ...state.calculatorSummaryStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_POST_CALCULATOR_SUMMARY:
      return {
        ...state,
        results: action.resultsFields,
        calculatorSummaryStatus: {
          ...state.calculatorSummaryStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_POST_CALCULATOR_SUMMARY_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        calculatorSummaryStatus: {
          ...state.calculatorSummaryStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    default:
      return state;
  }
}

export default calculatorSummary;
