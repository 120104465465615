import { invalidateTokenIfNeeded, fetchTokenIfNeeded } from '../../store/domains/csrf/actions';

const invalidCSRFTokenHeaderName = 'x-ctknbad';

export default (error, dispatch, client) => {
  const tokenInvalidHeader = ((error.response || {}).headers || {})[invalidCSRFTokenHeaderName];
  if (error.config && error.response && error.response.status === 403 && tokenInvalidHeader) {
    dispatch(invalidateTokenIfNeeded());
    return dispatch(fetchTokenIfNeeded()).then(() => client.request(error.config));
  }
  return Promise.reject(error);
};
