import { REQUEST_USER_SSO, REQUEST_USER_SSO_FINISHED } from './types';
import STATUS from '../../globalStatuses';

const cas = (state = {
  ssoAttempts: 0,
  casStatus: {
    getStatus: STATUS.UNFETCHED,
    modifyStatus: STATUS.UNFETCHED,
  },
}, action) => {
  switch (action.type) {
    case REQUEST_USER_SSO:
      return {
        ...state,
        ssoAttempts: state.ssoAttempts + 1,
        casStatus: {
          ...state.casStatus,
          getStatus: STATUS.FETCHING,
        },
      };
    case REQUEST_USER_SSO_FINISHED:
      return {
        ...state,
        casStatus: {
          ...state.casStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    // TODO: Handle error case?
    default:
      return state;
  }
};

export default cas;
