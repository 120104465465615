export const FormFields = {
  password: {
    id: 'password',
    fieldName: 'password',
    label: 'Password',
    ariaDescribedBy: 'password-helper-text',
    required: true,
  },
};

export const DefaultValues = {
  [FormFields.password.fieldName]: '',
};
