import { ACTIONS } from './types';
import GenesysChatStates from './helpers/GenesysChatStates';
import GenesysCobrowseStates from './helpers/GenesysCobrowseStates';

function genesys(
  state = {
    chatState: null,
    chatButtonEnabled: false,
    chatEventListenerSet: false,
    cobrowseState: null,
    textState: null,
    textEventListenerSet: false,
    error: '',
  }, { type, payload },
) {
  switch (type) {
    case ACTIONS.RESOLVE_CHAT_STATE:
      return { ...state, chatState: payload.state };
    case ACTIONS.REQUEST_SUBSCRIBE_TO_CHAT_STATE:
      return { ...state, chatEventListenerSet: true };
    case ACTIONS.REQUEST_UNSUBSCRIBE_TO_CHAT_STATE:
      return { ...state, chatEventListenerSet: false };
    case ACTIONS.REQUEST_CHAT_OPEN:
      return { ...state, chatState: GenesysChatStates.OPENED };
    case ACTIONS.REQUEST_CHAT_CLOSED:
      return { ...state, chatState: GenesysChatStates.CLOSED };
    case ACTIONS.REQUEST_CHAT_ERROR:
      return { ...state, error: payload.error, chatState: GenesysChatStates.REJECTED };
    case ACTIONS.REQUEST_CHAT_BUTTON_ENABLED:
      return { ...state, chatButtonEnabled: payload.chatButtonEnabled };
    case ACTIONS.REQUEST_SUBSCRIBE_TO_COBROWSE_STATE:
      return {
        ...state,
        cobrowseEventListenerSet: true,
      };
    case ACTIONS.REQUEST_UNSUBSCRIBE_TO_COBROWSE_STATE:
      return {
        ...state,
        cobrowseEventListenerSet: false,
      };
    case ACTIONS.REQUEST_COBROWSE_STATE_ERROR:
      return {
        ...state,
        error: payload.error,
        cobrowseState: GenesysCobrowseStates.ERROR,
      };
    case ACTIONS.RESOLVE_COBROWSE_STATE:
      return {
        ...state,
        cobrowseState: payload.state,
      };
    // Text
    case ACTIONS.RESOLVE_TEXT_STATE:
      return { ...state, textState: payload.state };
    case ACTIONS.REQUEST_SUBSCRIBE_TO_TEXT_STATE:
      return { ...state, textEventListenerSet: true };
    case ACTIONS.REQUEST_UNSUBSCRIBE_TO_TEXT_STATE:
      return { ...state, textEventListenerSet: false };
    case ACTIONS.REQUEST_TEXT_OPEN:
      return { ...state, textState: GenesysChatStates.OPENED };
    case ACTIONS.REQUEST_TEXT_CLOSED:
      return { ...state, textState: GenesysChatStates.CLOSED };
    case ACTIONS.REQUEST_TEXT_ERROR:
      return { ...state, error: payload.error, textState: GenesysChatStates.REJECTED };
    case ACTIONS.REQUEST_TEXT_BUTTON_ENABLED:
      return { ...state, textButtonEnabled: payload.textButtonEnabled };

    default:
      return state;
  }
}

export default genesys;
