export default {
  READY: 'ready',
  OPENED: 'opened',
  STARTED: 'started',
  SUBMITTED: 'submitted',
  REJECTED: 'rejected',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  CLOSED: 'closed',
  MINIMIZED: 'minimized',
  UNMINIMIZED: 'unminimized',
  MESSAGE_ADDED: 'messageAdded',
};
