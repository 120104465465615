import { ACTIONS } from '../types';
import STATUS from '../../../globalStatuses';

export const visaTypeReductions = {
  [ACTIONS.REQUEST_VISATYPES]: state => ({
    ...state,
    visaTypesStatus: {
      ...state.visaTypesStatus,
      getStatus: STATUS.FETCHING,
      errorData: null,
    },
  }),
  [ACTIONS.RECEIVE_VISATYPES]: (state, action) => ({
    ...state,
    visaTypes: action.payload,
    visaTypesStatus: {
      ...state.visaTypesStatus,
      getStatus: STATUS.FETCHED,
    },
  }),
  [ACTIONS.RECEIVE_VISATYPES_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    visaTypesStatus: {
      ...state.visaTypesStatus,
      getStatus: STATUS.ERROR,
      errorData: action.error,
    },
  }),
};

export default visaTypeReductions;
