import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Typography } from '@material-ui/core';
import ConfirmRadio from '../../../base/radio/ConfirmRadio';
import FormFields from './Maintenance.fields';

export { default as maintenanceSchema } from './Maintenance.schema';
export { DefaultValues as maintenanceDefaults, default as maintenanceFormFields } from './Maintenance.fields';

export const Maintenance = ({ classes }) => {
  const { control } = useFormContext();
  return (
    <>
      <Typography
        className={classes.note}
      >
        Maintenance Page Status:
      </Typography>
      <ConfirmRadio
        control={control}
        name={FormFields.maintenance.name}
        id={FormFields.maintenance.id}
        trueLabel={FormFields.maintenance.trueLabel}
        falseLabel={FormFields.maintenance.falseLabel}
        ariaDescribedBy={FormFields.maintenance.ariaDescribedBy}
      />
    </>
  );
};

Maintenance.propTypes = {
  classes: PropTypes.shape({}),
};
Maintenance.defaultProps = {
  classes: {},
};

export default Maintenance;
