import retryCallIfNeeded from '../../../restCalls';

export default function calculatorInputService(client) {
  const postCalculatorInput = async (payload) => retryCallIfNeeded(
    client,
    () => client.post('/api/financialaid/rules/v1/stateless/financialplancalculator/create', payload),
  );
  const getCalculatorInput = async financialPlanId => retryCallIfNeeded(
    client,
    () => client.get(`/api/financialaid/rules/v1/stateless/financialplancalculator/inputsessions/${financialPlanId}`),
  );
  const putCalculatorInput = async (financialPlanId, payload) => retryCallIfNeeded(
    client,
    () => client.put(`/api/financialaid/rules/v1/stateless/financialplancalculator/inputsessions/${financialPlanId}`, payload),
  );
  const patchCalculatorInput = async (financialPlanId, payload) => retryCallIfNeeded(
    client,
    () => client.patch(`/api/financialaid/rules/v1/stateless/financialplancalculator/inputsessions/${financialPlanId}`, payload),
  );

  return {
    postCalculatorInput,
    getCalculatorInput,
    putCalculatorInput,
    patchCalculatorInput,
  };
}
