export default theme => ({
  note: {
    fontSize: theme.spacing(1.3),
    fontStyle: 'italic',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.spacing(1.5),
    },
  },

  main: {
    minHeight: theme.spacing(60),
    margin: `${theme.header.height} auto 5rem`,
    padding: theme.spacing(2),
    '@media(orientation: landscape)': {
      marginTop: theme.spacing(5.8),
    },
    [theme.breakpoints.up('lg')]: {
      width: theme.breakpoints.values.lg,
    },
  },
  pageWrapper: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(4)}`,
    },
  },
  headerContainer: {
    minHeight: theme.header.height,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: theme.spacing(1.6),
    paddingRight: theme.spacing(1.6),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2.4),
      paddingRight: theme.spacing(2.4),
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.spacing(144),
    },
  },
  logoWrapper: {
    flexGrow: 1,
    marginTop: theme.spacing(-0.6),
  },
  logoIcon: {
    height: theme.spacing(6),
  },
  icon: {
    paddingLeft: theme.spacing(1),
    width: theme.spacing(8),
    marginBottom: theme.spacing(0.5),
  },
  pgHeader: {
    marginBottom: theme.spacing(2),
  },
  headerIcon: {
    paddingRight: theme.spacing(2),
    flex: 'none',
  },
  headerHeading: {
    alignSelf: 'center',
    '& h1': {
      marginBottom: 0,
    },
  },
  tagline: {
    marginTop: theme.spacing(2),
  },
  pageNav: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  topNav: {
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      padding: '2.6rem 0 0 4.8rem',
    },
  },
});
