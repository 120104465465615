import { ACTIONS } from './types';
import STATUS from '../../../globalStatuses';

function secondaryEd(
  state = {},
  action,
) {
  switch (action.type) {
    case ACTIONS.REQUEST_SECONDARY_ED:
      return {
        ...state,
        secondaryEdStatus: {
          ...state.secondaryEdStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_SECONDARY_ED:
      return {
        ...state,
        secondaryEd: action.secondaryEd,
        secondaryEdStatus: {
          ...state.secondaryEdStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_SECONDARY_ED_ERROR:
      return {
        ...state,
        secondaryEdStatus: {
          ...state.secondaryEdStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.CLEAR_SECONDARY_ED_GET_STATUS:
      return {
        ...state,
        secondaryEdStatus: {
          ...state.secondaryEdStatus,
          getStatus: STATUS.UNFETCHED,
          errorData: null,
        },
      };
    case ACTIONS.REQUEST_SAVE_SECONDARY_ED:
      return {
        ...state,
        secondaryEdStatus: {
          ...state.secondaryEdStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_SAVE_SECONDARY_ED:
      return {
        ...state,
        secondaryEd: action.secondaryEd,
        secondaryEdStatus: {
          ...state.secondaryEdStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_SAVE_SECONDARY_ED_ERROR:
      return {
        ...state,
        secondaryEdStatus: {
          ...state.secondaryEdStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // DELETE Secondary Ed
    case ACTIONS.REQUEST_DELETE_SECONDARY_ED:
      return {
        ...state,
        secondaryEd: [],
        secondaryEdStatus: {
          ...state.secondaryEdStatus,
          getStatus: STATUS.UNFETCHED,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_DELETE_SECONDARY_ED:
      return {
        ...state,
        secondaryEdStatus: {
          ...state.secondaryEdStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_DELETE_SECONDARY_ED_ERROR:
      return {
        ...state,
        error: action.error,
        secondaryEdStatus: {
          ...state.secondaryEdStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.REQUEST_SECONDARY_ED_DISTRICT_BY_COUNTRY:
      return {
        ...state,
        secondaryEdOtherByDistrictStatus: {
          ...state.secondaryEdOtherByDistrictStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_SECONDARY_ED_DISTRICT_BY_COUNTRY:
      return {
        ...state,
        secondaryEdOtherByDistrict: action.secondaryEdOtherByDistrict,
        secondaryEdOtherByDistrictStatus: {
          ...state.secondaryEdOtherByDistrictStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_SECONDARY_ED_DISTRICT_BY_COUNTRY_ERROR:
      return {
        ...state,
        secondaryEdOtherByDistrictStatus: {
          ...state.secondaryEdOtherByDistrictStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.RESET_SECONDARY_ED_DISTRICT:
      return {
        ...state,
        secondaryEdOtherByDistrict: [],
        secondaryEdOtherByDistrictStatus: {
          ...state.secondaryEdOtherByDistrictStatus,
          getStatus: STATUS.UNFETCHED,
        },
      };
    default:
      return state;
  }
}

export default secondaryEd;
