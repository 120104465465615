import { ACTIONS } from '../types';
import STATUS from '../../../globalStatuses';

export const militaryStateReductions = {
  [ACTIONS.REQUEST_MILITARY_STATES]: state => ({
    ...state,
    militaryStatesStatus: {
      ...state.militaryStatesStatus,
      getStatus: STATUS.FETCHING,
      errorData: null,
    },
  }),
  [ACTIONS.RECEIVE_MILITARY_STATES]: (state, action) => ({
    ...state,
    militaryStates: {
      militaryStatesUS: action.payload.militaryStatesUS,
    },
    militaryStatesStatus: {
      ...state.militaryStatesStatus,
      getStatus: STATUS.FETCHED,
    },
  }),
  [ACTIONS.RECEIVE_MILITARY_STATES_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    militaryStatesStatus: {
      ...state.militaryStatesStatus,
      getStatus: STATUS.ERROR,
      errorData: action.error,
    },
  }),
};

export default militaryStateReductions;
