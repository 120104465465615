import {
  REQUEST_POST_USER, RECEIVE_POST_USER, REQUEST_POST_USER_ERROR,
  REQUEST_TOKEN, RECEIVE_TOKEN, REQUEST_TOKEN_ERROR,
  REQUEST_PUT_TOKEN, RECEIVE_PUT_TOKEN, REQUEST_PUT_TOKEN_ERROR,
  REQUEST_POST_DECRYPT_TOKEN, RECEIVE_POST_DECRYPT_TOKEN, REQUEST_POST_DECRYPT_TOKEN_ERROR,
} from './types';
import STATUS from '../../globalStatuses';
import { errorToMessage } from '../../../errorMessaging';


function user(
  state = { user: {} },
  action,
) {
  switch (action.type) {
    // POST USER
    case REQUEST_POST_USER:
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_POST_USER:
      // Todo: Format the data better
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case REQUEST_POST_USER_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        userStatus: {
          ...state.userStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case REQUEST_TOKEN:
      return {
        ...state,
        userStatus: {
          ...state,
          tokenStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_TOKEN:
      return {
        ...state,
        token: action.payload,
        tokenStatus: STATUS.FETCHED,
      };
    case REQUEST_TOKEN_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        userStatus: {
          ...state.userStatus,
          tokenStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case REQUEST_PUT_TOKEN:
      return {
        ...state,
        userStatus: {
          ...state,
          tokenStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_PUT_TOKEN:
      return {
        ...state,
        token: action.payload,
        tokenStatus: STATUS.UPDATED,
      };
    case REQUEST_PUT_TOKEN_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        userStatus: {
          ...state.userStatus,
          tokenStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // POST DECRYPT TOKEN
    case REQUEST_POST_DECRYPT_TOKEN:
      return {
        ...state,
        decryptVerifyTokenStatus: {
          ...state.decryptVerifyTokenStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_POST_DECRYPT_TOKEN:
      return {
        ...state,
        verifyTokenDecryptedValue: action.value,
        decryptVerifyTokenStatus: {
          ...state.decryptVerifyTokenStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case REQUEST_POST_DECRYPT_TOKEN_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        decryptVerifyTokenStatus: {
          ...state.decryptVerifyTokenStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    default:
      return state;
  }
}

export default user;
