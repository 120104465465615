export { FormFields as passwordField } from '../core/password/Password.fields';
export { FormFields as usernameField } from '../core/username/Username.fields';

export const FormFields = {
  login: {
    id: 'login',
    fieldName: 'admin-login',
    label: 'Adminstrator Login',
    ariaDescribedBy: 'Adminstrator Login',
    required: true,
  },
};
