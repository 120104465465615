import retryCallIfNeeded from '../../../restCalls';

const baseEndpointUrl = '/api/document/v1';
const phormBaseEndpointUrl = '/api/documents/v1';

/*
  URLs should be the resource path the proxy via node
  will forward to MS otherwise it will go to json-server
  refer to the task service

  Https://api.st.uopx.io/api/document/v1/agreements
*/
export default function DocumentService(client) {
  const getFinancialPlanEstimate = async applicationId => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/financialPlanSummaryPDF/${applicationId}`, { responseType: 'arraybuffer', headers: { accept: 'application/pdf' } }));

  const getAgreementDocsByPersonIdAsync = async applicationId => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/agreements?type=formNames&applicationId=${applicationId}&sourceSystem=NAE&companyId=1&packetName=ESIGNABLE_PACKET`));

  const getAgreementDocsByApplicationIdAsync = async (applicationId, packetName) => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/agreements?type=formNames&applicationId=${applicationId}&sourceSystem=NAE&companyId=1&packetName=${packetName}`));

  const getGeneralAgreementDocsByApplicationIdAsync = async (applicationId, packetName) => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/agreements?type=formNames&applicationId=${applicationId}&sourceSystem=NAE&companyId=1&packetName=${packetName}`));

  // Call to get actual document contents for one or more documents by the packet or document name
  const getAgreementPackageByApplicationIdAsync = async (applicationId, packetName) => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/formOrPacket?applicationId=${applicationId}&formOrPacketName=${packetName}`));

  // Call to get a doc by packetName (e.g. criminal conviction)
  const getDocByApplicationIdAndPacketNameAsync = async (applicationId, packetName) => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/formOrPacket?applicationId=${applicationId}&formOrPacketName=${packetName}`));

  const postESign = payload => retryCallIfNeeded(client, () => client.post(`${baseEndpointUrl}/agreements`, payload));

  const postSubmitTasks = async payload => retryCallIfNeeded(client, () => client.post(`${baseEndpointUrl}/submitTasks`, payload));

  // call to post document service for agreements signed
  const postAgreements = async payload => retryCallIfNeeded(client, () => client.post(`${baseEndpointUrl}/sign`, payload));
  // call to get the list of document names
  const getAgreements = async applicationId => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/sign?applicationId=${applicationId}&sourceSystem=NAE&packetName=BECOME_ALL_ACKNOWLEDGEMENTS`));

  // Additional signed documents
  const postSignedDocument = async payload => retryCallIfNeeded(client, () => client.post(`${baseEndpointUrl}/signDocument`, payload));

  const deleteOptionalForms = async payload => retryCallIfNeeded(client, () => client.post(`${baseEndpointUrl}/cleanupForms`, payload));

  const uploadDocumentsToSAT = async payload => retryCallIfNeeded(client, () => client.post(`${baseEndpointUrl}/attachments`, payload));

  const uploadDocumentsToEXP = async payload => retryCallIfNeeded(client, () => client.post(`${baseEndpointUrl}/postDocumentToExp`, payload));

  // for docusign
  const createTaskAsync = async (personId, taskTemplate) => {
    const url = `${baseEndpointUrl}/task/${personId}`;
    return retryCallIfNeeded(client, () => client.post(url, taskTemplate));
  };
  const deleteTaskAsync = async (personId, taskId) => {
    const url = `${baseEndpointUrl}/task/${personId}/${taskId}`;
    return retryCallIfNeeded(client, () => client.delete(url));
  };
  // for docusign
  // const postEnvelopeCreation = async payload => retryCallIfNeeded(client,
  // () => client.post(`${phormBaseEndpointUrl}/phorms/create-envelope/multi-template`, payload));
  const postEnvelopeCreation = async payload => client.post(`${phormBaseEndpointUrl}/phorms/create-envelope/multi-template`, payload);
  const deleteEnvelope = async envelopeId => retryCallIfNeeded(client, () => client.delete(`${phormBaseEndpointUrl}/phorms/void-envelope/${envelopeId}?accountName=admiss`));
  // const postDocusigningUrl = async (envelopeId, payload) => retryCallIfNeeded(client,
  // () => client.post(`${phormBaseEndpointUrl}/phorms/create-session/${envelopeId}`, payload));
  const postDocusigningUrl = async (envelopeId, payload) => client.post(`${phormBaseEndpointUrl}/phorms/create-session/${envelopeId}`, payload);
  const getDocusigningStatus = async (envelopeId, payload) => retryCallIfNeeded(client, () => client.get(`${phormBaseEndpointUrl}/phorms/nimda/get-envelope-metadata/${envelopeId}?accountName=admiss&fromDocuSign=true`));
  const getPreviouslySignedEnrollmentDocument = async (irn, program, forPrincipalName) => retryCallIfNeeded(client, () => client.get(`${phormBaseEndpointUrl}/documents/enrollAgreement?irn=${irn}&program=${program}&forPrincipalName=${forPrincipalName}`));

  return {
    getFinancialPlanEstimate,
    getAgreementDocsByPersonIdAsync,
    getAgreementDocsByApplicationIdAsync,
    getGeneralAgreementDocsByApplicationIdAsync,
    getAgreementPackageByApplicationIdAsync,
    postESign,
    postSubmitTasks,
    postAgreements,
    getAgreements,
    getDocByApplicationIdAndPacketNameAsync,
    uploadDocumentsToSAT,
    uploadDocumentsToEXP,
    postEnvelopeCreation,
    deleteEnvelope,
    postDocusigningUrl,
    getDocusigningStatus,
    postSignedDocument,
    createTaskAsync,
    deleteTaskAsync,
    deleteOptionalForms,
    getPreviouslySignedEnrollmentDocument,
  };
}
