import retryCallIfNeeded from '../../../restCalls';

const preferencesEndpoint = '/api/preferences/v1';

export default client => {
  const getPreferenceAsync = async (personId, preferenceName) => retryCallIfNeeded(
    client,
    () => client.get(`${preferencesEndpoint}/preferences?linkId=${personId}&name=${preferenceName}`),
  );

  const postPreferenceAsync = async payload => retryCallIfNeeded(
    client,
    () => client.post(`${preferencesEndpoint}/preferences`, payload),
  );

  const putPreferenceAsync = async (preferenceId, payload) => {
    const config = {
      headers: {
        'If-Match': 'Default', // Will be overwritten and retried by resourceConflict interceptor
      },
    };
    return retryCallIfNeeded(
      client,
      () => client.put(
        `${preferencesEndpoint}/preferences/${preferenceId}`,
        payload,
        config,
      ),
    );
  };

  return {
    getPreferenceAsync,
    postPreferenceAsync,
    putPreferenceAsync,
  };
};
