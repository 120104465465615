import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Tooltip, useMediaQuery, Grow,
  ClickAwayListener,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  PriorityHigh, Info,
} from '@material-ui/icons';

const FormControlTooltip = ({
  tooltipSeverity,
  tooltipMessage,
  interactive,
  children,
}) => {
  const [tooltipToRight, setTooltipToRight] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const desktopView = useMediaQuery('(min-width:1105px)');

  useEffect(() => {
    if (desktopView) {
      setTooltipToRight(true);
    } else {
      setTooltipToRight(false);
    }
  }, [desktopView]);

  const [infoStyle, setInfoStyle] = useState(false);
  const [warningStyle, setWarningStyle] = useState(false);

  useEffect(() => {
    if (tooltipSeverity === 'info') {
      setInfoStyle(true);
    } else {
      setInfoStyle(false);
    }
    if (tooltipSeverity === 'warning') {
      setWarningStyle(true);
    } else {
      setWarningStyle(false);
    }
  }, [tooltipSeverity]);

  const tooltipContent = () => (
    <Grid container>
      <Grid item xs={1} className="FctIconGrid">
        {infoStyle ? (<Info fontSize="inherit" />) : null}
        {warningStyle ? (<PriorityHigh fontSize="inherit" />) : null}
      </Grid>
      <Grid item xs={11} className="FctMessageGrid">
        {tooltipMessage}
      </Grid>
    </Grid>
  );

  const infoTooltipStyles = makeStyles(theme => ({
    arrow: {
      color: theme.palette.background.alertBorder.info,
      fontSize: theme.spacing(3),
    },
    tooltip: {
      backgroundColor: theme.palette.background.alert.info,
      color: theme.palette.background.alertText.info,
      border: `1px solid ${theme.palette.background.alertBorder.info}`,
      maxWidth: 'none',
      boxShadow: 0,
      fontSize: theme.spacing(1.6),
      lineHeight: 1.4,
      padding: theme.spacing(1.4),
      fontWeight: 400,
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(2.2),
        marginRight: theme.spacing(1.8),
        marginBottom: theme.spacing(1.4),
        marginTop: theme.spacing(1.5),
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: theme.spacing(50),
      },
      '& svg': {
        color: theme.palette.background.alertBorder.info,
      },
    },
  }));
  const warningTooltipStyles = makeStyles(theme => ({
    arrow: {
      color: theme.palette.background.alertBorder.warning,
      fontSize: theme.spacing(3),
    },
    tooltip: {
      backgroundColor: theme.palette.background.alert.warning,
      color: theme.palette.background.alertText.warning,
      border: `1px solid ${theme.palette.background.alertBorder.warning}`,
      maxWidth: 'none',
      boxShadow: 0,
      fontSize: theme.spacing(1.6),
      lineHeight: 1.4,
      padding: theme.spacing(1.4),
      fontWeight: 400,
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(2.2),
        marginRight: theme.spacing(1.8),
        marginBottom: theme.spacing(1.4),
        marginTop: theme.spacing(1.5),
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: theme.spacing(50),
      },
      '& svg': {
        color: theme.palette.background.alertBorder.warning,
      },
    },
  }));
  const infoStyles = infoTooltipStyles();
  const warningStyles = warningTooltipStyles();

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Tooltip
        id={`FormControlTooltip-${tooltipSeverity}`}
        data-cy="FormControlTooltip-trigger"
        title={tooltipContent()}
        arrow
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        placement={tooltipToRight ? 'right' : 'top'}
        enterTouchDelay={0}
        classes={infoStyle ? infoStyles : warningStyles}
        disableHoverListener
        TransitionComponent={Grow}
        TransitionProps={{ timeout: 250 }}
        interactive={interactive}
        leaveTouchDelay={8000}
      >
        {children}
      </Tooltip>
    </ClickAwayListener>
  );
};

FormControlTooltip.defaultProps = {
  tooltipSeverity: 'info',
  interactive: false,
};

FormControlTooltip.propTypes = {
  tooltipSeverity: PropTypes.string,
  tooltipMessage: PropTypes.element.isRequired,
  interactive: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
};

export default FormControlTooltip;
