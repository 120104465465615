import { ACTIONS } from '../types';
import STATUS from '../../../globalStatuses';

export const districtReductions = {
  [ACTIONS.REQUEST_DISTRICTS]: state => ({
    ...state,
    districtsStatus: {
      ...state.districtsStatus,
      getStatus: STATUS.FETCHING,
      errorData: null,
    },
  }),
  [ACTIONS.RECEIVE_DISTRICTS]: (state, action) => ({
    ...state,
    districts: {
      districtsUS: action.payload.districtsUS,
    },
    districtsStatus: {
      ...state.districtsStatus,
      getStatus: STATUS.FETCHED,
    },
  }),
  [ACTIONS.RECEIVE_DISTRICTS_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    districtsStatus: {
      ...state.districtsStatus,
      getStatus: STATUS.ERROR,
      errorData: action.error,
    },
  }),
};

export default districtReductions;
