export default (config, getState) => {
  const { oidc: { user } = { user: {} } } = getState();
  const accessToken = user === null ? null : user.access_token;
  if (accessToken !== null && accessToken !== undefined) {
    const updatedConfig = config;
    updatedConfig.headers.Authorization = `Bearer ${accessToken}`;
    return updatedConfig;
  }
  return config;
};
