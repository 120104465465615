import { errorToMessage } from '../../../errorMessaging';
import STATUS from '../../globalStatuses';
import { ACTIONS } from './types';

function preferences(
  state = {
    preferences: {},
  },
  action,
) {
  switch (action.type) {
    // GET
    case ACTIONS.REQUEST_APP_EXPERIENCE:
      return {
        ...state,
        appExperienceStatus: {
          ...state.appExperienceStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_APP_EXPERIENCE:
      return {
        ...state,
        appExperience: action?.data?.id ? {
          id: action.data.id,
          value: JSON.parse(action.data.value).appExperience,
        } : {},
        appExperienceStatus: {
          ...state.appExperienceStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.RECEIVE_APP_EXPERIENCE_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        appExperienceStatus: {
          ...state.appExperienceStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // POST & PUT
    case ACTIONS.REQUEST_POST_APP_EXPERIENCE:
    case ACTIONS.REQUEST_PUT_APP_EXPERIENCE:
      return {
        ...state,
        appExperienceStatus: {
          ...state.appExperienceStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_POST_APP_EXPERIENCE:
    case ACTIONS.RECEIVE_PUT_APP_EXPERIENCE:
      return {
        ...state,
        appExperienceStatus: {
          ...state.appExperienceStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.RECEIVE_POST_APP_EXPERIENCE_ERROR:
    case ACTIONS.RECEIVE_PUT_APP_EXPERIENCE_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        appExperienceStatus: {
          ...state.appExperienceStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.CLEAR_APP_EXPERIENCE_PATH_MODIFY_STATUS:
      return {
        ...state,
        appExperienceStatus: {
          ...state.appExperienceStatus,
          modifyStatus: STATUS.UNFETCHED,
        },
      };

    // C H O O S E   P A T H
    // GET
    case ACTIONS.REQUEST_CHOOSE_PATH:
      return {
        ...state,
        choosePath: {
          ...state.choosePath,
          choosePathStatus: {
            ...state.choosePath.choosePathStatus,
            getStatus: STATUS.FETCHING,
            errorData: null,
          },
        },
      };
    case ACTIONS.RECEIVE_CHOOSE_PATH:
      return {
        ...state,
        choosePath: {
          id: action?.data?.id || '',
          value: action?.data?.value ? JSON.parse(action.data.value) : {},
          choosePathStatus: {
            ...state.choosePath.choosePathStatus,
            getStatus: STATUS.FETCHED,
            errorData: null,
          },
        },
      };
    case ACTIONS.RECEIVE_CHOOSE_PATH_ERROR:
      return {
        ...state,
        choosePath: {
          id: '',
          value: {},
          choosePathStatus: {
            ...state.choosePath.choosePathStatus,
            getStatus: STATUS.ERROR,
            errorData: action.error,
          },
        },
      };
    // POST & PUT
    case ACTIONS.REQUEST_POST_CHOOSE_PATH:
    case ACTIONS.REQUEST_PUT_CHOOSE_PATH:
      return {
        ...state,
        choosePath: {
          ...state.choosePath,
          choosePathStatus: {
            ...state.choosePath.choosePathStatus,
            modifyStatus: STATUS.UPDATING,
            errorData: null,
          },
        },
      };
    case ACTIONS.RECEIVE_POST_CHOOSE_PATH:
    case ACTIONS.RECEIVE_PUT_CHOOSE_PATH:
      return {
        ...state,
        choosePath: {
          id: action?.data?.id || '',
          value: action?.data?.value ? JSON.parse(action.data.value) : {},
          choosePathStatus: {
            ...state.choosePath.choosePathStatus,
            modifyStatus: STATUS.UPDATED,
            errorData: null,
          },
        },
      };
    case ACTIONS.RECEIVE_POST_CHOOSE_PATH_ERROR:
    case ACTIONS.RECEIVE_PUT_CHOOSE_PATH_ERROR:
      return {
        ...state,
        choosePath: {
          id: '',
          value: {},
          choosePathStatus: {
            ...state.choosePath.choosePathStatus,
            modifyStatus: STATUS.ERROR,
            errorData: action.error,
          },
        },
      };

    // Feature Flags
    // GET
    case ACTIONS.REQUEST_FEATURE_FLAGS:
      return {
        ...state,
        featureFlagsStatus: {
          ...state.featureFlagsStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_FEATURE_FLAGS:
      return {
        ...state,
        featureFlags: action?.data?.id ? {
          id: action.data.id,
          value: JSON.parse(action.data.value),
          createdDate: action?.data?.createdDate,
        } : {},
        featureFlagsStatus: {
          ...state.featureFlagsStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.RECEIVE_FEATURE_FLAGS_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        featureFlagsStatus: {
          ...state.featureFlagsStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // POST & PUT
    case ACTIONS.REQUEST_POST_FEATURE_FLAGS:
    case ACTIONS.REQUEST_PUT_FEATURE_FLAGS:
      return {
        ...state,
        featureFlagsStatus: {
          ...state.featureFlagsStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_POST_FEATURE_FLAGS:
    case ACTIONS.RECEIVE_PUT_FEATURE_FLAGS:
      return {
        ...state,
        featureFlags: action?.data?.id ? {
          id: action.data.id,
          value: JSON.parse(action.data.value),
        } : {},
        featureFlagsStatus: {
          ...state.featureFlagsStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.RECEIVE_POST_FEATURE_FLAGS_ERROR:
    case ACTIONS.RECEIVE_PUT_FEATURE_FLAGS_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        featureFlagsStatus: {
          ...state.featureFlagsStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    case ACTIONS.CLEAR_CHOOSE_PATH_MODIFY_STATUS:
      return {
        ...state,
        choosePath: {
          ...state.choosePath,
          choosePathStatus: {
            ...state.choosePath.choosePathStatus,
            modifyStatus: STATUS.UNFETCHED,
          },
        },
      };
    default:
      return state;
  }
}

export default preferences;
