import {
  REQUEST_POST_ADDRESS, RECEIVE_POST_ADDRESS, RECEIVE_POST_ADDRESS_SET_STATE, REQUEST_ADDRESS_POST_ERROR,
  REQUEST_POST_EMAIL, RECEIVE_POST_EMAIL, RECEIVE_POST_EMAIL_SET_STATE, REQUEST_EMAIL_POST_ERROR,
  REQUEST_POST_PHONE, RECEIVE_POST_PHONE, REQUEST_PHONE_POST_ERROR,
  REQUEST_PHONE_CONSENT, RECEIVE_PHONE_CONSENT, RECEIVE_POST_PHONE_CONSENT_SET_STATE, REQUEST_PHONE_CONSENT_ERROR,
  REQUEST_POST_PHONE_CONSENT, RECEIVE_POST_PHONE_CONSENT, REQUEST_PHONE_CONSENT_POST_ERROR,
  REQUEST_ADDRESS, RECEIVE_ADDRESS, REQUEST_ADDRESS_ERROR,
  REQUEST_EMAIL, RECEIVE_EMAIL, REQUEST_EMAIL_ERROR,
  REQUEST_PHONE, RECEIVE_PHONE, REQUEST_PHONE_ERROR, GET_ZIP_CODE,
  REQUEST_SECONDARY_INSTITUTION_ADDRESS, RECEIVE_SECONDARY_INSTITUTION_ADDRESS,
  REQUEST_SECONDARY_INSTITUTION_ADDRESS_ERROR,
  CLEAR_ADDRESS_MODIFY_STATUS,
  CLEAR_EMAIL_MODIFY_STATUS,
  CLEAR_PHONE_MODIFY_STATUS,
  CLEAR_PHONE_GET_STATUS,
  RECEIVE_EMAIL_CONSENT,
} from './types';
import STATUS from '../../globalStatuses';
import { errorToMessage } from '../../../errorMessaging';

function contact(
  state = { contact: {} },
  action,
) {
  switch (action.type) {
    // GET Address
    case REQUEST_ADDRESS:
      return {
        ...state,
        addressStatus: {
          ...state.addressStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_ADDRESS:
      return {
        ...state,
        address: action.address,
        addressETag: action.addressETag,
        addressStatus: {
          ...state.addressStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_ADDRESS_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        addressStatus: {
          ...state.addressStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // POST Address
    case REQUEST_POST_ADDRESS:
      return {
        ...state,
        addressStatus: {
          ...state.addressStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_POST_ADDRESS:
      return {
        ...state,
        address: action.address,
        addressETag: action.addressETag,
        addressStatus: {
          ...state.addressStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case RECEIVE_POST_ADDRESS_SET_STATE:
      return {
        ...state,
        addressStatus: {
          ...state.addressStatus,
          modifyStatus: action.status,
        },
      };
    case REQUEST_ADDRESS_POST_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        addressStatus: {
          ...state.addressStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case CLEAR_ADDRESS_MODIFY_STATUS:
      return {
        ...state,
        addressStatus: {
          ...state.contact.addressStatus,
          modifyStatus: STATUS.UNFETCHED,
        },
      };

    // GET Email Consent
    case RECEIVE_EMAIL_CONSENT:
      return {
        ...state,
        emailConsent: action.emailConsent,
        emailConsentETag: action.emailConsentETag,
        emailConsentStatus: {
          ...state.emailConsentStatus,
          getStatus: STATUS.FETCHED,
        },
      };

    // GET Email
    case REQUEST_EMAIL:
      return {
        ...state,
        emailStatus: {
          ...state.emailStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_EMAIL:
      return {
        ...state,
        email: action.email,
        emailETag: action.emailETag,
        emailStatus: {
          ...state.emailStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_EMAIL_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        emailStatus: {
          ...state.emailStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case CLEAR_EMAIL_MODIFY_STATUS:
      return {
        ...state,
        emailStatus: {
          ...state.contact.emailStatus,
          modifyStatus: STATUS.UNFETCHED,
        },
      };

    // POST Email
    case REQUEST_POST_EMAIL:
      return {
        ...state,
        emailStatus: {
          ...state.emailStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_POST_EMAIL:
      return {
        ...state,
        email: action.email,
        emailETag: action.emailETag,
        emailStatus: {
          ...state.emailStatus,
          getStatus: STATUS.FETCHED,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case RECEIVE_POST_EMAIL_SET_STATE:
      return {
        ...state,
        emailStatus: {
          ...state.emailStatus,
          modifyStatus: action.status,
        },
      };
    case REQUEST_EMAIL_POST_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        emailStatus: {
          ...state.emailStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // GET Phone
    case REQUEST_PHONE:
      return {
        ...state,
        phoneStatus: {
          ...state.phoneStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_PHONE:
      return {
        ...state,
        phone: action.phone,
        phoneETag: action.phoneETag,
        phoneStatus: {
          ...state.phoneStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_PHONE_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        phoneStatus: {
          ...state.phoneStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case CLEAR_PHONE_MODIFY_STATUS:
      return {
        ...state,
        phoneStatus: {
          ...state.phoneStatus,
          modifyStatus: STATUS.UNFETCHED,
        },
      };
    case CLEAR_PHONE_GET_STATUS:
      return {
        ...state,
        phoneStatus: {
          ...state.phoneStatus,
          getStatus: STATUS.UNFETCHED,
          modifyStatus: STATUS.UNFETCHED,
        },
        phoneConsentStatus: {
          ...state.phoneConsentStatus,
          getStatus: STATUS.UNFETCHED,
          modifyStatus: STATUS.UNFETCHED,
        },
      };

    // POST Phone
    case REQUEST_POST_PHONE:
      return {
        ...state,
        phoneStatus: {
          ...state.phoneStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_POST_PHONE:
      return {
        ...state,
        phone: action.phone,
        phoneETag: action.phoneETag,
        phoneStatus: {
          ...state.phoneStatus,
          getStatus: STATUS.UNFETCHED,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case REQUEST_PHONE_POST_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        phoneStatus: {
          ...state.phoneStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // GET PhoneConsent
    case REQUEST_PHONE_CONSENT:
      return {
        ...state,
        phoneConsentStatus: {
          ...state.phoneConsentStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_PHONE_CONSENT:
      return {
        ...state,
        phoneConsent: action.phoneConsent,
        phoneConsentETag: action.phoneConsentETag,
        phoneConsentStatus: {
          ...state.phoneConsentStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_PHONE_CONSENT_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        phoneConsentStatus: {
          ...state.phoneConsentStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // POST Phone Consent
    case REQUEST_POST_PHONE_CONSENT:
      return {
        ...state,
        phoneConsentStatus: {
          ...state.phoneConsentStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_POST_PHONE_CONSENT:
      return {
        ...state,
        phoneConsent: action.phoneConsent,
        phoneConsentETag: action.phoneConsentETag,
        phoneConsentStatus: {
          ...state.phoneConsentStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case RECEIVE_POST_PHONE_CONSENT_SET_STATE:
      return {
        ...state,
        phoneConsentStatus: {
          ...state.phoneConsentStatus,
          modifyStatus: action.status,
        },
      };
    case REQUEST_PHONE_CONSENT_POST_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        phoneConsentStatus: {
          ...state.phoneConsentStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // GET Zipcode
    // TODO: Why is it only fetched, not REQUEST/RECIEVE/ERROR?
    case GET_ZIP_CODE:
      return {
        ...state,
        zipCode: action.zipCode,
        zipCodeStatus: {
          ...state.zipCodeStatus,
          getStatus: STATUS.FETCHED,
        },
      };

    // GET Secondary Institution Address
    case REQUEST_SECONDARY_INSTITUTION_ADDRESS:
      return {
        ...state,
        secondaryInstitutionAddress: {
          ...state.secondaryInstitutionAddress,
          secondaryInstitutionAddressStatus: {
            ...state.secondaryInstitutionAddressStatus,
            getStatus: STATUS.FETCHING,
            errorData: null,
          },
        },
      };
    case RECEIVE_SECONDARY_INSTITUTION_ADDRESS:
      return {
        ...state,
        secondaryInstitutionAddress: {
          ...state.secondaryInstitutionAddress,
          ...action.address,
          secondaryInstitutionAddressStatus: {
            ...state.secondaryInstitutionAddressStatus,
            getStatus: STATUS.FETCHED,
          },
        },
      };
    case REQUEST_SECONDARY_INSTITUTION_ADDRESS_ERROR:
      return {
        ...state,
        secondaryInstitutionAddress: {
          ...state.secondaryInstitutionAddress,
          error: errorToMessage(action.error),
          secondaryInstitutionAddressStatus: {
            ...state.secondaryInstitutionAddressStatus,
            getStatus: STATUS.ERROR,
            errorData: action.error,
          },
        },
      };
    default:
      return state;
  }
}

export default contact;
