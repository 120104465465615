/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Slide from '@material-ui/core/Slide';

const { REACT_APP_BECOME_LOGIN_URL } = (window.__ENV || {});

// eslint-disable-next-line react/display-name
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    padding: theme.spacing(1, 0),
    color: theme.palette.common.black,
    fontSize: theme.spacing(1.6),
  },
  code: {
    color: theme.palette.error.main,
    fontSize: theme.spacing(1),
    fontWeight: '900',
    marginBottom: theme.spacing(-1),
  },
  h2: {
    color: theme.palette.error.main,
    paddingRight: theme.spacing(2),
  },
  icon: {
    color: theme.palette.common.black,
    marginBottom: theme.spacing(-1),
    fontSize: theme.spacing(1.5),
  },
}));

function Alert(props) {
  const {
    errorLink,
    errorLinkReloadAction,
    errorCode,
    dialogTitle,
    partialMessage,
    linkPrompt,
    endMessage,
    hidePartialMessage,
    hidePlease,
    hideLink,
    hideFinalDot,
    htmlFullMessage,
  } = props;
  const classes = useStyles();
  const [open] = useState(true);

  const reloadEvent = () => {
    window.location.reload();
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        className="Dialog"
      >
        <DialogTitle id="alert-dialog-title" disableTypography className={classes.root}>
          <Typography className={classes.h2} variant="h2" data-cy="alert-dialog-title">
            {dialogTitle}
          </Typography>
          <Typography className={classes.code} data-cy="alert-dialog-error-code" data-description={errorCode}>
            {errorCode}
          </Typography>
        </DialogTitle>
        <DialogContent className="DialogContent">
          <DialogContentText
            id="alert-dialog-description"
            className={classes.text}
            data-cy="alert-dialog-contents"
          >
            {htmlFullMessage && (
              htmlFullMessage
            )}
            <span className={hidePartialMessage ? 'hidePartialMessage' : ''}>
              {partialMessage}
              {' '}
            </span>
            <span className={hidePlease ? 'hidePlease' : ''}>Please </span>
            <Link
              // eslint-disable-next-line no-underscore-dangle
              className={hideLink ? 'hideLink' : ''}
              href={errorLink}
              aria-describedby="alert-dialog-description"
              onClick={errorLinkReloadAction ? reloadEvent : ''}
            >
              {linkPrompt}
            </Link>
            {endMessage}
            <span className={hideFinalDot ? 'hideFinalDot' : ''}>.</span>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

Alert.propTypes = {
  errorLink: PropTypes.string,
  errorLinkReloadAction: PropTypes.bool,
  errorCode: PropTypes.string,
  dialogTitle: PropTypes.string,
  partialMessage: PropTypes.string,
  linkPrompt: PropTypes.string,
  endMessage: PropTypes.string,
  hidePartialMessage: PropTypes.bool,
  hidePlease: PropTypes.bool,
  hideLink: PropTypes.bool,
  hideFinalDot: PropTypes.bool,
  htmlFullMessage: PropTypes.element,
};

Alert.defaultProps = {
  errorLink: `${REACT_APP_BECOME_LOGIN_URL}`,
  errorLinkReloadAction: false,
  errorCode: '',
  dialogTitle: 'ERROR',
  partialMessage: 'There is a problem with this page.',
  linkPrompt: 'continue your application here',
  endMessage: '',
  hidePartialMessage: false,
  hidePlease: false,
  hideLink: false,
  hideFinalDot: false,
  htmlFullMessage: <></>,
};

export default Alert;
