import { ACTIONS } from './types';
import STATUS from '../../../globalStatuses';

function higherEds(
  state = {
    higherEds: {},
    esignHigherEds: [],
    higherEdsStatus: {},
    esignHigherEdsStatus: {},
  },
  action,
) {
  switch (action.type) {
    // GET Higher Ed
    case ACTIONS.REQUEST_HIGHER_ED:
      return {
        ...state,
        higherEdsStatus: {
          ...state.higherEdsStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
        higherEds: null,
      };
    case ACTIONS.RECEIVE_HIGHER_ED:
      // Add original index for keeping track of schema errors
      return {
        ...state,
        higherEds: action.higherEds.map((ed, index) => (
          {
            ...ed,
            index,
          }
        )),
        higherEdsStatus: {
          ...state.higherEdsStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_HIGHER_ED_ERROR:
      return {
        ...state,
        error: action.error,
        higherEdsStatus: {
          ...state.higherEdsStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.CLEAR_HIGHER_ED_GET_STATUS:
      return {
        ...state,
        higherEdsStatus: {
          ...state.higherEdsStatus,
          getStatus: STATUS.UNFETCHED,
          errorData: null,
        },
      };
    // POST Higher Ed
    case ACTIONS.REQUEST_SAVE_HIGHER_ED:
      return {
        ...state,
        higherEdsStatus: {
          ...state.higherEdsStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
        esignHigherEds: [],
        esignHigherEdsStatus: {
          ...state.esignHigherEdsStatus,
          getStatus: STATUS.UNFETCHED,
        },
      };
    case ACTIONS.RECEIVE_SAVE_HIGHER_ED:
      return {
        ...state,
        higherEds: action.higherEds,
        higherEdsStatus: {
          ...state.higherEdsStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_SAVE_HIGHER_ED_ERROR:
      return {
        ...state,
        error: action.error,
        higherEdsStatus: {
          ...state.higherEdsStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // DELETE Higher Ed
    case ACTIONS.REQUEST_DELETE_HIGHER_ED:
      return {
        ...state,
        higherEds: [],
        higherEdsStatus: {
          ...state.higherEdsStatus,
          getStatus: STATUS.UNFETCHED,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
        esignHigherEds: [],
        esignHigherEdsStatus: {
          ...state.esignHigherEdsStatus,
          getStatus: STATUS.UNFETCHED,
        },
      };
    case ACTIONS.RECEIVE_DELETE_HIGHER_ED:
      return {
        ...state,
        higherEdsStatus: {
          ...state.higherEdsStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_DELETE_HIGHER_ED_ERROR:
      return {
        ...state,
        error: action.error,
        higherEdsStatus: {
          ...state.higherEdsStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // GET Pers Info
    case ACTIONS.REQUEST_PERS_INFORMATION:
      return {
        ...state,
        persInfoStatus: {
          ...state.persInfoStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_PERS_INFORMATION:
      return {
        ...state,
        information: action.payload,
        persInfoStatus: {
          ...state.persInfoStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.RECEIVE_PERS_INFORMATION_ERROR:
      return {
        ...state,
        error: action.error,
        persInfoStatus: {
          ...state.persInfoStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.CLEAR_HIGHER_ED_MODIFY_STATUS:
      return {
        ...state,
        persInfoStatus: {
          ...state.persInfoStatus,
          modifyStatus: STATUS.UNFETCHED,
        },
      };

    //get esignable colleges
    // GET Higher Ed
    case ACTIONS.REQUEST_ESIGN_HIGHER_ED:
      return {
        ...state,
        esignHigherEdsStatus: {
          ...state.esignHigherEdsStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
        esignHigherEds: null,
      };
    case ACTIONS.RECEIVE_ESIGN_HIGHER_ED:
      return {
        ...state,
        esignHigherEds: action.esignHigherEds,
        esignHigherEdsStatus: {
          ...state.esignHigherEdsStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_ESIGN_HIGHER_ED_ERROR:
      return {
        ...state,
        error: action.error,
        esignHigherEdsStatus: {
          ...state.esignHigherEdsStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.CLEAR_ESIGN_HIGHER_ED_GET_STATUS:
      return {
        ...state,
        esignHigherEdsStatus: {
          ...state.esignHigherEdsStatus,
          getStatus: STATUS.UNFETCHED,
          errorData: null,
        },
      };
    default:
      return state;
  }
}
export default higherEds;
