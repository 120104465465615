import { ACTIONS, WF_TASK_NAMES, getWorkflowKeyByValue } from './types';
import GLOBALSTATUS from '../../globalStatuses';

// eslint-disable-next-line max-len
const defaultWorkflowDetailStatus = Object.keys(WF_TASK_NAMES).reduce((previousObject, currentKey) => ({ ...previousObject, [currentKey]: GLOBALSTATUS.UNFETCHED }), {});

function task(
  state = {
    tasks: [],
    workflow: null,
    isStaticContent: false,
    taskStatus: {
      getStatus: GLOBALSTATUS.UNFETCHED,
      modifyStatus: GLOBALSTATUS.UNFETCHED,
    },
    workflowStatus: {
      getStatus: GLOBALSTATUS.UNFETCHED,
      modifyStatus: GLOBALSTATUS.UNFETCHED,
    },
    detailedWorkflowStatus: defaultWorkflowDetailStatus,
    workflowInstanceId: '',
  },
  { type, payload },
) {
  switch (type) {
    // GET Workflow
    case ACTIONS.REQUEST_WORKFLOW:
      return {
        ...state,
        workflowStatus: {
          ...state.workflowStatus,
          getStatus: GLOBALSTATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_WORKFLOW:
      return {
        ...state,
        workflow: payload.workflow,
        workflowInstanceId: payload.workflow.workflowId,
        workflowStatus: {
          ...state.workflowStatus,
          getStatus: GLOBALSTATUS.FETCHED,
        },
      };
    case ACTIONS.RECEIVE_WORKFLOW_ERROR:
      return {
        ...state,
        error: payload.error,
        workflowStatus: {
          ...state.workflowStatus,
          getStatus: GLOBALSTATUS.ERROR,
          errorData: payload.error,
        },
      };
    // Refresh workflow
    case ACTIONS.REFRESH_WORKFLOW:
      return {
        ...state,
        workflow: payload.workflow,
        workflowInstanceId: payload.workflow.workflowId,
      };
    // POST Workflow
    case ACTIONS.REQUEST_START_WORKFLOW:
      return {
        ...state,
        workflowStatus: {
          ...state.workflowStatus,
          modifyStatus: GLOBALSTATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_START_WORKFLOW:
      return {
        ...state,
        workflowInstanceId: payload.workflowInstanceId,
        workflowStatus: {
          ...state.workflowStatus,
          modifyStatus: GLOBALSTATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_START_WORKFLOW_ERROR:
      return {
        ...state,
        error: payload.error,
        workflowStatus: {
          ...state.workflowStatus,
          modifyStatus: GLOBALSTATUS.ERROR,
          errorData: payload.error,
        },
      };

    // Post Workflow Task
    case ACTIONS.REQUEST_START_COMPLETE_WF_TASK:
      return {
        ...state,
        detailedWorkflowStatus: {
          ...state.detailedWorkflowStatus,
          [getWorkflowKeyByValue(payload.taskName)]: {
            ...state.detailedWorkflowStatus[getWorkflowKeyByValue(payload.taskName)],
            modifyStatus: GLOBALSTATUS.UPDATING,
            errorData: payload.error,
          },
        },
      };
    case ACTIONS.RECEIVE_COMPLETE_WF_TASK:
      return {
        ...state,
        detailedWorkflowStatus: {
          ...state.detailedWorkflowStatus,
          [getWorkflowKeyByValue(payload.taskName)]: {
            ...state.detailedWorkflowStatus[getWorkflowKeyByValue(payload.taskName)],
            modifyStatus: GLOBALSTATUS.UPDATED,
            errorData: payload.error,
          },
        },
      };
    case ACTIONS.REQUEST_COMPLETE_WF_TASK_ERROR:
      return {
        ...state,
        error: payload.error,
        detailedWorkflowStatus: {
          ...state.detailedWorkflowStatus,
          [getWorkflowKeyByValue(payload.taskName)]: {
            ...state.detailedWorkflowStatus[getWorkflowKeyByValue(payload.taskName)],
            modifyStatus: GLOBALSTATUS.ERROR,
            errorData: payload.error,
          },
        },
      };
    // postsubmit tasks creation
    case ACTIONS.REQUEST_POSTSUBMIT_TASKS:
      return {
        ...state,
        provisionPostSubmitTasks: {
          status: GLOBALSTATUS.UNFETCHED,
          getStatus: GLOBALSTATUS.FETCHING,
        },
      };
    case ACTIONS.RECEIVE_POSTSUBMIT_TASKS:
      return {
        ...state,
        provisionPostSubmitTasks: {
          status: payload.status,
          getStatus: GLOBALSTATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_POSTSUBMIT_TASKS_ERROR:
      return {
        ...state,
        provisionPostSubmitTasks: {
          status: GLOBALSTATUS.UNFETCHED,
          getStatus: GLOBALSTATUS.ERROR,
          errorData: payload.error,
        },
      };
    // rerun Workflow Task
    case ACTIONS.REQUEST_RERUN_WF_TASK:
      return {
        ...state,
        detailedWorkflowStatus: {
          ...state.detailedWorkflowStatus,
          [getWorkflowKeyByValue(payload.taskName)]: {
            ...state.detailedWorkflowStatus[getWorkflowKeyByValue(payload.taskName)],
            modifyStatus: GLOBALSTATUS.UPDATING,
            errorData: payload.error,
          },
        },
      };
    case ACTIONS.RECEIVE_RERUN_WF_TASK:
      return {
        ...state,
        detailedWorkflowStatus: {
          ...state.detailedWorkflowStatus,
          [getWorkflowKeyByValue(payload.taskName)]: {
            ...state.detailedWorkflowStatus[getWorkflowKeyByValue(payload.taskName)],
            modifyStatus: GLOBALSTATUS.UPDATED,
            errorData: payload.error,
          },
        },
      };
    case ACTIONS.REQUEST_RERUN_WF_TASK_ERROR:
      return {
        ...state,
        error: payload.error,
        detailedWorkflowStatus: {
          ...state.detailedWorkflowStatus,
          [getWorkflowKeyByValue(payload.taskName)]: {
            ...state.detailedWorkflowStatus[getWorkflowKeyByValue(payload.taskName)],
            modifyStatus: GLOBALSTATUS.ERROR,
            errorData: payload.error,
          },
        },
      };
    default:
      return state;
  }
}

export default task;
