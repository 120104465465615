import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { ConnectedRouter } from 'connected-react-router';
import './index.css';
import './style.scss';
import 'modality-polyfill/src/keyboard-modality';
import Helmet from 'react-helmet';
import App from './FeatureWrappedApp';
import * as serviceWorker from './serviceWorker';
import store, { history } from './store/configureStore';
import userManager from './store/domains/auth0/userManager';
// https://github.com/dequelabs/react-axe
// DO NOT USE (window.__ENV || {})
if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_ENABLE_AXE === 'true') {
  import('react-dom').then(ReactDOM => {
    // Can be enabled locally for testing with react-axe
    // import('react-axe').then(axe => {
    //   axe.default(React, ReactDOM, 1000);
    // });
  });
}

// eslint-disable-next-line no-underscore-dangle
const {
  REACT_APP_AEC_LAUNCH_SCRIPT_URL,
} = window.__ENV || {};
const urlIncludesCallback = window.location.pathname.toLowerCase().includes('callback');
const urlIncludesOIDC = window.location.pathname.toLowerCase().includes('oidc');
const personalInfoPage = window.location.pathname.toLowerCase().includes('personal-info');
const maintenancePage = window.location.pathname.toLowerCase().includes('maintenance');
const adminPage = window.location.pathname.toLowerCase().includes('admin');
const AEC_LAUNCH_URL_FLAG_CHECKED = [REACT_APP_AEC_LAUNCH_SCRIPT_URL];

render(
  <Provider store={store}>
    <OidcProvider store={store} userManager={userManager}>
      <ConnectedRouter history={history}>
        <>
          {/* TODO: remove on analytics clean-up */}
          {((urlIncludesCallback && urlIncludesOIDC) || personalInfoPage || (maintenancePage && !adminPage)) && (
          <Helmet script={
                [...AEC_LAUNCH_URL_FLAG_CHECKED].map(script => ({
                  src: script,
                  type: 'text/javascript',
                  defer: true,
                  async: true,
                }))
              }
          />
          )}
          <App />
        </>
      </ConnectedRouter>
    </OidcProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
