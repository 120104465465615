import retryCallIfNeeded from '../../../restCalls';

export default function StudentNotificationService(client) {
  const getStudentNotification = async irn => {
    try {
      const response = await retryCallIfNeeded(
        client,
        () => client.get(`/studentnotification?irn=${irn}`),
      );
      const data = response?.data;
      return data;
    } catch (error) {
      return error;
    }
  };
  return { getStudentNotification };
}
