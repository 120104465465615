export const ACTIONS = {
  REQUEST_PERSON_EXAM_INFO: 'REQUEST_PERSON_EXAM_INFO',
  RECEIVE_PERSON_EXAM_INFO: 'RECEIVE_PERSON_EXAM_INFO',
  REQUEST_PERSON_EXAM_INFO_ERROR: 'REQUEST_PERSON_INFO_EXAM_ERROR',

  REQUEST_SAVE_PERSON_EXAM_INFO: 'REQUEST_SAVE_PERSON_EXAM_INFO',
  RECEIVE_SAVE_PERSON_EXAM_INFO: 'RECEIVE_SAVE_PERSON_EXAM_INFO',
  REQUEST_SAVE_PERSON_EXAM_INFO_ERROR: 'REQUEST_SAVE_PERSON_EXAM_INFO_ERROR',

  REQUEST_DELETE_PERSON_EXAM_INFO: 'REQUEST_DELETE_PERSON_EXAM_INFO',
  RECEIVE_DELETE_PERSON_EXAM_INFO: 'RECEIVE_DELETE_PERSON_EXAM_INFO',
  REQUEST_DELETE_PERSON_EXAM_INFO_ERROR: 'REQUEST_DELETE_PERSON_EXAM_INFO_ERROR',

  CLEAR_EXAM_CREDITS_MODIFY_STATUS: 'CLEAR_EXAM_CREDITS_MODIFY_STATUS',
};
export default ACTIONS;
