import retryCallIfNeeded from '../../../restCalls';

export default function citizenshipService(client) {
  const getPersonCitizenshipAsync = async personId => retryCallIfNeeded(
    client,
    () => client.get(`/api/persons/v1/person/${personId}/citizenship`),
  );
  const getPersonCitizenshipByCitizenshipId = async (personId, citizenshipId) => retryCallIfNeeded(
    client,
    () => client.get(`/api/persons/v1/person/${personId}/citizenship/${citizenshipId}`),
  );
  const postPersonCitizenshipAsync = async (personId, payload) => retryCallIfNeeded(
    client,
    () => client.post(`/api/persons/v1/person/${personId}/citizenship`, payload),
  );
  const putPersonCitizenshipAsync = async (
    personId,
    citizenshipId,
    payload,
    etag,
  ) => retryCallIfNeeded(
    client,
    () => {
      const config = {
        headers: {
          'If-Match': etag || 'Default',
        },
      };
      return client.put(`/api/persons/v1/person/${personId}/citizenship/${citizenshipId}`, payload, config);
    },
  );
  const deletePersonCitizenshipAsync = async citizenshipId => retryCallIfNeeded(
    client,
    () => client.delete(`/api/persons/v1/person/citizenship/${citizenshipId}`),
  );

  return {
    getPersonCitizenshipAsync,
    postPersonCitizenshipAsync,
    putPersonCitizenshipAsync,
    deletePersonCitizenshipAsync,
    getPersonCitizenshipByCitizenshipId,
  };
}
