import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';
import styles from './styles';

const Checkbox = props => {
  const {
    name, label, required, control, onChangeCallback, id, ...other
  } = props;

  const classes = styles();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { invalid, error } }) => (
        <FormControl
          component="fieldset"
          error={invalid}
          className={clsx(classes.checkWrapper, 'check-the-box')}
        >
          <FormControlLabel
            {...other}
            className={`${classes.label} ${other.className ? other.className : ''}`}
            control={(
              <MuiCheckbox
                {...field}
                id={id}
                value={field?.value}
                onChange={event => {
                  field.onChange(event);
                  onChangeCallback(event);
                }}
                checked={field?.value}
                color="primary"
                className={invalid ? classes.errorCheckbox : ''}
              />
            )}
            label={label}
            labelPlacement="end"
            required={required}
            error={invalid}
          />
          {invalid && (
            <FormHelperText id={`${id}-helper-text`} error={invalid}>
              {error?.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  control: PropTypes.shape({}).isRequired,
  required: PropTypes.bool,
  onChangeCallback: PropTypes.func,
};

Checkbox.defaultProps = {
  id: null,
  label: '',
  required: false,
  onChangeCallback: () => { },
};

export default Checkbox;
