import retryCallIfNeeded from '../../../restCalls';

export default function EducationService(client) {
  const getEducationByPersonIdAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`/api/persons/v1/person/${personId}/education?type=higher`));
  const getSecondaryEducationByPersonIdAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`/api/persons/v1/person/${personId}/education?type=secondary`));
  const getSecondaryEdByDistrictAsync = async (country, district) => retryCallIfNeeded(client, () => client
    .get(`/api/organizations/v1/districtHigherEdType/searchAll?$filter=country eq '${country}' and district eq '${district}'`));
  const saveEducationAsync = async (personId, payload) => retryCallIfNeeded(client, () => client
    .post(`/api/persons/v1/person/${personId}/educations`, payload));
  const deleteEducationAsync = async id => retryCallIfNeeded(client, () => client
    .delete(`/api/persons/v1/person/education/${id}`));
  return {
    getEducationByPersonIdAsync,
    getSecondaryEducationByPersonIdAsync,
    getSecondaryEdByDistrictAsync,
    saveEducationAsync,
    deleteEducationAsync,
  };
}
