const FormFields = {
  maintenance: {
    id: 'maintenance-controls',
    name: 'maintenanceEnabled',
    ariaDescribedBy: 'maintenance-helper-text',
    trueLabel: 'ON',
    falseLabel: 'OFF',
    value: '',
  },
};

export const DefaultValues = {
  [FormFields.maintenance.name]: 'False',
};
export default FormFields;
