import {
  REQUEST_POST_APPLICATION,
  RECEIVE_POST_APPLICATION,
  REQUEST_POST_APPLICATION_ERROR,
  REQUEST_UPDATE_APPLICATION,
  RECEIVE_UPDATE_APPLICATION,
  REQUEST_UPDATE_APPLICATION_ERROR,
  REQUEST_APPLICATION,
  RECEIVE_APPLICATION,
  REQUEST_APPLICATION_ERROR,
  REQUEST_GET_APPLICATIONS,
  RECEIVE_GET_APPLICATIONS,
  REQUEST_GET_APPLICATIONS_ERROR,
  CLEAR_APPLICATION_STATUS_GET_STATUS,
  CLEAR_APPLICATION_STATUS_MODIFY_STATUS,
  RECEIVE_NAVIGATION,
  RECEIVE_UPDATE_NAVIGATION,
  RECEIVE_POST_NAVIGATION,
  CLEAR_NAVIGATION_STATUS_MODIFY_STATUS,
  CHANGE_SUPPRESS_FINANCIAL_GRANTS_APPLICATION,
  CHANGE_SUPPRESS_FINANCIAL_LOAN_APPLICATION,
  CHANGE_SUPPRESS_FINANCIAL_MILITARY_APPLICATION,
  CHANGE_SUPPRESS_FINANCIAL_LOAN_GRAD_PLUS_APPLICATION,
  CHANGE_SUPPRESS_FINANCIAL_LOAN_PARENT_PLUS_APPLICATION,
  CHANGE_SUPPRESS_ADMISSION_LICENSE_PAGE,
  CHANGE_SUPPRESS_ADMISSION_EXPULSION_PAGE,
  CHANGE_SUPPRESS_ADMISSION_TRANSCRIPT_PAGE,
  REQUEST_APPLICATION_TYPE,
  RECEIVE_APPLICATION_TYPE,
  REQUEST_APPLICATION_TYPE_ERROR,
  REQUEST_DELETE_APPLICATION,
  RECEIVE_DELETE_APPLICATION,
  REQUEST_DELETE_APPLICATION_ERROR,
  REQUEST_EXPIRE_APPLICATION,
  RECEIVE_EXPIRE_APPLICATION,
  REQUEST_EXPIRE_APPLICATION_ERROR,
  REQUEST_PROGRAM_WORK_EXPERIENCE_REQUIREMENT,
  RECEIVE_PROGRAM_WORK_EXPERIENCE_REQUIREMENT,
  REQUEST_PROGRAM_WORK_EXPERIENCE_REQUIREMENT_ERROR,
  REQUEST_ATTACHMENT,
  RECEIVE_ATTACHMENT,
  REQUEST_ATTACHMENT_ERROR,
} from './types';
import STATUS from '../../globalStatuses';
import { errorToMessage } from '../../../errorMessaging';

function user(state = { application: {} }, action) {
  switch (action.type) {
    // GET Application
    case REQUEST_APPLICATION:
      return {
        ...state,
        applicationStatus: {
          ...state.applicationStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_APPLICATION:
      return {
        ...state,
        application: action.application,
        applicationETag: action.applicationETag,
        applicationStatus: {
          ...state.applicationStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_APPLICATION_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        applicationStatus: {
          ...state.applicationStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // POST Application
    case REQUEST_POST_APPLICATION:
      return {
        ...state,
        applicationStatus: {
          ...state.applicationStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_POST_APPLICATION:
      return {
        ...state,
        application: action.application,
        applicationETag: action.applicationETag,
        applicationStatus: {
          ...state.applicationStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case REQUEST_POST_APPLICATION_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        applicationStatus: {
          ...state.applicationStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case CLEAR_APPLICATION_STATUS_GET_STATUS:
      return {
        ...state,
        error: errorToMessage(action.error),
        applicationStatus: {
          ...state.applicationStatus,
          getStatus: STATUS.UNFETCHED,
          errorData: action.error,
        },
      };
    case CLEAR_APPLICATION_STATUS_MODIFY_STATUS:
      return {
        ...state,
        error: errorToMessage(action.error),
        applicationStatus: {
          ...state.applicationStatus,
          modifyStatus: STATUS.UNFETCHED,
          errorData: action.error,
        },
      };

    // PUT Application
    case REQUEST_UPDATE_APPLICATION:
      return {
        ...state,
        applicationStatus: {
          ...state.applicationStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_UPDATE_APPLICATION:
      return {
        ...state,
        application: action.application,
        applicationETag: action.applicationETag,
        applicationStatus: {
          ...state.applicationStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case REQUEST_UPDATE_APPLICATION_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        applicationStatus: {
          ...state.applicationStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // delete application
    case REQUEST_DELETE_APPLICATION:
      return {
        ...state,
        applicationStatus: {
          ...state.applicationStatus,
          deleteStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_DELETE_APPLICATION:
      return {
        ...state,
        application: { deletedId: action.payload },
        applicationETag: "",
        applicationStatus: {
          ...state.applicationStatus,
          deleteStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_DELETE_APPLICATION_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        applicationStatus: {
          ...state.applicationStatus,
          deleteStatus: STATUS.ERROR,
          errorData: { deletedId: action.applicationId, error: action.error },
        },
      };

    // expire application
    case REQUEST_EXPIRE_APPLICATION:
      return {
        ...state,
        applicationStatus: {
          ...state.applicationStatus,
          expireStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_EXPIRE_APPLICATION:
      return {
        ...state,
        applicationETag: "",
        applicationStatus: {
          ...state.applicationStatus,
          expireStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_EXPIRE_APPLICATION_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        applicationStatus: {
          ...state.applicationStatus,
          expireStatus: STATUS.ERROR,
          errorData: { deletedId: action.applicationId, error: action.error },
        },
      };

    // post navigation
    case RECEIVE_NAVIGATION:
      return {
        ...state,
        navigation: action.navigation,
        navigationStatus: {
          ...state.navigationStatus,
          getStatus: STATUS.FETCHED,
        },
      };

    case RECEIVE_POST_NAVIGATION:
      return {
        ...state,
        navigation: action.navigation,
        navigationStatus: {
          ...state.navigationStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };

    case RECEIVE_UPDATE_NAVIGATION:
      return {
        ...state,
        navigation: action.navigation,
        navigationStatus: {
          ...state.navigationStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };

    case CLEAR_NAVIGATION_STATUS_MODIFY_STATUS:
      return {
        ...state,
        error: errorToMessage(action.error),
        navigationStatus: {
          ...state.navigationStatus,
          modifyStatus: STATUS.UNFETCHED,
          errorData: action.error,
        },
      };

    // GET Applications (Plural)
    case REQUEST_GET_APPLICATIONS:
      return {
        ...state,
        applicationStatus: {
          ...state.applicationStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_GET_APPLICATIONS:
      return {
        ...state,
        application: (action.applications || [{}])[0],
        applicationStatus: {
          ...state.applicationStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_GET_APPLICATIONS_ERROR:
      return {
        ...state,
        error: action.error,
        applicationStatus: {
          ...state.applicationStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case CHANGE_SUPPRESS_FINANCIAL_GRANTS_APPLICATION:
      return {
        ...state,
        suppressFinancialGrantsApplication: action.suppressApplication,
      };
    case CHANGE_SUPPRESS_FINANCIAL_LOAN_APPLICATION:
      return {
        ...state,
        suppressFinancialLoanApplication: action.suppressApplication,
      };
    case CHANGE_SUPPRESS_FINANCIAL_MILITARY_APPLICATION:
      return {
        ...state,
        suppressFinancialMilitaryApplication: action.suppressApplication,
      };
    case CHANGE_SUPPRESS_FINANCIAL_LOAN_GRAD_PLUS_APPLICATION:
      return {
        ...state,
        suppressFinancialGradPlusLoanApplication: action.suppressApplication,
      };
    case CHANGE_SUPPRESS_FINANCIAL_LOAN_PARENT_PLUS_APPLICATION:
      return {
        ...state,
        suppressFinancialParentPlusLoanApplication: action.suppressApplication,
      };
    case CHANGE_SUPPRESS_ADMISSION_LICENSE_PAGE:
      return {
        ...state,
        suppressAdmissionLicenseInfoPage: action.suppressPage,
      };
    case CHANGE_SUPPRESS_ADMISSION_EXPULSION_PAGE:
      return {
        ...state,
        suppressAdmissionExpulsionInfoPage: action.suppressPage,
      };
    case CHANGE_SUPPRESS_ADMISSION_TRANSCRIPT_PAGE:
      return {
        ...state,
        suppressAdmissionTranscriptPage: action.suppressPage,
      };
    case REQUEST_APPLICATION_TYPE:
      return {
        ...state,
        applicationTypeStatus: {
          ...state.applicationTypeStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_APPLICATION_TYPE:
      return {
        ...state,
        applicationType: action.applicationType,
        applicationTypeStatus: {
          ...state.applicationTypeStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_APPLICATION_TYPE_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        applicationTypeStatus: {
          ...state.applicationTypeStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case REQUEST_PROGRAM_WORK_EXPERIENCE_REQUIREMENT:
      return {
        ...state,
        programWorkExperienceRequirementStatus: {
          ...state.programWorkExperienceRequirementStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_PROGRAM_WORK_EXPERIENCE_REQUIREMENT:
      return {
        ...state,
        programWorkExperienceRequirement:
          action.programWorkExperienceRequirement,
        programWorkExperienceRequirementStatus: {
          ...state.programWorkExperienceRequirementStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_PROGRAM_WORK_EXPERIENCE_REQUIREMENT_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        programWorkExperienceRequirementStatus: {
          ...state.programWorkExperienceRequirementStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // GET Attachment
    case REQUEST_ATTACHMENT:
      return {
        ...state,
        attachmentStatus: {
          ...state.attachmentStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_ATTACHMENT:
      return {
        ...state,
        attachment: action.attachment,
        attachmentStatus: {
          ...state.attachmentStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_ATTACHMENT_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        attachmentStatus: {
          ...state.attachmentStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    default:
      return state;
  }
}

export default user;
