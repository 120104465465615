/* eslint-disable linebreak-style */
import { errorToMessage } from '../../../errorMessaging';

export const triggerAlloyTrack = (eventName, payload, profile) => {
  try {
    window.uopxDataLayer.track(eventName, payload, profile);
  } catch (error) {
    // This will trigger an error event to full story
    errorToMessage('triggerAlloyTrack error');
  }
};

export const triggerAlloyPageView = (eventData, profile) => {
  try {
    window.uopxDataLayer.page(eventData, profile);
  } catch (error) {
    // This will trigger an error event to full story
    errorToMessage('triggerAlloyPageView error');
  }
};

export default {
  triggerAlloyTrack,
  triggerAlloyPageView,
};
