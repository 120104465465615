import * as Yup from 'yup';
import { FormFields } from './Password.fields';

export const passwordSchema = () => Yup.object().shape({
  [FormFields.password.fieldName]: Yup.string()
    .ensure()
    .required('Enter admin passord'),
});

export default passwordSchema;
