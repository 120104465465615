import React from 'react';
import PropTypes from 'prop-types';
import withService from '../../components/hoc/withService';
import { ReactComponent as Icon } from '../admissions/components/icons/construction.svg';
import MaintenanceControl from './maintenance/MaintenanceControl';
import AdminHome from './home/AdminHome';
import { AdminPageConstants } from './constants';
import AdminWrapper from './AdminWrapper';

const Admin = props => {
  const {
    maintenance,
  } = props;

  const MaintenanceControlWithService = withService(MaintenanceControl);
  const AdminHomeWithService = withService(AdminHome);

  return (

    <AdminWrapper
      pageId={AdminPageConstants.pageId}
      header={AdminPageConstants.pageHeader}
      tagline={AdminPageConstants.tagline}
      Icon={Icon}
    >
      {() => (

        maintenance
          ? <MaintenanceControlWithService /> : (
            <AdminHomeWithService />
          )

      )}
    </AdminWrapper>
  );
};

Admin.defaultProps = {
  maintenance: false,

};

Admin.propTypes = {
  maintenance: PropTypes.bool,
};
export default Admin;
