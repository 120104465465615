import retryCallIfNeeded from '../../../restCalls';


export const baseSagasEndpoint = '/api/sagas/v1';

export default function TaskService(client) {
  const getWorkflowByPersonId = personId => retryCallIfNeeded(client, () => client
    .get(`${baseSagasEndpoint}/workflow/ADMISSION/person/${personId}?includeTasks=true&includeClosed=true`));
  const getWorkflowByPersonIdAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`${baseSagasEndpoint}/workflow/ADMISSION/person/${personId}?includeTasks=true&includeClosed=true`));
  const getWorkflowByWorkflowIdAsync = async workflowId => retryCallIfNeeded(client, () => client
    .get(`${baseSagasEndpoint}/workflow/${workflowId}?includeTasks=true&includeClosed=true`));

  const startWorkFlow = async (wfName, wfVersion, personId, applicationId, financialPlanId,
    applicationType, appWorkflowType) => {
    const url = `${baseSagasEndpoint}/workflow`;
    const payload = {
      name: wfName,
      version: wfVersion,
      personId,
      input: {
        applicationId,
        personId,
        financialPlanId,
        applicationType,
        appWorkflowType,
      },
    };
    return retryCallIfNeeded(client, () => client.post(url, payload));
  };

  const completeWorkFlowTask = async (
    workflowInstanceId, personId, taskName,
    applicationId, financialPlanId, profileId, irn,
  ) => {
    const url = `${baseSagasEndpoint}/tasks`;
    const payload = {
      workflowInstanceId,
      taskName,
      status: 'COMPLETED',
      outputData: {
        personId,
        applicationId,
        financialPlanId,
        profileId,
        irn,
      },
    };
    return retryCallIfNeeded(client, () => client.post(url, payload), 12, true);
  };

  const completeAccountCreationWorkFlowTask = async (
    workflowInstanceId, personId, taskName,
    applicationId, financialPlanId, profileId, irn, userType, forgerockEnabled,
  ) => {
    const url = `${baseSagasEndpoint}/tasks`;
    const payload = {
      workflowInstanceId,
      taskName,
      status: 'COMPLETED',
      outputData: {
        personId,
        applicationId,
        financialPlanId,
        profileId,
        irn,
        userType,
        forgerockEnabled,
      },
    };
    return retryCallIfNeeded(client, () => client.post(url, payload), 12, true);
  };

  const getPersonTasksByPersonIdAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`${baseSagasEndpoint}/persontasks/${personId}`));

  const updatePersonTaskFeedbackAsync = async (personId, taskId, envelopeId) => {
    const url = `${baseSagasEndpoint}/persontask/${personId}/${taskId}`;
    const payload = {
      feedback: { envelopeId },
      status: 'ASSIGNED',
    };
    return retryCallIfNeeded(client, () => client.put(url, payload));
  };

  const completePersonTaskAsync = async (personId, taskId, updateFeedback) => {
    const url = `${baseSagasEndpoint}/persontask/${personId}/${taskId}`;
    let payload = {
      status: 'COMPLETED',
    };
    if (updateFeedback) {
      payload = {
        ...payload,
        feedback: updateFeedback,
      };
    }
    return retryCallIfNeeded(client, () => client.put(url, payload));
  };

  const rerunWorkFlowTask = async (workflowInstanceId, personId, taskId, applicationId, applicationType, appWorkflowType) => {
    const url = `${baseSagasEndpoint}/workflow/${workflowInstanceId}/rerun`;
    const payload = {
      correlationId: personId,
      reRunFromTaskId: taskId,
      reRunFromWorkflowId: workflowInstanceId,
      taskInput: {
        applicationId,
        personId,
      },
      workflowInput: {
        applicationId,
        personId,
        applicationType,
        appWorkflowType,
      },
    };
    return retryCallIfNeeded(client, () => client.post(url, payload));
  };

  const deleteWorkflowByWorkflowIdAsync = workflowId => retryCallIfNeeded(client, () => client
    .delete(`${baseSagasEndpoint}/workflow/${workflowId}`));

  return {
    getWorkflowByPersonId,
    getWorkflowByPersonIdAsync,
    getWorkflowByWorkflowIdAsync,
    startWorkFlow,
    completeWorkFlowTask,
    getPersonTasksByPersonIdAsync,
    updatePersonTaskFeedbackAsync,
    completePersonTaskAsync,
    rerunWorkFlowTask,
    deleteWorkflowByWorkflowIdAsync,
    completeAccountCreationWorkFlowTask,
  };
}
