import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './styles';

// ===========================================================
// This component is USED with the 'InTextReference' component
// ===========================================================

export default function Footnotes({
  headingADA,
  footnotes,
}) {
  const classes = styles();
  const [footnoteCount, setFootnoteCount] = useState(0);

  useEffect(() => {
    setFootnoteCount(footnotes.length);
  }, [footnotes.length]);

  const getReturnLink = i => (
    <a
      href={`#footnoteRef${i + 1}`}
      aria-label={`Return to superscript [${i + 1}]`}
      className={classes.backToContent}
    >
      {'\u21A9'}
    </a>
  );

  const getFootnoteContent = (footnote, i) => {
    if (footnote.component) {
      return (
        <>
          {footnote.component}
          {getReturnLink(i)}
        </>
      );
    }
    return (
      <>
        {
          footnote.title ? (
            <>
              <strong>{footnote.title}</strong>
              <br />
            </>
          ) : null
        }
        {footnote.text}
        {
          footnote.list ? (
            <ul className={clsx(classes.footnoteList, 'tight unordered-list')}>
              {footnote.list.map((item, index) => (
                <li key={index.toString()}>{item}</li>
              ))}
            </ul>
          ) : null
        }
        {' '}
        {getReturnLink(i)}
      </>
    );
  };

  const getFootnote = (footnote, i) => {
    if (footnoteCount > 1) {
      return (
        <Typography
          className={clsx(classes.asterisk, 'ref')}
          component="li"
          id={`footnote${i + 1}`}
          key={i.toString()}
        >
          { getFootnoteContent(footnote, i) }
        </Typography>
      );
    }
    return (
      <Typography
        className={clsx(classes.asterisk, 'ref one')}
        component="p"
        id="footnote1"
      >
        { getFootnoteContent(footnote, i) }
      </Typography>
    );
  };

  const getContent = () => {
    if (footnoteCount > 1) {
      return (
        <ol className={classes.footnotes}>
          {footnotes.map((footnote, i) => (
            getFootnote(footnote, i)
          ))}
        </ol>
      );
    }
    return (
      <div className={classes.footnotes}>
        {getFootnote(footnotes[0], 0)}
      </div>
    );
  };

  return (
    <div className={clsx(classes.fnReferences, 'footnotesWrapper')}>
      <Typography
        variant={headingADA}
        className="sr-only"
        id="footnote-label"
      >
        {`Footnote${footnoteCount > 1 ? 's' : ''}`}
      </Typography>
      { getContent() }
    </div>
  );
}

Footnotes.defaultProps = {
  headingADA: 'h2',
  footnotes: [],
};

Footnotes.propTypes = {
  headingADA: PropTypes.string,
  footnotes: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.string),
  })),
};
