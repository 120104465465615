import { types, financialSection } from '../../../modules/admissions/financial/constants';
import tribalBenefitsConstants from '../grouped/tribal-benefits/TribalBenefits.constants';
import employerBenefitsConstants from '../grouped/employer-benefits/EmployerBenefits.constants';
import scholarshipsConstants from '../grouped/scholarships/Scholarships.constants';
import grantsConstants from '../grouped/grants/Grants.constants';
import associationsConstants from '../grouped/associations/Associations.constants';

export const skipMilitaryBenefitsPage = (stateBenefits, stateMilitary) => {
  const militarySection = {
    ...stateMilitary,
    intrstInMilitaryBenefits: false,
    militaryDiscount: false,
  };
  delete militarySection.intrstInSecondaryMilitaryBenefits;

  const updatedStateBenefits = {
    type: types.json,
    value: {
      ...stateBenefits.value,
      militaryBenefits: [],
    },
  };
  const benefitsSectionToPatch = {
    section: financialSection.benefits,
    data: {
      // type: types.json,
      ...updatedStateBenefits.value,
    },
  };
  const militarySectionToPatch = {
    section: financialSection.military,
    data: militarySection,
  };
  return {
    modifiedBenefits: updatedStateBenefits,
    patchBenefits: benefitsSectionToPatch,
    patchMilitary: militarySectionToPatch,
  };
};

export const skipGrantsPage = (stateBenefits, stateContributions) => {
  const grantsBenefits = {
    type: types.pellGrants,
    dataPoints: {
      [grantsConstants.haveEfcField]: '',
      [grantsConstants.efcField]: '',
      // by setting studentMaritalStatusField to null this causes the calculator to not award PELL
      [grantsConstants.studentMaritalStatusField]: null,
      [grantsConstants.studentCashField]: 0,
      [grantsConstants.studentEduTaxAmtField]: 0.00,
      [grantsConstants.studentTaxExemptField]: 0.00,
      [grantsConstants.studentUntaxIncomeField]: 0.00,
      [grantsConstants.studentChildSupportPaidField]: 0.00,
      [grantsConstants.studentChildSupportReceivedField]: 0.00,
      [grantsConstants.studentRecvFedAsstField]: 0.00,
      [grantsConstants.studentIncomeField]: 0.00,
      [grantsConstants.studentTaxesPaidField]: 0.00,
      [grantsConstants.studentTaxExemptField]: 0.00,
      [grantsConstants.studentChildrenField]: '',
      [grantsConstants.parentMarriedField]: null,
      [grantsConstants.parentIncomeField]: 0.00,
      [grantsConstants.parentCashField]: 0.00,
      [grantsConstants.parentRecvFedAsstField]: '',
      [grantsConstants.parentTaxesPaidField]: 0.00,
      [grantsConstants.parentEduTaxAmtField]: 0.00,
      [grantsConstants.parentChildSupportPaidField]: 0.00,
      [grantsConstants.parentChildSupportReceivedField]: 0.00,
      [grantsConstants.parentTaxExemptField]: 0.00,
      [grantsConstants.parentUntaxIncomeField]: 0.00,
      [grantsConstants.hHoldSizeField]: '0',
      [grantsConstants.hHoldMembersCollegeField]: '0',
    },
    hidden: false,
  };

  // Expressed interest in a federal loan already?
  const federalLoan = (stateContributions?.value || []).find(({ type }) => type === 'FEDERAL_LOAN');
  if (federalLoan?.dataPoints?.INTRST_IN_FED_LOAN === 'true') {
    grantsBenefits.dataPoints = {
      [grantsConstants.intendToApplyFinAidField]: 'true',
    };
  }

  const grantsBenefitsSection = stateBenefits.value.grants.filter(
    ({ type }) => type !== types.pellGrants,
  ) || [];
  grantsBenefitsSection.push(grantsBenefits);
  const updatedStateBenefits = {
    type: types.json,
    value: {
      ...stateBenefits.value,
      grants: grantsBenefitsSection,
    },
  };

  const benefitsSectionToPatch = {
    section: financialSection.benefits,
    data: {
      ...updatedStateBenefits.value,
    },
  };

  return {
    modifiedBenefits: updatedStateBenefits,
    patchBenefits: benefitsSectionToPatch,
  };
};

export const skipEmployerBenefitsPage = stateBenefits => {
  const tuitionBenefits = {
    type: employerBenefitsConstants.types.tuition,
    dataPoints: {
      [employerBenefitsConstants.willReceiveBenefitsField]: 'false',
      [employerBenefitsConstants.willUseBenefitsField]: 'false',
      [employerBenefitsConstants.benefitAmountField]: '',
      [employerBenefitsConstants.dollarOrPercentageField]: '',
      [employerBenefitsConstants.fiscalDayField]: '',
      [employerBenefitsConstants.fiscalMonthField]: '',
      [employerBenefitsConstants.reimburseOrBillField]: '',
      [employerBenefitsConstants.percentageCapField]: '',
    },
    hidden: false,
  };
  const bookBenefits = {
    type: employerBenefitsConstants.types.booksResources,
    dataPoints: {
      [employerBenefitsConstants.willReceiveBenefitsField]: 'false',
      [employerBenefitsConstants.willUseBenefitsField]: 'false',
      [employerBenefitsConstants.benefitAmountField]: '',
      [employerBenefitsConstants.dollarOrPercentageField]: '',
      [employerBenefitsConstants.fiscalDayField]: '',
      [employerBenefitsConstants.fiscalMonthField]: '',
      [employerBenefitsConstants.reimburseOrBillField]: '',
      [employerBenefitsConstants.percentageCapField]: '',
    },
    hidden: false,
  };
  let employerBenefitsSection = stateBenefits.value.employerBenefits.filter(
    ({ type }) => type !== employerBenefitsConstants.types.tuition,
  ) || [];
  employerBenefitsSection = employerBenefitsSection.filter(
    ({ type }) => type !== employerBenefitsConstants.types.booksResources,
  ) || [];
  employerBenefitsSection.push(tuitionBenefits);
  employerBenefitsSection.push(bookBenefits);
  const updatedStateBenefits = {
    type: employerBenefitsConstants.types.json,
    value: {
      ...stateBenefits.value,
      employerBenefits: employerBenefitsSection,
    },
  };
  const benefitsSectionToPatch = {
    section: financialSection.benefits,
    data: {
      ...updatedStateBenefits.value,
    },
  };
  return {
    modifiedBenefits: updatedStateBenefits,
    patchBenefits: benefitsSectionToPatch,
  };
};

export const skipAssociationsPage = (stateBenefits, stateDiscounts) => {
  const associationsBenefits = {
    dataPoints: {
      RECV_ASSOCIATIONS: 'false',
    },
    hidden: false,
  };
  const affiliationBenefits = {
    dataPoints: {
      RECV_UOPXEMPLOYEE: 'false',
    },
    hidden: false,
  };
  const associationBenefitsSection = stateBenefits.value.associations.filter(
    ({ dataPoints }) => !(associationsConstants.dataPoint.recvAssociations in dataPoints),
  ) || [];
  const affiliationBenefitsSection = stateBenefits.value.affiliation.filter(
    ({ dataPoints }) => !(associationsConstants.dataPoint.recvUopxEmployee in dataPoints),
  ) || [];
  let discountsSection = stateDiscounts.value;
  discountsSection = discountsSection.filter(
    ({ type }) => type !== 'ASSOCIATION_DISCOUNT',
  ) || [];
  discountsSection = discountsSection.filter(
    ({ type }) => type !== 'AFFILIATION_DISCOUNT',
  ) || [];
  associationBenefitsSection.push(associationsBenefits);
  affiliationBenefitsSection.push(affiliationBenefits);
  const updatedStateBenefits = {
    type: associationsConstants.types.json,
    value: {
      ...stateBenefits.value,
      associations: associationBenefitsSection,
      affiliation: affiliationBenefitsSection,
    },
  };
  const updatedStateDiscounts = {
    type: associationsConstants.types.json,
    value: discountsSection,
  };
  const benefitsSectionToPatch = {
    section: financialSection.benefits,
    data: {
      // type: associationsConstants.types.json,
      ...updatedStateBenefits.value,
    },
  };
  const discountsSectionToPatch = {
    section: associationsConstants.financialSection.discounts,
    data: discountsSection,
  };
  return {
    modifiedBenefits: updatedStateBenefits,
    modifiedDiscounts: updatedStateDiscounts,
    patchBenefits: benefitsSectionToPatch,
    patchDiscounts: discountsSectionToPatch,
  };
};

export const skipScholarshipsPage = stateBenefits => {
  const scholarshipsBenefits = {
    dataPoints: {
      PAID_TO: '',
      RECV_SCHOLAR_GRANTS: 'false',
      SCHOLAR_AMOUNT: '',
      SCHOLAR_FREQUENCY: '',
    },
    hidden: false,
    name: '',
  };
  const scholarshipsBenefitsSection = stateBenefits.value.scholarships.filter(
    ({ dataPoints }) => !('RECV_SCHOLAR_GRANTS' in dataPoints),
  ) || [];
  scholarshipsBenefitsSection.push(scholarshipsBenefits);
  const updatedStateBenefits = {
    type: scholarshipsConstants.types.json,
    value: {
      ...stateBenefits.value,
      scholarships: scholarshipsBenefitsSection,
    },
  };
  const benefitsSectionToPatch = {
    section: financialSection.benefits,
    data: {
      // type: scholarshipsConstants.types.json,
      ...updatedStateBenefits.value,
    },
  };
  return {
    modifiedBenefits: updatedStateBenefits,
    patchBenefits: benefitsSectionToPatch,
  };
};

export const skipTribalBenefitsPage = (stateBenefits, stateDiscounts) => {
  const tribalBenefits = {
    type: tribalBenefitsConstants.types.tribalBenefit,
    dataPoints: {
      RECV_TRIBAL_BENEFITS: 'false',
      TRIBAL_AMOUNT: '',
      TRIBAL_FREQUENCY: '',
      TRIBAL_NAME: '',
      USE_TRIBAL_BENEFITS: 'false',
    },
    hidden: false,
  };
  const otherBenefitsSection = stateBenefits.value.otherBenefits.filter(
    ({ dataPoints }) => !('RECV_TRIBAL_BENEFITS' in dataPoints),
  ) || [];
  const discountsSection = stateDiscounts.value.filter(
    ({ type }) => type !== tribalBenefitsConstants.types.tribalDiscount,
  ) || [];
  otherBenefitsSection.push(tribalBenefits);
  const benefitsSectionToPatch = {
    section: financialSection.benefits,
    data: {
      ...stateBenefits.value,
      otherBenefits: otherBenefitsSection,
    },
  };
  const discountsSectionToPatch = {
    section: financialSection.discounts,
    data: discountsSection,
  };
  return {
    patchBenefits: benefitsSectionToPatch,
    patchDiscounts: discountsSectionToPatch,
  };
};
