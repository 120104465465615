const csrfHeaderName = 'CSRF-Token';
const csrfCookieName = 'CSRF-Token';

export default config => {
  const configUpdate = config;
  configUpdate.withCredentials = true;
  configUpdate.xsrfCookieName = csrfCookieName;
  configUpdate.xsrfHeaderName = csrfHeaderName;
  return configUpdate;
};
