import { errorToMessage } from '../../../../errorMessaging';
import STATUS from '../../../globalStatuses';
import { ACTIONS } from './types';

function calculatorInput(
  state = {
    results: {
      id: {},
      personId: {},
      inputContext: {},
      aidYear: {},
      dependencyStatus: {},
      softDeleteEnabled: {},
      dateOfBirth: {},
      anticipatedStartDate: {},
      selectedFinCampus: {},
      dirtyFlag: {},
      curHashValue: {},
      prevHashValue: {},
      skipFinancialPlan: {},
      isIndirectExpenseIncluded: {},
      appFee: {},
      modDate: {},
      taxAddress: {},
      academicProgramOfferings: {},
      deductions: {},
      discounts: {},
      indirectExpenses: {},
      benefits: {},
      contributions: {},
      military: {},
    },
    calculatorInputStatus: {
      getStatus: STATUS.UNFETCHED,
      modifyStatus: STATUS.UNFETCHED,
      errorData: null,
    },
  },
  action,
) {
  switch (action.type) {
    // POST CALCULATOR INPUT
    case ACTIONS.REQUEST_POST_CALCULATOR_INPUT:
      return {
        ...state,
        calculatorInputStatus: {
          ...state.calculatorInputStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_POST_CALCULATOR_INPUT:
      return {
        ...state,
        financialPlanId: action.calculatorInput.financialPlanId,
        calculatorInputStatus: {
          ...state.calculatorInputStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_POST_CALCULATOR_INPUT_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        calculatorInputStatus: {
          ...state.calculatorInputStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // CLEAR CALCULATOR INPUT MODIFY STATUS
    case ACTIONS.CLEAR_CALCULATOR_INPUT_MODIFY_STATUS:
      return {
        ...state,
        calculatorInputStatus: {
          ...state.calculatorInputStatus,
          modifyStatus: STATUS.UNFETCHED,
        },
      };
    // GET CALCULATOR INPUT
    case ACTIONS.REQUEST_CALCULATOR_INPUT:
      return {
        ...state,
        calculatorInputStatus: {
          ...state.calculatorInputStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_CALCULATOR_INPUT:
      return {
        ...state,
        results: action.resultsFields,
        calculatorInputStatus: {
          ...state.calculatorInputStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_CALCULATOR_INPUT_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        calculatorInputStatus: {
          ...state.calculatorInputStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // PATCH CALCULATOR INPUT
    case ACTIONS.REQUEST_PATCH_CALCULATOR_INPUT:
      return {
        ...state,
        calculatorInputStatus: {
          ...state.calculatorInputStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_PATCH_CALCULATOR_INPUT:
      return {
        ...state,
        results: action.resultsFields,
        calculatorInputStatus: {
          ...state.calculatorInputStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_PATCH_CALCULATOR_INPUT_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        calculatorInputStatus: {
          ...state.calculatorInputStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    default:
      return state;
  }
}

export default calculatorInput;
