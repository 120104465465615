import { selectFeatureFlag } from '@flopflip/react-redux';
import {
  getIsMilitary,
  getIsMilitaryDependent,
} from './commonHelper';

import STATUS from '../globalStatuses';
import flagNames from '../featureFlags';
import graduateProgramLevels from './constants';
import {
  PROGRAM_LEVEL_MAP,
  ORG_MS_LEVEL_MAP,
  PAGEPATH,
} from '../../modules/admissions/financial/constants';
import {
  sendDebouncedFullStoryEvent,
} from '../../modules/vendor/fullstory/FullStoryUpdater';
import {
  getSortedEmployerDiscountList,
} from '../domains/organization/employerDiscounts/employerDiscountCache';
import { PREVIOUS_WORK_EXPERIENCE_PROGRAMS } from '../../modules/admissions/work/constants';
import { getFeatureFlag } from './featureFlagHelper';

const START_FINANCIAL_TASKS = 11; // sagas workflow task
const FINANCIAL_SAGAS_VERSION = 3; // sagas workflow task

export const getIsSeekingFunding = reduxState => {
  const grants = reduxState?.calculatorInput?.results?.benefits?.value?.grants || [];
  const pellGrants = grants.find(({ type }) => type === 'PELL_GRANTS');
  return pellGrants?.dataPoints?.INTEND_TO_APPLY_FIN_AID === 'true';
};

export const isFAStudent = reduxState => {
  const fedLoans = (reduxState?.calculatorInput?.results?.contributions?.value || []).find(({ type }) => type === 'FEDERAL_LOAN');
  return getIsSeekingFunding(reduxState) || fedLoans?.dataPoints?.INTRST_IN_FED_LOAN === 'true';
};

export const getProgramOfferingCode = reduxState => {
  const programs = reduxState.calculatorInput?.results?.academicProgramOfferings?.value;

  if (!programs) return '';
  if (programs.length === 0) return '';

  return programs[0]?.academicProgram?.academicProgramCode || '';
};

export const getProgramOfferingVersion = reduxState => {
  const programs = reduxState.calculatorInput?.results?.academicProgramOfferings?.value;

  if (!programs) return '';
  if (programs.length === 0) return '';

  return programs[0]?.academicProgram?.academicProgramVersionCode || '';
};

export const formatProgramOfferingKey = reduxState => {
  const programs = reduxState.calculatorInput?.results?.academicProgramOfferings?.value;
  const programOfferingItem = reduxState?.programOfferingSearch?.programOfferingItem;

  if (!programs && !programOfferingItem) return '';

  let code = programOfferingItem?.programId;
  let version = programOfferingItem?.version;
  if (programs
    && programs.length > 0
    && programs[0]?.academicProgram?.academicProgramCode
    && programs[0]?.academicProgram?.academicProgramVersionCode) {
    code = programs[0].academicProgram.academicProgramCode;
    version = programs[0].academicProgram.academicProgramVersionCode;
  }

  if (code && version) {
    return `${code}_${version}`;
  }

  return '';
};

export const getProgramTemplateMapStatus = reduxState => {
  const { programTemplateMap } = reduxState.programOfferingSearch;

  if (Object.keys(programTemplateMap).length === 0 && programTemplateMap.constructor === Object) {
    return STATUS.UNFETCHED;
  }

  const templateMapKey = formatProgramOfferingKey(reduxState);

  if (!programTemplateMap[templateMapKey]) {
    return STATUS.UNFETCHED;
  }

  return programTemplateMap[templateMapKey].getStatus;
};

export const getProgramFinancialOptions = reduxState => {
  const programOffering = formatProgramOfferingKey(reduxState);
  const program = reduxState.programOfferingSearch
    ?.programTemplateMap[programOffering]
    ?.programTemplate || {};

  return {
    isCbeProgram: program?.cbeProgram === 'TRUE',
    isCbeDaProgram: program?.cbeDaProgram === 'TRUE',
    isGraduateStudent: program?.programLevel === 'GR' || program?.programLevel === 'DO',
    isTitleIvEligible: (program?.titleIVAYWeeks ?? 0) > 0,
    programType: program?.programType,
    isPrevWorkExpProgram: PREVIOUS_WORK_EXPERIENCE_PROGRAMS.includes(program?.programId),
  };
};

export const getSuppressFinancialGrants = reduxState => {
  const {
    isGraduateStudent,
    isTitleIvEligible,
    isCbeDaProgram,
  } = getProgramFinancialOptions(reduxState);

  const { ssn } = reduxState.wallet;

  const suppressed = getFeatureFlag(flagNames.NO_SSN_GRANTS_ENABLED)
    ? (
      // Suppress Grants Page if:
      !isTitleIvEligible // program is not Title IV Eligible
      || isCbeDaProgram // program is CBE DA
      || isGraduateStudent // is a Graduate Student
      // Handle the missing ssn with an alert on the page
    ) : (
      // Suppress Grants Page if:
      !isTitleIvEligible // program is not Title IV Eligible
      || isCbeDaProgram // program is CBE DA
      || isGraduateStudent // is a Graduate Student
      || !ssn // user has not provided an ssn
    );

  if (window.location.href.includes(PAGEPATH.choosePath)) {
    const program = reduxState.programOfferingSearch;
    sendDebouncedFullStoryEvent('NAE_PAGE_SUPPRESSED', {
      page: 'FinancialGrants',
      suppressed,
      data: {
        isTitleIvEligible,
        isGraduateStudent,
        ssnPresent: !!ssn,
        program: {
          id: program?.id,
          programId: program?.programId,
          version: program?.version,
          displayName: program?.displayName,
          titleIVAYWeeks: program?.titleIVAYWeeks,
        },
      },
    });
  }

  return suppressed;
};

export const getSuppressFinancialLoan = reduxState => {
  const {
    isTitleIvEligible,
    isCbeDaProgram,
  } = getProgramFinancialOptions(reduxState);

  // Suppress Loans Page if:
  // - The program is not title 4 eligible
  // - The program is a CBE-DA program
  return !isTitleIvEligible
    || isCbeDaProgram;
};

export const getFinancialPlanId = reduxState => {
  const finIds = reduxState?.application?.application?.externalSystemIds?.financialPlanId ?? [];
  return finIds[0] || '';
};

export const getCalcInputDependencyStatus = reduxState => {
  const calcInputResults = reduxState.calculatorInput.results;
  return calcInputResults.dependencyStatus?.value ?? 'I';
};

export const getIsDependentByAgeAndMilitary = state => {
  const birthYear = (state.person.information?.dateOfBirth || '').substring(0, 4);
  const aidYear = state.calculatorInput.results?.aidYear?.value || '';

  if (parseInt(birthYear, 10) < parseInt(aidYear, 10) - 24) {
    return false;
  }

  return getIsMilitaryDependent(state) || !getIsMilitary(state);
};

export const getIsDependent = state => {
  const isDependentByAgeAndMilitary = getIsDependentByAgeAndMilitary(state);

  if (!isDependentByAgeAndMilitary) {
    return false;
  }

  const pellGrant = (state.calculatorInput.results.benefits?.value?.grants || [])
    .find(({ type }) => type === 'PELL_GRANTS');

  if (pellGrant) {
    const isSingle = (pellGrant?.dataPoints?.PARENT_MARITAL_STATUS || '') === 'Single';
    const hasChildren = (pellGrant?.dataPoints?.STUDENT_CHILDREN || '') === 'true';
    return isSingle && !hasChildren;
  }

  return isDependentByAgeAndMilitary;
};

export function mapCalculatorInputFields(calculatorInput) {
  return calculatorInput.results.reduce((accumulator, current) => ({
    ...accumulator,
    [current.field]: {
      type: current.type,
      value: current.type === 'json' ? JSON.parse(current.value) : current.value,
    },
  }), {});
}

export const getFinancialTasksStarted = reduxState => {
  if (reduxState.task.workflowStatus.getStatus === STATUS.FETCHED
    && reduxState.task.workflow.version >= FINANCIAL_SAGAS_VERSION) {
    return reduxState.task.workflow.tasks.length >= START_FINANCIAL_TASKS;
  }

  return false;
};

export const getSuppressFinancialGradPlusLoan = reduxState => {
  const {
    academicYears = [],
    academicProgramOfferings = {},
  } = reduxState?.calculatorSummary?.results?.detail?.value?.program || {};

  const programLevel = (academicProgramOfferings?.academicAwardLevel?.name || '');
  const isGraduate = graduateProgramLevels.includes(programLevel);

  return !(isGraduate && academicYears.some(year => year.eligiblePlus === 'Y'));
};

export const getSuppressFinancialParentPlusLoan = reduxState => {
  const { academicYears = [] } = reduxState?.calculatorSummary?.results?.detail?.value?.program
    || {};

  return !(getIsDependent(reduxState) && academicYears.some(year => year.eligiblePlus === 'Y'));
};

export const militaryBenefitLevel = (academicAwardLevel, programType) => (
  programType === 'Certificate'
    ? `${academicAwardLevel} ${programType}`
    : academicAwardLevel
);

export const hasSecondaryMilitaryPageSelection = reduxState => (
  'intrstInSecondaryMilitaryBenefits' in (reduxState.calculatorInput.results?.military?.value || {})
);

export const isTapcapEligible = state => {
  // Currently just undergrad and non-cbe programs
  const programTemplateMap = state.programOfferingSearch?.programTemplateMap || {};
  const cbeProgram = programTemplateMap[Object.keys(programTemplateMap)[0]]?.programTemplate?.cbeProgram || 'FALSE';
  return cbeProgram === 'FALSE';
};

export const getTapcap = state => {
  // When the TAPCAP flag is off we always return null
  if (selectFeatureFlag(flagNames.TAPCAP_ENABLED)(state) && isTapcapEligible(state)) {
    const employerDiscounts = getSortedEmployerDiscountList() || [];
    const employments = state.person?.curEmployments || [];
    const level = ORG_MS_LEVEL_MAP[state.programOfferingSearch?.programOfferingItem?.programLevel || ''];

    // Match on duns number first
    const matches = employerDiscounts.filter(
      ed => (
        employments.some(employment => employment.dunsNumber?.padStart(9, '0') === ed.duns?.padStart(9, '0'))
      ),
    );
    if (matches.length > 0) {
      // Sort based to find the smallest cap
      const sorted = matches.map(entry => {
        const matchedDiscount = entry.benefits.find(d => d.tag === level && d.tapcap);
        return matchedDiscount
          ? {
            ...entry,
            matchedDiscount,
          } : null;
      }).filter(entry => entry).sort((a, b) => (Number.parseFloat(a.matchedDiscount?.tapcap)
        || 0) - (Number.parseFloat(b.matchedDiscount?.tapcap) || 0));
      if (sorted.length > 0) {
        return {
          accountName: sorted[0].accountName,
          duns: sorted[0].duns?.padStart(9, '0'),
          discount: sorted[0].matchedDiscount,
        };
      }
    }
  }
  return null;
};

export const isDunsAndProgramTapcapEligible = (state, duns) => {
  try {
    if (isTapcapEligible(state)) {
      const employerDiscounts = getSortedEmployerDiscountList() || [];
      const level = ORG_MS_LEVEL_MAP[state.programOfferingSearch?.programOfferingItem?.programLevel || ''];

      // Matches if these criteria are met
      // 1. Duns number matches for the employer/discount set
      const matchedEmployerWithTapcapDiscount = employerDiscounts.some(
        employerDiscount => (
          duns?.padStart(9, '0') === employerDiscount.duns?.padStart(9, '0')
          && employerDiscount.benefits.some(discount => (
            discount.tag === level
            // Ensure that tapcap has a value
            && discount.tapcap
          ))
        ),
      );
      return matchedEmployerWithTapcapDiscount;
    }
  } catch {
    // This process is not critical to the completion of the page so adding this for resiliency
  }
  return false;
};
