// import moment from 'moment';
import moment from 'moment-timezone';
import { MONTHS } from './constants';

export const getFullMonthList = () => MONTHS;

// drbishop: ES6 is your friend :)
// const getMonthListBefore = (previousMonth) =>
//  MONTHS.filter((_, index) => index < parseInt(previousMonth, 10));
export const getMonthListBefore = previousMonth => {
  const newMonths = [];
  for (let i = 0; ((i < MONTHS.length) && (i < parseInt(previousMonth, 10))); i += 1) {
    const monthItem = { ...MONTHS[i] };
    newMonths.push(monthItem);
  }

  return newMonths;
};

export const getEndMonthList = endYear => {
  const currentDate = new Date();
  const currentDateYear = currentDate.getFullYear().toString();
  const currentDateMonth = currentDate.getMonth() + 1;

  if (endYear && endYear === currentDateYear) {
    return getMonthListBefore(currentDateMonth);
  }
  return MONTHS;
};

export const getCurrentYear = () => {
  const currentDate = new Date();
  const currentDateYear = currentDate.getFullYear().toString();
  return currentDateYear;
};

// drbishop: const generateYearList = (yearFrom, yearTo = new Date().getFullYear()) =>
// ((years = Array.from({ length: (yearTo - yearFrom + 1) }, (_, index)
// => yearFrom + index).reverse())
// => (years.length > 0 ? years : [yearTo] ).map(year => ({value: `${year}`, label: `${year}`})))();
// Not as practical just for thinking
export const generateYearList = yearFrom => {
  let year = new Date().getFullYear();
  const yearArray = [];
  year += 1;
  do {
    year -= 1;
    yearArray.push({
      value: `${year}`, label: `${year}`,
    });
  } while (year > yearFrom);
  return yearArray;
};

export const getYearAfterList = (yearF, yearL) => yearL.filter(yr => yr.value >= yearF);

export const militaryBranchesArray = ['army', 'navy', 'coast_guard', 'air_force'];

export const getYearList = beginDate => {
  const year = new Date().getFullYear();
  let yearFrom = year - 50;
  if (beginDate && beginDate.length > 4) {
    yearFrom = parseInt(beginDate.substring(0, 4), 10) + 15;
  }
  return generateYearList(yearFrom);
};

export const compareValues = (key, order = 'asc') => (a, b) => {
  if (!Object.prototype.hasOwnProperty.call(a, key)
  || !Object.prototype.hasOwnProperty.call(b, key)) {
    return 0;
  }

  const varA = (typeof a[key] === 'string')
    ? a[key].toUpperCase() : a[key];
  const varB = (typeof b[key] === 'string')
    ? b[key].toUpperCase() : b[key];

  let comparison = 0;
  if (varA > varB) {
    comparison = 1;
  } else if (varA < varB) {
    comparison = -1;
  }
  return (
    (order === 'desc')
      ? (comparison * -1) : comparison
  );
};

export const isDateInPast = testDate => {
  const today = moment(new Date());
  const testDateVal = moment(testDate);
  if (today.isAfter(testDateVal)) {
    return true;
  }
  return false;
};

// convert mm/dd/yyyy to yyyy-mm-dd
export const convertSlashtoDashDateFormat = inputDate => inputDate.replace(/(\d\d)\/(\d\d)\/(\d{4})/, '$3-$1-$2');

// convert yyyy-mm-dd to mm/dd/yyyy
export const convertDashtoSlashDateFormat = inputDate => inputDate.replace(/(\d{4})-(\d\d)-(\d\d)/, '$2/$3/$1');

export const createB2BPayLoad = (
  eventName,
  employerBenefitType,
  empName,
  employerDunsNumber,
  vertical,
  sector,
  detail,
  channel,
) => ({
  eventName,
  employerBenefitType,
  empName,
  employerDunsNumber,
  vertical,
  sector,
  detail,
  channel,
  trackingSystem: ['FS'],
});

export const createB2BPayLoadWithValidation = (
  eventName,
  curEmployments = [],
  employerBenefits = {},
) => {
  const hasEmploymentInfo = curEmployments.length > 0;
  const hasValidDunsNumber = hasEmploymentInfo && curEmployments[0].dunsNumber.length > 2;
  const empName = hasEmploymentInfo ? curEmployments[0].companyName : '';
  const employerDunsNumber = hasValidDunsNumber ? curEmployments[0].dunsNumber : '';
  const employerBenefitType = hasValidDunsNumber ? employerBenefits.partnerType : '';
  const vertical = hasValidDunsNumber ? employerBenefits.industry : '';
  const sector = hasValidDunsNumber ? employerBenefits.sector : '';
  const detail = hasValidDunsNumber ? employerBenefits.detail : '';
  const channel = hasValidDunsNumber ? employerBenefits.channel : '';

  return {
    eventName,
    employerBenefitType,
    empName,
    employerDunsNumber,
    vertical,
    sector,
    detail,
    channel,
    trackingSystem: ['FS'],
  };
};

export const isVideoChatAvailable = () => {
  const websiteTimezone = 'America/Phoenix'; // MST timezone
  const nowMST = moment().tz(websiteTimezone);
  const dayOfWeekMST = nowMST.day();
  const hourMST = nowMST.hour();

  const availableDaysEnv = window.__ENV?.REACT_APP_VIDEO_IDVERIFY_AVAILABLE_DAYS;
  const availableHoursWeekdaysEnv = window.__ENV?.REACT_APP_VIDEO_IDVERIFY_AVAILABLE_HOURS_WEEKDAYS;
  const availableHoursSaturdayEnv = window.__ENV?.REACT_APP_VIDEO_IDVERIFY_AVAILABLE_HOURS_SATURDAY;

  if (!availableDaysEnv || !availableHoursWeekdaysEnv || !availableHoursSaturdayEnv) {
    console.warn('Environment variables for availability are not configured.');
    return false; // Or handle with a default availability
  }

  const availableDays = availableDaysEnv.split(',');

  // Check for weekdays (Monday to Friday)
  if (availableDays.includes('1-5') && dayOfWeekMST >= 1 && dayOfWeekMST <= 5) {
    const [startHour, endHour] = availableHoursWeekdaysEnv.split('-').map(Number);
    if (hourMST >= startHour && hourMST < endHour) {
      return true;
    }
  }

  // Check for Saturday
  if (availableDays.includes('6') && dayOfWeekMST === 6) {
    const [startHour, endHour] = availableHoursSaturdayEnv.split('-').map(Number);
    if (hourMST >= startHour && hourMST < endHour) {
      return true;
    }
  }

  return false;
};
