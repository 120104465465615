import axios from 'axios';
import idHeaders from '../interceptors/idHeaders';
import retryCallIfNeeded from '../../restCalls';

const adminBase = '/application/admin';

const maintenanceEndpoint = '/service/maintenance';
const adminEndpoint = '/login';

export default function AdminService() {
  axios.interceptors.request.use(idHeaders);
  const setServerMaintenance = async payload => retryCallIfNeeded(axios, () => axios
    .post(`${adminBase}${maintenanceEndpoint}`, payload), 1);
  const getServerMaintenance = async () => retryCallIfNeeded(axios, () => axios
    .get(`${adminBase}${maintenanceEndpoint}`));

  const adminLogin = async payload => retryCallIfNeeded(axios, () => axios
    .post(`${adminBase}${adminEndpoint}`, payload), 1);

  return {
    setServerMaintenance,
    getServerMaintenance,
    adminLogin,
  };
}
