import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Box } from '@material-ui/core';
import clsx from 'clsx';
import { Username } from '../core/username/Username';
import { Password } from '../core/password/Password';
import { FormFields } from './Credentials.fields';

export { default as credentialsSchema } from './Credentials.schema';

export const Credentials = ({
  classes, title, status, statusMessage,
}) => {
  const [credentialsStatus, setCredentialsStatus] = useState(false);

  useEffect(() => {
    if (status !== '') {
      setCredentialsStatus(true);
    }
  }, [status]);

  return (
    <>
      <Typography
        variant="h2"
        data-cy="login-information-section"
        id="login-information-label"
      >
        {title}
      </Typography>
      <Username />
      <Password />
      {credentialsStatus && (
      <Box className={status === 'success' ? classes.credentialsSuccess : classes.credentialsError} display={{ xs: 'none', sm: 'none', md: 'block' }}>
        <Typography align="left">
          {statusMessage}
        </Typography>
      </Box>
      )}
      <Button
        variant="contained"
        id={FormFields.login.id}
        type="submit"
        color="primary"
        className={clsx(classes.button, 'mainBtn')}
        disableRipple
        aria-describedby={FormFields.login.ariaDescribedBy}
        label={FormFields.login.label}
      >
        Submit
      </Button>
    </>
  );
};

Credentials.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string,
    credentialsError: PropTypes.string,
    credentialsSuccess: PropTypes.string,
  }),
  title: PropTypes.string,
  status: PropTypes.string,
  statusMessage: PropTypes.string,
};
Credentials.defaultProps = {
  classes: {},
  title: 'Enter your credentials',
  status: '',
  statusMessage: '',
};

export default Credentials;
