/* action types */
export const REQUEST_PERSON_CERTIFICATE = 'REQUEST_PERSON_CERTIFICATE';
export const REQUEST_PERSON_CERTIFICATE_ERROR = 'REQUEST_PERSON_CERTIFICATE_ERROR';
export const RECEIVE_PERSON_CERTIFICATE = 'RECEIVE_PERSON_CERTIFICATE';
export const REQUEST_SAVE_PERSON_CERTIFICATE = 'REQUEST_SAVE_PERSON_CERTIFICATE';
export const REQUEST_SAVE_PERSON_CERTIFICATE_ERROR = 'REQUEST_SAVE_PERSON_CERTIFICATE_ERROR';
export const RECEIVE_SAVE_PERSON_CERTIFICATE = 'RECEIVE_SAVE_PERSON_CERTIFICATE';
export const REQUEST_DELETE_PERSON_CERTIFICATE = 'REQUEST_DELETE_PERSON_CERTIFICATE';
export const REQUEST_DELETE_PERSON_CERTIFICATE_ERROR = 'REQUEST_DELETE_PERSON_CERTIFICATE_ERROR';
export const RECEIVE_DELETE_PERSON_CERTIFICATE = 'RECEIVE_DELETE_PERSON_CERTIFICATE';
