import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import styles from './styles';

function LoadingModal() {
  const classes = styles();

  return (
    <Modal open className={classes.modal}>
      <div aria-live="polite" aria-busy="true">
        <span
          className={classes.screenReaderText}
          id="loading-modal-title"
        >
          Loading
          {' '}
          <span className="sr-only">page content</span>
        </span>
        <Box className={classes.box}>
          <CircularProgress size={75} />
        </Box>
      </div>
    </Modal>
  );
}

export default LoadingModal;
