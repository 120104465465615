import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LoginInfo from '../login/LoginInfo';

const MaintenanceControl = ({ Services }) => {
  const [maintenanceEnabled, setMaintenanceEnabled] = useState(false);
  const [maintnenaceUpdateStatus, setMaintnenaceUpdateStatus] = useState('');
  const [maintenanceUpdateStatusMessage, setMaintenanceUpdateStatusMessage] = useState('');
  const resetStatus = () => {
    setMaintnenaceUpdateStatus('');
    setMaintenanceUpdateStatusMessage('');
  };
  const updateMaintenance = data => {
    resetStatus();
    const formattedData = { ...data };
    formattedData.maintenanceEnabled = formattedData.maintenanceEnabled.toLowerCase();
    Services.adminService.setServerMaintenance(formattedData)
      .then(response => {
        let podStatusesUpdated = true;
        const statuses = response.data.podsMaintenanceStatuses;
        if (statuses) {
          podStatusesUpdated = !Object.keys(statuses).some(podName => !statuses[podName]);
        }
        if (podStatusesUpdated) {
          const newStatus = response.data.maintenanceEnabled === 'true';
          setMaintenanceEnabled(newStatus);
          setMaintnenaceUpdateStatus('success');
          setMaintenanceUpdateStatusMessage(`Maintenance Page Turned ${newStatus ? 'ON' : 'OFF'} suceessfully`);
        } else {
          formattedData.podsMaintenanceStatuses = response.data.podsMaintenanceStatuses;
          updateMaintenance(formattedData);
        }
      }).catch(error => {
        setMaintnenaceUpdateStatus('failed');
        throw error;
      });
  };

  const getServerMaintenanceStatus = async () => {
    try {
      const serverStatus = await Services.adminService.getServerMaintenance();
      setMaintenanceEnabled(serverStatus.data.maintenanceEnabled === 'true');
    } catch (error) {
      setMaintnenaceUpdateStatus('failed');
      throw error;
    }
  };
  useEffect(() => {
    getServerMaintenanceStatus();
  }, []);

  return (
    <LoginInfo
      maintenance
      maintenanceEnabled={maintenanceEnabled}
      submitAction={updateMaintenance}
      status={maintnenaceUpdateStatus}
      customMessage={maintenanceUpdateStatusMessage}
    />
  );
};

MaintenanceControl.propTypes = {
  Services: PropTypes.shape({
    adminService: PropTypes.shape({
      getServerMaintenance: PropTypes.func.isRequired,
      setServerMaintenance: PropTypes.func.isRequired,
    }),
  }).isRequired,
};

export default MaintenanceControl;
