export const FormFields = {
  username: {
    id: 'username',
    fieldName: 'username',
    label: 'Username',
    ariaDescribedBy: 'username-helper-text',
    required: true,
  },
};

export const DefaultValues = {
  [FormFields.username.fieldName]: '',
};
