const aidYear = window.__ENV.REACT_APP_NEW_AID_YEAR;

export default {
  scholarshipsHeader: 'Tell us if you have any scholarships or other grants you may have already been awarded.',
  scholarshipsSummary: "List non-federal grants only -- don't include Federal Pell Grant funds. You'll have another opportunity to explore University-funded scholarships after you enroll in your program.",
  addScholarshipQuestion: 'Add a scholarship',
  additionalScholarshipsQuestion: 'Add another scholarship',
  scholarshipsAmountTooltip: 'Indicate the dollar amount of the scholarship or grant you expect to receive for the academic year.',
  scholarshipsFrequencyItems: [
    { value: 'yearly', label: 'Every year' },
    { value: 'one time', label: 'Only once' },
  ],
  scholarshipsPaidToItems: [
    { value: 'student', label: 'Paid to me' },
    { value: 'university', label: 'Paid to the school' },
  ],
  scholarshipsFinaidNote: `If applying for financial aid, the amount provided here may be calculated to reflect the expected benefit to be used between June 1, ${aidYear}, and May 31, ${Number(aidYear) + 1}, for financial processing.`,

  scholarshipsInputNameError: 'Enter the name of the scholarship or grant.',
  scholarshipsInputAmountError: 'Enter the amount of the scholarship or grant.',
  scholarshipsInputAmountNumberError: 'Enter only positive whole numbers with decimals and commas',
  scholarshipsInputFrequencyError: 'You must select one item from the drop-down menu to indicate that the scholarship/grant is to be paid yearly or only once.',
  scholarshipsInputPaidToError: 'You must select one item from the drop-down menu to indicate that the scholarship/grant is to be paid to either you or the school.',

  types: {
    json: 'json',
  },

  financialSection: {
    benefits: 'benefits',
    contributions: 'contributions',
    dependencyStatus: 'dependencyStatus',
    discounts: 'discounts',
    military: 'military',
  },
};
