import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    fontSize: theme.spacing(1.6),
    margin: 0,
    borderRadius: theme.spacing(0.4),
    borderColor: theme.palette.common.darkGrey,
    borderWidth: theme.spacing(0.1),
    borderStyle: 'solid',
    backgroundColor: theme.palette.background.lightBlue,
    padding: '1.2rem 1.2rem 1.8rem',
    [theme.breakpoints.up('sm')]: {
      margin: '0 -5rem',
      border: 0,
      padding: '3rem 5.6rem 4rem',
    },
    '& .iconIdent': {
      marginLeft: theme.spacing(4.8),
    },
  },
  title: {
    fontSize: '2rem!important',
    textAlign: 'center',
    paddingBottom: theme.spacing(1.2),
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.4rem!important',
      paddingBottom: theme.spacing(1.6),
    },
  },
  subTitle: {
    fontSize: '1.6rem!important',
    fontWeight: '300!important',
    textAlign: 'center',
    paddingBottom: theme.spacing(1.2),
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem!important',
      paddingBottom: theme.spacing(2.6),
    },
  },
  orderedList: {
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 0,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'stretch',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    '& li': {
      maxWidth: '400px',
      width: '100%',
      margin: '0 auto',
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      borderColor: theme.palette.common.darkGrey,
      borderStyle: 'solid',
      borderWidth: '0 0 1px 0',
      [theme.breakpoints.up('md')]: {
        borderWidth: '0 1px 0 0',
        marginLeft: 'unset',
        marginRight: 'unset',
      },
      '&:last-child': {
        [theme.breakpoints.up('sm')]: {
          borderWidth: 0,
          marginBottom: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        },
        // [theme.breakpoints.up('md')]: {
        //   marginBottom: theme.spacing(2),
        //   paddingBottom: theme.spacing(2),
        // },
      },
    },
  },
  listItem: {},
  header: {
    fontSize: '2rem!important',
    textAlign: 'center',
    fontWeight: '700!important',
    color: theme.palette.text.darkBlue,
    [theme.breakpoints.up('sm')]: {},
  },
  paragraph: {
    paddingBottom: theme.spacing(1.2),
    textAlign: 'center',
  },
  childContainer: {
    textAlign: 'center',
  },
  linkContainer: {
    textAlign: 'center',
  },
  link: {
    color: theme.palette.common.blue,
    textDecoration: 'underline',
    border: 0,
    background: 'none',
    fontSize: theme.spacing(1.6),
  },
  verifySuccessContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto 0 auto',
    maxWidth: '92rem',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(0.4),
    padding: theme.spacing(1),
  },
  headerSuccess: {
    fontSize: '2rem!important',
    textAlign: 'center',
    paddingBottom: theme.spacing(1.2),
    marginBottom: '1rem!important',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.4rem!important',
      paddingBottom: 0,
    },
  },
}));
