import * as yup from 'yup';
import FormFields from './Maintenance.fields';

const maintenanceSchema = () => yup.object().shape({
  [FormFields.maintenance.name]: yup.mixed()
    .oneOf(['True', 'False'], 'Enable or Disable Maintenance Page')
    .required(),
});

export default maintenanceSchema;
