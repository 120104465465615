import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useWatch } from 'react-hook-form';
import {
  Grid,
  FormHelperText,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  Typography,
} from '@material-ui/core';
import HelpTooltip from '../../../tooltips/help-tooltip/HelpTooltip';

const LegendHeading = (title, titleHeading, titleHeadingClassName) => {
  if (!title) {
    return null;
  }
  let HeadingTag;
  switch (titleHeading) {
    case 'h2':
      HeadingTag = 'h2';
      break;
    case 'h3':
      HeadingTag = 'h3';
      break;
    case 'h4':
      HeadingTag = 'h4';
      break;
    case 'h5':
      HeadingTag = 'h5';
      break;
    case 'h6':
      HeadingTag = 'h6';
      break;
    case 'p':
      HeadingTag = 'p';
      break;
    default:
      HeadingTag = 'h2';
      break;
  }

  return <HeadingTag className={titleHeadingClassName}>{title}</HeadingTag>;
};

const ConfirmRadio = ({
  ariaDescribedBy,
  ariaLabelledBy,
  id,
  name,
  title,
  titleHeading,
  titleHeadingClassName,
  tooltipText,
  description,
  descriptionNote,
  control,
  className,
  trueLabel,
  falseLabel,
  onChangeCallback,
  trueEnabled,
  falseEnabled,
  hideDisabled,
  ariaLabel,
  hideRadio,
  children,
}) => {
  const formControlName = useWatch({
    name,
    control,
  });

  return (
    <fieldset id={`${id}-radio-group`} className={`radio-group-fieldset ${hideRadio ? 'hideRadio' : ''}`}>
      {children && (
        <legend>{children}</legend>
      )}
      {!children && title && (
        <legend>
          <Grid container>
            <Grid item className="headerWithTooltip">
              {LegendHeading(title, titleHeading, titleHeadingClassName)}
            </Grid>
            <Grid item>
              {tooltipText && (
                <span className="headerTooltip-btn">
                  <HelpTooltip
                    id={`${id}-tooltip`}
                    placement="top"
                    iconSize="inherit"
                    title={tooltipText}
                  />
                </span>
              )}
            </Grid>
          </Grid>
        </legend>
      )}
      {!children && description && (
        <Typography
          className={descriptionNote ? 'note desc-radio-group' : 'desc-radio-group'}
        >
          {description}
        </Typography>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl className="radio-group" data-error={invalid}>
            <RadioGroup
              {...field}
              id={`${id}-input`}
              aria-labelledby={ariaLabelledBy}
              aria-describedby={ariaDescribedBy}
              aria-label={ariaLabel}
              row
              onChange={event => {
                field.onChange(event);
                onChangeCallback(event.target.value);
              }}
              className={className}
              style={hideRadio ? { display: 'none' } : {}}
              data-radio-value={formControlName}
            >
              {(trueEnabled || !hideDisabled) && (
              <FormControlLabel
                value="True"
                control={(
                  <Radio
                    id={`${id}-${trueLabel.replace(' ', '').toLowerCase()}`}
                    color="default"
                  />
              )}
                label={trueLabel}
                disabled={!trueEnabled}
                style={{ textDecoration: trueEnabled ? 'none' : 'line-through' }}
                // data-radio={trueLabel}
                data-radio="Yes"
              />
              )}
              {(falseEnabled || !hideDisabled) && (
              <FormControlLabel
                value="False"
                control={(
                  <Radio
                    id={`${id}-${falseLabel.replace(' ', '').toLowerCase()}`}
                    color="default"
                  />
            )}
                label={falseLabel}
                disabled={!falseEnabled}
                style={{ textDecoration: falseEnabled ? 'none' : 'line-through' }}
                // data-radio={falseLabel}
                data-radio="No"
              />
              )}
            </RadioGroup>
            {invalid && (
              <FormHelperText id={`${id}-helper-text`} error={invalid}>
                {error?.message}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </fieldset>
  );
};

ConfirmRadio.propTypes = {
  ariaDescribedBy: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  titleHeading: PropTypes.string,
  titleHeadingClassName: PropTypes.string,
  tooltipText: PropTypes.string,
  description: PropTypes.string,
  descriptionNote: PropTypes.bool,
  control: PropTypes.shape({}).isRequired,
  required: PropTypes.bool,
  className: PropTypes.string,
  trueLabel: PropTypes.string,
  falseLabel: PropTypes.string,
  trueEnabled: PropTypes.bool,
  falseEnabled: PropTypes.bool,
  hideDisabled: PropTypes.bool,
  onChangeCallback: PropTypes.func,
  ariaLabel: PropTypes.string,
  children: PropTypes.element,
  hideRadio: PropTypes.bool,
};

ConfirmRadio.defaultProps = {
  title: '',
  titleHeading: 'h2',
  titleHeadingClassName: '',
  tooltipText: '',
  description: '',
  descriptionNote: false,
  ariaDescribedBy: '',
  ariaLabelledBy: '',
  required: false,
  className: '',
  trueLabel: 'Yes',
  falseLabel: 'No',
  trueEnabled: true,
  falseEnabled: true,
  hideDisabled: true,
  onChangeCallback: () => {},
  ariaLabel: '',
  children: null,
  hideRadio: false,
};

export default ConfirmRadio;
