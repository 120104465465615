/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Typography, Grid,
  Divider,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  ReactComponent as TryAgainIcon,
} from '../../modules/admissions/components/icons/try-again-icon.svg';
import {
  ReactComponent as IdFailure,
} from '../../modules/admissions/components/icons/id-failure.svg';
import styles from './styles';
import { phoneNumber10Digit, UOPX_ESI_EMAIL, UOPX_ESI_PHONE_NUMBER } from '../../modules/admissions/constants';
import IdentifyVerifyRetryConstants from './constants';

export default function IdentityVerifyRetry({
  numsOfRetryLeft,
  videoChatEnabled,
  verificationFlowType,
}) {
  const classes = styles();
  const getTitleHeaderText = () => {
    if (numsOfRetryLeft === 2) {
      return IdentifyVerifyRetryConstants.titleHeaderTextOne;
    }
    if (numsOfRetryLeft === 1) {
      return IdentifyVerifyRetryConstants.titleHeaderTextTwo;
    }
    return IdentifyVerifyRetryConstants.titleHeaderTextThree;
  };

  const renderAttemptsSection = () => {
    if (numsOfRetryLeft > 0) {
      const endAttemptText = numsOfRetryLeft > 1
        ? IdentifyVerifyRetryConstants.endAttemptTextPlural
        : IdentifyVerifyRetryConstants.endAttemptTextSingular;

      return (
        <>
          <Grid item>
            <Typography className={classes.paragraph}>
              {IdentifyVerifyRetryConstants.startAttemptText}
              {' '}
              <span className={classes.boldText}>{numsOfRetryLeft}</span>
              {' '}
              {endAttemptText}
            </Typography>
          </Grid>
          <Grid item>
            <Divider className={classes.divider} />
          </Grid>
        </>
      );
    }
    return null;
  };

  return (
    <>
      {verificationFlowType === 'regBuild'
        ? (<h1 className="sr-only">Identity Verification Failure</h1>)
        : null}
      <div className={classes.verifyRetryContainer}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className={numsOfRetryLeft <= 0 ? classes.paddingBottom : ''}>
            {numsOfRetryLeft > 0 ? <TryAgainIcon /> : <IdFailure />}
          </Grid>
          <Grid item>
            <Typography variant="h2" className={classes.title}>
              {videoChatEnabled ? (
                IdentifyVerifyRetryConstants.titleHeaderTextTwo
              ) : (
                getTitleHeaderText()
              )}
            </Typography>
          </Grid>
          {renderAttemptsSection()}
          <Grid item>
            {videoChatEnabled ? (
              <Typography variant="h3" className={classes.questionHeader}>
                {IdentifyVerifyRetryConstants.questionHeaderTextFour}
              </Typography>
            ) : (
              <Typography variant="h3" className={classes.questionHeader}>
                {numsOfRetryLeft > 0 ? IdentifyVerifyRetryConstants.questionHeaderTextOne
                  : IdentifyVerifyRetryConstants.questionHeaderTextTwo}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Typography className={classes.paragraph}>
              {videoChatEnabled ? (
                <>
                  {IdentifyVerifyRetryConstants.introContactsTextTwo}
                  {' '}
                </>
              ) : (
                <>
                  {IdentifyVerifyRetryConstants.introContactsText}
                  {' '}
                </>
              )}
              <Button
                className="defaultCtaBtn textLink idVerifyPhoneNumber"
                variant="text"
                href={`tel:+1${phoneNumber10Digit(UOPX_ESI_PHONE_NUMBER)}`}
                aria-label={`For the identity verification call us at ${phoneNumber10Digit(
                  UOPX_ESI_PHONE_NUMBER,
                )}`}
                disableRipple
              >
                {UOPX_ESI_PHONE_NUMBER}
              </Button>
              {' '}
              {IdentifyVerifyRetryConstants.endContactsText}
              {/* <br />
              {IdentifyVerifyRetryConstants.introEmailText}
              <Button
                className="defaultCtaBtn textLink idVerifyEmail"
                variant="text"
                href={`mailto:${UOPX_ESI_EMAIL}`}
                aria-label={`For the identity verification email us at ${UOPX_ESI_EMAIL}`}
                disableRipple
              >
                {UOPX_ESI_EMAIL}
              </Button>
              {IdentifyVerifyRetryConstants.endEmailText} */}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

IdentityVerifyRetry.defaultProps = {
  verificationFlowType: '',
};

IdentityVerifyRetry.propTypes = {
  numsOfRetryLeft: PropTypes.number.isRequired,
  videoChatEnabled: PropTypes.bool.isRequired,
  verificationFlowType: PropTypes.string,
};
