export default {
  // Field Constants
  willReceiveBenefitsField: 'RECV_EMP_BENEFITS',
  willUseBenefitsField: 'USE_EMP_BENEFITS',
  reimburseOrBillField: 'REIM_OR_BILL',
  dollarOrPercentageField: 'BENEFIT_UNIT',
  benefitAmountField: 'BENEFIT_AMOUNT',
  percentageCapField: 'ANNUAL_CAP_AMT_IN_CURRENCY',
  fiscalDayField: 'FISCAL_START_DAY',
  fiscalMonthField: 'FISCAL_START_MONTH',

  // UoPX Affiliation
  affiliationHeader: 'University of Phoenix Affiliation',
  affiliationQuestion: 'Do you have an affiliation with a University of Phoenix employee or faculty member?',
  affiliationsItems: [
    { value: 'Employee', label: 'Employee' },
    { value: 'EmployeeSpouse', label: 'Spouse of Employee' },
    { value: 'EmployeeChildren', label: 'Dependent Child of Employee' },
    { value: 'EmployeePartner', label: 'Domestic Partner of Employee' },
    { value: 'Faculty', label: 'Faculty' },
    { value: 'FacultySpouse', label: 'Spouse of Faculty' },
    { value: 'FacultyChildren', label: 'Dependent Child of Faculty' },
    { value: 'FacultyPartner', label: 'Domestic Partner of Faculty' },
  ],

  // Associations
  associationsHeader: 'Memberships and Associations',
  associationsSummary: 'University of Phoenix collaborates with various associations to provide academic solutions for working adults; including special tuition reductions, grants and fee waivers.',
  associationsQuestion: 'Are you involved with any associations?',
  associationsNote: 'Begin typing the full name of your association and select the name if displayed. Please do not use abbreviations or acronyms. For example, use National Organization of Black Law Enforcement Executives instead of NOBLE.',

  // Errors
  associationsEmptyError: 'Enter information for your association.',
  uopxAffiliationEmptyError: 'You must select one item from the drop-down menu.',

  types: {
    json: 'json',
    plusLoan: 'PLUS_LOAN',
    federalLoan: 'FEDERAL_LOAN',
    pellGrants: 'PELL_GRANTS',
    tuition: 'TUITION',
    booksResources: 'BOOKS_RESOURCES',
    affiliationDiscount: 'AFFILIATION_DISCOUNT',
    associationDiscount: 'ASSOCIATION_DISCOUNT',
    tribalBenefit: 'TRIBAL_BENEFIT',
    tribalDiscount: 'TRIBAL_DISCOUNT',
    employerDiscount: 'EMPLOYER_DISCOUNT',
    personal: 'PERSONAL',
  },

  dataPoint: {
    recvUopxEmployee: 'RECV_UOPXEMPLOYEE',
    recvAssociations: 'RECV_ASSOCIATIONS',
    currency: 'CURRENCY',
    percentage: 'PERCENTAGE',
    reimbursement: 'REIMBURSEMENT',
    directBill: 'DIRECT_BILL',
  },

  financialSection: {
    benefits: 'benefits',
    contributions: 'contributions',
    dependencyStatus: 'dependencyStatus',
    discounts: 'discounts',
    military: 'military',
  },

};
