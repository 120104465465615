import { ACTIONS } from '../types';
import STATUS from '../../../globalStatuses';

export const militaryCountryReductions = {
  [ACTIONS.REQUEST_MILITARY_COUNTRIES]: state => ({
    ...state,
    militaryCountriesStatus: {
      ...state.militaryCountriesStatus,
      getStatus: STATUS.FETCHING,
      errorData: null,
    },
  }),
  [ACTIONS.RECEIVE_MILITARY_COUNTRIES]: (state, action) => ({
    ...state,
    militaryCountries: action.payload.militaryCountries,
    militaryCountriesStatus: {
      ...state.militaryCountriesStatus,
      getStatus: STATUS.FETCHED,
    },
  }),
  [ACTIONS.RECEIVE_MILITARY_COUNTRIES_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    militaryCountriesStatus: {
      ...state.militaryCountriesStatus,
      getStatus: STATUS.ERROR,
      errorData: action.error,
    },
  }),
};

export default militaryCountryReductions;
