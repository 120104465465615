export default {
  ONBOARDING_DYNAMIC: 'onboardDynamicEnabled',
  PROGRAM_ONLINE_ONLY: 'onlineProgramOnlyEnabled',
  GENESYS_CHAT_ENABLED: 'genesysChatEnabled',
  GENESYS_COBROWSE_ENABLED: 'genesysChatCobrowseEnabled',
  GENESYS_TEXT_ENABLED: 'genesysTextEnabled',
  QUICKAPP_PERSON_IMPORT_ENABLED: 'quickAppPersonImportEnabled',
  BRIDGE_PROGRAM_ENABLED: 'bridgeProgramEnabled',
  QUICKAPP_FORCE_LOGOUT: 'quickAppForceLogout',
  INVITE_TO_APPLY_PROGR_EXCLUDE_ENABLED: 'inviteToApplyProgramExcludedEnabled',
  BLACKBOARD_COLLABORATION_ENABLED: 'blackBoardCollaborationEnabled',
  MAINTENANCE_PAGE_ENABLED: 'maintenancePageEnabled',
  ENABLE_SAGAS_CANARY_DEPLOYMENT: 'enableSagasCanaryDeployment',
  ENABLE_POST_SUB_TASKS_MODAL: 'enablePostSubTasksModal',
  ESIGN_CALLBACK_ENABLED: 'esigncallbackEnabled',
  NAVIGATION_ENABLED: 'navigationEnabled',
  REENROLL_REENTRY_ENABLED: 'reentryRenrollEnabled',
  ALUMNI_ONLY_ENABLED: 'alumniOnlyEnabled',
  SAGA_REDIRECT_MODAL_ENABLED: 'sagaRedirectModalEnabled',
  TAPCAP_ENABLED: 'tapcapEnabled',
  SCLP_TEST_ENABLED: 'sclpTestEnabled',
  LOAN_AND_CONTRIBUTION_MERGE_TEST_ENABLED: 'loanAndContributionMergeTestEnabled',
  REENROLL_ONLINE_ONLY_ENABLED: 'reenrollOnlineOnlyEnabled',
  REENROLL_TEMPLATE_MS_ENABLED: 'reenrollTemplateMSEnabled',
  COGNITO_VALIDATION_ENABLED: 'cognitoValidationEnabled',
  REMOVE_PUB_CLIENT_WALKIN_ENABLED: 'removePubClientWalkinEnabled',
  CONTACT_V2_ENABLED: 'contactV2Enabled',
  PROGRAM_CHANGE_ENABLED: 'programChangeEnabled',
  ACKNOWLEDGEMENTS_TEST_ENABLED: 'acknowledgementsTestEnabled',
  ENABLE_APP_EXPIRATION: 'enableAppExpiration',
  ENABLE_ALLOY_EVENT: 'enableAlloyEvent',
  ENABLE_NAVIGATION_SWITCH: 'navigationSwitchEnabled',
  DGSP_ENABLED: 'dgspEnabled',
  NO_SSN_GRANTS_ENABLED: 'noSsnGrantsEnabled',
  ENABLE_APP_STATUS_SYNC_SRM: 'enableAppStatusSyncSrm',
  ENABLE_NEW_NAVIGATION: 'enableNewNavigation',
  SHOW_EMPLOYER_DROPDOWN: 'showEmployerDropdown',
  SHOW_EMPLOYER_BENEFIT_MSG: 'showEmployerBenefitMsg',
  CYOP_REDESIGN_ENABLED: 'cyopRedesignEnabled',
  ENABLE_DASHBOARD_SWITCH: 'dashboardSwitchEnabled',
  PROGRAMCHANGE_AFTER_REG: 'programChangeAfterReg',
  NAE_DASHBOARD_ENABLED: 'dashboardEnabled',
  NAE_DASHBOARD_FIRST: 'dashboardFirst',
  FORGEROCK_SSO_ENABLED: 'forgerockSsoEnabled',
  ENABLE_ACPREPOP_COOKIE: 'enableACPrepopCookie',
  UPLOAD_TRANSCRIPT_DOCUMENT: 'uploadTranscriptEnabled',
  MOBILE_ADA_AUTOCOMPLETE_ENABLED: 'mobileAdaAutocompleteEnabled',
  RECAPTCHA_ENABLED: 'reCaptchaEnabled',
  SHOW_EMPLOYER_BORROW_MSG: 'showEmployerBorrowMsg',
  AUTO_CHECK_EMPLOYER_ON_CYOP: 'autoSelectEmployerBenefitOnCYOP',
  ENABLE_SAI_CALCULATOR: 'enableSaiCalculator',
  ENABLE_ESI_INDICATOR: 'enableEsiIndicator',
  ENABLE_DV_INTEGRATION: 'enableDVIntegration',
  ENABLE_SAVED_PROGRAM_INFO: 'enableSavedProgramInfo',
  ENABLE_IDENTITY_VERIFICATION: 'enableIdentityVerification',
  IDENTITY_VERIFY_DESKTOP_ONLY: 'identityVerifyDesktopOnly',
  IDENTITY_VERIFY_USE_SEGMENT: 'identityVerifyUseSegment',
  ADMISSIONS_IDENTITYVERIFY_ROUTING: 'identityVerifyPageEnabled',
  ENABLE_DUP_SSN_CHECK: 'enableDupSsnCheck',
  ENABLE_NEW_POSTSIGN: 'enableNewPostsign',
  ENABLE_CRIMINAL_CONVICTION_INFO: 'enableCriminalConvictionInfo',
  ENABLE_GEN3: 'enableGen3',
  VIDEO_CHAT_ENABLED: 'videoChatEnabled',
  ENABLE_DATAVISOR_POSTSIGN: 'enableDatavisorPostSign',
  ENABLE_MODIFIED_TAPCAP_FLOW: 'enableTapCapFlow',
};
