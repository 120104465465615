import militaryBenefits from "../../../store/domains/financialaid/militarybenefits/reducer";

export const financialSection = {
  benefits: 'benefits',
  contributions: 'contributions',
  dependencyStatus: 'dependencyStatus',
  discounts: 'discounts',
  military: 'military',
};

export const status = {
  dependent: 'D',
  independent: 'I',
};

export const dataPoint = {
  recvUopxEmployee: 'RECV_UOPXEMPLOYEE',
  recvAssociations: 'RECV_ASSOCIATIONS',
  currency: 'CURRENCY',
  percentage: 'PERCENTAGE',
  reimbursement: 'REIMBURSEMENT',
  directBill: 'DIRECT_BILL',
};

export const types = {
  json: 'json',
  plusLoan: 'PLUS_LOAN',
  federalLoan: 'FEDERAL_LOAN',
  pellGrants: 'PELL_GRANTS',
  tuition: 'TUITION',
  booksResources: 'BOOKS_RESOURCES',
  affiliationDiscount: 'AFFILIATION_DISCOUNT',
  associationDiscount: 'ASSOCIATION_DISCOUNT',
  tribalBenefit: 'TRIBAL_BENEFIT',
  tribalDiscount: 'TRIBAL_DISCOUNT',
  employerDiscount: 'EMPLOYER_DISCOUNT',
  personal: 'PERSONAL',
};

export const PAGENAME = {
  INTRO: 'intro',
  CHOOSEPATH: 'choosePath',
  MILITARYBENEFITS: 'militaryBenefits',
  MILITARYBENEFITSSECONDARY: 'militaryBenefitsSecondary',
  FEDERALGRANTS: 'federalGrants',
  EMPLOYERBENEFITS: 'employerBenefits',
  ASSOCIATIONS: 'associations',
  SCHOLARSHIPS: 'scholarships',
  TRIBALBENEFITS: 'tribalBenefits',
  PERSONALCONTRIBUTION: 'personalContribution',
  CONTRIBUTIONS: 'contributions',
  LOANS: 'loans',
  SUMMARY: 'summary',
};

export const PAGEPATH = {
  intro: 'financial/introduction',
  choosePath: 'financial/choose-your-path',
  militaryBenefits: 'financial/military',
  militaryBenefitsSecondary: 'financial/military/secondary',
  federalGrants: 'financial/grants',
  employerBenefits: 'financial/employer-benefits',
  associations: 'financial/associations',
  scholarships: 'financial/scholarships',
  tribalBenefits: 'financial/tribal-benefits',
  personalContribution: 'financial/personal',
  contributions: 'financial/contributions',
  loans: 'financial/loan',
  summary: 'financial/summary',
};

export const PROGRAM_LEVEL_MAP = {
  A: 'Associates',
  UG: 'Undergraduate',
  GR: 'Graduate',
  DO: 'Doctoral',
};

// Map for Program level to Organization MS response
export const ORG_MS_LEVEL_MAP = {
  UG: 'undergrad',
  GR: 'grad',
  DO: 'doctoral',
  A: 'undergrad',
};

export default {
  types,
  dataPoint,
  status,
  financialSection,
};
