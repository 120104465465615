import { errorToMessage } from '../../../../errorMessaging';
import STATUS from '../../../globalStatuses';
import { ACTIONS } from './types';

function militaryBenefits(
  state = {
    primary: {
      results: {},
      militaryBenefitsStatus: {
        getStatus: STATUS.UNFETCHED,
        modifyStatus: STATUS.UNFETCHED,
        errorData: null,
      },
    },
    secondary: {
      results: {},
      militaryBenefitsStatus: {
        getStatus: STATUS.UNFETCHED,
        modifyStatus: STATUS.UNFETCHED,
        errorData: null,
      },
    },
  },
  action,
) {
  switch (action.type) {
    // POST MILITARY BENEFITS
    case ACTIONS.REQUEST_POST_MILITARY_BENEFITS:
      return {
        ...state,
        primary: {
          ...state.primary,
          militaryBenefitsStatus: {
            ...state.primary.militaryBenefitsStatus,
            getStatus: STATUS.FETCHING,
            errorData: null,
          },
        },
      };
    case ACTIONS.RECEIVE_POST_MILITARY_BENEFITS:
      return {
        ...state,
        primary: {
          ...state.primary,
          results: action.militaryBenefits,
          militaryBenefitsStatus: {
            ...state.primary.militaryBenefitsStatus,
            getStatus: STATUS.FETCHED,
          },
        },
      };
    case ACTIONS.REQUEST_POST_MILITARY_BENEFITS_ERROR:
      return {
        ...state,
        primary: {
          ...state.primary,
          error: errorToMessage(action.error),
          militaryBenefitsStatus: {
            ...state.primary.militaryBenefitsStatus,
            getStatus: STATUS.ERROR,
            errorData: action.error,
          },
        },
      };
    // POST SECONDARY MILITARY BENEFITS
    case ACTIONS.CLEAR_SECONDARY_MILITARY_BENEFITS:
      return {
        ...state,
        secondary: {
          ...state.secondary,
          results: {},
          militaryBenefitsStatus: {
            ...state.secondary.militaryBenefitsStatus,
            getStatus: STATUS.UNFETCHED,
            modifyStatus: STATUS.UNFETCHED,
            errorData: null,
          },
        },
      };
    case ACTIONS.REQUEST_POST_SECONDARY_MILITARY_BENEFITS:
      return {
        ...state,
        secondary: {
          ...state.secondary,
          militaryBenefitsStatus: {
            ...state.secondary.militaryBenefitsStatus,
            getStatus: STATUS.FETCHING,
            modifyStatus: STATUS.UPDATING,
            errorData: null,
          },
        },
      };
    case ACTIONS.RECEIVE_POST_SECONDARY_MILITARY_BENEFITS:
      return {
        ...state,
        secondary: {
          ...state.secondary,
          results: action.militaryBenefits,
          militaryBenefitsStatus: {
            ...state.secondary.militaryBenefitsStatus,
            getStatus: STATUS.FETCHED,
            modifyStatus: STATUS.UPDATED,
          },
        },
      };
    case ACTIONS.REQUEST_POST_SECONDARY_MILITARY_BENEFITS_ERROR:
      return {
        ...state,
        secondary: {
          ...state.secondary,
          error: errorToMessage(action.error),
          militaryBenefitsStatus: {
            ...state.secondary.militaryBenefitsStatus,
            getStatus: STATUS.ERROR,
            modifyStatus: STATUS.ERROR,
            errorData: action.error,
          },
        },
      };
    default:
      return state;
  }
}

export default militaryBenefits;
