import { errorToMessage } from '../../../../errorMessaging';
import STATUS from '../../../globalStatuses';
import {
  REQUEST_PERSON_MILITARY,
  RECEIVE_PERSON_MILITARY,
  REQUEST_PERSON_MILITARY_ERROR,
  REQUEST_POST_PERSON_MILITARY,
  RECEIVE_POST_PERSON_MILITARY,
  REQUEST_POST_PERSON_MILITARY_ERROR,
  REQUEST_PUT_PERSON_MILITARY,
  RECEIVE_PUT_PERSON_MILITARY,
  REQUEST_PUT_PERSON_MILITARY_ERROR,
  REQUEST_DELETE_PERSON_MILITARY,
  RECEIVE_DELETE_PERSON_MILITARY,
  REQUEST_DELETE_PERSON_MILITARY_ERROR,
  CLEAR_PERSON_MILITARY_MODIFY_STATUS,
} from './types';

function military(
  state = {
    military: {},
  },
  action,
) {
  switch (action.type) {
    // GET Military
    case REQUEST_PERSON_MILITARY:
      return {
        ...state,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_PERSON_MILITARY:
      return {
        ...state,
        militaryETag: action.etag,
        military: action.military,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_PERSON_MILITARY_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // POST Military
    case REQUEST_POST_PERSON_MILITARY:
      return {
        ...state,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_POST_PERSON_MILITARY:
      return {
        ...state,
        militaryETag: action.etag,
        military: action.military,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case REQUEST_POST_PERSON_MILITARY_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // PUT Military
    case REQUEST_PUT_PERSON_MILITARY:
      return {
        ...state,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_PUT_PERSON_MILITARY:
      return {
        ...state,
        militaryETag: action.etag,
        military: action.military,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case REQUEST_PUT_PERSON_MILITARY_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // DELETE Military
    case REQUEST_DELETE_PERSON_MILITARY:
      return {
        ...state,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_DELETE_PERSON_MILITARY:
      return {
        ...state,
        military: action.military,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case REQUEST_DELETE_PERSON_MILITARY_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case CLEAR_PERSON_MILITARY_MODIFY_STATUS:
      return {
        ...state,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.UNFETCHED,
        },
      };
    default:
      return state;
  }
}

export default military;
