import { errorToMessage } from '../../../errorMessaging';
import STATUS from '../../globalStatuses';
import {
  REQUEST_POST_FORGEROCK,
  RECEIVE_POST_FORGEROCK,
  REQUEST_POST_FORGEROCK_ERROR,
} from './types';

function forgerock(
  state = { },
  action,
) {
  switch (action.type) {
    case REQUEST_POST_FORGEROCK:
      return {
        ...state,
        forgerockStatus: {
          ...state.forgerockStatus,
          modifyStatus: STATUS.UPDATING,
          errorDate: null,
        },
      };
    case RECEIVE_POST_FORGEROCK:
      return {
        ...state,
        forgerockStatus: {
          ...state.forgerockStatus,
          modifyStatus: STATUS.UPDATED,
          errorDate: null,
        },
      };
    case REQUEST_POST_FORGEROCK_ERROR:
      return {
        ...state,
        error: `FR-${errorToMessage(action.error)}`,
        forgerockStatus: {
          ...state.forgerockStatus,
          modifyStatus: STATUS.ERROR,
          errorDate: action.error,
        },
      };
    default:
      return state;
  }
}

export default forgerock;
