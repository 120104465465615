import { errorToMessage } from '../../../../errorMessaging';
import STATUS from '../../../globalStatuses';
import { ACTIONS } from './types';
import {
  setSortedEmployerDiscountList,
  setEmployerDiscountMap,
  setEmployerDiscountsGroupedAlphabetically,
} from './employerDiscountCache';

function employerDiscounts(
  state = {
    employerDiscounts: {},
  },
  action,
) {
  switch (action.type) {
    // GET
    case ACTIONS.REQUEST_EMPLOYER_DISCOUNTS:
      return {
        ...state,
        employerDiscountsStatus: {
          ...state.employerDiscountsStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_EMPLOYER_DISCOUNTS:
      setSortedEmployerDiscountList(action.sortedEmployerDiscountList);
      setEmployerDiscountMap(action.employerDiscountMap);
      setEmployerDiscountsGroupedAlphabetically(action.employerDiscountsGroupedAlphabetically);
      return {
        ...state,
        employerDiscountsStatus: {
          ...state.employerDiscountsStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.RECEIVE_EMPLOYER_DISCOUNTS_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        employerDiscountsStatus: {
          ...state.employerDiscountsStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.REQUEST_EMP_BENEFIT_INFO:
      return {
        ...state,
        employerDiscountStatus: {
          ...state.employerDiscountStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_EMP_BENEFIT_INFO:
      return {
        ...state,
        employerDiscount: action.employerData,
        employerDiscountStatus: {
          ...state.employerDiscountStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_EMP_BENEFIT_INFO_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        employerDiscountStatus: {
          ...state.employerDiscountStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    default:
      return state;
  }
}

export default employerDiscounts;
