import { makeStyles } from '@material-ui/styles';

import TechSupportRepLarge from './hyrum3.png';

const agentImage = TechSupportRepLarge;

export default makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    maxWidth: theme.spacing(76.1),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    backgroundSize: 'cover',
    boxShadow: '2px 5px 20px rgba(0,0,0,0.2)',
  },
  secondaryContainer: {
    paddingBottom: theme.spacing(3.2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2.4),
      paddingRight: theme.spacing(2.4),
    },
  },
  contentContainer: {
    '&:first-child': {
      paddingTop: theme.spacing(6.5),
      paddingLeft: 0,
      paddingRight: 0,
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(4.5),
      },
    },
  },
  titleContainer: {
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1.6),
      paddingBottom: theme.spacing(0.8),
    },
  },
  title: {
    height: theme.spacing(4.8),
    fontSize: theme.spacing(4),
    lineHeight: '48px',
    textDecoration: 'none solid rgb(0, 24, 35)',
    color: theme.palette.text.primary,
    fontWeight: 700,
    paddingBottom: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(2.5),
      lineHeight: '30px',
      margin: '0 auto',
      paddingBottom: 0,
    },
  },
  description: {
    width: '85%',
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    color: theme.palette.text.primary,
    textDecoration: 'none solid rgb(0, 24, 35)',
    lineHeight: '28px',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: theme.spacing(1.6),
      lineHeight: '20px',
      marginBottom: 0,
    },
  },
  imageContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  image: {
    width: theme.spacing(25.5),
    height: theme.spacing(42.1),
    backgroundColor: '#ebebeb',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundImage: `url(${agentImage})`,
    paddingBottom: theme.spacing(1.7),
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    marginTop: theme.spacing(2),
    '& > *': {
      marginBottom: '2rem!important',
    },
    '& :not(:first-child)': {
      marginLeft: 0,
      marginBottom: '0!important',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  agentContainer: {
    width: theme.spacing(20.8),
    height: theme.spacing(3.8),
    backgroundColor: 'rgba(17, 28, 36, 0.7)',
    backgroundSize: 'cover',
  },
  agentName: {
    fontSize: theme.spacing(1.6),
    color: theme.palette.common.white,
    textDecoration: 'none solid rgb(255, 255, 255)',
    textAlign: 'center',
  },
  code: {
    color: theme.palette.error.main,
    fontSize: theme.spacing(1),
    fontWeight: '900',
    marginBottom: theme.spacing(-1),
    textAlign: 'right',
    width: '90%',
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  errorCodeWrapper: {
    marginTop: theme.spacing(1),
    fontSize: theme.spacing(1.2),
    fontWeight: '400',
  },
  errorCodeTitle: {
    fontSize: 'inherit',
    display: 'inline-block',
    paddingRight: theme.spacing(0.6),
  },
  errorCode: {
    fontSize: 'inherit',
    display: 'inline-block',
    '& > span:last-child': {
      '& span': {
        display: 'none',
      },
    },
  },
  chatGenesys: {
    width: 'auto',
    height: theme.spacing(2.8),
    marginRight: theme.spacing(0.4),
  },
}));
