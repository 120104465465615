import { createReducer } from '../../helpers/reducerHelper';
import { countryReductions } from './reductions/countryReductions';
import { militaryTypeReductions } from './reductions/militaryTypeReductions';
import { secondaryInstitutionReductions } from './reductions/secondaryInstitutionReductions';
import { degreeTypeReductions } from './reductions/degreeTypeReductions';
import { testProgressReductions } from './reductions/testProgressReductions';
import { visaTypeReductions } from './reductions/visaTypeReductions';
import { stateReductions } from './reductions/stateReductions';
import { administrativeTerritoryReductions } from './reductions/administrativeTerritoryReductions';
import { districtReductions } from './reductions/districtReductions';
import { militaryCityReductions } from './reductions/militaryCityReductions';
import { militaryCountryReductions } from './reductions/militaryCountryReductions';
import { militaryStateReductions } from './reductions/militaryStateReductions';
import { outlyingAreaReductions } from './reductions/outlyingAreaReductions';
import { provinceReductions } from './reductions/provinceReductions';

const initialState = { enumReducer: {} };

export const enumReducer = createReducer(initialState, {
  ...countryReductions,
  ...stateReductions,
  ...militaryTypeReductions,
  ...secondaryInstitutionReductions,
  ...degreeTypeReductions,
  ...testProgressReductions,
  ...visaTypeReductions,
  ...administrativeTerritoryReductions,
  ...districtReductions,
  ...militaryCityReductions,
  ...militaryCountryReductions,
  ...militaryStateReductions,
  ...outlyingAreaReductions,
  ...provinceReductions,
});

export default enumReducer;
