/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, Suspense, lazy } from 'react';
import './App.css';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';
import Cookies from 'js-cookie';
import WebFont from 'webfontloader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { ConnectedRouter as Router } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import url from 'url';
import { isIE, isEdge, isEdgeChromium } from 'react-device-detect';
import SkipTo from './components/skip-to/SkipTo';
import AuthedRoute from './store/domains/auth0/AuthedRoute';
import CallbackComponentWrapper from './components/auth/CallbackComponentWrapper';
import LoadingModal from './components/modal/LoadingModal';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';
import Theme from './theme';
import flagNames from './store/featureFlags';
import { history } from './store/configureStore';
import NotSupportedBrowser from './modules/admissions/components/not-supported-browser/NotSupportedBrowser';
import Admin from './modules/admin/Admin';
import { checkSkipMaintenancePage } from './store/helpers/commonHelper';
import Documentation from './modules/doc/Documentation';

const Admissions = lazy(() => import('./store/containers/admissions/AdmissionsContainer'));
const Maintenance = lazy(() => import('./store/containers/admissions/maintenance/MaintenanceContainer'));
const QuickApp = lazy(() => import('./store/containers/quickapp/QuickAppContainer'));
const LogoutContainer = lazy(() => import('./store/containers/logout/LogoutContainer'));

const rerouteFromBecome = () => {
  if (window.__ENV.REACT_APP_ENV !== 'local' && window.__ENV.REACT_APP_ENV !== 'dev') {
    let rerouteRequired = false;
    let newUrl = window.location.href;
    if (window.location.href.includes(window.__ENV.REACT_APP_BECOME_REDIRECT_FROM_DOMAIN)) {
      const wwwPrefix = window.__ENV.REACT_APP_ENV === 'production' && !window.location.href.includes('www.') ? 'www.' : '';
      newUrl = window.location.href.replace(
        window.__ENV.REACT_APP_BECOME_REDIRECT_FROM_DOMAIN,
        `${wwwPrefix}${window.__ENV.REACT_APP_BECOME_REDIRECT_TO_DOMAIN}`,
      );
      rerouteRequired = true;
    }
    if (!newUrl.includes(`${window.__ENV.REACT_APP_BECOME_REDIRECT_TO_DOMAIN}/application`)) {
      newUrl = newUrl.replace(
        window.__ENV.REACT_APP_BECOME_REDIRECT_TO_DOMAIN,
        `${window.__ENV.REACT_APP_BECOME_REDIRECT_TO_DOMAIN}/application`,
      );
      rerouteRequired = true;
    }
    if (rerouteRequired) {
      window.location.replace(newUrl);
      return true;
    }
  }
  return false;
};

const setSessionValuesFromQueryString = () => {
  const { query } = url.parse(window.location.href, true);
  if (query?.sagasCanaryDeployment) {
    sessionStorage.setItem('sagasCanaryDeployment', query.sagasCanaryDeployment);
  }
  if (query?.synthetic) {
    sessionStorage.setItem('synthetic', query.synthetic);
  }
};

const checkMaintenanceEnabled = () => {
  const skipMaintenance = checkSkipMaintenancePage();
  if (skipMaintenance) {
    return false;
  }
  if (window.__ENV.REACT_APP_FEATURE_FLAG_MAINTENANCE_PAGE_ENABLED === 'true') {
    sessionStorage.setItem('nae-maintenance-enabled', 'true');
    return true;
  }
  return sessionStorage.getItem('nae-maintenance-enabled') === 'true' || false;
};

const isUnsupportedBrowser = () => {
  let isUnsupported = false;

  if (isIE) {
    isUnsupported = true;
  }

  if (isEdge && !isEdgeChromium) {
    isUnsupported = true;
  }

  return isUnsupported;
};

// Only run once when page is loaded as brower version won't change.
const isUserBrowserUnsupported = isUnsupportedBrowser();

const isMaintenanceEnabled = checkMaintenanceEnabled();

const App = ({ featureToggles }) => {
  const rerouteRequired = rerouteFromBecome();

  let mcIdValue = Cookies.get('s_ecid');
  if (mcIdValue) {
    const parts = mcIdValue.split('|');
    mcIdValue = parts.length > 1 && parts[0] === 'MCMID' ? parts[1] : '';
  }
  const cookie = document.cookie.match('(^|;) *AMCV_8DF667C25245B0070A490D4C%40AdobeOrg=([^;]*)');
  let value;
  let mcid;
  if (cookie) {
    value = decodeURI(cookie[2]);
    value = value.substring(value.lastIndexOf('|MCMID|') + 7);
    mcid = value.substring(0, value.indexOf('|'));
  }
  sessionStorage.setItem('mcId', mcIdValue || mcid || '');

  setSessionValuesFromQueryString();

  const loadingModal = <LoadingModal />;

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Roboto:300,400,500,700,900', 'IBM Plex Serif:300,400,500,700'],
      },
    });
  }, []);

  return rerouteRequired ? loadingModal : (
    <ThemeProvider theme={Theme}>
      <SnackbarProvider
        maxSnack={2}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={6000}
        TransitionComponent={Slide}
      >
        <CssBaseline />
        {isUserBrowserUnsupported ? <NotSupportedBrowser /> : (
          <Suspense fallback={loadingModal}>
            <Router history={history}>
              <SkipTo />
              <ScrollToTop>
                <Switch>
                  <Route path="/admin/maintenance" render={() => <Admin maintenance />} />
                  <Route path="/admin" render={() => <Admin />} />
                  {isMaintenanceEnabled ? (
                    <Route path="/maintenance" render={() => <Maintenance />} />
                  ) : (
                    ''
                  )}
                  {process.env.NODE_ENV !== 'production' && window.location.search === '?doc' ? (
                    <Route path="/" render={() => <Documentation />} />
                  ) : (
                    null
                  )}
                  <Route path="/quick-app" render={() => <QuickApp forceLogoutEnabled={featureToggles[flagNames.QUICKAPP_FORCE_LOGOUT]} featureToggles={featureToggles} flagNames={flagNames} />} />
                  <AuthedRoute
                    path="/admissions"
                    component={() => <Admissions featureToggles={featureToggles} flagNames={flagNames} />}
                  />
                  <Route
                    path="/onboarding"
                    component={() => {
                      window.location.href = window.__ENV.REACT_APP_MYPHOENIX_HOME_URL;
                    }}
                  />
                  <Route path="/oidc/callback" component={CallbackComponentWrapper} />
                  <AuthedRoute
                    path="/authdepot/callback"
                    component={() => <Admissions featureToggles={featureToggles} flagNames={flagNames} />}
                  />
                  <Route path="/logout" component={LogoutContainer} />
                  {/* Redirecting to quick app since Dashboard is currently out of scope */}
                  <Redirect to="/quick-app" />
                </Switch>
              </ScrollToTop>
            </Router>
          </Suspense>
        )}
      </SnackbarProvider>
    </ThemeProvider>
  );
};

App.propTypes = {
  featureToggles: PropTypes.objectOf(PropTypes.bool).isRequired,
};

export default App;
