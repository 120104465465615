const retryCallIfNeeded = async (
  clientInstance,
  request,
  maxRetryAttempts = 5,
  enableBackoff = false,
  originalRetryAttempts = maxRetryAttempts,
) => {
  try {
    return await request();
  } catch (error) {
    const isPersonPut = request.toString().includes('person') && request.toString().includes('put');
    if (maxRetryAttempts <= 0
      || !error.response
      || (error.response.status === 400 && !enableBackoff)
      || (error.response.status === 404 && !isPersonPut)
      || error.response.status === 409) {
      throw error;
    }
    if (isPersonPut) {
      await new Promise(resolve => { setTimeout(resolve, 5000); });
    }
    if (enableBackoff) {
      await new Promise(resolve => {
        setTimeout(
          resolve,
          (originalRetryAttempts - maxRetryAttempts) * 1000,
        );
      });
    }
    return retryCallIfNeeded(
      clientInstance,
      request,
      maxRetryAttempts - 1,
      enableBackoff,
      originalRetryAttempts,
    );
  }
};
export default retryCallIfNeeded;
