import { ACTIONS } from '../types';
import STATUS from '../../../globalStatuses';

export const provinceReductions = {
  [ACTIONS.REQUEST_PROVINCES]: state => ({
    ...state,
    provincesStatus: {
      ...state.provincesStatus,
      getStatus: STATUS.FETCHING,
      errorData: null,
    },
  }),
  [ACTIONS.RECEIVE_PROVINCES]: (state, action) => ({
    ...state,
    provinces: {
      provincesMexico: action.payload.provincesMexico,
      provincesCanada: action.payload.provincesCanada,
    },
    provincesStatus: {
      ...state.provincesStatus,
      getStatus: STATUS.FETCHED,
    },
  }),
  [ACTIONS.RECEIVE_PROVINCES_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    provincesStatus: {
      ...state.provincesStatus,
      getStatus: STATUS.ERROR,
      errorData: action.error,
    },
  }),
};

export default provinceReductions;
