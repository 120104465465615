export const ACTIONS = {
  REQUEST_HIGHER_ED: 'REQUEST_HIGHER_ED',
  RECEIVE_HIGHER_ED: 'RECEIVE_HIGHER_ED',
  REQUEST_HIGHER_ED_ERROR: 'REQUEST_HIGHER_ED_ERROR',

  REQUEST_SAVE_HIGHER_ED: 'REQUEST_SAVE_HIGHER_ED',
  RECEIVE_SAVE_HIGHER_ED: 'RECEIVE_SAVE_HIGHER_ED',
  REQUEST_SAVE_HIGHER_ED_ERROR: 'REQUEST_SAVE_HIGHER_ED_ERROR',

  REQUEST_DELETE_HIGHER_ED: 'REQUEST_DELETE_HIGHER_ED',
  RECEIVE_DELETE_HIGHER_ED: 'RECEIVE_DELETE_HIGHER_ED',
  REQUEST_DELETE_HIGHER_ED_ERROR: 'REQUEST_DELETE_HIGHER_ED_ERROR',

  CLEAR_HIGHER_ED_MODIFY_STATUS: 'CLEAR_HIGHER_ED_MODIFY_STATUS',
  CLEAR_HIGHER_ED_GET_STATUS: 'CLEAR_HIGHER_ED_GET_STATUS',

  REQUEST_ESIGN_HIGHER_ED: 'REQUEST_ESIGN_HIGHER_ED',
  RECEIVE_ESIGN_HIGHER_ED: 'RECEIVE_ESIGN_HIGHER_ED',
  REQUEST_ESIGN_HIGHER_ED_ERROR: 'REQUEST_ESIGN_HIGHER_ED_ERROR',
  CLEAR_ESIGN_HIGHER_ED_GET_STATUS: 'CLEAR_ESIGN_HIGHER_ED_GET_STATUS',
};
export default ACTIONS;
