import { makeStyles } from '@material-ui/styles';

import getBaseStyles from '../baseStyles';

export default makeStyles(theme => ({
  ...getBaseStyles(theme),
  root: {
    '& label.Mui-focused': {
      color: theme.palette.common.black,
    },
    '& .Mui-error': {
      borderColor: theme.palette.error.main,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary.main,
      },
    },
    '& .MuiInputLabel-outlined': {
      color: theme.palette.text.primary,
    },
    marginTop: theme.header.height,
    marginBottom: theme.spacing(16.6),
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    flexGrow: 1,
    position: 'relative',
    paddingTop: theme.spacing(1),
  },
  gridOne: {
    padding: '0',
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2),
      maxWidth: theme.spacing(40),
    },
  },
  gridTwo: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.spacing(50),
      marginTop: theme.spacing(2.6),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(9),
    },
  },
  body: {
    fontSize: theme.spacing(1.8),
  },
  button: {
    fontSize: theme.spacing(1.7),
    fontWeight: '700',
    height: theme.spacing(4.6),
    boxShadow: 'none',
    textTransform: 'unset',
    borderRadius: theme.spacing(3),
    marginTop: theme.spacing(4),
    width: '100%',
    maxWidth: theme.spacing(35),
    backgroundColor: theme.palette.background.mainBtn,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.background.hoverBtn,
    },
    '&.Mui-focusVisible': {
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.background.disabledBtn,
    },
  },
  credentialsError: {
    color: theme.palette.error.main,
  },
  credentialsSuccess: {
    color: 'green',
  },
}));
