import axios from 'axios';
import { FLOPFLIP_STATE_SLICE } from '@flopflip/react-redux';
import { request } from 'stream-http';
import tokenAuthorization from '../interceptors/tokenAuthorization';
import idHeaders from '../interceptors/idHeaders';
import csrfHeaders from '../interceptors/csrfHeaders';
import invalidCsrfTokenHandler from '../interceptors/invalidCsrfTokenHandler';
import rewriteToProxy from '../interceptors/rewriteToProxy';
import rewriteToMock from '../interceptors/rewriteToMock';
import store from '../../store/configureStore';
import resourceConflict from '../interceptors/resourceConflict';
import flagNames from '../../store/featureFlags';
import { updateFlopFlipFeatureFlags } from '../../store/domains/preferences/actions';
import retryCallIfNeeded from '../../restCalls';
import { checkSkipMaintenancePage } from '../../store/helpers/commonHelper';

// eslint-disable-next-line no-underscore-dangle
const { REACT_APP_BYPASS_PROXY } = process.env;

export const baseClient = axios.create({});

export const proxyClient = () => {
  const client = axios.create({});
  client.interceptors.request.use(idHeaders);
  client.interceptors.request.use(config => tokenAuthorization(config, store.getState));
  client.interceptors.request.use(csrfHeaders);
  if (REACT_APP_BYPASS_PROXY === 'true') {
    client.interceptors.request.use(rewriteToMock);
  } else {
    client.interceptors.request.use(rewriteToProxy);
  }
  client.interceptors.response.use(
    response => response,
    error => invalidCsrfTokenHandler(error, store.dispatch, client),
  );
  client.interceptors.response.use(
    response => {
      const maintenanceFlagFromHeader = response.headers['x-maintenance-enabled'] || false;
      if (maintenanceFlagFromHeader) {
        const skipMaintenance = checkSkipMaintenancePage();
        if (skipMaintenance) {
          return response;
        }
        sessionStorage.setItem('nae-maintenance-enabled', maintenanceFlagFromHeader);
      } else {
        sessionStorage.removeItem('nae-maintenance-enabled');
        sessionStorage.removeItem('skipMaintenance');
      }
      const maintenanceFlagFromState = store.getState()[
        FLOPFLIP_STATE_SLICE
      ].flags[flagNames.MAINTENANCE_PAGE_ENABLED];
      if (maintenanceFlagFromHeader !== maintenanceFlagFromState) {
        updateFlopFlipFeatureFlags(
          { flags: { MAINTENANCE_PAGE_ENABLED: maintenanceFlagFromHeader } },
          store.dispatch,
        );
      }
      return response;
    },
    error => resourceConflict(error, client),
  );
  client.interceptors.response.use(
    response => response,
    async error => {
      if (error.message.includes('Network Error')) {
        return retryCallIfNeeded(client, () => client.request(error.config));
      }
      return Promise.reject(error);
    },
  );

  return client;
};

export const proxyClientInstance = proxyClient();
