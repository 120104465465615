/* eslint-disable max-len */
export default {
  // field constants
  intendToApplyFinAidField: 'INTEND_TO_APPLY_FIN_AID',
  haveEfcField: 'HAVE_EFC',
  efcField: 'EFC',
  pellGrantAmountField: 'PELL_GRANT_AMOUNT',

  parentMaritalStatusField: 'PARENT_MARITAL_STATUS', // used for student
  studentMaritalStatusField: 'STUDENT_MARITAL_STATUS',
  studentChildrenField: 'STUDENT_CHILDREN',
  // Calculate EFC
  studentIncomeField: 'STUDENT_INCOME',
  studentIncomeEarnedFromWork: 'STUDENT_INCOME_EARNED_FROM_WORK',
  studentCashField: 'STUDENT_CASH',
  studentRecvFedAsstField: 'STUDENT_RECV_FED_ASST',
  studentTaxesPaidField: 'STUDENT_TAXES_PAID',
  studentEduTaxAmtField: 'STUDENT_EDU_TAX_AMT',
  studentChildSupportPaidField: 'STUDENT_CHILD_SUPPORT_PAID',
  studentChildSupportReceivedField: 'STUDENT_CHILD_SUPPORT_RECEIVED',
  studentTaxExemptField: 'STUDENT_TAX_EXEMPT',

  studentBirthdateField: 'STUDENT_BIRTHDATE',
  studentUntaxIncomeField: 'STUDENT_UNTAX_INCOME',

  // Parent info
  parentMarriedField: 'PARENT_MARRIED',
  parentIncomeField: 'PARENTS_INCOME',
  parentIncomeEarnedFromWork: 'PARENT_INCOME_EARNED_FROM_WORK',
  parentCashField: 'PARENTS_CASH',
  parentRecvFedAsstField: 'PARENT_RECV_FED_ASST',
  parentTaxesPaidField: 'PARENT_TAXES_PAID',
  parentEduTaxAmtField: 'PARENT_EDU_TAX_AMT',
  parentChildSupportPaidField: 'PARENT_CHILD_SUPPORT_PAID',
  parentChildSupportReceivedField: 'PARENT_CHILD_SUPPORT_RECEIVED',
  parentTaxExemptField: 'PARENTS_TAX_EXEMPT',
  parentUntaxIncomeField: 'PARENT_UNTAX_INCOME',

  // household info
  hHoldSizeField: 'HHOLD_SIZE',
  hHoldMembersCollegeField: 'HHOLD_MEMBERS_COLLEGE',

  // Other stuff not in this page
  studentStateResidenceField: 'STUDENT_STATE_RESIDENCE',
  childSupportReceivedField: 'CHILD_SUPPORT_RECEIVED',
  childSupportPaidField: 'CHILD_SUPPORT_PAID',
  hHoldRecvFedAsstField: 'HHOLD_RECV_FED_ASST',
  studentSpouseIncomeField: 'STUDENT_SPOUSE_INCOME',

  // labels
  pageHeader: 'See if you’re eligible for Federal Pell Grant',
  optionalDisclaimer: 'All fields are required except those marked optional.',
  pageTagline: 'A Federal Pell Grant is funding that typically doesn\'t require repayment and helps you pay for college.',
  willGrantQuestion: 'Do you intend to apply for the Federal financial aid?',
  grantAmountNote: 'Note for grants',
  willGrantInputEmptyError: 'If you would like to apply Grants to your education expense, select Yes.',
  efcNote: 'Do you have an Expected Family Contribution (EFC) to estimate aid?',
  efcSubText: 'Your EFC is only generated if you have completed your FAFSA. EFC is a measure of your family\'s financial strength and is calculated according to a formula established by law to determine the amount of federal student aid you\'re eligible to receive.',
  efcContributionQuestion: 'Expected Family Contribution:',
  efcContributionNote: 'To get your EFC, log in to fafsa.ed.gov and click on \'View and Print Your Student Aid Report.\' You\'ll see an official EFC on your Student Aid Report based on the processing results of your FAFSA in the upper-right-corner.',
  efcAidInputEmptyError: 'If you would like to apply EFC Contribution to your education expense, select Yes.',
  saiNote: 'Have you already completed the Free Application for Federal Student Aid (FAFSA®) for 2024-25?',
  saiSubText: 'You\'ll be able to locate the federal student aid amount and SAI in your online FAFSA Submission Summary under the "Eligibility Overview" tab upon completing your FAFSA form. Please provide the estimated Pell Grant amount and SAI listed on this tab.',
  pellGrantAmountQuestion: 'Estimated Pell Grant Award:',
  pellGrantAmountNote: '',
  saiContributionQuestion: 'Student Aid Index (SAI):',
  saiContributionNote: '',
  saiAidInputEmptyError: 'If you would like to apply SAI Contribution to your education expense, select Yes.',
  maritalStatusQuestion: 'Marital status:',
  dependentsQuestion: 'Do you have any children or dependents who will receive more than half of their financial support from you while you attend school?',
  calcEfcHeader: 'Estimate your Expected Family Contribution (EFC)',
  annualIncomeNote: 'Total annual income:',
  calcSaiHeader: 'Estimate your Student Aid Index (SAI)',
  householdIncomeNote: 'Student\'s household income:',
  studentIncomeEarnedFromWorkNote: 'Student\'s income earned from work:',
  studentIncomeEarnedFromWorkTooltip: 'This is X, Y, and Z but not your AGI.',
  cashNote: 'Cash, savings and checking amounts:',
  fedAssistanceNote: 'Are you or anyone in your household receiving assistance from any federal benefit programs?',
  incomeTaxesNote: 'Annual income taxes paid:',
  educationTaxNote: 'Annual education tax credit amount:',
  childSupportPaidNote: 'Annual child support paid:',
  childSupportRecNote: 'Annual child support received:',
  taxExemptIntNote: 'Annual tax-exempt interest income:',
  otherUntaxedNote: 'Other annual untaxed income:',
  householdNote: 'Household size:',
  householdEmptyError: 'Please enter a value for Household Size.',
  familySizeNote: 'Family size:',
  familySizeEmptyError: 'Please enter a value for Family Size.',
  familySizeTooltip: 'Family size (formerly “household size”) now aligns with the number of exemptions (which equal the tax filer(s) plus dependents) claimed on an individual’s tax return.',
  inCollegeNote: 'Household members in college:',
  inCollegeEmptyError: 'Please enter a value for Household members in college.',
  pellGrantAmountEmptyError: 'Enter a Pell Grant Award amount.',
  efcContributionEmptyError: 'Enter an Expected Family Contribution.',
  saiContributionEmptyError: 'Enter a Student Aid Index (SAI) number.',
  efcContributionRegexError: 'Please enter a valid number in currency format.',
  annualIncomeEmptyError: 'Please enter a value for Total annual income.',
  studentIncomeEarnedFromWorkError: 'Please enter a value for student\'s income earned from work.',
  studentHouseholdIncomeEmptyError: 'Please enter a value for Student\'s household income.',
  cashSavingsEmptyError: 'Please enter a value for Cash, savings and checking amounts.',
  incomeTaxesEmptyError: 'Please enter a value for Annual income taxes paid.',
  edTaxCreditEmptyError: 'Please enter a value for Annual education tax credit amount.',
  childSupportPaidEmptyError: 'Please enter a value for Annual child support paid.',
  childSupportRecEmptyError: 'Please enter a value for Annual child support received.',
  taxExemptEmptyError: 'Please enter a value for Annual tax-exempt interest income.',
  otherUntaxedEmptyError: 'Please enter a value for Other annual untaxed income.',
  parentAnnualIncomeEmptyError: 'Please enter a value for Total annual parents\' income.',
  parentIncomeEarnedFromWorkError: 'Please enter a value for parents\' income earned from work.',
  parentAdjustedGrossIncomeEmptyError: 'Please enter a value for parents\' Household income.',
  parentCashSavingsEmptyError: 'Please enter a value for Cash, savings and checking amounts.',
  parentIncomeTaxesEmptyError: 'Please enter a value for Annual parents\' income taxes paid.',
  parentEdTaxCreditEmptyError: 'Please enter a value for Annual parents\' education tax credit amount.',
  parentChildSupportPaidEmptyError: 'Please enter a value for Annual parents\' child support paid.',
  parentChildSupportRecEmptyError: 'Please enter a value for Annual parents\' child support received.',
  parentTaxExemptEmptyError: 'Please enter a value for Annual parents\' tax-exempt interest income.',
  parentOtherUntaxedEmptyError: 'Please enter a value for Other annual parents\' untaxed income.',
  lessThanZeroError: 'Please enter a contribution that is greater than $0.',
  validNumberError: 'Please enter a valid number.',
  maritalStatusEmptyError: 'Please select an option for Marital Status.',
  dependentsInputEmptyError: 'Please select Yes or No for dependents.',
  parentMarried: 'Parent(s) Married:',
  yesOrNoError: 'Please select Yes or No.',
  parentAnnualIncomeNote: 'Total annual parents\' income:',
  parentIncomeEarnedFromWorkNote: 'Parents\' income earned from work:',
  parentIncomeEarnedFromWorkTooltip: 'This is your parents\' X, Y, and Z but not their AGI.',
  parentFedAssistanceNote: 'Is anyone in your parents\' household receiving assistance from any federal benefit programs?',
  parentIncomeTaxesNote: 'Annual parents\' income taxes paid:',
  parentEducationTaxNote: 'Annual parents\' education tax credit amount:',
  parentChildSupportPaidNote: 'Annual parents\' child support paid:',
  parentChildSupportRecNote: 'Annual parents\' child support recieved:',
  parentTaxExemptIntNote: 'Annual parents\' tax-exempt interest income:',
  parentOtherUntaxedNote: 'Other annual parents\' untaxed income:',

  // tool tips
  tipApplyAid: 'Completing the FAFSA (Free Application for Federal Student Aid) is required to apply for federal student aid, such as federal grants and loans. The FAFSA is also used to apply for some state and university financial aid.',
  tipMaritalStatus: 'Provide your marital status as of today.',
  tipTotalAnnualInc: 'Indicate the total annual amount you (and your spouse, if applicable) earned. For the most accurate estimate, use your adjusted gross income (AGI) from the most recent federal income tax return.',
  tipCashSavings: 'As of today, indicate the total amount you have in cash, savings and checking accounts (for you and your spouse, if applicable).',
  tipFedAssistance: 'Programs include Supplemental Security Income, Supplemental Nutrition Assistance Program, Free or Reduced Price Lunch, Temporary Assistance for Needy Families, and Special Supplemental Nutrition Program for Women, Infants and Children.',
  tipTaxesPaid: 'Indicate the dollar amount of income taxes paid and included on your (and your spouse\'s, if applicable) most recent federal income tax return.',
  tipEdTaxCredit: 'Indicate the dollar amount of any education credit, such as American Oppurtunity, Hope Scholarship and Lifetime Learning tax credit, included on your (and your spouse\'s, if applicable) most recent federal income tax return.',
  tipChildSuppPaid: 'Indicate the total amount of child support paid (by you and your spouse, if applicable).',
  tipChildSuppRcvd: 'Indicate the total amount of any child support payments received (by you and your spouse, if applicable) for any of your children. Don\'t include foster care or adoption payments.',
  tipTaxExempt: 'Indicate the total amount of any tax-exempt interest income you (and your spouse, if applicable) received. Estimate or enter 0, if unknown.',
  tipOtherInc: 'Indicate the total amount of any other untaxed income not reported in items above, such as workers\' compensation, disability, etc. Estimate or enter 0, if unknown.',
  tipHouseholdSize: 'Count all of the following people as applicable: yourself, your spouse, your children under the age of 24 if you provide more than half of their financial support, other people who now live with you if you provide more than half of their support. For example, if your family includes your spouse, your child and you, enter 3.',
  tipMembersCollege: 'Count yourself and any other people in your household who will be college students while you attend.',
  tipParentMarried: 'As of today, indicate if your parents are legally married',
  tipParentTotalAnnualInc: 'Indicate the total annual amount earned in your parents\' household. For the most accurate estimate, use the adjusted gross income (AGI) from your parents\' most recent federal income tax return.',
  tipParentCashSavings: 'As of today, indicate the total amount your parents have in cash, savings and checking accounts.',
  tipParentTaxesPaid: 'Indicate the dollar amount of income taxes paid and included on your parents\' most recent federal income tax return.',
  tipParentEdTaxCredit: 'Indicate the dollar amount of any education credit, such as American Opportunity, Hope Scholarship and Lifetime Learning tax credit, included on your parents\' most recent federal income tax return.',
  tipParentChildSuppPaid: 'Indicate the total amount of child support paid by your parents.',
  tipParentChildSuppRcvd: 'Indicate the total amount of any child support payments received by your parents for any of their children. Don\t include foster care or adoption payments.',
  tipParentTaxExempt: 'Indicate the total amount of any tax-exempt interest income your parents received. Estimate or enter 0, if unknown.',
};
