import React, {
  useState, memo, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Tooltip, ClickAwayListener, IconButton } from '@material-ui/core';
import {
  Lock as LockIcon,
} from '@material-ui/icons';
import styles from './styles';

const useStyles = styles;

function DisabledTooltip(props) {
  const classes = useStyles();

  const {
    title, color, iconSize, arrow, theme, placement,
    id, tabIndex, popperProps, tooltipContextId, ...other
  } = props;

  const tooltipClasses = {
    tooltip: `${classes.tooltip} ${theme === 'light' ? classes.lightTooltip : classes.darkTooltip}`,
    arrow: (theme === 'light' ? classes.lightArrow : classes.darkArrow),
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  // Accessibility - ADA
  const targetMouseout = () => {
    const tooltipBtn = document.getElementById(`${id}-btn`);
    // eslint-disable-next-line no-use-before-define
    tooltipBtn.removeEventListener('click', keepOpen, false);
    setTimeout(() => {
      setOpen(true);
    }, 50);
    tooltipBtn.removeEventListener('mouseout', targetMouseout, false);
  };

  const keepOpen = () => {
    const tooltipBtn = document.getElementById(`${id}-btn`);
    tooltipBtn.addEventListener('mouseout', targetMouseout, false);
  };

  const handleOpen = () => {
    setOpen(true);
    const tooltipBtn = document.getElementById(`${id}-btn`);
    tooltipBtn.addEventListener('click', keepOpen, false);
  };

  const escADA = useCallback(event => {
    if (event.key === 'Escape') {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('keydown', escADA, false);
    return () => {
      document.body.removeEventListener('keydown', escADA, false);
    };
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Tooltip
        {...other}
        id={`${id}-text`}
        data-cy={`${id}-btn`}
        classes={tooltipClasses}
        title={title}
        arrow={arrow}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        placement={placement}
        tabIndex={tabIndex}
        PopperProps={popperProps}
        enterTouchDelay={0}
      >
        <IconButton
          id={`${id}-btn`}
          className="tooltip-btn"
          color={color}
          disableFocusRipple
          disableRipple
          // tooltipContextId is to bring context to the tooltip - ada
          aria-labelledby={tooltipContextId}
        >
          <LockIcon
            aria-hidden="true"
            fontSize={iconSize}
          />
          <span id={`${id}-ada`} hidden>This field is disabled.</span>
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
}

DisabledTooltip.defaultProps = {
  color: 'inherit',
  arrow: true,
  iconSize: 'inherit',
  theme: 'dark',
  placement: 'bottom',
  id: null,
  tabIndex: 0,
  popperProps: { disablePortal: true },
  tooltipContextId: '',
};

DisabledTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
  id: PropTypes.string,
  color: PropTypes.oneOf(['inherit', 'primary', 'secondary']),
  arrow: PropTypes.bool,
  iconSize: PropTypes.oneOf(['inherit', 'default', 'small', 'large']),
  theme: PropTypes.oneOf(['light', 'dark']),
  placement: PropTypes.oneOf(['top', 'top-end', 'bottom', 'start', 'end']),
  popperProps: PropTypes.shape({}),
  tooltipContextId: PropTypes.string,
};

export default memo(DisabledTooltip);
