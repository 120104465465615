import retryCallIfNeeded from '../../../restCalls';

export default function AssociationsService(client) {
  const getAssociationsByPersonIdAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`/api/persons/v1/person/${personId}/associations`));

  const getAssociationByPersonIdAsync = async (
    personId,
    associationId,
  ) => retryCallIfNeeded(client, () => client
    .get(`/api/persons/v1/person/${personId}/association/${associationId}`));

  const postAssociationAsync = async (personId, payload) => retryCallIfNeeded(client, () => client
    .post(`/api/persons/v1/person/${personId}/association`, payload));

  const putAssociationAsync = async (
    personId,
    associationId,
    payload,
    etag,
  ) => retryCallIfNeeded(client, () => client
    .put(`/api/persons/v1/person/${personId}/association/${associationId}`, payload, {
      headers: {
        'if-match': etag,
      },
    }));

  const deleteAssociationAsync = async (
    associationId,
    etag,
  ) => retryCallIfNeeded(client, () => client
    .delete(`/api/persons/v1/person/association/${associationId}`, {
      headers: {
        'if-match': etag,
      },
    }));

  return {
    getAssociationsByPersonIdAsync,
    getAssociationByPersonIdAsync,
    postAssociationAsync,
    putAssociationAsync,
    deleteAssociationAsync,
  };
}
