export const ACTIONS = {
  REQUEST_DGSP_PACKETS_SUPPORTED: 'REQUEST_DGSP_PACKETS_SUPPORTED',
  RECEIVE_DGSP_PACKETS_SUPPORTED: 'RECEIVE_DGSP_PACKETS_SUPPORTED',
  RECEIVE_DGSP_PACKETS_SUPPORTED_ERROR: 'RECEIVE_DGSP_PACKETS_SUPPORTED_ERROR',

  REQUEST_DGSP_ATTESTATION_PACKET: 'REQUEST_DGSP_ATTESTATION_PACKET',
  RECEIVE_DGSP_ATTESTATION_PACKET: 'RECEIVE_DGSP_ATTESTATION_PACKET',
  RECEIVE_DGSP_ATTESTATION_PACKET_ERROR: 'RECEIVE_DGSP_ATTESTATION_PACKET_ERROR',

  RESET_AGREEMENT_DOCS: 'RESET_AGREEMENT_DOCS',
  REQUEST_AGREEMENT_DOCS: 'REQUEST_AGREEMENT_DOCS',
  RECEIVE_AGREEMENT_DOCS: 'RECEIVE_AGREEMENT_DOCS',
  RECEIVE_AGREEMENT_DOCS_ERROR: 'RECEIVE_AGREEMENT_DOCS_ERROR',
  RESET_GENERAL_AGREEMENT_DOCS: 'RESET_GENERAL_AGREEMENT_DOCS',
  REQUEST_GENERAL_AGREEMENT_DOCS: 'REQUEST_GENERAL_AGREEMENT_DOCS',
  RECEIVE_GENERAL_AGREEMENT_DOCS: 'RECEIVE_GENERAL_AGREEMENT_DOCS',
  RECEIVE_GENERAL_AGREEMENT_DOCS_ERROR: 'RECEIVE_GENERAL_AGREEMENT_DOCS_ERROR',
  RESET_AGREEMENT_PACKAGE: 'RESET_AGREEMENT_PACKAGE',
  REQUEST_AGREEMENT_PACKAGE: 'REQUEST_AGREEMENT_PACKAGE',
  RECEIVE_AGREEMENT_PACKAGE: 'RECEIVE_AGREEMENT_PACKAGE',
  RECEIVE_AGREEMENT_PACKAGE_ERROR: 'RECEIVE_AGREEMENT_PACKAGE_ERROR',
  REQUEST_POST_SIGN_PACKAGE: 'REQUEST_POST_SIGN_PACKAGE',
  RECEIVE_POST_SIGN_PACKAGE: 'RECEIVE_POST_SIGN_PACKAGE',
  RECEIVE_POST_SIGN_PACKAGE_ERROR: 'RECEIVE_POST_SIGN_PACKAGE_ERROR',
  // POST actions for acknowledgement agreement page, remove before pushing
  REQUEST_POST_AGREEMENT: 'REQUEST_POST_AGREEMENT',
  RECEIVE_POST_AGREEMENT: 'RECEIVE_POST_AGREEMENT',
  RECEIVE_POST_AGREEMENT_ERROR: 'RECEIVE_POST_AGREEMENT_ERROR',
  // GET actions for acknowledgement agreement page, remove before pushing
  REQUEST_GET_AGREEMENT: 'REQUEST_GET_AGREEMENT',
  RECEIVE_GET_AGREEMENT: 'RECEIVE_GET_AGREEMENT',
  RECEIVE_GET_AGREEMENT_ERROR: 'RECEIVE_GET_AGREEMENT_ERROR',
  // FOR Criminal conviction doc actions
  RESET_CRINIMAL_CONVICTION_DOC: 'RESET_CRINIMAL_CONVICTION_DOC',
  REQUEST_CRIMINAL_CONVICTION_DOC: 'REQUEST_CRIMINAL_CONVICTION_DOC',
  RECEIVE_CRIMINAL_CONVICTION_DOC: 'RECEIVE_CRIMINAL_CONVICTION_DOC',
  RECEIVE_CRIMINAL_CONVICTION_DOC_ERROR: 'RECEIVE_CRIMINAL_CONVICTION_DOC_ERROR',
  // FOR New student checklist doc actions
  RESET_NEW_STUDENT_CHECKLIST_DOC: 'RESET_NEW_STUDENT_CHECKLIST_DOC',
  REQUEST_NEW_STUDENT_CHECKLIST_DOC: 'REQUEST_NEW_STUDENT_CHECKLIST_DOC',
  RECEIVE_NEW_STUDENT_CHECKLIST_DOC: 'RECEIVE_NEW_STUDENT_CHECKLIST_DOC',
  RECEIVE_NEW_STUDENT_CHECKLIST_DOC_ERROR: 'RECEIVE_NEW_STUDENT_CHECKLIST_DOC_ERROR',
  // FOR Massachusetts program disclosure
  RESET_MA_DISCLOSURE_DOC: 'RESET_MA_DISCLOSURE_DOC',
  REQUEST_MA_DISCLOSURE_DOC: 'REQUEST_MA_DISCLOSURE_DOC',
  RECEIVE_MA_DISCLOSURE_DOC: 'RECEIVE_MA_DISCLOSURE_DOC',
  RECEIVE_MA_DISCLOSURE_DOC_ERROR: 'RECEIVE_MA_DISCLOSURE_DOC_ERROR',
  // POST actions for acknowledgement agreement page, remove before pushing
  REQUEST_UPLOAD_DOCUMENTS_SAT: 'REQUEST_UPLOAD_DOCUMENTS_SAT',
  RECEIVE_UPLOAD_DOCUMENTS_SAT: 'RECEIVE_UPLOAD_DOCUMENTS_SAT',
  RECEIVE_UPLOAD_DOCUMENTS_SAT_ERROR: 'RECEIVE_UPLOAD_DOCUMENTS_SAT_ERROR',

  REQUEST_UPLOAD_DOCUMENTS_S3: 'REQUEST_UPLOAD_DOCUMENTS_S3',
  RECEIVE_UPLOAD_DOCUMENTS_S3: 'RECEIVE_UPLOAD_DOCUMENTS_S3',
  RECEIVE_UPLOAD_DOCUMENTS_S3_ERROR: 'RECEIVE_UPLOAD_DOCUMENTS_S3_ERROR',

  // Upload to EXP
  REQUEST_UPLOAD_DOCUMENTS_EXP: 'REQUEST_UPLOAD_DOCUMENTS_EXP',
  RECEIVE_UPLOAD_DOCUMENTS_EXP: 'RECEIVE_UPLOAD_DOCUMENTS_EXP',
  RECEIVE_UPLOAD_DOCUMENTS_EXP_ERROR: 'RECEIVE_UPLOAD_DOCUMENTS_EXP_ERROR',
  // POST Signed Doc
  REQUEST_POST_SIGNED_DOCUMENT: 'REQUEST_POST_SIGNED_DOCUMENT',
  RECEIVE_POST_SIGNED_DOCUMENT: 'RECEIVE_POST_SIGNED_DOCUMENT',
  RECEIVE_POST_SIGNED_DOCUMENT_ERROR: 'RECEIVE_POST_SIGNED_DOCUMENT_ERROR',
  // California Performance Fact Sheet Doc
  RESET_CA_PERFORMANCE_FACTSHEET_DOC: 'RESET_CA_PERFORMANCE_FACTSHEET_DOC',
  REQUEST_CA_PERFORMANCE_FACTSHEET_DOC: 'REQUEST_CA_PERFORMANCE_FACTSHEET_DOC',
  RECEIVE_CA_PERFORMANCE_FACTSHEET_DOC: 'RECEIVE_CA_PERFORMANCE_FACTSHEET_DOC',
  RECEIVE_CA_PERFORMANCE_FACTSHEET_DOC_ERROR: 'RECEIVE_CA_PERFORMANCE_FACTSHEET_DOC_ERROR',
  // Reenroll Document
  REQUEST_GET_REENROLL_DOCUMENT: 'REQUEST_GET_REENROLL_DOCUMENT',
  RECEIVE_GET_REENROLL_DOCUMENT: 'RECEIVE_GET_REENROLL_DOCUMENT',
  RECEIVE_GET_REENROLL_DOCUMENT_ERROR: 'RECEIVE_GET_REENROLL_DOCUMENT_ERROR',
  // FOR Financial Plan Summary
  REQUEST_FINANCIAL_PLAN_SUMMARY: 'REQUEST_FINANCIAL_PLAN_SUMMARY',
  RECEIVE_FINANCIAL_PLAN_SUMMARY: 'RECEIVE_FINANCIAL_PLAN_SUMMARY',
  RECEIVE_FINANCIAL_PLAN_SUMMARY_ERROR: 'RECEIVE_FINANCIAL_PLAN_SUMMARY_ERROR',
  REQUEST_FINANCIAL_PLAN_SUMMARY_DOWNLOADED: 'REQUEST_FINANCIAL_PLAN_SUMMARY_DOWNLOADED',
  RECEIVE_FINANCIAL_PLAN_SUMMARY_DOWNLOADED: 'RECEIVE_FINANCIAL_PLAN_SUMMARY_DOWNLOADED',
  RECEIVE_FINANCIAL_PLAN_SUMMARY_DOWNLOADED_ERROR: 'RECEIVE_FINANCIAL_PLAN_SUMMARY_DOWNLOADED_ERROR',
  SET_FINANCIAL_PLAN_SUMMARY_DOWNLOADED_TRUE: 'SET_FINANCIAL_PLAN_SUMMARY_DOWNLOADED_TRUE',
};

export const STATUS = {
  AGREEMENT_DOCS_UNFETCHED: 'AGREEMENT_DOCS_UNFETCHED',
  AGREEMENT_DOCS_FETCHING: 'AGREEMENT_DOCS_FETCHING',
  AGREEMENT_DOCS_FETCHED: 'AGREEMENT_DOCS_FETCHED',
  AGREEMENT_DOCS_ERROR: 'AGREEMENT_DOCS_ERROR',
  GENERAL_AGREEMENT_DOCS_UNFETCHED: 'GENERAL_AGREEMENT_DOCS_UNFETCHED',
  GENERAL_AGREEMENT_DOCS_FETCHING: 'GENERAL_AGREEMENT_DOCS_FETCHING',
  GENERAL_AGREEMENT_DOCS_FETCHED: 'GENERAL_AGREEMENT_DOCS_FETCHED',
  GENERAL_AGREEMENT_DOCS_ERROR: 'GENERAL_AGREEMENT_DOCS_ERROR',
  AGREEMENT_PACKAGE_UNFETCHED: 'AGREEMENT_PACKAGE_UNFETCHED',
  AGREEMENT_PACKAGE_FETCHING: 'AGREEMENT_PACKAGE_FETCHING',
  AGREEMENT_PACKAGE_FETCHED: 'AGREEMENT_PACKAGE_FETCHED',
  AGREEMENT_PACKAGE_ERROR: 'AGREEMENT_PACKAGE_ERROR',
  POSTING_AGREEMENT_UNFETCHED: 'POSTING_AGREEMENT_UNFETCHED',
  POST_AGREEMENT_FETCHING: 'POST_AGREEMENT_FETCHING',
  // POST status for acknowledgement agreement page, remove before pushing
  POST_AGREEMENT_UNFETCHED: 'POST_AGREEMENT_UNFETCHED',
  POST_AGREEMENT_FETCHED: 'POST_AGREEMENT_FETCHED',
  POST_AGREEMENT_ERROR: 'POST_AGREEMENT_ERROR',
  GET_AGREEMENT_UNFETCHED: 'GET_AGREEMENT_UNFETCHED',
  // GET status for acknowledgement agreement page, remove before pushing
  GET_AGREEMENT_FETCHING: 'GET_AGREEMENT_FETCHING',
  GET_AGREEMENT_FETCHED: 'GET_AGREEMENT_FETCHED',
  GET_AGREEMENT_ERROR: 'GET_AGREEMENT_ERROR',
  // FOR Criminal conviction doc status
  CRIMINAL_CONVICTION_DOC_UNFETCHED: 'CRIMINAL_CONVICTION_DOC_UNFETCHED',
  CRIMINAL_CONVICTION_DOC_FETCHING: 'CRIMINAL_CONVICTION_DOC_FETCHING',
  CRIMINAL_CONVICTION_DOC_FETCHED: 'CRIMINAL_CONVICTION_DOC_FETCHED',
  CRIMINAL_CONVICTION_DOC_ERROR: 'CRIMINAL_CONVICTION_DOC_ERROR',
  // FOR New student checklist doc status
  NEW_STUDENT_CHECKLIST_DOC_UNFETCHED: 'NEW_STUDENT_CHECKLIST_DOC_UNFETCHED',
  // FOR Massachusetts program disclosure doc status
  MA_DISCLOSURE_DOC_UNFETCHED: 'MA_DISCLOSURE_DOC_UNFETCHED',
  MA_DISCLOSURE_DOC_FETCHING: 'MA_DISCLOSURE_DOC_FETCHING',
  MA_DISCLOSURE_DOC_FETCHED: 'MA_DISCLOSURE_DOC_FETCHED',
  MA_DISCLOSURE_DOC_ERROR: 'MA_DISCLOSURE_DOC_ERROR',
  // Upload documents to SAT status
  UPLOAD_DOCUMENTS_FETCHED: 'UPLOAD_DOCUMENTS_FETCHED',
  UPLOAD_DOCUMENTS_ERROR: 'UPLOAD_DOCUMENTS_ERROR',
  UPLOAD_DOCUMENTS_FETCHING: 'UPLOAD_DOCUMENTS_FETCHING',
  // Signed Document
  POST_SIGNED_DOCUMENT_FETCHING: 'POST_SIGNED_DOCUMENT_FETCHING',
  POST_SIGNED_DOCUMENT_FETCHED: 'POST_SIGNED_DOCUMENT_FETCHED',
  POST_SIGNED_DOCUMENT_ERROR: 'POST_SIGNED_DOCUMENT_ERROR',
  // California Performance Fact Sheet doc status
  CA_PERFORMANCE_FACTSHEET_DOC_UNFETCHED: 'CA_PERFORMANCE_FACTSHEET_DOC_UNFETCHED',
  CA_PERFORMANCE_FACTSHEET_DOC_FETCHING: 'CA_PERFORMANCE_FACTSHEET_DOC_FETCHING',
  CA_PERFORMANCE_FACTSHEET_DOC_FETCHED: 'CA_PERFORMANCE_FACTSHEET_DOC_FETCHED',
  CA_PERFORMANCE_FACTSHEET_DOC_ERROR: 'CA_PERFORMANCE_FACTSHEET_DOC_ERROR',
};

export const TYPES = {
  REQUEST_DOCUMENTS: 'REQUEST_DOCUMENTS',
  RECIEVE_DOCUMENTS: 'RECIEVE_DOCUMENTS',
  ERROR_DOCUMENTS: 'ERROR_DOCUMENTS',
  REQUEST_POST_DOCUMENTS: 'REQUEST_POST_DOCUMENTS',
  RECEIVE_POST_DOCUMENTS: 'RECEIVE_POST_DOCUMENTS',
  ERROR_POST_DOCUMENTS: 'ERROR_POST_DOCUMENTS',
  REQUEST_SUBMIT_TASKS: 'REQUEST_SUBMIT_TASKS',
  RECEIVE_SUBMIT_TASKS: 'RECEIVE_SUBMIT_TASKS',
  ERROR_SUBMIT_TASKS: 'ERROR_SUBMIT_TASKS',
  // POST types for acknowledgement agreement page, remove before pushing
  REQUEST_POST_SIGN: 'REQUEST_POST_SIGN',
  RECEIVE_POST_SIGN: 'RECEIVE_POST_SIGN',
  ERROR_POST_SIGN: 'ERROR_POST_SIGN',
  // GET types for acknowledgement agreement page, remove before pushing
  REQUEST_GET_SIGN: 'REQUEST_GET_SIGN',
  RECEIVE_GET_SIGN: 'RECEIVE_GET_SIGN',
  ERROR_GET_SIGN: 'ERROR_GET_SIGN',
  // GET types for docusigning url for docu sign redirect
  REQUEST_POST_ESIGNING_URL: 'REQUEST_POST_ESIGNING_URL',
  RECEIVE_POST_ESIGNING_URL: 'RECEIVE_POST_ESIGNING_URL',
  ERROR_POST_ESIGNING_URL: 'ERROR_POST_ESIGNING_URL',
  // GET types for esign notification for esign redirect
  REQUEST_GET_ESIGN_NOTIFICATION: 'REQUEST_GET_ESIGN_NOTIFICATION',
  RECEIVE_GET_ESIGN_NOTIFICATION: 'RECEIVE_GET_ESIGN_NOTIFICATION',
  ERROR_GET_ESIGN_NOTIFICATION: 'ERROR_GET_ESIGN_NOTIFICATION',
  // get esignTranscript task for docu sign
  REQUEST_GET_ESIGN_TRANSCRIPT_TASK: 'REQUEST_GET_ESIGN_TRANSCRIPT_TASK',
  RECEIVE_GET_ESIGN_TRANSCRIPT_TASK: 'RECEIVE_GET_ESIGN_TRANSCRIPT_TASK',
  ERROR_GET_ESIGN_TRANSCRIPT_TASK: 'ERROR_GET_ESIGN_TRANSCRIPT_TASK',
  // delete esignTranscript envelope for docu sign
  REQUEST_DELETE_ESIGN_TRANSCRIPT_ENVELOPE: 'REQUEST_DELETE_ESIGN_TRANSCRIPT_ENVELOPE',
  RECEIVE_DELETE_ESIGN_TRANSCRIPT_ENVELOPE: 'RECEIVE_DELETE_ESIGN_TRANSCRIPT_ENVELOPE',
  ERROR_DELETE_ESIGN_TRANSCRIPT_ENVELOPE: 'ERROR_DELETE_ESIGN_TRANSCRIPT_ENVELOPE',
};
