import STATUS from '../../globalStatuses';
import {
  RECEIVE_TOKEN,
  REQUEST_TOKEN,
  REQUEST_TOKEN_ERROR,
  INVALIDATE_TOKEN,
} from './types';
import { proxyClientInstance } from '../../../services/wrappers/axios';

/* action creators */
function requestToken() {
  return { type: REQUEST_TOKEN };
}

function receiveToken() {
  return { type: RECEIVE_TOKEN, receivedAt: Date.now() };
}

function requestTokenError(error) {
  return { type: REQUEST_TOKEN_ERROR, error, receivedAt: Date.now() };
}

function invalidateToken() {
  return { type: INVALIDATE_TOKEN, receivedAt: Date.now() };
}

function fetchToken() {
  return dispatch => {
    dispatch(requestToken());
    return proxyClientInstance.get('/csrf')
      .then(dispatch(receiveToken()))
      .catch(error => dispatch(requestTokenError(error)));
  };
}

function shouldFetchToken(state) {
  const { csrf } = state;
  if (!csrf) {
    return true;
  }
  return !csrf.status || csrf.status === STATUS.UNFETCHED || csrf.status === STATUS.INVALIDATED;
}

export function fetchTokenIfNeeded() {
  // Note that the function also receives getState()
  // which lets you choose what to dispatch next.

  // This is useful for avoiding a network request if
  // a cached value is already available.

  return (dispatch, getState) => {
    if (shouldFetchToken(getState())) {
      // Dispatch a thunk from thunk!
      return dispatch(fetchToken());
    }
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  };
}

function shouldInvalidateToken(state) {
  const { csrf } = state;
  if (!csrf) {
    return true;
  }
  return !csrf.status || csrf.status !== STATUS.FETCHING;
}

export function invalidateTokenIfNeeded() {
  // Note that the function also receives getState()
  // which lets you choose what to dispatch next.

  // This is useful for avoiding a network request if
  // a cached value is already available.

  return (dispatch, getState) => {
    if (shouldInvalidateToken(getState())) {
      // Dispatch a thunk from thunk!
      return dispatch(invalidateToken());
    }
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  };
}
