import { errorToMessage } from '../../../../errorMessaging';
import STATUS from '../../../globalStatuses';
import {
  RECEIVE_PERSON_CERTIFICATE,
  REQUEST_PERSON_CERTIFICATE,
  REQUEST_PERSON_CERTIFICATE_ERROR,
  REQUEST_SAVE_PERSON_CERTIFICATE,
  REQUEST_SAVE_PERSON_CERTIFICATE_ERROR,
  RECEIVE_SAVE_PERSON_CERTIFICATE,
  REQUEST_DELETE_PERSON_CERTIFICATE,
  REQUEST_DELETE_PERSON_CERTIFICATE_ERROR,
  RECEIVE_DELETE_PERSON_CERTIFICATE,
} from './types';

function certificate(
  state = {
    certificate: [],
  },
  action,
) {
  switch (action.type) {
    // GET Certificate
    case REQUEST_PERSON_CERTIFICATE:
      return {
        ...state,
        personCertificateStatus: {
          ...state.personCertificateStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_PERSON_CERTIFICATE:
      return {
        ...state,
        certificate: action.certificate,
        personCertificateStatus: {
          ...state.personCertificateStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_PERSON_CERTIFICATE_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personCertificateStatus: {
          ...state.personCertificateStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // POST Certificate
    case REQUEST_SAVE_PERSON_CERTIFICATE:
      return {
        ...state,
        personCertificateStatus: {
          ...state.personCertificateStatus,
          getStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_SAVE_PERSON_CERTIFICATE:
      return {
        ...state,
        certificate: action.certificate,
        personCertificateStatus: {
          ...state.personCertificateStatus,
          getStatus: STATUS.UPDATED,
        },
      };
    case REQUEST_SAVE_PERSON_CERTIFICATE_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personCertificateStatus: {
          ...state.personCertificateStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // DELETE Certificate
    case REQUEST_DELETE_PERSON_CERTIFICATE:
      return {
        ...state,
        personCertificateStatus: {
          ...state.personCertificateStatus,
          getStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_DELETE_PERSON_CERTIFICATE:
      return {
        ...state,
        certificate: state.certificate.filter(
          cert => (action.certificateIds || []).indexOf(cert.id) === -1,
        ),
        personCertificateStatus: {
          ...state.personCertificateStatus,
          getStatus: STATUS.UPDATED,
        },
      };
    case REQUEST_DELETE_PERSON_CERTIFICATE_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personCertificateStatus: {
          ...state.personCertificateStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    default:
      return state;
  }
}

export default certificate;
