/* action types */
export const REQUEST_POST_ADDRESS = 'REQUEST_POST_ADDRESS';
export const RECEIVE_POST_ADDRESS = 'RECEIVE_POST_ADDRESS';
export const RECEIVE_POST_ADDRESS_SET_STATE = 'RECEIVE_POST_ADDRESS_SET_STATE';
export const REQUEST_ADDRESS_POST_ERROR = 'REQUEST_ADDRESS_POST_ERROR';

export const REQUEST_POST_EMAIL = 'REQUEST_POST_EMAIL';
export const RECEIVE_POST_EMAIL = 'RECEIVE_POST_EMAIL';
export const RECEIVE_POST_EMAIL_SET_STATE = 'RECEIVE_POST_EMAIL_SET_STATE';
export const REQUEST_EMAIL_POST_ERROR = 'REQUEST_EMAIL_POST_ERROR';

export const REQUEST_POST_PHONE = 'REQUEST_POST_PHONE';
export const RECEIVE_POST_PHONE = 'RECEIVE_POST_PHONE';
export const REQUEST_PHONE_POST_ERROR = 'REQUEST_PHONE_POST_ERROR';

export const REQUEST_PHONE_CONSENT = 'REQUEST_PHONE_CONSENT';
export const RECEIVE_PHONE_CONSENT = 'RECEIVE_PHONE_CONSENT';
export const REQUEST_PHONE_CONSENT_ERROR = 'REQUEST_PHONE_CONSENT_ERROR';

export const REQUEST_POST_PHONE_CONSENT = 'REQUEST_POST_PHONE_CONSENT';
export const RECEIVE_POST_PHONE_CONSENT = 'RECEIVE_POST_PHONE_CONSENT';
export const RECEIVE_POST_PHONE_CONSENT_SET_STATE = 'RECEIVE_POST_PHONE_CONSENT_SET_STATE';
export const REQUEST_PHONE_CONSENT_POST_ERROR = 'REQUEST_PHONE_CONSENT_POST_ERROR';

export const REQUEST_POST_EMAIL_CONSENT = 'REQUEST_POST_EMAIL_CONSENT';
export const RECEIVE_POST_EMAIL_CONSENT = 'RECEIVE_POST_EMAIL_CONSENT';
export const RECEIVE_POST_EMAIL_CONSENT_SET_STATE = 'RECEIVE_POST_EMAIL_CONSENT_SET_STATE';
export const REQUEST_EMAIL_CONSENT_POST_ERROR = 'REQUEST_EMAIL_CONSENT_POST_ERROR';

export const REQUEST_EMAIL_CONSENT = 'REQUEST_EMAIL_CONSENT';
export const RECEIVE_EMAIL_CONSENT = 'RECEIVE_EMAIL_CONSENT';
export const REQUEST_EMAIL_CONSENT_ERROR = 'REQUEST_EMAIL_CONSENT_ERROR';

export const REQUEST_INDIANA_CONSENT = 'REQUEST_INDIANA_CONSENT';
export const RECEIVE_INDIANA_CONSENT = 'RECEIVE_INDIANA_CONSENT';
export const REQUEST_INDIANA_CONSENT_ERROR = 'REQUEST_INDIANA_CONSENT_ERROR';

export const REQUEST_POST_INDIANA_CONSENT = 'REQUEST_POST_INDIANA_CONSENT';
export const RECEIVE_POST_INDIANA_CONSENT = 'RECEIVE_POST_INDIANA_CONSENT';
export const RECEIVE_POST_INDIANA_CONSENT_SET_STATE = 'RECEIVE_POST_INDIANA_CONSENT_SET_STATE ';
export const REQUEST_POST_INDIANA_CONSENT_ERROR = 'REQUEST_POST_INDIANA_CONSENT_ERROR';

export const REQUEST_ADDRESS = 'REQUEST_ADDRESS';
export const RECEIVE_ADDRESS = 'RECEIVE_ADDRESS';
export const REQUEST_ADDRESS_ERROR = 'REQUEST_ADDRESS_ERROR';

export const REQUEST_EMAIL = 'REQUEST_EMAIL';
export const RECEIVE_EMAIL = 'RECEIVE_EMAIL';
export const REQUEST_EMAIL_ERROR = 'REQUEST_EMAIL_ERROR';

export const REQUEST_PHONE = 'REQUEST_PHONE';
export const RECEIVE_PHONE = 'RECEIVE_PHONE';
export const REQUEST_PHONE_ERROR = 'REQUEST_PHONE_ERROR';

export const GET_ZIP_CODE = 'GET_ZIP_CODE';

export const REQUEST_SECONDARY_INSTITUTION_ADDRESS = 'REQUEST_SECONDARY_INSTITUTION_ADDRESS';
export const RECEIVE_SECONDARY_INSTITUTION_ADDRESS = 'RECEIVE_SECONDARY_INSTITUTION_ADDRESS';
export const REQUEST_SECONDARY_INSTITUTION_ADDRESS_ERROR = 'REQUEST_SECONDARY_INSTITUTION_ADDRESS_ERROR';

export const REQUEST_HIGHER_INSTITUTION_ADDRESS = 'REQUEST_HIGHER_INSTITUTION_ADDRESS';
export const RECEIVE_HIGHER_INSTITUTION_ADDRESS = 'RECEIVE_HIGHER_INSTITUTION_ADDRESS';
export const REQUEST_HIGHER_INSTITUTION_ADDRESS_ERROR = 'REQUEST_HIGHER_INSTITUTION_ADDRESS_ERROR';

export const CLEAR_ADDRESS_MODIFY_STATUS = 'CLEAR_ADDRESS_MODIFY_STATUS';
export const CLEAR_EMAIL_MODIFY_STATUS = 'CLEAR_EMAIL_MODIFY_STATUS';
export const CLEAR_PHONE_MODIFY_STATUS = 'CLEAR_PHONE_MODIFY_STATUS';
export const CLEAR_PHONE_GET_STATUS = 'CLEAR_PHONE_GET_STATUS';
