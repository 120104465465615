import retryCallIfNeeded from '../../../restCalls';

const baseEndpointUrl = '/api/organizations/v1';

export default function OrganizationService(client) {
  const getOrganizationContactAsync = async higheredId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpointUrl}/highered/${higheredId}`));

  const getOrganizationAddressAsync = async higheredId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpointUrl}/higheredaddress/search?higherEdId=${higheredId}`));

  const getEmployerDiscounts = async () => retryCallIfNeeded(client, () => client
    // .get(`${baseEndpointUrl}/enum/employerDiscount`));
    .get(`${baseEndpointUrl}/employerBenefits?size=10000`));

  const getEmployerBenefit = async dunsNumber => retryCallIfNeeded(client, () => client
    .get(`${baseEndpointUrl}/employerBenefits/?$filter=oracleDuns%20eq%20%27${dunsNumber}%27`));

  const getEmployerBenefitInfoByDuns = async dunsNumber => retryCallIfNeeded(client, () => client
    .get(`${baseEndpointUrl}/employer/${dunsNumber}`));

  const getEmployerBenefitInfoByName = async empName => retryCallIfNeeded(client, () => client
    .get(`${baseEndpointUrl}/employer/search?name=${empName}&showScore=true&minimumScore=0`));
  return {
    getOrganizationContactAsync,
    getOrganizationAddressAsync,
    getEmployerDiscounts,
    getEmployerBenefitInfoByDuns,
    getEmployerBenefitInfoByName,
    getEmployerBenefit,
  };
}
