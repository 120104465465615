import { errorToMessage } from '../../../../errorMessaging';
import STATUS from '../../../globalStatuses';
import {
  RECEIVE_IDENTITY_VERIFICATION,
  REQUEST_IDENTITY_VERIFICATION,
  REQUEST_IDENTITY_VERIFICATION_ERROR,
  CLEAR_IDENTITY_VERIFICATION_GET_STATUS,
  REQUEST_POST_IDENTITY_VERIFICATION,
  REQUEST_POST_IDENTITY_VERIFICATION_ERROR,
  RECEIVE_POST_IDENTITY_VERIFICATION,
} from './types';

function identityverify(
  state = {
    identityVerificationStatus: '',
    identityVerifyStatus: {
      getStatus: STATUS.UNFETCHED,
      modifyStatus: STATUS.UNFETCHED,
    },
  },
  action,
) {
  switch (action.type) {
    // GET identity verification
    case REQUEST_IDENTITY_VERIFICATION:
      return {
        ...state,
        identityVerifyStatus: {
          ...state.identityVerifyStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_IDENTITY_VERIFICATION:
      return {
        ...state,
        identityVerificationStatus: action.identityVerificationStatus,
        identityVerifyStatus: {
          ...state.identityVerifyStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_IDENTITY_VERIFICATION_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        identityVerifyStatus: {
          ...state.identityVerifyStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case CLEAR_IDENTITY_VERIFICATION_GET_STATUS:
      return {
        ...state,
        identityVerifyStatus: {
          ...state.identityVerifyStatus,
          getStatus: STATUS.UNFETCHED,
        },
      };
    // POST identity verification status
    case REQUEST_POST_IDENTITY_VERIFICATION:
      return {
        ...state,
        identityVerifyStatus: {
          ...state.identityVerifyStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_POST_IDENTITY_VERIFICATION:
      return {
        ...state,
        identityVerificationStatus: action.identityVerificationStatus,
        identityVerifyStatus: {
          ...state.identityVerifyStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case REQUEST_POST_IDENTITY_VERIFICATION_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        identityVerifyStatus: {
          ...state.identityVerifyStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    default:
      return state;
  }
}

export default identityverify;
