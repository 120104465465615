import retryCallIfNeeded from '../../../restCalls';

export default function calculatorSummaryService(client) {
  const postCalculatorSummary = async payload => retryCallIfNeeded(
    client,
    () => client.post('/api/financialaid/rules/v1/stateless/financialplancalculator', payload),
  );

  return {
    postCalculatorSummary,
  };
}
