import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: theme.spacing(2.5),
    padding: '1rem 2rem 0',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '2rem',
      marginRight: '2rem',
      padding: theme.spacing(2),
    },
    '& > .MuiTypography-h3': {
      color: '#554238',
    },
    '& > .MuiTypography-h4': {
      color: '#554238',
    },
    // doc overrides
    '& .check-the-box-legend2': {
      fontSize: theme.spacing(2),
    },
  },
  tableOfContents: {
    '& a, & a:active, & a:visited': {
      textDecoration: 'underline!important',
    },
    '& a:hover': {
      textDecoration: 'none!important',
    },
    fontSize: '1.6rem',
    '& li': {
      padding: '0.5rem 0',
      '&::marker': {
        fontSize: '2rem',
      },
    },
    '& a': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  select: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  contentA: {
    padding: '2rem',
    border: '1px dashed darkgray',
    maxWidth: theme.spacing(50),
  },
}));
