import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    margin: 0,
  },
  introduction: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
  title: {
    fontSize: '2.4rem!important',
    fontWeight: '300!important',
    paddingBottom: theme.spacing(1.2),
    [theme.breakpoints.up('sm')]: {
      fontSize: '3.2rem!important',
      paddingBottom: theme.spacing(2.6),
      marginBottom: '0!important',
    },
  },
  titleBold: {
    fontSize: '2.4rem!important',
    fontWeight: '700!important',
    paddingBottom: theme.spacing(1.2),
    [theme.breakpoints.up('sm')]: {
      fontSize: '3.2rem!important',
      paddingBottom: theme.spacing(2.6),
      marginBottom: '0!important',
    },
  },
  illustration: {
    alignSelf: 'center',
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-start',
    },
    [theme.breakpoints.down('xs')]: {
      '& svg': {
        width: '100%',
      },
    },
  },
}));
