export const ACTIONS = {
  REQUEST_POST_CALCULATOR_SUMMARY: 'REQUEST_POST_CALCULATOR_SUMMARY',
  RECEIVE_POST_CALCULATOR_SUMMARY: 'RECEIVE_POST_CALCULATOR_SUMMARY',
  REQUEST_POST_CALCULATOR_SUMMARY_ERROR: 'REQUEST_POST_CALCULATOR_SUMMARY_ERROR',
  REQUEST_INITIAL_POST_CALCULATOR_SUMMARY: 'REQUEST_INITIAL_POST_CALCULATOR_SUMMARY',
  RECEIVE_INITIAL_POST_CALCULATOR_SUMMARY: 'RECEIVE_INITIAL_POST_CALCULATOR_SUMMARY',
  REQUEST_INITIAL_POST_CALCULATOR_SUMMARY_ERROR: 'REQUEST_INITIAL_POST_CALCULATOR_SUMMARY_ERROR',
};

export default ACTIONS;
