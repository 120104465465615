// TODO: Determine if we need the large support of moment
// https://www.npmjs.com/package/dayjs
import moment from 'moment';

export const getDatesTodayAndFutureFromNow = (interval, type = 'months', format = 'YYYY-MM-DD') => {
  const currentDate = moment(new Date());
  const today = currentDate.format(format);
  const future = currentDate.add(interval, type).format(format);
  return {
    today,
    future,
  };
};

export default getDatesTodayAndFutureFromNow;
