import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TextField } from '../../../../base/text/TextField';
import { FormFields } from './Password.fields';

export const Password = () => {
  const { control } = useFormContext();
  return (
    <TextField
      id={FormFields.password.id}
      name={FormFields.password.fieldName}
      label={FormFields.password.label}
      ariaDescribedBy={FormFields.password.ariaDescribedBy}
      required={FormFields.password.required}
      type="password"
      control={control}
      data-mask="all"
    />
  );
};

export default Password;
