import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  checkWrapper: {
    '& label.MuiFormControlLabel-root': {
      alignItems: 'flex-start',
    },
  },
  label: {
    '& span.MuiTypography-root': {
      marginTop: theme.spacing(1),
    },
  },
  errorText: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    color: theme.palette.error.main,
    fontWeight: 500,
    fontSize: theme.spacing(1.2),
    '& a': {
      color: theme.palette.error.main,
    },
    formWrap: {
      display: 'flex',
    },
  },
  errorCheckbox: {
    '& svg': {
      fill: `${theme.palette.error.main} !important`,
    },
  },
}));
