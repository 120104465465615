import Services from '../index';
import { sendCustomFullStoryEvent } from '../../modules/vendor/fullstory/FullStoryUpdater';

const retryCountHeaderName = 'X-Conflict-Retry-Attempt';
const etagHeaderName = 'etag';
const statusCode = 409;
const maxRetries = 2;

const specialCaseUrls = [
  {
    include: '/application/api/applications/v1/applications/',
    omit: [],
    callback: async requestConfig => {
      const data = JSON.parse(requestConfig.data);
      const responseGet = await Services.applicationService.getApplicationByApplicationIdAsync(
        data.id,
      );
      const updatedRequestConfig = {
        ...requestConfig,
        data: {
          ...data,
          externalSystemIds: responseGet.data.externalSystemIds,
        },
      };

      if (!updatedRequestConfig.data.externalSystemIds) {
        sendCustomFullStoryEvent('NAE_APPLICATION_MS_NULL_ID_UPDATE', {
          updateApplication: JSON.stringify(data),
          fetchedApplication: JSON.stringify(responseGet.data),
          mergedApplication: JSON.stringify(updatedRequestConfig.data),
        });
      }
      return updatedRequestConfig;
    },
  },
  {
    include: '/application/api/persons/v1/person/',
    omit: [
      'name',
      'information',
      'military',
      'exam',
      'employment',
      'certification',
      'association',
    ],
    callback: async requestConfig => {
      const data = JSON.parse(requestConfig.data);
      const responseGet = await Services.personService.getPersonMetaByPersonIdAsync(
        data.id,
      );
      const updatedRequestConfig = {
        ...requestConfig,
        data: {
          ...data,
          externalSystemIds: responseGet.data.externalSystemIds,
        },
      };
      return updatedRequestConfig;
    },
  },
];

export default async (error, client) => {
  const retryAttempts = ((error.config || {}).headers || {})[retryCountHeaderName] || 0;
  if (error.config
    && (error.config.method || '').toLowerCase() === 'put'
    && error.config.url
    && error.config.headers
    && error.response
    && error.response.status === statusCode
    && retryAttempts < maxRetries) {
    let reRequestConfig = { ...error.config, headers: { ...error.config.headers, [retryCountHeaderName]: retryAttempts + 1, 'If-Match': error.response.headers[etagHeaderName] } };

    // This handles special cases where we want to
    // manipulate the data or merge data for specific urls.

    // eslint-disable-next-line no-restricted-syntax
    for (const specialCase of specialCaseUrls) {
      if (error.config.url.includes(specialCase.include)
        && (!specialCase.omit || !specialCase.omit.some(
          omitCase => error.config.url.includes(omitCase),
        ))) {
        // eslint-disable-next-line no-await-in-loop
        reRequestConfig = await specialCase.callback(reRequestConfig);
      }
    }
    return client.request(reRequestConfig);
  }
  return Promise.reject(error);
};
