import { errorToMessage } from '../../../../errorMessaging';
import STATUS from '../../../globalStatuses';
import { ACTIONS } from './types';

function employerBenefits(
  state = {
    employerBenefits: {},
  },
  action,
) {
  switch (action.type) {
    case ACTIONS.REQUEST_EMPLOYER_BENEFIT_INFO:
      return {
        ...state,
        employerBenefitsStatus: {
          ...state.employerBenefitsStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_EMPLOYER_BENEFIT_INFO:
      return {
        ...state,
        employerBenefits: action.employerData,
        employerBenefitsStatus: {
          ...state.employerBenefitsStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_EMPLOYER_BENEFIT_INFO_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        employerBenefitsStatus: {
          ...state.employerBenefitsStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    default:
      return state;
  }
}

export default employerBenefits;
