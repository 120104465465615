import { UPDATE_FLAGS, FLOPFLIP_STATE_SLICE } from '@flopflip/react-redux';
import STATUS from '../../globalStatuses';
import { ACTIONS, CONSTANTS } from './types';
import featureFlags from '../../featureFlags';

// GET
function getLastPreference(data) {
  // Retrieve latest preference if there are multiple entries
  let last = data;
  if (Array.isArray(data)) {
    if (data.length > 1) {
      data.sort((a, b) => {
        const aDate = a.createdDate || '';
        const bDate = b.createdDate || '';
        if (aDate === bDate) {
          return a.modifiedDate > b.modifiedDate ? -1 : 1;
        }
        return (aDate > bDate) ? -1 : 1;
      });
      [last] = data;
    } else {
      [last] = data;
    }
  }
  return last;
}

function shouldFetchAppExperience(state) {
  const status = state.preferences?.appExperienceStatus?.getStatus;
  return !status || status === STATUS.UNFETCHED;
}

function receiveAppExperience(data) {
  return {
    type: ACTIONS.RECEIVE_APP_EXPERIENCE,
    data: getLastPreference(data.filter(({ name }) => name === 'AppExperience') || []),
    receivedAt: Date.now(),
  };
}

function requestAppExperienceError(error) {
  return {
    type: ACTIONS.RECEIVE_APP_EXPERIENCE_ERROR,
    error,
    receivedAt: Date.now(),
  };
}

export const fetchAppExperience = personId => async (dispatch, getState, { Services }) => {
  dispatch({ type: ACTIONS.REQUEST_APP_EXPERIENCE });
  try {
    const response = await Services.preferencesService.getPreferenceAsync(
      personId,
      CONSTANTS.PREFERENCE_APP_EXPERIENCE,
    );
    return dispatch(receiveAppExperience(response.data));
  } catch (error) {
    return dispatch(requestAppExperienceError(error));
  }
};

export function fetchAppExperienceIfNeeded(personId) {
  return (dispatch, getState) => {
    if (shouldFetchAppExperience(getState())) {
      return dispatch(fetchAppExperience(personId));
    }
    return Promise.resolve();
  };
}

// POST

function postAppExperienceResponse(data) {
  return {
    type: ACTIONS.RECEIVE_POST_APP_EXPERIENCE,
    data,
    receivedAt: Date.now(),
  };
}

function postAppExperienceError(error) {
  return {
    type: ACTIONS.RECEIVE_POST_APP_EXPERIENCE_ERROR,
    error,
    receivedAt: Date.now(),
  };
}

export const postAppExperience = (personId, applicationId, useNAE) => {
  const isoDate = new Date().toISOString();
  let experience = CONSTANTS.EXPERIENCE_NAE;
  if (!useNAE) {
    experience = CONSTANTS.EXPERIENCE_APP_CENTER;
  }

  const value = {
    appExperience: experience,
    applicationId,
    personId,
  };

  const payload = {
    type: 'External',
    linkId: personId,
    linkType: 'Person',
    name: CONSTANTS.PREFERENCE_APP_EXPERIENCE,
    value: JSON.stringify(value),
    valueType: 'json',
    tags: [
      'NAE',
    ],
    createdDate: isoDate,
    createdBy: 'NAE',
  };

  return async (dispatch, getState, { Services }) => {
    dispatch({ type: ACTIONS.REQUEST_POST_APP_EXPERIENCE });
    try {
      const response = await Services.preferencesService.postPreferenceAsync(payload);
      return dispatch(postAppExperienceResponse(response.data));
    } catch (error) {
      return dispatch(postAppExperienceError(error));
    }
  };
};

// PUT

function putAppExperienceResponse(data) {
  return {
    type: ACTIONS.RECEIVE_PUT_APP_EXPERIENCE,
    data,
    receivedAt: Date.now(),
  };
}

function putAppExperienceError(error) {
  return {
    type: ACTIONS.RECEIVE_PUT_APP_EXPERIENCE_ERROR,
    error,
    receivedAt: Date.now(),
  };
}

export const putAppExperience = (preferenceId, personId, applicationId, useNAE) => {
  const isoDate = new Date().toISOString();
  let experience = CONSTANTS.EXPERIENCE_NAE;
  if (!useNAE) {
    experience = CONSTANTS.EXPERIENCE_APP_CENTER;
  }

  const value = {
    appExperience: experience,
    applicationId,
    personId,
  };

  const payload = {
    type: 'External',
    linkId: personId,
    linkType: 'Person',
    name: CONSTANTS.PREFERENCE_APP_EXPERIENCE,
    value: JSON.stringify(value),
    valueType: 'json',
    tags: [
      'NAE',
    ],
    modifiedDate: isoDate,
    modifiedBy: 'NAE',
  };

  return async (dispatch, getState, { Services }) => {
    dispatch({ type: ACTIONS.REQUEST_PUT_APP_EXPERIENCE });
    try {
      const response = await Services.preferencesService.putPreferenceAsync(
        preferenceId,
        payload,
      );
      return dispatch(putAppExperienceResponse(response.data));
    } catch (error) {
      return dispatch(putAppExperienceError(error));
    }
  };
};

// CLEAR Status
export const clearAppExperienceModifyStatus = () => ({
  type: ACTIONS.CLEAR_APP_EXPERIENCE_PATH_MODIFY_STATUS,
});

// C H O O S E   P A T H
export const buildChoosePathValue = values => (
  {
    militarySelected: values.militarySelection,
    federalGrantsSelected: values.federalGrantsSelection,
    employerBenefitsSelected: values.employerBenefitsSelection,
    employerDiscountsSelected: values.employerDiscountsSelection,
    scholarshipsSelected: values.scholarshipsSelection,
    tribalBenefitsSelected: values.tribalBenefitsSelection,
    noneApplySelected: values.noneApplySelection,
    showLivingExpenses: values.showLivingExpenses === 'True',
  }
);

export const buildChoosePathPayload = (personId, values) => {
  const isoDate = new Date().toISOString();
  return {
    type: 'External',
    linkId: personId,
    linkType: 'Person',
    name: CONSTANTS.PREFERENCE_CHOOSE_PATH,
    value: JSON.stringify(buildChoosePathValue(values)),
    valueType: 'json',
    tags: [
      'NAE',
    ],
    createdDate: isoDate,
    createdBy: 'NAE',
  };
};

// POST
function postChoosePathResponse(data) {
  return {
    type: ACTIONS.RECEIVE_POST_CHOOSE_PATH,
    data,
    receivedAt: Date.now(),
  };
}

function postChoosePathError(error) {
  return {
    type: ACTIONS.RECEIVE_POST_CHOOSE_PATH_ERROR,
    error,
    receivedAt: Date.now(),
  };
}

export const postChoosePath = (personId, applicationId, values) => {
  const payload = buildChoosePathPayload(personId, values);
  return async (dispatch, getState, { Services }) => {
    dispatch({ type: ACTIONS.REQUEST_POST_CHOOSE_PATH });
    try {
      const response = await
      Services.preferencesService.postPreferenceAsync(payload);
      return dispatch(postChoosePathResponse(response.data));
    } catch (error) {
      return dispatch(postChoosePathError(error));
    }
  };
};

// PUT

function putChoosePathResponse(data) {
  return {
    type: ACTIONS.RECEIVE_PUT_CHOOSE_PATH,
    data,
    receivedAt: Date.now(),
  };
}

function putChoosePathError(error) {
  return {
    type: ACTIONS.RECEIVE_PUT_CHOOSE_PATH_ERROR,
    error,
    receivedAt: Date.now(),
  };
}

export const putChoosePath = (personId, values) => {
  const payload = buildChoosePathPayload(personId, values);
  return async (dispatch, getState, { Services }) => {
    const state = getState();
    const preferenceId = state.preferences?.choosePath?.id;
    dispatch({ type: ACTIONS.REQUEST_PUT_CHOOSE_PATH });
    try {
      const response = await Services.preferencesService.putPreferenceAsync(
        preferenceId,
        payload,
      );
      return dispatch(putChoosePathResponse(response.data));
    } catch (error) {
      return dispatch(putChoosePathError(error));
    }
  };
};

// GET

function shouldFetchChoosePath(state) {
  const status = state.preferences?.choosePath?.choosePathStatus?.getStatus;
  return !status || status === STATUS.UNFETCHED;
}

function receiveChoosePath(data) {
  return {
    type: ACTIONS.RECEIVE_CHOOSE_PATH,
    data: getLastPreference(data.filter(({ name }) => name === 'ChoosePath') || []),
    receivedAt: Date.now(),
  };
}

function requestChoosePathError(error) {
  return {
    type: ACTIONS.RECEIVE_CHOOSE_PATH_ERROR,
    error,
    receivedAt: Date.now(),
  };
}

export const fetchChoosePath = personId => async (dispatch, getState, { Services }) => {
  dispatch({ type: ACTIONS.REQUEST_CHOOSE_PATH });
  try {
    const response = await Services.preferencesService.getPreferenceAsync(
      personId,
      CONSTANTS.PREFERENCE_CHOOSE_PATH,
    );
    return dispatch(receiveChoosePath(response.data));
  } catch (error) {
    const { status } = error?.response || {};
    if (status === 404) {
      return dispatch(receiveChoosePath({}));
    }
    return dispatch(requestChoosePathError(error));
  }
};

export function fetchChoosePathIfNeeded(personId) {
  return (dispatch, getState) => {
    if (shouldFetchChoosePath(getState())) {
      return dispatch(fetchChoosePath(personId));
    }
    return Promise.resolve();
  };
}

// Feature Flags
export const updateFlopFlipFeatureFlags = async (preferenceValues, dispatch) => {
  // Convert into right format and update in flopflip
  const flags = {};
  Object.keys(preferenceValues.flags).forEach(flag => {
    const name = featureFlags[flag];
    if (name) {
      flags[name] = preferenceValues.flags[flag] === 'true';
    }
  });
  await dispatch(
    {
      type: UPDATE_FLAGS,
      payload: {
        id: FLOPFLIP_STATE_SLICE,
        flags: {
          ...flags,
        },
      },
    },
  );
};

function shouldFetchFeatureFlags(state) {
  const status = state.preferences?.featureFlagsStatus?.getStatus;
  const empty = Object.keys(state.preferences?.featureFlags).length === 0;
  return !status || status === STATUS.UNFETCHED || empty;
}

function receiveFeatureFlags(data) {
  return {
    type: ACTIONS.RECEIVE_FEATURE_FLAGS,
    data: getLastPreference(data.filter(({ name }) => name === 'FeatureFlags') || []),
    receivedAt: Date.now(),
  };
}

function requestFeatureFlagsError(error) {
  return {
    type: ACTIONS.RECEIVE_FEATURE_FLAGS_ERROR,
    error,
    receivedAt: Date.now(),
  };
}

export const fetchFeatureFlags = personId => async (dispatch, getState, { Services }) => {
  dispatch({ type: ACTIONS.REQUEST_FEATURE_FLAGS });
  try {
    const response = await Services.preferencesService.getPreferenceAsync(
      personId,
      CONSTANTS.PREFERENCE_FEATURE_FLAGS,
    );
    const preference = getLastPreference(response.data.filter(({ name }) => name === 'FeatureFlags') || []);

    if (preference?.value) {
      await updateFlopFlipFeatureFlags(JSON.parse(preference?.value), dispatch);
    }
    return dispatch(receiveFeatureFlags(response.data));
  } catch (error) {
    return dispatch(requestFeatureFlagsError(error));
  }
};

export function fetchFeatureFlagsIfNeeded(personId) {
  return (dispatch, getState) => {
    if (shouldFetchFeatureFlags(getState())) {
      return dispatch(fetchFeatureFlags(personId));
    }
    return Promise.resolve();
  };
}

// POST
function postFeatureFlagsResponse(data) {
  return {
    type: ACTIONS.RECEIVE_POST_FEATURE_FLAGS,
    data,
    receivedAt: Date.now(),
  };
}

function postFeatureFlagsError(error) {
  return {
    type: ACTIONS.RECEIVE_POST_FEATURE_FLAGS_ERROR,
    error,
    receivedAt: Date.now(),
  };
}

export const postFeatureFlags = (personId, flags) => {
  const isoDate = new Date().toISOString();

  const value = {
    flags,
  };

  const payload = {
    type: 'External',
    linkId: personId,
    linkType: 'Person',
    name: CONSTANTS.PREFERENCE_FEATURE_FLAGS,
    value: JSON.stringify(value),
    valueType: 'json',
    tags: [
      'NAE',
    ],
    createdDate: isoDate,
    createdBy: 'NAE',
  };

  return async (dispatch, getState, { Services }) => {
    dispatch({ type: ACTIONS.REQUEST_POST_FEATURE_FLAGS });
    try {
      const response = await Services.preferencesService.postPreferenceAsync(payload);
      await updateFlopFlipFeatureFlags(value, dispatch);
      return dispatch(postFeatureFlagsResponse(response.data));
    } catch (error) {
      return dispatch(postFeatureFlagsError(error));
    }
  };
};

// PUT

function putFeatureFlagsResponse(data) {
  return {
    type: ACTIONS.RECEIVE_PUT_FEATURE_FLAGS,
    data,
    receivedAt: Date.now(),
  };
}

function putFeatureFlagsError(error) {
  return {
    type: ACTIONS.RECEIVE_PUT_FEATURE_FLAGS_ERROR,
    error,
    receivedAt: Date.now(),
  };
}

export const putFeatureFlags = (preferenceId, personId, flags) => {
  const isoDate = new Date().toISOString();

  const value = {
    flags,
  };

  const payload = {
    type: 'External',
    linkId: personId,
    linkType: 'Person',
    name: CONSTANTS.PREFERENCE_FEATURE_FLAGS,
    value: JSON.stringify(value),
    valueType: 'json',
    tags: [
      'NAE',
    ],
    modifiedDate: isoDate,
    modifiedBy: 'NAE',
  };

  return async (dispatch, getState, { Services }) => {
    dispatch({ type: ACTIONS.REQUEST_PUT_FEATURE_FLAGS });
    try {
      const response = await Services.preferencesService.putPreferenceAsync(
        preferenceId,
        payload,
      );
      await updateFlopFlipFeatureFlags(value, dispatch);
      return dispatch(putFeatureFlagsResponse(response.data));
    } catch (error) {
      return dispatch(putFeatureFlagsError(error));
    }
  };
};

// CLEAR Status
export const clearChoosePathModifyStatus = () => ({
  type: ACTIONS.CLEAR_CHOOSE_PATH_MODIFY_STATUS,
});
