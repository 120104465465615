import React from 'react';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { replace } from 'connected-react-router';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import userManager from '../../store/domains/auth0/userManager';
import Alert from '../alert/Alert';
import LoadingModal from '../modal/LoadingModal';
import { sendCustomFullStoryEvent } from '../../modules/vendor/fullstory/FullStoryUpdater';
import { UOPX_HELP_DESK_PHONE_NUMBER, phoneNumber10Digit } from '../../modules/admissions/constants';

const DeviceClockInfo = () => (
  <>
    <span>
      The clock on your device doesn&apos;t match the time in your current time zone.
      We&apos;re not able to connect you to your application until your device&apos;s
      clock is updated. Go to your system settings to set your date and time automatically.
      Then make sure your time zone matches your current location.
      If you need help making these changes, contact Tech Support at
      {' '}
    </span>
    <Button
      id="sessionCtaAlert"
      className="smallCtaBtn"
      variant="text"
      href={`tel:+1${phoneNumber10Digit(UOPX_HELP_DESK_PHONE_NUMBER)}`}
      disableRipple
    >
      {UOPX_HELP_DESK_PHONE_NUMBER}
    </Button>
    <span>.</span>
    <br />
    <br />
    <span>After your clock is updated, you can return to this page and refresh your browser</span>
  </>
);

const CallbackComponentWrapper = ({ dispatch }) => {
  const [isErrored, setIsErrored] = React.useState(false);
  const [callbackError, setCallbackError] = React.useState('');
  const successCallback = user => {
    const returnUrl = (user.state || {}).redirectUrl || '/';
    dispatch(replace(returnUrl));
  };

  const errorCallback = error => {
    setCallbackError(error);
    setIsErrored(true);
  };

  const redirectAttempts = () => {
    const now = Date.now();
    const expires = now + 900000;

    const sessionRedirectAttemptsObject = JSON.parse(sessionStorage.getItem('redirectAttempts'));
    let redirectAttemptsObject = {
      expiresAt: expires,
      attempts: 1,
    };

    if (sessionRedirectAttemptsObject?.expiresAt
      && now < sessionRedirectAttemptsObject?.expiresAt) {
      redirectAttemptsObject = {
        ...sessionRedirectAttemptsObject,
        attempts: Number(sessionRedirectAttemptsObject?.attempts) + 1,
      };
    }
    sessionStorage.setItem('redirectAttempts', JSON.stringify(redirectAttemptsObject));

    return Number(redirectAttemptsObject.attempts);
  };

  const handleCallbackError = () => {
    let returnVal = null;

    if (redirectAttempts() < 3) {
      // eslint-disable-next-line no-underscore-dangle
      window.location.replace(`${(window.__ENV || {}).REACT_APP_BECOME_LOGIN_URL}`);
    } else {
      returnVal = (
        <Alert
          // eslint-disable-next-line no-underscore-dangle
          htmlFullMessage={<DeviceClockInfo />}
          dialogTitle="Your device's clock isn't quite right"
          hidePartialMessage
          hidePlease
          hideLink
          hideFinalDot
        />
      );
      sendCustomFullStoryEvent('OIDC_CALLBACK_ERROR', {
        errorCode: '00-20000-999',
        clientTimeStamp: Math.round(new Date().getTime() / 1000),
        errorMessage: callbackError.message,
        errorStackTrace: callbackError.stack,
      });
    }
    return returnVal;
  };

  // https://github.com/IdentityModel/oidc-client-js/issues/309
  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={successCallback}
      errorCallback={errorCallback}
    >
      {isErrored ? (
        handleCallbackError()
      ) : (
        <LoadingModal />
      )}
    </CallbackComponent>
  );
};

CallbackComponentWrapper.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(CallbackComponentWrapper);
