import { ACTIONS } from '../types';
import STATUS from '../../../globalStatuses';

export const secondaryInstitutionReductions = {
  [ACTIONS.REQUEST_SECINSTTYPE]: state => ({
    ...state,
    secInstTypesStatus: {
      ...state.secInstTypesStatus,
      getStatus: STATUS.FETCHING,
      errorData: null,
    },
  }),
  [ACTIONS.RECEIVE_SECINSTTYPE]: (state, action) => ({
    ...state,
    secInstTypes: action.payload.secInstTypes,
    secInstTypesStatus: {
      ...state.secInstTypesStatus,
      getStatus: STATUS.FETCHED,
    },
  }),
  [ACTIONS.RECEIVE_SECINSTTYPE_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    secInstTypesStatus: {
      ...state.secInstTypesStatus,
      getStatus: STATUS.ERROR,
      errorData: action.error,
    },
  }),
};

export default secondaryInstitutionReductions;
