export default {
  MONTHS: [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ],
  // Field Constants
  willReceiveBenefitsField: 'RECV_EMP_BENEFITS',
  willUseBenefitsField: 'USE_EMP_BENEFITS',
  reimburseOrBillField: 'REIM_OR_BILL',
  dollarOrPercentageField: 'BENEFIT_UNIT',
  benefitAmountField: 'BENEFIT_AMOUNT',
  percentageCapField: 'ANNUAL_CAP_AMT_IN_CURRENCY',
  fiscalDayField: 'FISCAL_START_DAY',
  fiscalMonthField: 'FISCAL_START_MONTH',
  // Tribal Benefits
  tribalBenefitsHeader: 'Native American tribal benefits',
  tribalBenefitsSummary: "If you're affiliated with a Native American tribe, you may be able to receive tribal benefits for college.",
  tribalBenefitsQuestion: 'Are you affiliated with a Native American tribe?',
  tribalBenefitsNameHeader: 'Select your affiliated tribe name:',
  tribalBenefitsNameNote: 'Choose "Not Listed" if your tribe name is not found.',
  tribalBenefitsUseQuestion: 'Do you plan to use any tribal benefits?',
  tribalBenefitsAmountNote: 'If you don\'t know your tribal benefit amount, please enter "0" for the amount.',
  tribalBenefitsFrequencyItems: [
    { value: 'yearly', label: 'Every year' },
    { value: 'one time', label: 'Only once' },
  ],
  // Errors
  tribalAffiliationEmptyError: 'You must select one item from the drop-down menu to indicate tribal name.',
  tribalBenefitsAmountEmptyError: 'Enter the estimated tribal benefit amount.',
  tribalBenefitsAmountRegexError: 'Enter only positive whole numbers with decimals and commas',
  tribalBenefitsFrequencyEmptyError: 'You must select one item from the drop-down menu to indicate that the scholarship/grant is to be paid yearly or only once.',
  // Types
  types: {
    json: 'json',
    tribalBenefit: 'TRIBAL_BENEFIT',
    tribalDiscount: 'TRIBAL_DISCOUNT',
  },
};
