import retryCallIfNeeded from '../../../restCalls';
// import { personId } from '../../../../mocks/generators/wallet/v1/creditbalance_direct_deposit';
import defaultFeatureFlags from '../../../store/defaultFeatureFlags';
import flagNames from '../../../store/featureFlags';

const baseEndpointUrl = '/api/contacts/v1';
const baseEndpointUrlV2 = '/api/contacts/v2';

/*
  URLs should be the resource path the proxy via node
  will forward to MS otherwise it will go to json-server
  refer to the task service
*/
export default function ContactService(client) {
  const getAddressSuggestionAsync = (input, cityAndState) => client
    .get(`${baseEndpointUrl}/suggest/address?prefix=${encodeURI(input)}&prefer=${encodeURI(cityAndState)}&prefer_ratio=1`);
  const getAddressByPersonId = async personId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpointUrl}/addresses?linkId=${personId}&linkType=person`));
  const getAddressByAddressId = async addressId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpointUrl}/address/${addressId}`));
  const getEmailByPersonId = async personId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpointUrl}/emails?linkId=${personId}&linkType=person`));
  const getZipCodeValidationAsync = zipCode => retryCallIfNeeded(client, () => client
    .get(`${baseEndpointUrl}/validate/zipcode?zipcode=${encodeURI(zipCode)}`));
  const getAddressValidationAsync = (street, city, state) => retryCallIfNeeded(client, () => client
    .get(`${baseEndpointUrl}/validate/address?street=${encodeURI(street)}&city=${encodeURI(city)}&state=${encodeURI(state)}`));
  const getContactAddressByLinkIdAsync = async linkId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpointUrl}/addresses?linkId=${linkId}&pref=yes`));
  const getPhoneByPersonId = async personId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpointUrl}/phones?linkId=${personId}&linkType=person`));
  const getPhoneConsent = async linkId => retryCallIfNeeded(client, () => client
    .get(`/api/contacts/v2/consent/phone/${linkId}`));
  const getPhoneConsentAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`/api/contacts/v2/consent/phone/${personId}`));
  const getEmailConsent = async personId => retryCallIfNeeded(client, () => client
    .get(`/api/contacts/consent/v1/email/${personId}`));
  const putEmail = async (emailId, payload) => retryCallIfNeeded(client, () => client
    .put(`${baseEndpointUrl}/email/${emailId}`, payload));
  const postEmail = async payload => retryCallIfNeeded(client, () => client
    .post(`${baseEndpointUrl}/email`, payload));
  const putAddress = async (addressId, payload) => retryCallIfNeeded(client, () => client
    .put(`${baseEndpointUrl}/address/${addressId}`, payload));
  const postAddress = async payload => retryCallIfNeeded(client, () => client
    .post(`${baseEndpointUrl}/address`, payload));
  const putPhone = async (phoneId, payload) => retryCallIfNeeded(client, () => client
    .put(`${baseEndpointUrl}/phone/${phoneId}`, payload));
  const postPhone = async payload => retryCallIfNeeded(client, () => client
    .post(`${baseEndpointUrl}/phone`, payload));
  // const putPhoneConsent = async (phoneId, payload) => retryCallIfNeeded(client, () => client
  //   .put(`/api/contacts/v2/consent/phone/${phoneId}`, payload));
  const putPhoneConsent = async payload => retryCallIfNeeded(client, () => client
    .put('/api/contacts/v2/consent/phone', payload));
  const postPhoneConsent = async payload => retryCallIfNeeded(client, () => client
    .post('/api/contacts/v2/consent/phone', payload));
  const postEmailConsent = async payload => retryCallIfNeeded(client, () => client
    .post('/api/contacts/consent/v1/email', payload));
  const getIpInformation = ipAddress => retryCallIfNeeded(client, () => client
    .get(`${baseEndpointUrl}/locationinfo/${ipAddress}`));
  const getFormattedAddressSuggestionAsync = (input, dataSetter, { cityAndState }) => {
    if (input && input.length > 1) {
      const encodedInput = encodeURI(input);
      const encodedCityAndState = encodeURI(cityAndState);
      let url;
      if (defaultFeatureFlags[flagNames.CONTACT_V2_ENABLED]) {
        url = `${baseEndpointUrlV2}/suggest/address?prefix=${encodedInput}&prefer=${encodedCityAndState}&prefer_ratio=1`;
      } else {
        url = `${baseEndpointUrl}/suggest/address?prefix=${encodedInput}&prefer=${encodedCityAndState}&prefer_ratio=1`;
      }
      client.get(url)
        .then(responseData => {
          if (Array.isArray(responseData?.data?.suggestions)) {
            dataSetter(responseData.data.suggestions.map(suggestion => ({
              label: suggestion.text, value: suggestion,
            })));
          } else {
            dataSetter([]);
          }
        // eslint-disable-next-line no-console
        }).catch(error => {
          dataSetter([]);
          console.warn(`Attempted to fetch address suggestion but received ${error?.response?.status}`);
        });
    }
  };
  return {
    getAddressByPersonId,
    getAddressByAddressId,
    getEmailByPersonId,
    postEmail,
    putEmail,
    postAddress,
    putAddress,
    getPhoneByPersonId,
    postPhone,
    putPhone,
    getPhoneConsent,
    postPhoneConsent,
    putPhoneConsent,
    getZipCodeValidationAsync,
    getAddressValidationAsync,
    getAddressSuggestionAsync,
    getFormattedAddressSuggestionAsync,
    getContactAddressByLinkIdAsync,
    getEmailConsent,
    postEmailConsent,
    getPhoneConsentAsync,
    getIpInformation,
  };
}
