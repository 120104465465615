import retryCallIfNeeded from '../../../restCalls';

export default function WorkService(client) {
  const getEmploymentsByPersonIdAsync = async personId => retryCallIfNeeded(client, () => client.get(`/api/persons/v1/person/${personId}/employments`));
  const deleteEmploymentAsync = async id => retryCallIfNeeded(client, () => client.delete(`/api/persons/v1/person/employment/${id}`));
  const saveEmploymentAsync = async (payload, workETag) => {
    const { id } = payload;
    const baseUri = '/api/persons/v1/person/employment';
    const config = {
      headers: {
        'If-Match': workETag || 'Default',
      },
    };
    return id ? client.put(`${baseUri}/${id}`, payload, config) : client.post(baseUri, payload);
  };
  return {
    getEmploymentsByPersonIdAsync,
    saveEmploymentAsync,
    deleteEmploymentAsync,
  };
}
