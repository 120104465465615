import retryCallIfNeeded from '../../../restCalls';

export default function CertificateService(client) {
  const getCertificatesByPersonIdAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`/api/persons/v1/person/${personId}/certifications`));

  const postCertificatesAsync = async (personId, payload) => retryCallIfNeeded(client, () => client
    .post(`/api/persons/v1/person/${personId}/certifications`, payload));

  const deleteCertificateAsync = async certificateId => retryCallIfNeeded(client, () => client
    .delete(`/api/persons/v1/person/certification/${certificateId}`));

  return {
    getCertificatesByPersonIdAsync,
    postCertificatesAsync,
    deleteCertificateAsync,
  };
}
