import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  dialog: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(0),
    },
    '& .MuiDialogTitle-root': {
      [theme.breakpoints.down('sm')]: {
        padding: '2rem 1rem 0!important',
      },
    },
    '& .MuiDialogContent-root': {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0!important',
        paddingRight: '0!important',
      },
    },
    '& .MuiDialog-container.MuiDialog-scrollPaper': {
      display: 'block',
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        height: '111%',
      },
      [theme.breakpoints.up('lg')]: {
        height: '118%',
      },
    },
    '& .MuiDialog-paper.MuiDialog-paperScrollPaper': {
      maxWidth: '100%',
      height: '100%',
      borderRadius: 'unset',
      margin: '0!important',
      marginRight: '0!important',
      backgroundColor: theme.palette.common.black,
      position: 'relative',
    },
  },
  videoWrapper: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  videoContainer: {
    maxWidth: theme.spacing(120),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0!important',
      paddingRight: '0!important',
    },
  },
  video: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    paddingBottom: '56.25%', // 16:9
  },
  iframeVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  text: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    paddingTop: theme.spacing(2),
    fontSize: '3.2rem!important',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.spacing(3),
    },
  },
  transcriptWrapper: {
    marginBottom: theme.spacing(10),
  },
  transcriptTitle: {
    fontSize: '1.8rem!important',
    fontWeight: '600!important',
  },
  transcriptList: {
    listStyleType: 'none',
    height: theme.spacing(30),
    overflow: 'scroll',
    padding: 0,
  },
  time: {
    fontWeight: '600',
  },
  timeText: {
    fontWeight: '300',
  },
}));

export const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    textTransform: 'none',
  },
  closeLink: {
    display: 'inline-grid',
    '& .MuiButton-label': {
      paddingBottom: 0,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.text.primary,
      borderBottomWidth: theme.spacing(0.3),
      textDecoration: 'none',
      marginTop: '-1rem',
      color: theme.palette.common.white,
    },
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      '& .MuiButton-label': {
        borderBottomColor: theme.palette.common.white,
        textDecoration: 'none',
      },
    },
    '&:active': {
      backgroundColor: 'transparent',
      '& .MuiButton-label': {
        borderBottomColor: theme.palette.primary.dark,
        textDecoration: 'none',
      },
    },
  },
  heading: {
    flex: 'auto',
  },
  close: {
    padding: '0 0 0 1rem',
    flex: 'none',
  },
  modalContainer: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.spacing(165),
      margin: '0 auto',
    },
  },
});
