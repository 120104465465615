import retryCallIfNeeded from '../../../restCalls';

export default function ExamService(client) {
  const getExamByPersonIdAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`/api/persons/v1/person/${personId}/exams`));
  const saveExamAsync = async (personId, payload) => retryCallIfNeeded(client, () => client
    .post(`/api/persons/v1/person/${personId}/exams`, payload));
  const deleteExamAsync = async payload => retryCallIfNeeded(client, () => client
    .post('/api/persons/v1/person/null/exams', payload));
  const deleteExamByIdAsync = async creditId => retryCallIfNeeded(client, () => client
    .delete(`/api/persons/v1/person/exam/${creditId}`));
  return {
    deleteExamAsync,
    deleteExamByIdAsync,
    getExamByPersonIdAsync,
    saveExamAsync,
  };
}
