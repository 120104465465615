export const ACTIONS = {
  REQUEST_CHAT_OPEN: 'REQUEST_CHAT_OPEN',
  REQUEST_CHAT_CLOSED: 'REQUEST_CHAT_CLOSED',
  REQUEST_CHAT_ERROR: 'REQUEST_CHAT_ERROR',
  REQUEST_SUBSCRIBE_TO_CHAT_STATE: 'REQUEST_SUBSCRIBE_TO_CHAT_STATE',
  REQUEST_UNSUBSCRIBE_TO_CHAT_STATE: 'REQUEST_UNSUBSCRIBE_TO_CHAT_STATE',
  RESOLVE_CHAT_STATE: 'RESOLVE_CHAT_STATE',
  REQUEST_CHAT_BUTTON_ENABLED: 'REQUEST_CHAT_BUTTON_ENABLED',

  REQUEST_SUBSCRIBE_TO_COBROWSE_STATE: 'REQUEST_SUBSCRIBE_TO_COBROWSE_STATE',
  REQUEST_UNSUBSCRIBE_TO_COBROWSE_STATE: 'REQUEST_UNSUBSCRIBE_TO_COBROWSE_STATE',
  RESOLVE_COBROWSE_STATE: 'RESOLVE_COBROWSE_STATE',
  REQUEST_COBROWSE_STATE_ERROR: 'REQUEST_COBROWSE_STATE_ERROR',

  REQUEST_TEXT_OPEN: 'REQUEST_TEXT_OPEN',
  REQUEST_TEXT_CLOSED: 'REQUEST_TEXT_CLOSED',
  REQUEST_TEXT_ERROR: 'REQUEST_TEXT_ERROR',
  REQUEST_SUBSCRIBE_TO_TEXT_STATE: 'REQUEST_SUBSCRIBE_TO_TEXT_STATE',
  REQUEST_UNSUBSCRIBE_TO_TEXT_STATE: 'REQUEST_UNSUBSCRIBE_TO_TEXT_STATE',
  RESOLVE_TEXT_STATE: 'RESOLVE_TEXT_STATE',
  REQUEST_TEXT_BUTTON_ENABLED: 'REQUEST_TEXT_BUTTON_ENABLED',
};

export default ACTIONS;
