import { sendCustomFullStoryEvent } from '../fullstory/FullStoryUpdater';
import defaultFeatureFlags from '../../../store/defaultFeatureFlags';
import flagNames from '../../../store/featureFlags';
import { triggerAlloyTrack } from '../alloy/AlloyUpdater';

export const CYOPTealiumNames = {
  MILITARY_BENEFITS: 'military',
  GRANTS: 'grants',
  EMPLOYER_BENEFITS: 'employer benefits',
  ASSOCIATIONS: 'associations',
  SCHOLARSHIPS: 'scholarships',
  TRIBAL_BENEFITS: 'tribal benefits',
  SHOW_LIVING_EXPENSES: 'show living expenses',
};

export const triggerTealiumLinkEvent = ({
  tealiumEventName,
  otherAttributes,
}) => {
  if (window.utag && window.utag.link instanceof Function) {
    const tealiumPayload = {
      tealium_event: tealiumEventName,
      ...otherAttributes,
    };
    window.utag.link(tealiumPayload);
  }
};

export const triggerTealiumSkipPageEvent = (pageName, personId) => {
  triggerAlloyTrack('skip_this_step_new_admission', {
    componentName: 'button',
    name: 'Skip step',
    text: `skip this step - ${pageName}`,
    interactLocation: 'Content',
  }, { personId });

  sendCustomFullStoryEvent('NAE_SKIP_CYOP_PAGE', { skippedPage: pageName });
};

export const triggerTealiumTapcapEligibleEvent = (tapcapEligible, personId) => {
  triggerAlloyTrack('tapcap_eligible', {
    tapcap_eligible: tapcapEligible,
  }, { personId });
  sendCustomFullStoryEvent('NAE_TAPCAP_ELIGIBILE', { eligible: tapcapEligible });
};

export const triggerTealiumTapcapOptInEvent = (tapcapOptIn, personId) => {
  triggerAlloyTrack('tapcap_opt_in', {
    tapcap_opt_in: tapcapOptIn,
  }, { personId });

  sendCustomFullStoryEvent('NAE_TAPCAP_OPT_IN', { optIn: tapcapOptIn });
};

export const triggerTealiumFormGenerationTypeEvent = (applicationId, sourceSystem) => {
  if (defaultFeatureFlags[flagNames.DGSP_ENABLED]) {
    triggerAlloyTrack('NAE_FORM_GENERATION_TYPE', {
      source_system: sourceSystem,
    }, { applicationId });
  }

  sendCustomFullStoryEvent('NAE_FORM_GENERATION_TYPE', {
    source_system: sourceSystem,
  });
};

export default {
  triggerTealiumLinkEvent,
  triggerTealiumSkipPageEvent,
  CYOPTealiumNames,
};
