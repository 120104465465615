import retryCallIfNeeded from '../../../restCalls';

const drsBaseEndpointUrl = '/api/dgsp/drs/v1';
const dfeBaseEndpoint = '/api/dgsp/dfe/v1';
const dwsBaseEndpointURL = '/api/dgsp/dws/v1';

export default function DgspService(client) {
  const getPacketsSupported = async (applicationId, packets) => {
    const payload = {
      applicationId,
      packets: packets.map(p => ({
        packetName: p,
      })),
    };
    try {
      const response = await retryCallIfNeeded(
        client,
        () => client.post(
          `${drsBaseEndpointUrl}/packet-forms/packets-supported`,
          payload,
        ),
      );
      const data = response?.data;
      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('DGSP support check failed:', error);
      throw error;
    }
  };

  const getDwsPacketByApplicationIdAsync = async (applicationId, packetName) => {
    try {
      const payload = {
        applicationId,
        // BECOME_ALL_ACKNOWLEDGEMENTS contains all documents including criminal conviction
        // etc while BECOME_GENERAL_ACKNOWLEDGEMENTS contains only the general docs.
        packetName: packetName === 'BECOME_GENERAL_ACKNOWLEDGEMENTS' ? 'BECOME_ALL_ACKNOWLEDGEMENTS' : packetName,
      };
      const response = await retryCallIfNeeded(
        client,
        () => client.post(
          `${dwsBaseEndpointURL}/packet/packets`,
          payload,
        ),
      );
      const data = response?.data;
      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('DWS get packet failed:', error);
      throw error;
    }
  };

  const downloadDocument = async (applicationId, educationId, formName) => retryCallIfNeeded(
    client,
    () => client.get(
      `${dfeBaseEndpoint}/document-export/form-pdf?applicationId=${applicationId}&formName=${formName}&educationId=${educationId}`,
      {
        responseType: 'arraybuffer', headers: { accept: 'application/pdf' },
      },
    ),
  );

  const dgspPostSignDocuments = async (formIDs, applicationId) => {
    const payload = { formIDs, applicationId };
    try {
      const response = await retryCallIfNeeded(
        client,
        () => client.post(
          `${dwsBaseEndpointURL}/sign`,
          payload,
        ),
      );
      const data = response?.data;
      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('DGSP sign documents failed:', error);
      throw error;
    }
  };

  return {
    getPacketsSupported,
    downloadDocument,
    getDwsPacketByApplicationIdAsync,
    dgspPostSignDocuments,
  };
}
