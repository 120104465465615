import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    textTransform: 'none',
    fontSize: '1.2rem',
  },
  dialogActions: {
    flexDirection: 'column-reverse',
    '& .confirmBtn.MuiButton-textPrimary': {
      margin: '0.5rem 2.4rem 0!important',
    },
    '& .confirmBtn.MuiButton-textSecondary': {
      margin: '2.4rem!important',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '& .confirmBtn.MuiButton-textPrimary': {
        margin: '2.4rem 0 2.4rem 2.4rem!important',
        minWidth: theme.spacing(16),
      },
      '& .confirmBtn.MuiButton-textSecondary': {
        margin: '2.4rem 0!important',
        minWidth: theme.spacing(16),
      },
    },
  },
  messageText: {},
  loadingWrapper: {
    minWidth: theme.spacing(55),
  },
  h3: {
    fontWeight: '400',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    fontSize: theme.spacing(1.8),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.spacing(1.8),
      width: '75%',
    },
  },
  h2: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    fontWeight: '500',
  },
  imgWrapper: {
    // float: 'right',
    position: 'absolute',
    zIndex: 0,
    top: theme.spacing(6.4),
    right: theme.spacing(-4),
  },
  dialogContent: {
    position: 'relative',
    overflowX: 'hidden',
    minHeight: theme.spacing(42.5),
  },
  editAppButtonWrapper: {
    marginLeft: theme.spacing(2),
  },
  modal: {
    display: 'flex',
    padding: '5%',
    alignItems: 'center',
    justifyContent: 'left',
  },
  box: {
    backgroundColor: 'white',
    outline: 'none',
    borderRadius: theme.spacing(0.25),
  },
  modalTitle: {
    padding: '5%',
    justifyContent: 'left',
  },
  modalText: {
    padding: '5%',
    paddingTop: '7.5%',
    justifyContent: 'left',
  },
  modalButtons: {
    display: 'flex',
    paddingTop: '2.5%',
    paddingBottom: '1.5%',
    paddingRight: '5%',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
    padding: theme.spacing(1),
    textTransform: 'none',
    minHeight: theme.spacing(7),
  },
  closeButton: {
    color: theme.palette.grey[500],
    minWidth: theme.spacing(24),
  },
  heading: {
    flex: 'auto',
  },
  close: {
    padding: '0 0 0 1rem',
    flex: 'none',
  },
});
