import { ACTIONS } from './types';
import GLOBALSTATUS from '../../../globalStatuses';

function personTasks(
  state = {
    personTasks: [],
    personTasksStatus: {
      getStatus: GLOBALSTATUS.UNFETCHED,
      modifyStatus: GLOBALSTATUS.UNFETCHED,
    },
  },
  { type, payload },
) {
  switch (type) {
    // person tasks
    case ACTIONS.REQUEST_PERSONTASK:
      return {
        ...state,
        personTasksStatus: {
          ...state.personTaskStatus,
          getStatus: GLOBALSTATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_PERSONTASK:
      return {
        ...state,
        personTasks: payload.tasks,
        personTasksStatus: {
          ...state.personTaskStatus,
          getStatus: GLOBALSTATUS.FETCHED,
        },
      };
    case ACTIONS.RECEIVE_PERSONTASK_ERROR:
      return {
        ...state,
        personTasksStatus: {
          ...state.personTaskStatus,
          getStatus: GLOBALSTATUS.ERROR,
          errorData: payload.error,
        },
      };
    default:
      return state;
  }
}

export default personTasks;
