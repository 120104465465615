import axios from 'axios';
import idHeaders from '../interceptors/idHeaders';
import retryCallIfNeeded from '../../restCalls';

const forgerockBase = '/application/forgerock';

const sessionCheckEndpoint = '/validate-session';

export default function ForgerockService() {
  axios.interceptors.request.use(idHeaders);
  const validateLoggedInUser = async () => retryCallIfNeeded(axios, () => axios
    .post(`${forgerockBase}${sessionCheckEndpoint}`));

  return {
    validateLoggedInUser,
  };
}
