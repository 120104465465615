import { PAGENAME, PAGEPATH, types, financialSection } from './constants';
import store from '../../../store/configureStore';
import {
  skipAssociationsPage,
  skipEmployerBenefitsPage,
  skipGrantsPage,
  skipMilitaryBenefitsPage,
  skipScholarshipsPage,
  skipTribalBenefitsPage,
} from '../../../components/react-hook-forms/helpers/skipper';
import {
  CYOPTealiumNames,
} from '../../vendor/tealium/TealiumUpdater';
import {
  PROGRAM_LEVEL_MAP,
} from './military/constants';
import { getFeatureFlag } from '../../../store/helpers/featureFlagHelper';
import flagNames from '../../../store/featureFlags';

export { PAGENAME, PAGEPATH } from './constants';

export const sanitizeCurrencyInput = currency => currency.toString().replace(/,/g, '');

export const defaultCurrencyInput = '0';

export const CurrencyStrToFloat = currency => (
  Number.parseFloat(currency.toString().replace(/,/g, ''))
);

export const parseCurrencyInput = input => (
  !Number.isNaN(parseFloat(input))
    ? parseFloat(input.toString().replace(/,/g, '')).toFixed(2)
    : input
);

export const valueToLowerCase = (string = '') => {
  switch (string.toLowerCase().trim()) {
    case 'true': case 'yes': case '1': return 'true';
    case 'false': case 'no': case '0': return 'false';
    default: return null;
  }
};

export const switchChoice = (string = '') => {
  switch (string.toLowerCase().trim()) {
    case 'true': return 'YEARLY';
    case 'false': return 'ONE TIME';
    default: return null;
  }
};

export const stringToButtonValue = string => {
  if (!string) { return null; }
  switch (string.toLowerCase().trim()) {
    case 'true': case 'yes': case '1': return 'True';
    case 'false': case 'no': case '0': return 'False';
    default: return null;
  }
};

export const numToCurrencyStr = num => (
  num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
);

export const renderSelectedBenefit = (selection, value) => {
  switch (selection) {
    case 'CHAPTER30':
    case 'CHAPTER35':
    case 'M1606':
      return { FLAT_RATE: value };
    case 'FED_TA':
    case 'COMBO_TA_TOPUP':
    case 'COMBO_TA_CHAPTER33':
      return { AMOUNT_USED: value };
    case 'STATE_TA':
      return { AMOUNT_RECEIVED: value };
    default:
      return '';
  }
};

export const getSuggestedLoan = value => {
  const state = store.getState();
  const contributions = state?.calculatorSummary?.results?.detail?.value || [];
  const directLoan = (contributions?.contributions || []).find(({ type }) => type === 'DIRECTLOAN');

  const loanMap = {
    max: directLoan?.dataPoints?.maxprogramamount,
    min: directLoan?.dataPoints?.minprogramamount,
    suggested: directLoan?.dataPoints?.suggestedprogramamount,
  };

  return loanMap[value] ?? 0;
};

export const getTapcapOptIn = () => (
  (store.getState().calculatorInput?.results?.discounts?.value || []
  ).find(({ type }) => type === 'EMPLOYER_DISCOUNT')?.dataPoints?.TAPCAP || false
);

// Formik Helpers
export const setFieldAndUnTouch = (formikProps, fieldName, value) => formikProps
  .setFieldValue(fieldName, value, false)
  .then(() => formikProps.setFieldTouched(fieldName, false));

const radioValues = ['True', 'False'];
export const setFieldAndTouch = (formikProps, fieldName, value) => {
  let shouldValidateOnUpdate = false;
  if (radioValues.includes(value)) {
    shouldValidateOnUpdate = true;
  }
  return formikProps
    .setFieldValue(fieldName, value, shouldValidateOnUpdate)
    .then(() => formikProps.setFieldTouched(fieldName, true));
};

export const setUntouchedIfNoValue = (formikProps, fieldName) => {
  if ((formikProps.values[fieldName] || '') === '') {
    formikProps.setFieldTouched(fieldName, false, false);
  }
};

export const getMilitarySelectionInputs = () => [
  {
    field: 'serviceType',
    type: 'string',
    value: store.getState().calculatorInput?.results?.military?.value?.serviceType || '',
  },
  {
    field: 'degreeLevel',
    type: 'string',
    value: PROGRAM_LEVEL_MAP[store.getState().programOfferingSearch.programOfferingItem?.programLevel] || '',
  },
  {
    field: 'militaryBranch',
    type: 'string',
    value: store.getState().calculatorInput?.results?.military?.value?.militaryBranch || '',
  },
  {
    field: 'BAH',
    type: 'string',
    value: store.getState().calculatorInput?.results?.military?.value?.BAH ? '1' : '0',
  },
  {
    field: 'indirectCosts',
    type: 'string',
    value: '0',
  },
];

const militaryBenefits = reduxState => (
  reduxState
    .calculatorInput
    ?.results
    ?.benefits
    ?.value
    ?.militaryBenefits
  || []
);

const selectedPrimaryBenefit = benefits => benefits.find(
  e => e.dataPoints.primaryBenefit === true
    || e.dataPoints.primaryBenefit === 'true',
) || {};

export const getMilitarySecondarySelectionInputs = () => [
  ...getMilitarySelectionInputs(),
  {
    field: 'optionalPrimaryBenefit',
    type: 'string',
    value: selectedPrimaryBenefit(militaryBenefits(store.getState()))?.type || ' ',
  },
];

const hasSecondaryMilitaryBenefits = reduxState => (
  (reduxState.militaryBenefits
    ?.secondary
    ?.results
    ?.eligibility
    ?.benefits || []
  ).length > 0
);

const getSelectedPages = choosePathPreferences => {
  const selectedPages = [];
  selectedPages.push(PAGENAME.INTRO);
  selectedPages.push(PAGENAME.CHOOSEPATH);
  if (choosePathPreferences?.militarySelected) {
    selectedPages.push(PAGENAME.MILITARYBENEFITS);
  }
  if (choosePathPreferences?.militarySelected
    && hasSecondaryMilitaryBenefits(store.getState())) {
    selectedPages.push(PAGENAME.MILITARYBENEFITSSECONDARY);
  }
  if (choosePathPreferences?.federalGrantsSelected) {
    selectedPages.push(PAGENAME.FEDERALGRANTS);
  }
  if (choosePathPreferences?.employerBenefitsSelected) {
    selectedPages.push(PAGENAME.EMPLOYERBENEFITS);
  }
  if (choosePathPreferences?.employerDiscountsSelected) {
    selectedPages.push(PAGENAME.ASSOCIATIONS);
  }
  if (choosePathPreferences?.scholarshipsSelected) {
    selectedPages.push(PAGENAME.SCHOLARSHIPS);
  }
  if (choosePathPreferences?.tribalBenefitsSelected) {
    selectedPages.push(PAGENAME.TRIBALBENEFITS);
  }
  if (getFeatureFlag(flagNames.LOAN_AND_CONTRIBUTION_MERGE_TEST_ENABLED)) {
    selectedPages.push(PAGENAME.CONTRIBUTIONS);
    selectedPages.push(PAGENAME.SUMMARY);
  } else {
    selectedPages.push(PAGENAME.PERSONALCONTRIBUTION);
  }
  return selectedPages;
};

export const getNextPageLink = (currentPage, choosePathPreferences) => {
  const selectedPages = getSelectedPages(choosePathPreferences);
  const currentPageIndex = selectedPages.indexOf(currentPage) === -1
    ? 1
    : selectedPages.indexOf(currentPage);
  const nextPageValue = selectedPages[currentPageIndex + 1];
  const nextPageKey = Object.keys(selectedPages).find(key => selectedPages[key] === nextPageValue);
  return PAGEPATH[selectedPages[nextPageKey]];
};

export const getBackPageLink = (currentPage, choosePathPreferences) => {
  const selectedPages = getSelectedPages(choosePathPreferences);
  const currentPageIndex = selectedPages.indexOf(currentPage) === -1
    ? 1
    : selectedPages.indexOf(currentPage);
  const backPageValue = selectedPages[currentPageIndex - 1];
  const backPageKey = Object.keys(selectedPages).find(key => selectedPages[key] === backPageValue);
  return PAGEPATH[selectedPages[backPageKey]];
};

export const skipInChoosePath = (
  values,
  stateBenefits,
  stateDiscounts,
  stateContributions,
  stateMilitary,
) => {
  let updatedBenefits = stateBenefits;
  let updatedDiscounts = stateDiscounts;
  let benefitsPatch;
  let discountsPatch;
  let contributionsPatch;
  let militaryPatch;
  const sectionsToPatch = [];
  if (!values?.militarySelection) {
    // benefits & military
    const {
      modifiedBenefits,
      patchBenefits,
      patchMilitary,
    } = skipMilitaryBenefitsPage(updatedBenefits, stateMilitary);
    updatedBenefits = modifiedBenefits;
    benefitsPatch = patchBenefits;
    militaryPatch = patchMilitary;
  }
  if (!values?.federalGrantsSelection) {
    // benefits & contributions
    const {
      modifiedBenefits,
      patchBenefits,
      patchContributions,
    } = skipGrantsPage(updatedBenefits, stateContributions);
    updatedBenefits = modifiedBenefits;
    benefitsPatch = patchBenefits;
    contributionsPatch = patchContributions;
  }
  if (!values?.employerBenefitsSelection) {
    // benefits
    const {
      modifiedBenefits,
      patchBenefits,
    } = skipEmployerBenefitsPage(updatedBenefits);
    updatedBenefits = modifiedBenefits;
    benefitsPatch = patchBenefits;
  }
  if (!values?.employerDiscountsSelection) {
    // benefits & discounts
    const {
      modifiedBenefits,
      modifiedDiscounts,
      patchBenefits,
      patchDiscounts,
    } = skipAssociationsPage(updatedBenefits, updatedDiscounts);
    updatedBenefits = modifiedBenefits;
    updatedDiscounts = modifiedDiscounts;
    benefitsPatch = patchBenefits;
    discountsPatch = patchDiscounts;
  }
  if (!values?.scholarshipsSelection) {
    // benefits
    const {
      modifiedBenefits,
      patchBenefits,
    } = skipScholarshipsPage(updatedBenefits);
    updatedBenefits = modifiedBenefits;
    benefitsPatch = patchBenefits;
  }
  if (!values?.tribalBenefitsSelection) {
    // benefits & discounts
    const {
      patchBenefits,
      patchDiscounts,
    } = skipTribalBenefitsPage(updatedBenefits, updatedDiscounts);
    benefitsPatch = patchBenefits;
    discountsPatch = patchDiscounts;
  }
  if (benefitsPatch) {
    sectionsToPatch.push(benefitsPatch);
  }
  if (discountsPatch) {
    sectionsToPatch.push(discountsPatch);
  }
  if (contributionsPatch) {
    sectionsToPatch.push(contributionsPatch);
  }
  if (militaryPatch) {
    sectionsToPatch.push(militaryPatch);
  }
  return sectionsToPatch;
};

export const convertCYOPValuesToEnabledTealiumPageNameList = values => {
  const pageNames = [];

  if (values.militarySelection) {
    pageNames.push(CYOPTealiumNames.MILITARY_BENEFITS);
  }
  if (values.federalGrantsSelection) {
    pageNames.push(CYOPTealiumNames.GRANTS);
  }
  if (values.employerBenefitsSelection) {
    pageNames.push(CYOPTealiumNames.EMPLOYER_BENEFITS);
  }
  if (values.employerDiscountsSelection) {
    pageNames.push(CYOPTealiumNames.ASSOCIATIONS);
  }
  if (values.scholarshipsSelection) {
    pageNames.push(CYOPTealiumNames.SCHOLARSHIPS);
  }
  if (values.tribalBenefitsSelection) {
    pageNames.push(CYOPTealiumNames.TRIBAL_BENEFITS);
  }
  if (values.showLivingExpenses === 'True') {
    pageNames.push(CYOPTealiumNames.SHOW_LIVING_EXPENSES);
  }

  return pageNames;
};

export const convertCYOPPageIdToTealiumPageName = pageId => {
  switch (pageId) {
    case 'pgFinanceMilitaryPrimary':
      return CYOPTealiumNames.MILITARY_BENEFITS;
    case 'pgFinanceGrants':
      return CYOPTealiumNames.GRANTS;
    case 'pgFinanceEmployerBenefits':
      return CYOPTealiumNames.EMPLOYER_BENEFITS;
    case 'pgFinanceAssociations':
      return CYOPTealiumNames.ASSOCIATIONS;
    case 'pgFinanceScholarships':
      return CYOPTealiumNames.SCHOLARSHIPS;
    case 'pgFinanceTribal':
      return CYOPTealiumNames.TRIBAL_BENEFITS;
    default:
      return 'unknown page';
  }
};
