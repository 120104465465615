import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Grid,
} from '@material-ui/core';
import clsx from 'clsx';
import {
  ReactComponent as ApplicationSecurity,
} from '../../modules/admissions/components/illustration/ApplicationSecurity.svg';
import styles from './styles';

export default function InformationAndIllustration({
  title,
  heading,
  children,
  titleStyle,
}) {
  const classes = styles();

  return (
    <div className={clsx(classes.root, 'InfoAndIllustration')}>
      <Grid
        container
        className={classes.introduction}
      >
        <Grid item sm={12} md={6}>
          <Typography
            variant={heading}
            className={clsx(titleStyle ? classes.titleBold : classes.title, 'introIllustration')}
          >
            {title}
          </Typography>
          {children}
        </Grid>
        <Grid item sm={12} md={2} />
        <Grid
          item
          sm={12}
          md={4}
          className={classes.illustration}
        >
          <ApplicationSecurity />
        </Grid>
      </Grid>
    </div>
  );
}

InformationAndIllustration.defaultProps = {
  heading: 'h2',
  children: null,
  titleStyle: false,
};

InformationAndIllustration.propTypes = {
  title: PropTypes.string.isRequired,
  heading: PropTypes.string,
  children: PropTypes.element,
  titleStyle: PropTypes.bool,
};
