const autoHopNavMap = () => ([
  {
    key: 'contactChange', // contactInfo :firstName, lastName, previousFirstName, previousLastName, hasPreviousName, addressLine, city, state, zipCode, country, phoneNumber
    secondarySteps: ['transcript', 'financial', 'financialMilitary', 'financialGrants', 'financialEmployerBenefits', 'financialDiscounts', 'financialScholarships', 'financialTribalBenefits', 'financialBenefits', 'financialPersonal', 'financialLoans', 'financialSummary', 'financialEstimate', 'review', 'reviewAcknowledge', 'reviewSignSubmit'],
  },
  {
    key: 'zipCodeChange', // contact info: zipcode
    secondarySteps: ['programSelection', 'transcript', 'financial', 'financialMilitary', 'financialGrants', 'financialEmployerBenefits', 'financialDiscounts', 'financialScholarships', 'financialTribalBenefits', 'financialBenefits', 'financialPersonal', 'financialLoans', 'financialSummary', 'financialEstimate', 'review', 'reviewAcknowledge', 'reviewSignSubmit'],
  },
  {
    key: 'militaryAddressSwitch', // contact info : address type
    secondarySteps: ['military', 'financial', 'financialMilitary', 'financialGrants', 'financialEmployerBenefits', 'financialDiscounts', 'financialScholarships', 'financialTribalBenefits', 'financialBenefits', 'financialPersonal', 'financialLoans', 'financialSummary', 'financialEstimate', 'review', 'reviewAcknowledge', 'reviewSignSubmit'],
  },
  {
    key: 'citizenshipUpdate', // contact info: citizenship question for unsupported country
    secondarySteps: ['tellUsMore', 'military', 'financial', 'financialMilitary', 'financialGrants', 'financialEmployerBenefits', 'financialDiscounts', 'financialScholarships', 'financialTribalBenefits', 'financialBenefits', 'financialPersonal', 'financialLoans', 'financialSummary', 'financialEstimate', 'review', 'reviewAcknowledge', 'reviewSignSubmit'],
  },
  {
    key: 'dobChange', // more : date of birth
    secondarySteps: ['secondary', 'higher', 'transcript', 'financial', 'financialMilitary', 'financialGrants', 'financialEmployerBenefits', 'financialDiscounts', 'financialScholarships', 'financialTribalBenefits', 'financialBenefits', 'financialPersonal', 'financialLoans', 'financialSummary', 'financialEstimate', 'review', 'reviewAcknowledge', 'reviewSignSubmit'],
  },
  {
    key: 'ssnChange', // more: ssn add, ssn change
    secondarySteps: ['transcript', 'financial', 'financialMilitary', 'financialGrants', 'financialEmployerBenefits', 'financialDiscounts', 'financialScholarships', 'financialTribalBenefits', 'financialBenefits', 'financialPersonal', 'financialLoans', 'financialSummary', 'financialEstimate', 'review', 'reviewAcknowledge', 'reviewSignSubmit'],
  },
  {
    key: 'ssnRemove', // more : ssn remove
    secondarySteps: ['financial', 'financialMilitary', 'financialGrants', 'financialEmployerBenefits', 'financialDiscounts', 'financialScholarships', 'financialTribalBenefits', 'financialBenefits', 'financialPersonal', 'financialLoans', 'financialSummary', 'financialEstimate', 'review', 'reviewAcknowledge', 'reviewSignSubmit'],
  },
  {
    key: 'militaryChange', // military: active duty <-> dependant/spouse, no military <-> has military
    secondarySteps: ['work', 'financial', 'financialMilitary', 'financialGrants', 'financialEmployerBenefits', 'financialDiscounts', 'financialScholarships', 'financialTribalBenefits', 'financialBenefits', 'financialPersonal', 'financialLoans', 'financialSummary', 'financialEstimate', 'review', 'reviewAcknowledge', 'reviewSignSubmit'],
  },
  {
    key: 'militaryBenefitChange', // military: service type, branch
    secondarySteps: ['higher', 'financial', 'financialMilitary', 'financialGrants', 'financialEmployerBenefits', 'financialDiscounts', 'financialScholarships', 'financialTribalBenefits', 'financialBenefits', 'financialPersonal', 'financialLoans', 'financialSummary', 'financialEstimate', 'review', 'reviewAcknowledge', 'reviewSignSubmit'],
  },
  {
    key: 'militaryEducationRemoved', // military: military education
    secondarySteps: ['transcript', 'financial', 'financialMilitary', 'financialGrants', 'financialEmployerBenefits', 'financialDiscounts', 'financialScholarships', 'financialTribalBenefits', 'financialBenefits', 'financialPersonal', 'financialLoans', 'financialSummary', 'financialEstimate', 'review', 'reviewAcknowledge', 'reviewSignSubmit'],
  },
  {
    key: 'militaryTrainingChange', // military: military traning type, credit, start date, end date
    secondarySteps: ['transcript', 'financial', 'financialMilitary', 'financialGrants', 'financialEmployerBenefits', 'financialDiscounts', 'financialScholarships', 'financialTribalBenefits', 'financialBenefits', 'financialPersonal', 'financialLoans', 'financialSummary', 'financialEstimate', 'review', 'reviewAcknowledge', 'reviewSignSubmit'],
  },
  {
    key: 'programUpdate', // program: update program
    secondarySteps: ['higher', 'work', 'financial', 'financialMilitary', 'financialGrants', 'financialEmployerBenefits', 'financialDiscounts', 'financialScholarships', 'financialTribalBenefits', 'financialBenefits', 'financialPersonal', 'financialLoans', 'financialSummary', 'financialEstimate', 'review', 'reviewAcknowledge', 'reviewSignSubmit'],
  },
  {
    key: 'collegeUpdate', // college: add esign college, remove esign college, credit, start date, end date
    secondarySteps: ['transcript', 'financial', 'financialMilitary', 'financialGrants', 'financialEmployerBenefits', 'financialDiscounts', 'financialScholarships', 'financialTribalBenefits', 'financialBenefits', 'financialPersonal', 'financialLoans', 'financialSummary', 'financialEstimate', 'review', 'reviewAcknowledge', 'reviewSignSubmit'],
  },
  {
    key: 'creditChange', // college: undergraduate credit update
    secondarySteps: ['financial', 'financialMilitary', 'financialGrants', 'financialEmployerBenefits', 'financialDiscounts', 'financialScholarships', 'financialTribalBenefits', 'financialBenefits', 'financialPersonal', 'financialLoans', 'financialSummary', 'financialEstimate', 'review', 'reviewAcknowledge', 'reviewSignSubmit'],
  },
  {
    key: 'curEmployerChange', // work: current primary employer name change
    secondarySteps: ['financial', 'financialMilitary', 'financialGrants', 'financialEmployerBenefits', 'financialDiscounts', 'financialScholarships', 'financialTribalBenefits', 'financialBenefits', 'financialPersonal', 'financialLoans', 'financialSummary', 'financialEstimate', 'review', 'reviewAcknowledge', 'reviewSignSubmit'],
  },
  {
    key: 'financialChange', // financial section: any change on each financial page
    secondarySteps: ['financialLoans', 'financialSummary', 'financialEstimate', 'review', 'reviewAcknowledge', 'reviewSignSubmit'],
  },
  {
    key: 'financialLoanChange', // financial section: any change on each loans page
    secondarySteps: ['financialSummary', 'financialEstimate', 'review', 'reviewAcknowledge', 'reviewSignSubmit'],
  },
]);

export default autoHopNavMap;
