import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  tooltip: {
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(0.6),
    maxWidth: theme.spacing(20),
    marginBottom: theme.spacing(0.7),
    marginTop: theme.spacing(0.7),
    fontSize: theme.spacing(1.4),
    lineHeight: 1.6,
  },
  lightTooltip: {
    color: '#001823',
    backgroundColor: '#FFF',
    boxShadow: '0 1px 3px 2px #e6e6e6',
  },
  darkTooltip: {
    color: '#FFF',
    backgroundColor: '#000',
    boxShadow: 'none',
    maxWidth: '360px',
  },
  lightArrow: {
    color: '#a6a6a6',
  },
  darkArrow: {
    color: '#000',
  },
}));
