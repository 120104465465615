import React from 'react';
import { Button, Box, Grid } from '@material-ui/core';
import { ReactComponent as GoToIcon } from '../../admissions/components/icons/right-long-arrow.svg';
import styles from './styles';

const useStyles = styles;

const DashBoard = () => {
  const ariaLabel = 'Maintenance Control Page';
  const id = 'go-to-maintenence';
  const hrefLink = '/admin/maintenance';
  const label = 'Go to Maintenance Admin';
  const classes = useStyles();
  const handleClick = () => {

  };
  return (
    <>
      <h1> Welcome Admin! </h1>

      <Grid item xs={12} className={classes.backBtnContainer} style={{ paddingBottom: 0 }}>
        <Box className={classes.backBtnBox}>
          <Button
            id={id}
            role="link"
            aria-label={ariaLabel}
            className="pageBackBtn"
            onClick={handleClick}
            endIcon={(
              <GoToIcon
                aria-hidden
                className={classes.gotoIcon}
              />
            )}
            href={hrefLink}
          >
            {' '}
            {label}
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default DashBoard;
