/* action types */
export const REQUEST_POST_APPLICATION = 'REQUEST_POST_APPLICATION';
export const RECEIVE_POST_APPLICATION = 'RECEIVE_POST_APPLICATION';
export const REQUEST_POST_APPLICATION_ERROR = 'REQUEST_POST_APPLICATION_ERROR';
export const REQUEST_UPDATE_APPLICATION = 'REQUEST_UPDATE_APPLICATION';
export const RECEIVE_UPDATE_APPLICATION = 'RECEIVE_UPDATE_APPLICATION';
export const REQUEST_UPDATE_APPLICATION_ERROR = 'REQUEST_UPDATE_APPLICATION_ERROR';
export const REQUEST_APPLICATION = 'REQUEST_APPLICATION';
export const RECEIVE_APPLICATION = 'RECEIVE_APPLICATION';
export const REQUEST_APPLICATION_ERROR = 'REQUEST_APPLICATION_ERROR';
export const REQUEST_GET_APPLICATIONS = 'REQUEST_GET_APPLICATIONS';
export const RECEIVE_GET_APPLICATIONS = 'RECEIVE_GET_APPLICATIONS';
export const REQUEST_GET_APPLICATIONS_ERROR = 'REQUEST_GET_APPLICATIONS_ERROR';
export const CLEAR_APPLICATION_STATUS_GET_STATUS = 'CLEAR_APPLICATION_STATUS_GET_STATUS';
export const CLEAR_APPLICATION_STATUS_MODIFY_STATUS = 'CLEAR_APPLICATION_STATUS_MODIFY_STATUS';
export const RECEIVE_NAVIGATION = 'RECEIVE_NAVIGATION';
export const REQUEST_NAVIGATION = 'REQUEST_NAVIGATION';
export const REQUEST_NAVIGATION_ERROR = 'REQUEST_NAVIGATION_ERROR';
export const REQUEST_UPDATE_NAVIGATION = 'REQUEST_UPDATE_NAVIGATION';
export const RECEIVE_UPDATE_NAVIGATION = 'RECEIVE_UPDATE_NAVIGATION';
export const REQUEST_UPDATE_NAVIGATION_ERROR = 'REQUEST_UPDATE_NAVIGATION_ERROR';
export const REQUEST_POST_NAVIGATION = 'REQUEST_POST_NAVIGATION';
export const RECEIVE_POST_NAVIGATION = 'RECEIVE_POST_NAVIGATION';
export const REQUEST_POST_NAVIGATION_ERROR = 'REQUEST_POST_NAVIGATION';
export const CLEAR_NAVIGATION_STATUS_MODIFY_STATUS = 'CLEAR_NAVIGATION_STATUS_MODIFY_STATUS';
export const CHANGE_SUPPRESS_FINANCIAL_GRANTS_APPLICATION = 'CHANGE_SUPPRESS_FINANCIAL_GRANTS_APPLICATION';
export const CHANGE_SUPPRESS_FINANCIAL_LOAN_APPLICATION = 'CHANGE_SUPPRESS_FINANCIAL_LOAN_APPLICATION';
export const CHANGE_SUPPRESS_FINANCIAL_MILITARY_APPLICATION = 'CHANGE_SUPPRESS_FINANCIAL_MILITARY_APPLICATION';
export const CHANGE_SUPPRESS_FINANCIAL_LOAN_GRAD_PLUS_APPLICATION = 'CHANGE_SUPPRESS_FINANCIAL_LOAN_GRAD_PLUS_APPLICATION';
export const CHANGE_SUPPRESS_FINANCIAL_LOAN_PARENT_PLUS_APPLICATION = 'CHANGE_SUPPRESS_FINANCIAL_LOAN_PARENT_PLUS_APPLICATION';
export const CHANGE_SUPPRESS_ADMISSION_LICENSE_PAGE = 'CHANGE_SUPPRESS_ADMISSION_LICENSE_PAGE';
export const CHANGE_SUPPRESS_ADMISSION_EXPULSION_PAGE = 'CHANGE_SUPPRESS_ADMISSION_EXPULSION_PAGE';
export const CHANGE_SUPPRESS_ADMISSION_TRANSCRIPT_PAGE = 'CHANGE_SUPPRESS_ADMISSION_TRANSCRIPT_PAGE';
export const REQUEST_APPLICATION_TYPE = 'REQUEST_APPLICATION_TYPE';
export const RECEIVE_APPLICATION_TYPE = 'RECEIVE_APPLICATION_TYPE';
export const REQUEST_APPLICATION_TYPE_ERROR = 'REQUEST_APPLICATION__TYPE_ERROR';
export const REQUEST_DELETE_APPLICATION = 'REQUEST_DELETE_APPLICATION';
export const RECEIVE_DELETE_APPLICATION = 'RECEIVE_DELETE_APPLICATION';
export const REQUEST_DELETE_APPLICATION_ERROR = 'REQUEST_DELETE_APPLICATION_ERROR';
export const REQUEST_EXPIRE_APPLICATION = 'REQUEST_EXPIRE_APPLICATION';
export const RECEIVE_EXPIRE_APPLICATION = 'RECEIVE_EXPIRE_APPLICATION';
export const REQUEST_EXPIRE_APPLICATION_ERROR = 'REQUEST_EXPIRE_APPLICATION_ERROR';
export const REQUEST_PROGRAM_WORK_EXPERIENCE_REQUIREMENT = 'REQUEST_PROGRAM_WORK_EXPERIENCE_REQUIREMENT';
export const RECEIVE_PROGRAM_WORK_EXPERIENCE_REQUIREMENT = 'RECEIVE_PROGRAM_WORK_EXPERIENCE_REQUIREMENT';
export const REQUEST_PROGRAM_WORK_EXPERIENCE_REQUIREMENT_ERROR = 'REQUEST_PROGRAM_WORK_EXPERIENCE_REQUIREMENT_ERROR';
export const REQUEST_ATTACHMENT = 'REQUEST_ATTACHMENT';
export const RECEIVE_ATTACHMENT = 'RECEIVE_ATTACHMENT';
export const REQUEST_ATTACHMENT_ERROR = 'REQUEST_ATTACHMENT_ERROR';
