import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  gotoIcon: {
    marginRight: theme.spacing(1),
  },
  gotoBtnContainer: {
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.spacing(98.2),
      margin: '0 auto',
    },
  },
  gotoBtnBox: {
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(0),
    },
  },
}));
