import Cookies from 'js-cookie';
import STATUS from '../globalStatuses';
import { APPLICATION_ADMISSION_STATUS } from './constants';
import { triggerAlloyTrack } from '../../modules/vendor/alloy/AlloyUpdater';

export const getPersonId = reduxState => {
  // Set defaults to avoid drilling into undefined properties
  const authPersonId = (((reduxState.oidc || {}).user || {}).profile || {})[
    'custom:STUDENT_PERSON_ID'
  ];
  const { personId } = (reduxState.person || {}).person || {};
  // First choice is personId from auth otherwise from state then sessionStorage (for old quick-app)
  return authPersonId || personId || sessionStorage.getItem('personId') || reduxState.person?.person?.id;
};

export const getUsername = reduxState => {
  const usernameCombo = reduxState?.oidc?.user?.profile?.username;
  // Removing cognito prefix
  // Split on first underscore in case the actual username itself has one too
  // For example: AuthdepotStudentFacultyST_annanosaga
  if (!usernameCombo) return null;
  return usernameCombo.substring(usernameCombo.indexOf('_') + 1);
};

export const areUsernamesMatching = (username1, username2) => (
  (username1 || '').toLowerCase() === (username2 || '').toLowerCase()
);

export const getProfileId = reduxState => (reduxState.person?.person?.externalSystemIds
  ? (reduxState.person?.person?.externalSystemIds?.PROFILE_ID || [])[0]
  : (reduxState.person?.meta?.externalSystemIds?.PROFILE_ID || [])[0]);

const getIrnFromMeta = reduxState => (reduxState.person?.person?.externalSystemIds
  ? (reduxState.person.person.externalSystemIds.SUPPLEMENTAL_ID || [])[0]
  : (reduxState.person?.meta?.externalSystemIds?.SUPPLEMENTAL_ID || [])[0]);

export const getIrn = reduxState => (reduxState.person?.person?.issuerId && reduxState.person.person.issuerId !== ''
  ? reduxState.person.person.issuerId
  : getIrnFromMeta(reduxState));

const nameSearchPredicate = (names, type) => (names || [{}]).find(name => (name.type || '').toLowerCase() === type);

export const getOfficialName = names => nameSearchPredicate(names, 'official');
export const getPreviousName = names => nameSearchPredicate(names, 'previous');

export const shouldFetch = ({ getStatus }) => getStatus
  === STATUS.UNFETCHED || getStatus === STATUS.MISSING;

export const areDependenciesResolved = (...args) => args.every(
  ({ getStatus }) => getStatus
    === STATUS.FETCHED || getStatus === STATUS.ERROR || getStatus === STATUS.MISSING,
);

export const areDependenciesErrored = (...args) => args.some(status => status.getStatus
  === STATUS.ERROR);

export const areOptionalSaveCallsResolved = (...args) => args.every(
  status => status.modifyStatus === STATUS.UNFETCHED
      || status.modifyStatus === STATUS.UPDATED,
);

export const areRequiredSaveCallsResolved = (...args) => args.every(
  status => status.modifyStatus === STATUS.UPDATED,
);

export const buildErrorResolvedArray = (...args) => args
  .filter(
    status => status.modifyStatus === STATUS.ERROR,
  )
  .map(status => ({
    errorData: status.errorData,
  }));

export const buildDependencyErrorArray = (...args) => args
  .filter(
    status => status.getStatus === STATUS.ERROR,
  )
  .map(status => ({
    errorData: status.errorData,
  }));

export const enumDataToSuggestList = enumData => (enumData || []).map(
  item => ({
    id: item?.id || '',
    value: item?.code || '',
    label: item?.name || '',
    parentId: item?.parentId || '',
    meta: item?.metadata || {},
  }),
  [],
);

export const addFields = objArr => (objArr || [{}]).reduce(
  (accumulator, current) => [
    ...accumulator,
    { ...current, label: current.name, value: current.code },
  ],
  [],
);

const territoriesCache = {
  territories: [],
  isProcessed: false,
};

export const setUsTerritory = territories => {
  if (!territoriesCache.isProcessed && territories?.length) {
    territoriesCache.territories = (territories || [{}])
      .filter(territory => territory.code !== 'UM')
      .reduce(
        (accumulator, current) => [...accumulator, { ...current, group: 'U.S. Territory' }],
        [],
      );
    territoriesCache.isProcessed = !!territoriesCache.territories?.length;
  }
  return territoriesCache.territories;
};

const usStatesCache = {
  usStates: [],
  isProcessed: false,
};

export const setUsState = (states, districts) => {
  if (!usStatesCache.isProcessed && states?.length && districts?.length) {
    const consolidatedArray = states.concat(districts);
    usStatesCache.usStates = (consolidatedArray || [{}]).reduce(
      (accumulator, current) => [...accumulator, { ...current, group: 'U.S. State' }],
      [],
    );
    usStatesCache.isProcessed = true;
  }
  return usStatesCache.usStates;
};

const apoFpoCache = {
  apoFpo: [],
  isProcessed: false,
};

export const setApoFpo = territories => {
  if (!apoFpoCache.isProcessed && territories?.length) {
    apoFpoCache.apoFpo = (territories || [{}])
      .filter(territory => territory.code === 'UM')
      .reduce((accumulator, current) => [...accumulator, { ...current, group: 'APO/FPO' }], []);
    apoFpoCache.isProcessed = true;
  }
  return apoFpoCache.apoFpo;
};

const groupedUsStateCache = {
  groupedUsStates: {},
  isProcessed: false,
};

export const groupedUsStateEnum = (states, territories, areas) => {
  if (!groupedUsStateCache.isProcessed && states?.length && territories?.length && areas?.length) {
    const consolidatedStates = [...states, ...territories, ...areas];
    groupedUsStateCache.groupedUsStates = (consolidatedStates || [{}]).reduce(
      (accumulator, current) => ({
        ...accumulator,
        [current.group]: accumulator[current.group]
          ? [...accumulator[current.group], ...enumDataToSuggestList([current])]
          : enumDataToSuggestList([current]),
      }),
      {},
    );
    groupedUsStateCache.isProcessed = true;
  }
  return groupedUsStateCache.groupedUsStates;
};

export const enumCountrySortedWithUsFirst = enumData => (enumData || [{}]).reduce(
  (accumulator, current) => (['US', 'US-MIL'].some(countryCode => countryCode === current.value)
    ? [current, ...accumulator]
    : [...accumulator, current]),
  [],
);

export const getCountryPhoneCodes = countries => {
  const countryPhoneCodeDict = {};
  countries.forEach(country => {
    countryPhoneCodeDict[country.code] = `${country.metadata.phoneCode}`;
  });
  return countryPhoneCodeDict;
};

export const enumDataToValidAreaCodes = (enumData = [{}]) => enumData.reduce(
  (accumulator, country) => (country?.code && country?.metadata?.validAreaCodes
    ? {
      ...accumulator,
      [country?.code]: country?.metadata?.validAreaCodes,
    }
    : accumulator),
  {},
);

export const getIsMilitary = reduxState => {
  const { hasMilitaryInfo } = (reduxState.person || {}).information || {};
  const { serviceType, serviceCategory } = (reduxState.military || {}).military || {};
  return hasMilitaryInfo === 'True' && !!serviceType && !!serviceCategory;
};

export const getIsMilitaryDependent = reduxState => {
  if (!getIsMilitary(reduxState)) {
    return false;
  }
  const { serviceType } = reduxState?.calculatorInput?.results?.military?.value || {};
  return serviceType?.includes('Dependent');
};

export const getProgramTemplateMapStatus = reduxState => {
  const { programTemplateMap, programOfferingItem } = reduxState.programOfferingSearch;

  if (Object.keys(programTemplateMap).length === 0 && programTemplateMap.constructor === Object) {
    return STATUS.UNFETCHED;
  }

  const programId = programOfferingItem.programId || '';
  const version = programOfferingItem.version || '';

  const templateMapKey = `${programId}_${version}`;

  if (!(templateMapKey in programTemplateMap)) {
    return STATUS.UNFETCHED;
  }

  return programTemplateMap[templateMapKey].getStatus;
};

// Optional Dependency handling
// Field conditions are &&ed together to determine if the optional field is required
export const optionalDependencyBuilder = optionalStatusList => formikProps => optionalStatusList.every(statusItem => {
  const conditionValidationCb = field => field.fieldCondition(formikProps.values[field.fieldName]);

  let areConditionsSatisfied = false;
  switch (statusItem.conditionJoiner) {
    case 'OR':
      areConditionsSatisfied = statusItem.dependentFormikFields.some(conditionValidationCb);
      break;
    case 'AND':
    default:
      areConditionsSatisfied = statusItem.dependentFormikFields.every(conditionValidationCb);
  }

  if (areConditionsSatisfied) {
    return statusItem.modifyStatus === STATUS.UPDATED;
  }
  return true;
});

// Optional Dependency handling for hook-forms
// Field conditions are &&ed together to determine if the optional field is required
export const hookOptionalDependencyBuilder = optionalStatusList => formFields => optionalStatusList.every(statusItem => {
  const conditionValidationCb = field => field.fieldCondition(formFields[field.fieldName]);

  let areConditionsSatisfied = false;
  switch (statusItem.conditionJoiner) {
    case 'OR':
      areConditionsSatisfied = statusItem.dependentFormFields.some(conditionValidationCb);
      break;
    case 'AND':
    default:
      areConditionsSatisfied = statusItem.dependentFormFields.every(conditionValidationCb);
  }

  if (areConditionsSatisfied) {
    return statusItem.modifyStatus === STATUS.UPDATED
      || statusItem.modifyStatus === STATUS.FETCHED;
  }
  return true;
});

export const formatPhoneNumber = (phoneNumber, phoneCode) => {
  if (phoneNumber.startsWith(phoneCode)) {
    return `+${phoneNumber}`;
  }
  return (phoneNumber.includes('+') ? phoneNumber : `+${phoneCode}${phoneNumber}`);
};

export const stripPhoneNumber = (phoneNumber, countryCode, countriesList) => {
  let phoneNumUpd = phoneNumber;
  const phoneCode = countriesList.filter(
    element => element.value === countryCode,
  )?.[0]?.meta?.phoneCode;
  if (phoneNumber.includes('+') && phoneCode) {
    phoneNumUpd = phoneNumber.substring(phoneCode.length + 1);
  }
  return phoneNumUpd;
};

export const getWorkflowVersion = reduxState => {
  const wfVersion = reduxState.task?.workflow?.version;
  return wfVersion || '';
};

// Email is defaulted to Primary or username (post FR) or only email in contact MS
export const getPrimaryEmail = reduxState => {
  const primaryEmails = (reduxState.contact?.email || []).filter(email => email.primary === 'true');
  if (primaryEmails && primaryEmails.length > 0) {
    return primaryEmails[0];
  }
  if (reduxState.contact?.email[0]?.emailAddress) {
    return reduxState.contact?.email[0];
  }
  const username = reduxState.person.person.username?.includes('@') ? reduxState.person.person.username : '';
  if (username) {
    const email = {
      emailAddress: username,
      primary: 'true', // was 'false' - resolving missingEmail (outage fix)
    };
    return email;
  }
  return reduxState.contact?.email[0];
};

export const verifyPostSubmitTaskCompleted = (reduxState, taskList) => {
  if (taskList && taskList.length > 0) {
    const provisionPostSubmitTask = taskList.find(
      task => task.taskType === 'provisionPostSubmitTasks'
        && (task.taskStatus === 'COMPLETED' || task.taskStatus === 'COMPLETED_WITH_ERRORS' || task.taskStatus === 'FAILED'),
    );
    return !!provisionPostSubmitTask;
  }

  const postsubmitTasksCreationStatus = reduxState.task?.provisionPostSubmitTasks;
  return STATUS.COMPLETED === (postsubmitTasksCreationStatus?.status || '');
};

export const getApplicationAdmissionState = taskList => {
  let applicationAdmissionState = {
    status: APPLICATION_ADMISSION_STATUS.INPROGRESS,
    date: '',
  };
  if (taskList && taskList.length > 0) {
    const reviewSignSubmitTask = taskList.find(
      task => task.referenceTaskName === 'reviewSignSubmit'
        && (task.taskStatus === 'COMPLETED' || task.taskStatus === 'COMPLETED_WITH_ERRORS'),
    );
    if (reviewSignSubmitTask) {
      applicationAdmissionState = {
        ...applicationAdmissionState,
        status: APPLICATION_ADMISSION_STATUS.SUBMITTED,
        date: reviewSignSubmitTask.updateTime,
      };
    }
    const advisorReviewAwaitTask = taskList.find(
      task => task.referenceTaskName === 'advisorReviewAwait'
        && (task.taskStatus === 'COMPLETED' || task.taskStatus === 'COMPLETED_WITH_ERRORS'),
    );
    if (advisorReviewAwaitTask) {
      applicationAdmissionState = {
        ...applicationAdmissionState,
        status: APPLICATION_ADMISSION_STATUS.UNDERREVIEW,
        // date: reviewSignSubmitTask.
      };
    }
    const advisorReviewTask = taskList.find(
      task => task.referenceTaskName === 'advisorReview'
        && (task.taskStatus === 'COMPLETED' || task.taskStatus === 'COMPLETED_WITH_ERRORS'),
    );
    if (advisorReviewTask) {
      applicationAdmissionState = {
        ...applicationAdmissionState,
        status: APPLICATION_ADMISSION_STATUS.COMPLETED,
        // date: reviewSignSubmitTask.
      };
    }
  }
  return applicationAdmissionState;
};

export const hasSSN = reduxState => {
  const ssnOptIn = reduxState?.person?.information?.ssnOptOut === 'false';
  return ssnOptIn;
};

export const hasEnableV4Flag = () => (window.location.search
  ? window.location.search.toLowerCase().includes('myphoenixtasks=true')
  : false);

export const getSuppressAdmissionLicenseInfo = reduxState => {
  const isNursing = !!(
    reduxState.application.application.areaOfInterest !== ''
    && reduxState.application.application.areaOfInterest === 'Nursing'
  );
  // Suppress Admission License info if:
  return !isNursing;
};

export const getSuppressAdmissionExpulsionInfo = reduxState => {
  const hasHigherEds = reduxState.higherEds?.higherEds?.length > 0;
  // Suppress Admission Expulsion info if:
  return !hasHigherEds;
};

export const getSuppressAdmissionTranscript = reduxState => {
  const hasTranscript = reduxState.higherEds?.esignHigherEds?.length > 0
    && !!getIrn(reduxState) && hasSSN(reduxState);
  // Suppress Admission Expulsion info if:
  return !hasTranscript;
};

export const getSuppressAdmissionSecondary = reduxState => {
  const skipSecondaryEd = !!(
    reduxState.application.application.additionalAttributes?.currentDegreeLevel
    && ['Bachelors', 'Masters'].includes(reduxState.application.application.additionalAttributes?.currentDegreeLevel)
  );
  // Suppress Admission secondary education if:
  return skipSecondaryEd;
};

export const needRevisitHigher = reduxState => {
  const needRevisited = (
    reduxState.application.application.additionalAttributes?.higherNeedRevisited
    && reduxState.application.application.additionalAttributes?.higherNeedRevisited === 'true'
  );
  return needRevisited;
};

export const getSuppressAdmissionCredits = reduxState => {
  const skipCreditsEd = !!(
    reduxState.application.application.additionalAttributes?.currentDegreeLevel
    && ['Bachelors', 'Masters'].includes(reduxState.application.application.additionalAttributes?.currentDegreeLevel)
  );
  // Suppress Admission credits education if:
  return skipCreditsEd;
};

export const isInviteToApply = state => {
  const marketingContextState = state.application.application.marketingContextAtCreation;
  let isSrmLeadSource = false;
  if (marketingContextState) {
    isSrmLeadSource = marketingContextState.find(
      item => item.key === 'LEAD_SOURCE' && item.value === 'SRM',
    );
  }
  return state.user?.token?.data || isSrmLeadSource;
};

export const scrollToFirstElementByClassIfItIsVisible = className => {
  try {
    const visibleErrorMessage = Array.prototype.find.call(
      document.getElementsByClassName(className),
      element => element?.getBoundingClientRect()?.top > 0,
    );
    window.scrollTo(0, visibleErrorMessage.getBoundingClientRect().top + window.scrollY - 185);
  } catch {
    // Catching any error here for resiliency if this isn't supported
    // since this is mostly cosmetic functionality.
  }
};

export const getPrimary = object => {
  if (object && Array.isArray(object)) {
    return object.filter(obj => obj.primary === 'true')[0];
  }
  return object;
};

export const getWorkflowStatus = workflow => {
  if (workflow && workflow.workflowVersion < 4) {
    return 'COMPLETED';
  }
  return workflow?.status;
};

export const getSagaTaskStatus = (workflow, taskName) => {
  const taskStatus = workflow?.tasks?.find(task => task.referenceTaskName === taskName)?.status;
  return taskStatus || '';
};

export const checkSkipMaintenancePage = () => {
  const skipMaintenanceUrl = window.location.href.includes('skipMaintenance=true');
  const skipMaintenanceSession = sessionStorage.getItem('skipMaintenance') === 'true';
  const skipMaintenance = skipMaintenanceUrl || skipMaintenanceSession;
  if (skipMaintenance) {
    sessionStorage.setItem('skipMaintenance', 'true');
  }
  return skipMaintenance;
};

export const disableFinancialSection = navigation => {
  const financialSection = navigation.pages.find(page => page.label === 'Financial Plan');
  financialSection.enabled = false;
  return navigation;
};

export const getTealiumVisitorId = () => {
  const cookie = Cookies.get('utag_main');
  if (cookie) {
    const parts = cookie.split('$');
    const id = parts.find(item => item.includes('v_id'));
    return id?.split(':')[1] || '';
  }
  return '';
};

export const getTealiumSessionId = () => {
  const cookie = Cookies.get('utag_main');
  if (cookie) {
    const parts = cookie.split('$');
    const id = parts.find(item => item.includes('ses_id'));
    return id?.split(':')[1]?.split(';')[0] || '';
  }
  return '';
};

export const getGoogleId = () => Cookies.get('_ga') || '';

export const getContentSquareId = () => Cookies.get('_cs_id')?.split('.')[0] || '';

export const getSegmentAnonymousId = () => Cookies.get('ajs_anonymous_id') || '';

export const getChatService = state => {
  const applicationType = state.application?.application?.applicationType || '';
  if (applicationType.toLowerCase() === 'program change') {
    return 'SS_Chat_AppCenter';
  }
  return 'ER_Chat_ApplyNow';
};

export const getAllFeatureFlags = state => {
  const appFeatureFlag = state.application?.application?.marketingContextAtCreation?.find(context => context.key === 'featureFlags')?.value;
  let featureFlags = {};
  if (appFeatureFlag) {
    featureFlags = JSON.parse(state.application.application.marketingContextAtCreation.find(context => context.key === 'featureFlags').value);
  } else {
    featureFlags = state.preferences?.featureFlags?.value?.flags;
  }
  // Dashboard A/B/C test feature flag suppression to simplify the test - remove after test - beg
  try {
    if (featureFlags && Object.keys(featureFlags).some(key => key === 'NAE_DASHBOARD_ENABLED')) {
      if (featureFlags.NAE_DASHBOARD_ENABLED === 'false') {
        // Test A, remove NAE_DASHBOARD_FIRST out of obj
        delete featureFlags.NAE_DASHBOARD_FIRST;
      } else {
        // Test B or C, remove NAE_DASHBOARD_ENABLED out of obj
        delete featureFlags.NAE_DASHBOARD_ENABLED;
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('ERROR: ', e);
  }
  // Dashboard A/B/C test feature flag suppression to simplify the test - remove after test - end
  return featureFlags;
};

const findPhoneWithFilter = (phoneList, isPrimary, isConsent, phoneType) => {
  let filteredPhoneList = [];
  if (isPrimary && isConsent) {
    filteredPhoneList = phoneList.filter(phone => phone.primary === isPrimary
      && phone.isAutoDialConsent === isConsent && phone.type === phoneType);
  }
  if (isPrimary && !isConsent) {
    filteredPhoneList = phoneList.filter(phone => phone.primary === isPrimary
      && phone.type === phoneType);
  }
  if (!isPrimary && isConsent) {
    filteredPhoneList = phoneList.filter(phone => phone.isAutoDialConsent === isConsent
      && phone.type === phoneType);
  }
  if (!isPrimary && !isConsent) {
    filteredPhoneList = phoneList.filter(phone => phone.type === phoneType);
  }
  if (filteredPhoneList && filteredPhoneList.length > 0) {
    return filteredPhoneList[0];
  }
  return filteredPhoneList;
};
const findFilteredPhoneWithAllPhoneTypes = (phoneList, isPrimary, isConsent) => {
  const phoneTypes = ['Mobile', 'Home', 'Work'];
  let findPhone = {};
  for (let i = 0; i < phoneTypes.length; i++) {
    findPhone = findPhoneWithFilter(phoneList, isPrimary, isConsent, phoneTypes[i]);
    if (findPhone && findPhone.id) {
      break;
    }
  }
  return findPhone;
};
export const getPrimaryPhone = reduxState => {
  const phoneList = reduxState.contact?.phone;
  const consentList = reduxState?.contact?.phoneConsent;
  const phoneConsentList = [];
  if (phoneList && Array.isArray(phoneList) && phoneList[0].id) {
    for (let i = 0; i < phoneList.length; i++) {
      const findConsent = consentList && Array.isArray(consentList)
        ? consentList.find(itmInner => itmInner.phoneNumber === phoneList[i].phoneNumber) : {};
      const { id, isAutoDialConsent } = findConsent || {};
      if (id) {
        phoneConsentList.push({
          ...phoneList[i],
          isAutoDialConsent,
        });
      } else {
        phoneConsentList.push({
          ...phoneList[i],
          isAutoDialConsent: 'false',
        });
      }
    }
    const primaryConsentPhones = findFilteredPhoneWithAllPhoneTypes(phoneConsentList, 'true', 'true');
    if (primaryConsentPhones && primaryConsentPhones.id) {
      return primaryConsentPhones;
    }
    const primaryNoConsentPhones = findFilteredPhoneWithAllPhoneTypes(phoneConsentList, 'true', 'false');
    if (primaryNoConsentPhones && primaryNoConsentPhones.id) {
      return primaryNoConsentPhones;
    }
    const noPrimaryConsentPhones = findFilteredPhoneWithAllPhoneTypes(phoneConsentList, 'false', 'true');
    if (noPrimaryConsentPhones && noPrimaryConsentPhones.id) {
      return noPrimaryConsentPhones;
    }
    const noPrimaryNoConsentPhones = findFilteredPhoneWithAllPhoneTypes(phoneConsentList, 'false', 'false');
    if (noPrimaryNoConsentPhones && noPrimaryNoConsentPhones.id) {
      return noPrimaryNoConsentPhones;
    }
  }
  if (phoneList && Array.isArray(phoneList)) {
    return phoneList[0];
  }
  return { phoneNumber: '' };
};

export const getStudentIrn = reduxState => {
  let irn = reduxState.person?.person?.issuerId
    || reduxState.person?.person?.externalSystemIds?.SUPPLEMENTAL_ID?.[0];
  if (!irn) {
    irn = reduxState.person?.meta?.issuerId
      || reduxState.person?.meta?.externalSystemIds?.SUPPLEMENTAL_ID?.[0];
  }
  return irn || '';
};

export const getIncompleteTaskListStr = (
  incompleteAdminTasks,
  incompleteFinTasks,
  incompleteOptTasks,
) => {
  let incompleteTaskListStr = '';
  incompleteAdminTasks.forEach(task => {
    incompleteTaskListStr += incompleteTaskListStr === '' ? task : `; ${task}`;
  });
  incompleteFinTasks.forEach(task => {
    incompleteTaskListStr += incompleteTaskListStr === '' ? task : `; ${task}`;
  });
  incompleteOptTasks.forEach(task => {
    incompleteTaskListStr += incompleteTaskListStr === '' ? task : `; ${task}`;
  });
  return incompleteTaskListStr;
};

export const getTaskStatusStr = (incompleteAdminTasks, incompleteFinTasks, incompleteOptTasks) => {
  let taskStatusStr = '';
  if (incompleteAdminTasks.length === 0) {
    taskStatusStr += 'admissiontask_complete';
  } else {
    taskStatusStr += 'admissiontask_incomplete';
  }
  if (incompleteFinTasks.length === 0) {
    taskStatusStr += '; financialtask_complete';
  } else {
    taskStatusStr += '; financialtask_incomplete';
  }
  if (incompleteOptTasks.length === 0) {
    taskStatusStr += '; optionaltask_complete';
  } else {
    taskStatusStr += '; optionaltask_incomplete';
  }
  return taskStatusStr;
};

export const getAutoCompleteTaskObjList = () => {
  const autoCompleteTaskConfigList = (window.__ENV?.REACT_APP_AUTOCOMPLETE_TASK_LIST || '').split(',');
  const autoCompleteTaskList = autoCompleteTaskConfigList.map(itemstr => {
    const items = itemstr.split('=');
    return {
      taskTag: items[0],
      notificationType: items[1],
    };
  });
  return autoCompleteTaskList || [];
};

export const getAutoCompleteTaskList = () => getAutoCompleteTaskObjList().map(item => item.taskTag);

export const getAutoCompleteNotificationList = () => getAutoCompleteTaskObjList().map(item => item.notificationType);

export const getFormattedDate = dateVal => {
  if (dateVal) {
    const dateUpd = new Date(dateVal.substring(0, 10));
    const options = {
      month: 'short', day: 'numeric', year: 'numeric', timeZone: 'UTC',
    };
    return dateUpd.toLocaleDateString('en-US', options);
  }
  return '';
};

export const handleERCallClick = (analyticComponent, phoneNumber, personId) => {
  triggerAlloyTrack('ContactClick', {
    componentName: analyticComponent,
    name: 'Enrollment Rep',
    text: phoneNumber,
    interactLocation: 'Content',
  }, { personId });
};

export const handleEREmailClick = (analyticComponent, emailAddress, personId) => {
  triggerAlloyTrack('ContactClick', {
    componentName: analyticComponent,
    name: 'Enrollment Rep',
    text: emailAddress,
    interactLocation: 'Content',
  }, { personId });
};

export const getIdVerifyAnalyticStatus = identityVerificationStatus => {
  let idVerifyAnalyticStatus = '';
  if (identityVerificationStatus && identityVerificationStatus.toLowerCase().includes('success')) {
    idVerifyAnalyticStatus = 'COMPLETED';
  }
  if (identityVerificationStatus && identityVerificationStatus.toLowerCase().includes('fail')) {
    idVerifyAnalyticStatus = 'FAILED';
  }
  if (identityVerificationStatus && !idVerifyAnalyticStatus) {
    idVerifyAnalyticStatus = 'START';
  }
  return idVerifyAnalyticStatus;
};

export default {
  getPersonId,
};
