/* eslint-disable react/jsx-indent */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  AppBar, Toolbar, Grid, Typography,
} from '@material-ui/core';
import {
  ReactComponent as CareerWithConfidenceIcon,
} from '../admissions/components/icons/careerWithConfidence.svg';
import getBaseStyles from './baseStyles';

const useStyles = makeStyles(theme => ({ ...getBaseStyles(theme) }));

const AdminWrapper = ({
  pageId,
  Icon,
  header,
  tagline,
  children,
}) => {
  const classes = useStyles();
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
    <AppBar
      position="fixed"
      color="inherit"
      elevation={4}
    >

    <Toolbar className={classes.headerContainer}>
    <div className={classes.logoWrapper}>
          <CareerWithConfidenceIcon className={classes.logoIcon} />
    </div>

    </Toolbar>
    </AppBar>
    <main id={pageId} className={classes.main}>
        <nav className={classes.topNav} aria-label="Admin page navigation" />
        <Grid container>
          <Grid
            item
            xs={12}
            className={classes.pageWrapper}
            elevation={2}
          >
            <Grid container alignItems="center" className={classes.pgHeader}>
              <Grid item xs={4} sm={2} className={classes.headerIcon}>
                {Icon && <Icon className={classes.icon} />}
              </Grid>
              <Grid item xs={8} sm={10} className={classes.headerHeading}>
                {header && (
                  <Typography variant="h1">
                    {header}
                  </Typography>
                )}
                {tagline && (
                  <Typography variant="h2" className="tagline">
                    {tagline}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <nav aria-label="Admin page navigation" className={classes.pageNav} />
            { children() }
          </Grid>
        </Grid>
    </main>
    </>
  );
};

AdminWrapper.defaultProps = {
  pageId: '',
  Icon: null,
  header: '',
  tagline: '',
};

AdminWrapper.propTypes = {
  pageId: PropTypes.string,
  Icon: PropTypes.objectOf(PropTypes.any),
  header: PropTypes.string,
  tagline: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func]).isRequired,
};

export default AdminWrapper;
