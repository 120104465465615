export const ListOfPhoneTypes = [
  {
    label: 'Mobile',
    value: 'Mobile',
  },
  {
    label: 'Home',
    value: 'Home',
  },
  {
    label: 'Work',
    value: 'Work',
  },
];

export default ListOfPhoneTypes;
