import {
  REQUEST_PROGRAM_MEMBERSHIP_DETAILS, RECEIVE_PROGRAM_MEMBERSHIP_DETAILS, REQUEST_PROGRAM_MEMBERSHIP_DETAILS_ERROR, 
  REQUEST_STUDENT_STATUS, RECEIVE_STUDENT_STATUS, REQUEST_STUDENT_STATUS_ERROR,
} from './types';
import STATUS from '../../globalStatuses';
import { errorToMessage } from '../../../errorMessaging';

function student(
  state = { student: {} },
  action,
) {
  switch (action.type) {
    case REQUEST_PROGRAM_MEMBERSHIP_DETAILS:
      return {
        ...state,
        programMembershipStatus: {
          ...state.programMembershipStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_PROGRAM_MEMBERSHIP_DETAILS:
      return {
        ...state,
        programMembership: action.programMembership,        
        programMembershipStatus: {
          ...state.programMembershipStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_PROGRAM_MEMBERSHIP_DETAILS_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        programMembershipStatus: {
          ...state.programMembershipStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };   
      case REQUEST_STUDENT_STATUS:
      return {
        ...state,
        studentStatusStatus: {
          ...state.studentStatusStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_STUDENT_STATUS:
      return {
        ...state,
        status: action.status,        
        studentStatusStatus: {
          ...state.studentStatusStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_STUDENT_STATUS_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        studentStatusStatus: {
          ...state.studentStatusStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    default:
      return state;
  }
}

export default student;