import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TextField } from '../../../../base/text/TextField';
import { FormFields } from './Username.fields';

export const Username = () => {
  const { control } = useFormContext();
  return (
    <TextField
      id={FormFields.username.id}
      name={FormFields.username.fieldName}
      label={FormFields.username.label}
      ariaDescribedBy={FormFields.username.ariaDescribedBy}
      required={FormFields.username.required}
      control={control}
    />
  );
};

export default Username;
