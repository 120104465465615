const IdentifyVerifyRetryConstants = {
  introContactsText: 'Business hours: Call',
  introContactsTextTwo: 'Call',
  endContactsText: 'or chat with us',
  introEmailText: 'After hours: Email ',
  introEmailTextTwo: 'Email ',
  endEmailText: ", we'll reply the next business day.",
  titleHeaderTextOne: 'Let\'s try again',
  titleHeaderTextTwo: 'We\'re still unable to verify your identity',
  titleHeaderTextThree: "We're unable to successfully verify your identity",
  titleHeaderTextFour: 'We still couldn\'t confirm your identity. ',
  titleHeaderPingAndVideo: 'We couldn\'t verify your ID. Don\'t worry, let\'s try again.',
  titleHeaderVideo: 'We still couldn\'t confirm your identity.',
  titleHeaderPing1: 'We couldn\'t verify your ID',
  titleHeaderPing2: 'We still couldn\'t verify your ID',
  titleSubHeaderPing1: 'Don\'t worry, let\'s try PingOne Verify.',
  titleSubHeaderPing2: 'Don\'t worry, let\'s try PingOne Verify again.',
  questionHeaderTextOne: 'Questions? We\'re here to help.',
  questionHeaderTextTwo: 'Please contact us for next steps',
  questionHeaderTextThree: 'Need help? We\'ve got you',
  questionHeaderTextFour: 'Don\'t give up! We may still be able to help you. Please contact us for next steps.',
  startAttemptText: 'You still have',
  endAttemptTextPlural: 'more attempts to confirm your identity.',
  endAttemptTextSingular: 'more attempt to confirm your identity.',
  titleSubHeaderText: 'Start a video chat ASAP in order to continue.',
  titleSubHeaderPingAndVideo: 'Choose a way to verify:',
  titleSubHeaderVideo: 'Start a video chat ASAP in order to continue.',
  pingVerifyMeNow: 'Verify me now',
};

export default IdentifyVerifyRetryConstants;
