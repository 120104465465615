export const CA_PROG_ELIGIBLE_SEXUAL_ORIENTATION = [
  'BSLS',
  'MAED/TED-S',
  'MAED/TED-E',
  // 'BSHA', // ONLY for local testing - NEEDS to be commented OUT
];

export default {
  CA_PROG_ELIGIBLE_SEXUAL_ORIENTATION,
};
