import retryCallIfNeeded from '../../../restCalls';
// Unsure what the types endpoint is suppose to be. No swagger docs for contacts or enumerations
const baseEndpointUrl = '/api/contacts/v1/address';
const personBaseEndpointUrl = '/api/persons/v1/person/enum';


/*
  URLs should be the resource path the proxy via node
  will forward to MS otherwise it will go to json-server
  refer to the task service
*/
export default function enumService(client) {
  const getStatesAsync = async () => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}?type=State`));
  const getCountriesAsync = async () => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}?type=Country`));
  const getProvincesAsync = async () => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}?type=Province`));
  const getAdministrativeTerritoriesAsync = async () => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}?type=Administrative Territory`));
  const getOutlyingAreasAsync = async () => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}?type=Outlying Area`));
  const getMilitaryCountriesAsync = async () => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}?type=Country Military`));
  const getMilitaryStatesAsync = async () => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}?type=Military State`));
  const getMilitaryCitiesAsync = async () => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}?type=Military City`));
  const getDistrictsAsync = async () => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}?type=District`));

  const getMilServiceTypesAsync = async () => retryCallIfNeeded(client, () => client.get(`${personBaseEndpointUrl}/MilitaryServiceType`));
  const getMilBranchTypesAsync = async () => retryCallIfNeeded(client, () => client.get(`${personBaseEndpointUrl}/MilitaryServiceCategory`));
  // TODO: These endpoints don't actually exist?
  const getMilEducTypesAsync = async () => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/milEducTypes`));
  const getTestProgTypesAsync = async () => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/testProgTypes`));
  const getSecInstTypesAsync = async () => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/secInstTypes`));
  const getDegreeTypesAsync = async () => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/degreeTypes`));

  return {
    getCountriesAsync,
    getStatesAsync,
    getProvincesAsync,
    getAdministrativeTerritoriesAsync,
    getOutlyingAreasAsync,
    getMilitaryCountriesAsync,
    getMilitaryStatesAsync,
    getMilitaryCitiesAsync,
    getDistrictsAsync,

    // TODO: These endpoints don't actually exist?
    getMilServiceTypesAsync,
    getMilBranchTypesAsync,
    getMilEducTypesAsync,
    getSecInstTypesAsync,
    getTestProgTypesAsync,
    getDegreeTypesAsync,
  };
}
