import debounce from 'lodash/debounce';

// Debounced calls to fullstory
const debouncedCalls = {};

/* eslint-disable no-console */
export const updateFullStory = ({
  personId,
  applicationId,
  applyWebId,
  irn,
  userId,
  segmentAnonymousId,
  adobeMCId,
  tealiumSessionId,
  tealiumVisitorId,
  googleId,
  contentSquareSessionId,
  userFeatureFlags,
  ekataRiskScore,
}) => {
  const fullStoryPayload = {
    ...(personId !== '' && { personId }),
    ...(applicationId !== '' && { applicationId }),
    ...(applyWebId !== '' && { applyWebId }),
    ...(irn !== '' && { irn }),
    ...(userId !== '' && { userId }),
    ...(segmentAnonymousId !== '' && { segmentAnonymousId }),
    ...(adobeMCId !== '' && { adobeMCId }),
    ...(tealiumSessionId !== '' && { tealiumSessionId }),
    ...(tealiumVisitorId !== '' && { tealiumVisitorId }),
    ...(googleId !== '' && { googleId }),
    ...(contentSquareSessionId !== '' && { contentSquareSessionId }),
    ...(userFeatureFlags || {}),
    ...(ekataRiskScore > 0 && { ekataRiskScore }),
  };
  if (!personId && window.FS && window.FS.setUserVars instanceof Function) {
    window.FS.setUserVars(fullStoryPayload);
  }
  if (!!personId && window.FS && window.FS.identify instanceof Function) {
    window.FS.identify(personId, fullStoryPayload);
  }
  // TESTING ONLY CODE
  // sessionStorage.setItem('segmentAnonymousId', segmentAnonymousId);
  // sessionStorage.setItem('adobeMCId', adobeMCId);
  // sessionStorage.setItem('tealiumSessionId', tealiumSessionId);
  // sessionStorage.setItem('tealiumVisitorId', tealiumVisitorId);
  // sessionStorage.setItem('googleId', googleId);
  // sessionStorage.setItem('contentSquareSessionId', contentSquareSessionId);
};

export const sendCustomFullStoryEvent = (eventName, eventValue) => {
  try {
    if (window && window.FS && window.FS.event instanceof Function) {
      window.FS.event(eventName, eventValue);
    }
  } catch (err) {
    console.error('Fullstory custom event error:', err);
  }
};

export const sendDebouncedFullStoryEvent = (eventName, eventValue, hash) => {
  // By default only eventName will be used to group the calls
  // but you can include a custom hash to refine it further
  try {
    const lookupHash = eventName + (hash ? `|${hash}` : '');
    if (!(lookupHash in debouncedCalls)) {
      debouncedCalls[lookupHash] = debounce(sendCustomFullStoryEvent, 1500);
    }
    const call = debouncedCalls[lookupHash];
    call(eventName, eventValue);
  } catch (err) {
    console.error('Fullstory debounced event error:', err);
  }
};

export default {
  updateFullStory,
  sendCustomFullStoryEvent,
};
