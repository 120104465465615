import { makeStyles } from '@material-ui/core/styles';
import youngWomanLaptop from '../../modules/admissions/components/images/molly-laptop.svg';

export default makeStyles(theme => ({
  root: {
    margin: 0,
    padding: 0,
    textAlign: 'center',
    borderRadius: theme.spacing(0.4),
    borderColor: theme.palette.common.darkGrey,
    borderWidth: theme.spacing(0.1),
    borderStyle: 'solid',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
  imageContainer: {
    overflow: 'hidden',
  },
  image: {
    width: 'auto',
    height: '320px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.background.paper,
    backgroundImage: `url(${youngWomanLaptop})`,
    borderTopLeftRadius: theme.spacing(0.3),
    borderBottomLeftRadius: theme.spacing(0.3),
    [theme.breakpoints.up('sm')]: {
      width: '476px',
      height: '100%',
      backgroundPosition: '-110px top',
    },
    [theme.breakpoints.between('992', '1250')]: {
      backgroundPosition: '-225px top',
    },
  },
  videoChatContainer: {
    padding: theme.spacing(2.4),
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
  header: {
    fontSize: '2rem!important',
    color: theme.palette.text.darkBlue,
  },
  paragraph: {
    marginBottom: theme.spacing(2),
  },
  contactInfo: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
    '& .phoneHelp': {
      width: '14rem',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
  cta: {
    marginRight: '0!important',
    minWidth: 'auto!important',
    paddingRight: '3rem!important',
    paddingLeft: '3rem!important',
    [theme.breakpoints.between('992', '1160')]: {
      paddingRight: '1rem!important',
      paddingLeft: '1rem!important',
    },
  },
}));
