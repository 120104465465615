import { ACTIONS } from '../types';
import STATUS from '../../../globalStatuses';

export const militaryTypeReductions = {
  [ACTIONS.REQUEST_MILEDUCTYPE]: state => ({
    ...state,
    milEducTypesStatus: {
      ...state.milEducTypesStatus,
      getStatus: STATUS.FETCHING,
      errorData: null,
    },
  }),
  [ACTIONS.RECEIVE_MILEDUCTYPE]: (state, action) => ({
    ...state,
    milEducTypes: action.payload.milEducTypes,
    milEducTypesStatus: {
      ...state.milEducTypesStatus,
      getStatus: STATUS.FETCHED,
    },
  }),
  [ACTIONS.RECEIVE_MILEDUCTYPE_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    milEducTypesStatus: {
      ...state.milEducTypesStatus,
      getStatus: STATUS.ERROR,
      errorData: action.error,
    },
  }),
  [ACTIONS.REQUEST_MILSERVICETYPE]: state => ({
    ...state,
    milServiceTypesStatus: {
      ...state.milServiceTypesStatus,
      getStatus: STATUS.FETCHING,
      errorData: null,
    },
  }),
  [ACTIONS.RECEIVE_MILSERVICETYPE]: (state, action) => ({
    ...state,
    milServiceTypes: action.payload.milServiceTypes,
    milServiceTypesStatus: {
      ...state.milServiceTypesStatus,
      getStatus: STATUS.FETCHED,
    },
  }),
  [ACTIONS.RECEIVE_MILSERVICETYPE_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    milServiceTypesStatus: {
      ...state.milServiceTypesStatus,
      getStatus: STATUS.ERROR,
      errorData: action.error,
    },
  }),
  [ACTIONS.REQUEST_MILBRANCHTYPE]: state => ({
    ...state,
    milBranchTypesStatus: {
      ...state.milBranchTypesStatus,
      getStatus: STATUS.FETCHING,
      errorData: null,
    },
  }),
  [ACTIONS.RECEIVE_MILBRANCHTYPE]: (state, action) => ({
    ...state,
    milBranchTypes: action.payload.milBranchTypes,
    milBranchTypesStatus: {
      ...state.milBranchTypesStatus,
      getStatus: STATUS.FETCHED,
    },
  }),
  [ACTIONS.RECEIVE_MILBRANCHTYPE_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    milBranchTypesStatus: {
      ...state.milBranchTypesStatus,
      getStatus: STATUS.ERROR,
      errorData: action.error,
    },
  }),
};

export default militaryTypeReductions;
