export const WorkConstant = {
  workLikeEnv: 'Do you have access to a work-like environment?',
  workLikeEnvNote: 'OK, we see you have no work experience. So for now, let\'s continue with the application process.',
  nextPageLink: 'financial',
  mainStep: 3,
  secondaryStep: 0,
  pageHeader: 'Tell us about your work experience',
  pageHeader2Main: 'Specific work experience is required for admission to the program you selected',
  pageHeading2: 'We ask for your work information to determine your eligibility for University of Phoenix tuition savings',
  pageSubHeading2: 'If we work with your employer, you may be able to pay less for tuition and fees.',
  pageHeading2WithBullets: 'We ask for your work information to:',
  jobResponsibilitiesCharacterLimit: 1000,
};

export const renderWorkTitle = (index, current) => {
  let title = '';
  if (current) {
    if (index + 1 === 1) {
      title = 'Primary employer';
    } else {
      title = 'Additional current employer';
    }
  } else {
    title = `Work History: #${index + 1}`;
  }
  return title;
};

export const workTemplate = {
  index: 0,
  id: '',
  organizationId: '',
  companyName: null,
  department: '',
  title: '',
  hoursPerWeek: '',
  jobResponsibilities: '',
  fromMonth: 0,
  fromYear: '',
  toMonth: 0,
  toYear: '',
  isPrimary: '',
  current: false,
  beginDate: null,
  endDate: null,
  personId: null,
  dunsNumber: null,
  type: null,
  inValid: true,
  dirty: false,
};

export const workOrgTemplate = {
  id: '',
  personId: null,
  workOrganizationName: '',
  workContactPerson: '',
  workCountry: '',
  workContactPhone: null,
  workJobFunctions: '',
  dirty: false,
};

export const PREVIOUS_WORK_EXPERIENCE_PROGRAMS = (window.__ENV?.REACT_APP_WORK_EXP_PROGRAMS || '').split(',');

export const phoneNumber = {
  ariaLabel: 'Click this number to make a phone call and get help with any questions',
};

export const chatNow = {
  ariaLabel: 'Chat with us',
};
