import retryCallIfNeeded from '../../../restCalls';

export default function contributionService(client) {
  const getMaxContributionAsync = async personId => retryCallIfNeeded(
    client,
    () => client.get(`/rules/v1/stateless/calculatorinput/${personId}/contributions`),
  );
  const postMaxContributionAsync = async (personId, payload) => retryCallIfNeeded(
    client,
    () => client.post(`/rules/v1/stateless/calculatorinput/${personId}/contributions`, payload),
  );
  const putMaxContributionAsync = async (personId, maxContributionId, payload) => retryCallIfNeeded(
    client,
    () => client.put(`/rules/v1/stateless/calculatorinput/${personId}/contributions/${maxContributionId}`, payload),
  );
  const deleteMaxContributionAsync = async maxContributionId => retryCallIfNeeded(
    client,
    () => client.delete(`/rules/v1/stateless/calculatorinput/contributions/${maxContributionId}`),
  );

  return {
    getMaxContributionAsync,
    postMaxContributionAsync,
    putMaxContributionAsync,
    deleteMaxContributionAsync,
  };
}
