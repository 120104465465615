import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { UOPX_PHONE_NUMBER, phoneNumber10Digit } from '../../constants';
import { ReactComponent as UopxLogo } from '../icons/uopx-logo.svg';
import { ReactComponent as ChromeLogo } from '../icons/google-chrome-logo.svg';
import { ReactComponent as FirefoxLogo } from '../icons/mozilla-firefox-logo.svg';
import { ReactComponent as EdgeLogo } from '../icons/microsoft-edge-logo.svg';
import './NotSupportedBrowser.scss';

const DialogTitle = props => {
  const {
    children, ...other
  } = props;

  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography
        variant="h6"
        className="visually-hidden"
      >
        {children}
      </Typography>
    </MuiDialogTitle>
  );
};

const DialogContent = () => (
  <MuiDialogContent data-utid="ie11-content">
    <div className="modal-logo">
      <UopxLogo />
    </div>
    <Typography variant="h1" component="h1">
      Oops! Internet Explorer (IE 11) and Microsoft Edge Legacy are not supported by this website.
    </Typography>

    <Typography className="modal-text" id="app-modal-desc">
      For a better experience, we recommend upgrading to the latest
      Microsoft browser, or using another browser like Chrome, Firefox or Safari.
    </Typography>

    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="flex-start"
    >
      <Grid item sm={4}>
        <a href="https://www.google.com/chrome/" aria-label="Download Google Chrome" target="_blank" rel="noopener noreferrer" className="browser-icon">
          <ChromeLogo />
          <div className="browser-label">
            <span className="visually-hidden">Open link in a new tab to the download site	of</span>
            {' '}
            Google Chrome
          </div>
        </a>
      </Grid>
      <Grid item sm={4}>
        <a href="https://www.mozilla.org/en-US/firefox/new/" aria-label="Download Mozilla Firefox" target="_blank" rel="noopener noreferrer" className="browser-icon">
          <FirefoxLogo />
          <div className="browser-label">
            <span className="visually-hidden">Open link in a new tab to the download site	of</span>
            {' '}
            Mozilla Firefox
          </div>
        </a>
      </Grid>
      <Grid item sm={4}>
        <a href="https://www.microsoft.com/en-us/edge" aria-label="Download Microsoft Edge" target="_blank" rel="noopener noreferrer" className="browser-icon">
          <EdgeLogo />
          <div className="browser-label">
            <span className="visually-hidden">Open link in a new tab to the download site	of</span>
            {' '}
            Microsoft Edge
          </div>
        </a>
      </Grid>
    </Grid>

    <div className="modal-footer-content">
      <strong>
        Questions? Call us for help at
        {' '}
        <a
          href={`tel:+1${phoneNumber10Digit(UOPX_PHONE_NUMBER)}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-labelledby="Call Us"
          className="call-us"
        >
          {UOPX_PHONE_NUMBER}
        </a>
      </strong>
    </div>
  </MuiDialogContent>
);

const NotSupportedBrowser = () => (
  <Dialog
    maxWidth="md"
    id="ie11NotSupported"
    disableEscapeKeyDown
    aria-labelledby="customized-dialog-title"
    open
  >
    <DialogTitle
      id="customized-dialog-title"
    >
      Internet Explorer and Microsoft Edge Legacy are not
      supported by this website. Please use a different browser
      or download any of our 3 suggested browsers.
    </DialogTitle>
    <DialogContent />

  </Dialog>
);

export default NotSupportedBrowser;
