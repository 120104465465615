import ProgramServiceBase from '../programBase';
import retryCallIfNeeded from '../../../restCalls';

export default class ProgramServiceV1 extends ProgramServiceBase {
  constructor(client) {
    super(client, 'v1');
  }

  getProgramAttestation = (programId, state) => retryCallIfNeeded(this.client, () => this.client.get(
    `${this.baseEndpointUrl}/attestation?$filter=type eq mandatory and state eq ${state} and programCode eq ${programId}`,
  ));
}
