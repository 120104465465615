/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import {
  Typography, Grid, Button, CircularProgress,
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import {
  OpenInNew as OpenInNewWindow,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  ReactComponent as Laptop,
} from '../../modules/admissions/components/icons/UopxLaptopMedium.svg';
import {
  ReactComponent as Documents,
} from '../../modules/admissions/components/icons/DocumentsMedium.svg';
import {
  ReactComponent as LaptopList,
} from '../../modules/admissions/components/icons/ListMedium.svg';
import {
  ReactComponent as OpenBookBookmark,
} from '../../modules/admissions/components/icons/open-book-bookmard.svg';
import {
  ReactComponent as CertificateAward,
} from '../../modules/admissions/components/icons/certificate-award.svg';
import {
  ReactComponent as ErrorIcon,
} from '../../modules/admissions/components/icons/error.svg';
import {
  ReactComponent as CheckIcon,
} from '../../modules/admissions/components/icons/check-circle/green-small-check-circle.svg';
import {
  ReactComponent as Lightbulb,
} from '../../modules/admissions/components/icons/lightbulb-idea.svg';
import {
  ReactComponent as LaptopTag,
} from '../../modules/admissions/components/icons/laptop-with-tag.svg';
import {
  ReactComponent as MoneyCoin,
} from '../../modules/admissions/components/icons/money-coin.svg';
import {
  ReactComponent as ParentChild,
} from '../../modules/admissions/components/icons/parent-child.svg';
import {
  ReactComponent as LifeRing,
} from '../../modules/admissions/components/icons/lifebuoy.svg';
import {
  ReactComponent as TimeCalendar,
} from '../../modules/admissions/components/icons/time-calendar-clock-stopwatch.svg';
import LinkLongArrow from '../../modules/admissions/components/button/link-long-arrow/LinkLongArrow';
import styles from './styles';

export default function CtaWithIconCard({
  id,
  className,
  heading,
  icon,
  iconIdent,
  iconSeparateLine,
  header,
  listItems,
  listType,
  paragraph,
  paragraphBeg,
  paragraphLinkLabel,
  handleParagraphLinkClick,
  paragraphEnd,
  ctaType,
  ctaRoleType,
  ctaLabel,
  ariaLabel,
  linkColor,
  buttonColor,
  buttonType,
  hrefLink,
  iconLink,
  to,
  handleClick,
  outlineCardContent,
  backgroundCardContent,
  centerCardContent,
  disabledCard,
  children,
  disabled,
}) {
  const classes = styles();
  const createMarkup = content => ({ __html: content });
  const [iconDisplay, setIconDisplay] = useState(null);
  const [ariaHidden, setAriaHidden] = useState(true);

  useEffect(() => {
    if (icon === 'documents') {
      setIconDisplay(
        <Laptop className="documentsIconGrid" />,
      );
    } else if (icon === 'laptop') {
      setIconDisplay(
        <LaptopList className="laptopIconGrid" />,
      );
    } else if (icon === 'openBook') {
      setIconDisplay(
        <OpenBookBookmark className="openBookBookmark" />,
      );
    } else if (icon === 'lightbulb') {
      setIconDisplay(
        <Lightbulb className="lightbulb" />,
      );
    } else if (icon === 'laptoptag') {
      setIconDisplay(
        <LaptopTag className="laptoptag" />,
      );
    } else if (icon === 'moneycoin') {
      setIconDisplay(
        <MoneyCoin className="moneycoin" />,
      );
    } else if (icon === 'parentchild') {
      setIconDisplay(
        <ParentChild className="parentchild" />,
      );
    } else if (icon === 'lifering') {
      setIconDisplay(
        <LifeRing className="lifering" />,
      );
    } else if (icon === 'list') {
      setIconDisplay(
        <Documents className="documentsIconGrid" />,
      );
    } else if (icon === 'certificateAward') {
      setIconDisplay(
        <CertificateAward className="certAwardIconGrid" />,
      );
    } else if (icon === 'error') {
      setIconDisplay(
        <ErrorIcon className="errorIconGrid" />,
      );
      setAriaHidden(false);
    } else if (icon === 'check') {
      setIconDisplay(
        <CheckIcon className="errorIconGrid" />,
      );
    } else if (icon === 'timecalendar') {
      setIconDisplay(
        <TimeCalendar className="timecalendar" />,
      );
    }
  }, [disabledCard, icon]);

  return (
    <div
      className={
      clsx(
        classes.root,
        `${className || ''}`,
        `${ctaType === 'link' ? classes.ctaLinkType : ''}`,
        `${ctaType === 'button' ? classes.ctaButtonType : ''}`,
        `${outlineCardContent ? classes.outlineCardContent : ''}`,
        `${backgroundCardContent === 'backgroundColor' ? classes.backgroundColor : ''}`,
        `${backgroundCardContent === 'backgroundColor2' ? classes.backgroundColor2 : ''}`,
        `${backgroundCardContent === 'backgroundDropShadow' ? classes.backgroundDropShadow : ''}`,
        `${centerCardContent ? classes.centerCardContent : ''}`,
        `${disabledCard ? classes.disabledCard : ''}`,
        'ctaWithIconCard',
      )
      }
      id={id}
    >
      <Grid
        container
        alignItems="center"
        className={iconSeparateLine
          ? `ctaHeaderGrid ${icon}HeaderGrid ${classes.iconSeparateLine}`
          : `ctaHeaderGrid ${icon}HeaderGrid`}
        spacing={1}
        wrap="nowrap"
      >
        <Grid
          item
          aria-hidden={ariaHidden}
        >
          {iconDisplay}
        </Grid>
        <Grid item>
          <Typography variant={heading} className={classes.header}>
            {header}
          </Typography>
        </Grid>
      </Grid>
      <div className={iconIdent ? 'iconIdent' : null}>
        {paragraph ? (
          <div
            id={`${id}Paragraph`}
            className={clsx(classes.paragraph, 'ctaParagraph')}
            dangerouslySetInnerHTML={createMarkup(paragraph)}
          />
        ) : null}
        {paragraphBeg ? (
          <div className={clsx(classes.paragraph, 'ctaParagraph newWindowLinkInParagraph')}>
            {paragraphBeg}
            {' '}
            <span id={`stayHere${uuidv4()}`} />
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              /*
                If more complex linking is needed inside the paragraph
                we'll need to create a separate component.
                This component is becoming too complex
                Use handleParagraphLinkClick function to use an href
                like: window.location.href = 'your url here;
              */
              href={`#stayHere${uuidv4()}`}
              rel="noreferrer"
              className="defaultCtaBtn textLink"
              onClick={() => {
                handleParagraphLinkClick();
              }}
            >
              {paragraphLinkLabel}
            </a>
            {' '}
            {paragraphEnd}
          </div>
        ) : null}
        {listItems && listType === 'unordered' ? (
          <ul className="unordered-list main">
            {listItems.map((item, index) => (
              <li key={index}><span>{item}</span></li>
            ))}
          </ul>
        ) : null}
        {listItems && listType === 'ordered' ? (
          <ol className={clsx(classes.list, 'ordered-list main')}>
            {listItems.map((item, index) => (
              <li key={index}><span>{item}</span></li>
            ))}
          </ol>
        ) : null}
        {children}
        {ctaLabel && !iconLink && (
          <div className={classes.cta}>
            {(ctaType === 'link' && to === '') && (
              <LinkLongArrow
                color={linkColor}
                ariaLabel={ariaLabel}
                label={ctaLabel}
                hrefLink={hrefLink !== null ? hrefLink : null}
                handleClick={() => {
                  handleClick();
                }}
                disabled={disabledCard}
              />
            )}
            {(ctaType === 'link' && to !== '') && (
              <LinkLongArrow
                color={linkColor}
                ariaLabel={ariaLabel}
                label={ctaLabel}
                hrefLink={hrefLink !== null ? hrefLink : null}
                handleClick={() => {
                  handleClick();
                }}
                to={to}
                disabled={disabledCard}
              />
            )}
            {(ctaType === 'button' && !iconLink) && (
              <Button
                className={`mainBtn ${buttonColor === 'blue' ? 'colorOverBtn' : ''}`}
                color={buttonType}
                ariaLabel={ariaLabel}
                disableRipple
                disableFocusRipple
                onClick={() => {
                  handleClick();
                }}
                disabled={disabledCard || disabled}
                role={ctaRoleType}
              >
                {disabled && <CircularProgress size={18} className={classes.circularProgress} />}
                {ctaLabel}
              </Button>
            )}
          </div>
        )}
        {ctaLabel && ctaType === 'button' && iconLink && (
          <div className={classes.cta}>
            <Button
              className={`mainBtn ${buttonColor === 'blue' ? 'colorOverBtn' : ''}`}
              color="secondary"
              aria-label={`${ctaLabel}, opens in new window`}
              aria-hidden={false}
              aria-disabled={false}
              disableRipple
              disableFocusRipple
              onClick={() => { handleClick(); }}
              disabled={disabledCard}
              href={hrefLink !== null ? hrefLink : null}
              endIcon={<OpenInNewWindow />}
              target={hrefLink ? '_blank' : null}
              role={ctaRoleType}
            >
              {ctaLabel}
            </Button>
          </div>
        )}
        {ctaLabel && ctaType === 'link' && iconLink && (
          <Grid
            container
            wrap="nowrap"
            className="linkWithIcon"
            alignItems="center"
          >
            <Grid item>
              <a
                href={hrefLink !== null ? hrefLink : null}
                target="_blank"
                // onClick={() => handleOpenDialog()}
                className="defaultCtaBtn"
                aria-label={`${ctaLabel}, opens in new window.`}
                aria-disabled={disabledCard}
                rel="noreferrer"
              >
                {ctaLabel}
              </a>
            </Grid>
            <Grid item>
              <OpenInNewWindow />
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}

CtaWithIconCard.defaultProps = {
  id: '',
  className: '',
  heading: 'h3',
  icon: 'documents',
  iconIdent: false,
  iconSeparateLine: false,
  header: 'Add the header prop, please',
  listItems: null,
  listType: 'unordered',
  paragraph: null,
  paragraphBeg: null,
  paragraphLinkLabel: null,
  handleParagraphLinkClick: () => {},
  paragraphEnd: null,
  ctaLabel: null,
  ariaLabel: '',
  ctaType: 'link',
  ctaRoleType: 'button',
  linkColor: 'primary',
  buttonColor: 'red',
  buttonType: 'primary',
  hrefLink: null,
  iconLink: false,
  to: '',
  handleClick: () => {},
  disabledCard: false,
  outlineCardContent: false,
  backgroundCardContent: null,
  centerCardContent: false,
  children: null,
  disabled: false,
};

CtaWithIconCard.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  heading: PropTypes.string,
  icon: PropTypes.oneOf(['documents', 'laptop', 'openBook', 'list', 'certificateAward', 'error', 'lightbulb', 'laptoptag', 'moneycoin', 'parentchild', 'lifering', 'timecalendar']),
  iconIdent: PropTypes.bool,
  iconSeparateLine: PropTypes.bool,
  header: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.string),
  listType: PropTypes.oneOf(['unordered', 'ordered']),
  paragraph: PropTypes.string,
  paragraphBeg: PropTypes.string,
  paragraphLinkLabel: PropTypes.string,
  handleParagraphLinkClick: PropTypes.func,
  paragraphEnd: PropTypes.string,
  ctaLabel: PropTypes.string,
  ariaLabel: PropTypes.string,
  ctaType: PropTypes.oneOf(['link', 'button']),
  ctaRoleType: PropTypes.oneOf(['link', 'button']),
  linkColor: PropTypes.oneOf(['primary', 'secondary']),
  buttonColor: PropTypes.oneOf(['red', 'blue']),
  buttonType: PropTypes.oneOf(['primary', 'secondary']),
  hrefLink: PropTypes.string,
  iconLink: PropTypes.bool,
  to: PropTypes.string,
  handleClick: PropTypes.func,
  disabledCard: PropTypes.bool,
  outlineCardContent: PropTypes.bool,
  backgroundCardContent: PropTypes.oneOf(['backgroundColor', 'backgroundColor2', 'backgroundDropShadow']),
  centerCardContent: PropTypes.bool,
  children: PropTypes.element,
  disabled: PropTypes.bool,
};
