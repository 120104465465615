export const ACTIONS = {
  REQUEST_SECONDARY_ED: 'REQUEST_SECONDARY_ED',
  RECEIVE_SECONDARY_ED: 'RECEIVE_SECONDARY_ED',
  REQUEST_SECONDARY_ED_ERROR: 'REQUEST_SECONDARY_ED_ERROR',
  CLEAR_SECONDARY_ED_GET_STATUS: 'CLEAR_SECONDARY_ED_GET_STATUS',

  REQUEST_SAVE_SECONDARY_ED: 'REQUEST_SAVE_SECONDARY_ED',
  RECEIVE_SAVE_SECONDARY_ED: 'RECEIVE_SAVE_SECONDARY_ED',
  REQUEST_SAVE_SECONDARY_ED_ERROR: 'REQUEST_SAVE_SECONDARY_ED_ERROR',

  REQUEST_SECONDARY_ED_DISTRICT_BY_COUNTRY: 'REQUEST_SECONDARY_ED_DISTRICT_BY_COUNTRY',
  RECEIVE_SECONDARY_ED_DISTRICT_BY_COUNTRY: 'RECEIVE_SECONDARY_ED_DISTRICT_BY_COUNTRY',
  REQUEST_SECONDARY_ED_DISTRICT_BY_COUNTRY_ERROR: 'REQUEST_SECONDARY_ED_DISTRICT_BY_COUNTRY_ERROR',
  RESET_SECONDARY_ED_DISTRICT: 'RESET_SECONDARY_ED_DISTRICT',

  REQUEST_DELETE_SECONDARY_ED: 'REQUEST_DELETE_SECONDARY_ED',
  RECEIVE_DELETE_SECONDARY_ED: 'RECEIVE_DELETE_SECONDARY_ED',
  REQUEST_DELETE_SECONDARY_ED_ERROR: 'REQUEST_DELETE_SECONDARY_ED_ERROR',
};
export default ACTIONS;
