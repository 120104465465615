import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import userManager from './userManager';
import LoadingModal from '../../../components/modal/LoadingModal';

const AuthedRoute = ({
  component: Component, path, location, ...rest
}) => {
  const { user, isLoadingUser } = useSelector(state => state.oidc);
  const [initialRoute, setInitialRoute] = useState(null);
  const [initialLocation, setInitialLocation] = useState(null);

  const isLoggedIn = user && !user.expired;

  if (!isLoggedIn) {
    const authDepotRedirect = `${window.__ENV.REACT_APP_OAUTH_AUTHDEPOT_URI}=${window.__ENV.REACT_APP_AUTHDEPOT_REDIRECT_URI}`;
    if (path.includes('/authdepot/callback')) {
      userManager.signinRedirect({
        data: { redirectUrl: `${location.pathname.split('/authdepot/callback')[1]}${location.search}` },
      });
    } else {
      window.location.href = authDepotRedirect + location.pathname
        + encodeURIComponent(location.search);
    }

    // Returning LoadingModal here to avoid flickering between the redirect and the next page.
    return <LoadingModal />;
  }

  // eslint-disable-next-line max-len
  const render = props => (isLoadingUser || !isLoggedIn ? <LoadingModal /> : <Component {...props} />);

  let resolvedRoute = <Route path={path} render={render} {...rest} />;
  // Use the initial resolved route if path and location matches to prevent re-render
  if (
    initialRoute !== null && initialRoute.props.path === resolvedRoute.props.path
    && initialLocation === location.pathname
  ) {
    resolvedRoute = initialRoute;
  } else {
    setInitialRoute(resolvedRoute);
    setInitialLocation(location.pathname);
  }

  return resolvedRoute;
};

AuthedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  location: PropTypes.shape({ pathname: PropTypes.string, search: PropTypes.string }),
};

AuthedRoute.defaultProps = {
  component: <></>,
  path: '/',
  location: {},
};

export default AuthedRoute;
