export const linkText = 'FAQs: College degree levels';
export const header = 'Frequently asked questions about college degree levels';
export const list = [
  {
    index: '0',
    id: 'panel0a-header',
    ariaControls: 'panel0a-content',
    header: 'Which degree comes first in college?',
    paragraph: 'Most students start by earning an associate degree or a bachelor\'s degree before moving on to a graduate degree, such as a master\'s and/or doctoral degree.',
  },
  {
    index: '1',
    id: 'panel1a-header',
    ariaControls: 'panel1a-content',
    header: 'What comes after a bachelor\'s degree?',
    paragraph: 'Students may choose to return to college to pursue a master\'s degree program after getting their bachelor\'s degree.',
  },
  {
    index: '2',
    id: 'panel2a-header',
    ariaControls: 'panel2a-content',
    header: 'What is the highest degree?',
    paragraph: 'That would be a doctoral degree, which represents one of the highest levels of educational attainment within a particular field.',
  },
  {
    index: '3',
    id: 'panel3a-header',
    ariaControls: 'panel3a-content',
    header: 'Which certificate program level should I choose?',
    paragraph: 'All students can complete one of our undergraduate certificate programs. If you\'ve already completed your bachelor\'s degree program, you can also search our graduate certificate programs. In addition, if you\'ve completed a master\'s degree you can explore a post-master\'s certificate.',
  },
];
