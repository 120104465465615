import retryCallIfNeeded from '../../../restCalls';

export default function personService(client) {
  const personProxyBase = '/application/persons';
  const checkUsername = '/check-username';
  

  // Persons
  const getPersonMetaByPersonIdAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`/api/persons/v1/person/${personId}`));
  const putPersonAsync = async (personId, payload, etag) => retryCallIfNeeded(client, () => {
    const config = {
      headers: {
        'If-Match': etag || 'Default',
      },
    };
    return client.put(`/api/persons/v1/person/${personId}`, payload, config);
  });
  // Enums
  const getVisaTypesAsync = async () => retryCallIfNeeded(client, () => client
    .get('/api/person/v1/visaTypes'));

  // Names
  const getNamesByPersonIdAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`/api/persons/v1/person/${personId}/names`));
  const getNameByNameIdAsync = async (personId, nameId) => retryCallIfNeeded(client, () => client
    .get(`/api/persons/v1/person/${personId}/name/${nameId}`));
  const postPersonNamesAsync = async payload => retryCallIfNeeded(client, () => client
    .post('/api/persons/v1/persons/names', payload));
  const postPersonAsync = async payload => retryCallIfNeeded(client, () => client
    .post('/api/persons/v1/persons', payload));
  const postNamesAsync = async (personId, payload) => retryCallIfNeeded(client, () => client
    .post(`/api/persons/v1/person/${personId}/names`, payload));
  const putNamesAsync = async (nameId, payload, nameETag) => retryCallIfNeeded(client, () => {
    const config = {
      headers: {
        'If-Match': nameETag || 'Default',
      },
    };
    return client.put(`/api/persons/v1/person/name/${nameId}`, payload, config);
  });
  const deleteNameAsync = async nameId => retryCallIfNeeded(client, () => client
    .delete(`/api/persons/v1/person/name/${nameId}`));

  // Person Info
  const getPersonInfoAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`/api/persons/v1/person/${personId}/information`));
  const getPersonInfoByIdAsync = async (personId, id) => retryCallIfNeeded(client, () => client
    .get(`/api/persons/v1/person/${personId}/information/${id}`));
  const postPersonInfoAsync = async payload => {
    const { personId } = payload;
    const baseUri = `/api/persons/v1/person/${personId}/information`;
    return retryCallIfNeeded(client, () => client.post(baseUri, payload));
  };
  const putPersonInfoAsync = async (payload, etag) => {
    const { personId, id } = payload;
    const config = {
      headers: {
        'If-Match': etag || 'Default',
      },
    };
    const baseUri = `/api/persons/v1/person/${personId}/information`;
    return retryCallIfNeeded(client, () => client.put(`${baseUri}/${id}`, payload, config));
  };
  const getUsernameStatus = async personId => retryCallIfNeeded(client, () => client
    .get(`${personProxyBase}${checkUsername}?personId=${personId}`));

    const getVerificationStatusByPersonId = async personId => retryCallIfNeeded(client, () => client
    .get(`${personProxyBase}/check-verificationStatus?personId=${personId}`));


  const postPersonGenericInfoAsync = async payload => {
    const baseUri = `/api/persons/v1/person/${payload[0]?.personId}/genericinfo`;
    return retryCallIfNeeded(client, () => client.post(baseUri, payload));
  };
  const getGenericInfoByPersonIdAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`/api/persons/v1/person/${personId}/genericinfo`));

  const putPersonGenericInfoAsync = async payload => {
    const { id } = payload;
    const baseUri = '/api/persons/v1/person/genericinfo';
    return retryCallIfNeeded(client, () => client.put(`${baseUri}/${id}`, payload));
  };

  return {
    getPersonMetaByPersonIdAsync,
    putPersonAsync,

    getVisaTypesAsync,

    getNamesByPersonIdAsync,
    getNameByNameIdAsync,
    postPersonNamesAsync,
    putNamesAsync,
    postNamesAsync,
    deleteNameAsync,

    getPersonInfoAsync,
    getPersonInfoByIdAsync,
    postPersonInfoAsync,
    putPersonInfoAsync,
    postPersonAsync,
    getUsernameStatus,
    getVerificationStatusByPersonId,

    postPersonGenericInfoAsync,
    getGenericInfoByPersonIdAsync,
    putPersonGenericInfoAsync,
  };
}
