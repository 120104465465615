import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  Container,
  Grid,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { titleCaseWord } from '../../../utils/stringUtils';
import styles from './styles';
import {
  Credentials,
  credentialsSchema,
} from '../../../components/react-hook-forms/grouped/admin/credentials/Credentials';
import {
  Maintenance,
  maintenanceSchema,
  maintenanceDefaults,
  maintenanceFormFields,
} from '../../../components/react-hook-forms/grouped/admin/maintenance/Maintenance';

const useStyles = styles;

const LoginInfo = props => {
  const {
    maintenance,
    maintenanceEnabled,
    submitAction,
    status,
    customMessage,
  } = props;

  const classes = useStyles();
  const [formStatus, setFormStatus] = useState('');
  const [displayMessage, setDisplayMessage] = useState('');

  const defaultValues = {
    ...maintenanceDefaults,
  };

  const validationSchema = useMemo(
    () => yup
      .object()
      .concat(maintenanceSchema())
      .concat(credentialsSchema()),
    [],
  );

  const formMethods = useForm({
    resolver: yupResolver(validationSchema, { abortEarly: false }),
    mode: 'onBlur',
    defaultValues,
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  });

  const {
    handleSubmit,
    control,
    setValue,
  } = formMethods;

  useEffect(() => {
    setValue(
      maintenanceFormFields.maintenance.name,
      titleCaseWord(maintenanceEnabled),
    );
  }, [maintenanceEnabled, setValue]);

  useEffect(() => {
    setFormStatus(status);
    if (status.length > 0) {
      if (customMessage) {
        setDisplayMessage(customMessage);
      } else if (status === 'failed') {
        setDisplayMessage('Invalid Credentials!! Try again');
      } else {
        setDisplayMessage('Login Success!!');
      }
    }
  }, [status, customMessage]);

  const onPageSubmit = data => {
    setDisplayMessage('');
    setFormStatus('');
    submitAction(data);
  };

  return (
    <div>
      <Container
        id="adminLogin"
        className={classes.root}
        control={control}
        component="main"
        data-cy="logn-info-container"
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          className={classes.container}
        >
          {' '}
          <Grid item sm={6} className={classes.gridTwo}>
            <FormProvider {...formMethods}>
              <form
                onSubmit={handleSubmit(onPageSubmit)}
              >
                {maintenance && <Maintenance classes={classes} control={control} /> }

                <Credentials
                  title="Enter your Admin Credentials"
                  classes={classes}
                  status={formStatus}
                  statusMessage={displayMessage}
                />

              </form>
            </FormProvider>
          </Grid>
        </Grid>

      </Container>
    </div>
  );
};

LoginInfo.defaultProps = {
  maintenanceEnabled: false,
  maintenance: false,
  status: '',
  customMessage: '',
};

LoginInfo.propTypes = {
  maintenance: PropTypes.bool,
  maintenanceEnabled: PropTypes.bool,
  submitAction: PropTypes.func.isRequired,
  status: PropTypes.string,
  customMessage: PropTypes.string,
};

export default LoginInfo;
