export const ACTIONS = {
  REQUEST_MILSERVICETYPE: 'REQUEST_MILSERVICETYPE',
  RECEIVE_MILSERVICETYPE: 'RECEIVE_MILSERVICETYPE',
  RECEIVE_MILSERVICETYPE_ERROR: 'RECEIVE_MILSERVICETYPE_ERROR',

  REQUEST_MILBRANCHTYPE: 'REQUEST_MILBRANCHTYPE',
  RECEIVE_MILBRANCHTYPE: 'RECEIVE_MILBRANCHTYPE',
  RECEIVE_MILBRANCHTYPE_ERROR: 'RECEIVE_MILBRANCHTYPE_ERROR',

  REQUEST_SECINSTTYPE: 'REQUEST_SECINSTTYPE',
  RECEIVE_SECINSTTYPE: 'RECEIVE_SECINSTTYPE',
  RECEIVE_SECINSTTYPE_ERROR: 'RECEIVE_SECINSTTYPE_ERROR',

  REQUEST_COUNTRY: 'REQUEST_COUNTRY',
  RECEIVE_COUNTRY: 'RECEIVE_COUNTRY',
  RECEIVE_COUNTRY_ERROR: 'RECEIVE_COUNTRY_ERROR',

  REQUEST_STATE: 'REQUEST_STATE',
  RECEIVE_STATE: 'RECEIVE_STATE',
  RECEIVE_STATE_ERROR: 'RECEIVE_STATE_ERROR',

  REQUEST_DEGREETYPE: 'REQUEST_DEGREETYPE',
  RECEIVE_DEGREETYPE: 'RECEIVE_DEGREETYPE',
  RECEIVE_DEGREETYPE_ERROR: 'RECEIVE_DEGREETYPE_ERROR',

  REQUEST_MILEDUCTYPE: 'REQUEST_MILEDUCTYPE',
  RECEIVE_MILEDUCTYPE: 'RECEIVE_MILEDUCTYPE',
  RECEIVE_MILEDUCTYPE_ERROR: 'RECEIVE_MILEDUCTYPE_ERROR',

  REQUEST_TESTPROGTYPE: 'REQUEST_TESTPROGTYPE',
  RECEIVE_TESTPROGTYPE: 'RECEIVE_TESTPROGTYPE',
  RECEIVE_TESTPROGTYPE_ERROR: 'RECEIVE_TESTPROGTYPE_ERROR',

  REQUEST_VISATYPES: 'REQUEST_VISATYPES',
  RECEIVE_VISATYPES: 'RECEIVE_VISATYPES',
  RECEIVE_VISATYPES_ERROR: 'RECEIVE_VISATYPES_ERROR',

  REQUEST_PROVINCES: 'REQUEST_PROVINCES',
  RECEIVE_PROVINCES: 'RECEIVE_PROVINCES',
  RECEIVE_PROVINCES_ERROR: 'RECEIVE_PROVINCES_ERROR',

  REQUEST_OUTLYING_AREAS: 'REQUEST_OUTLYING_AREAS',
  RECEIVE_OUTLYING_AREAS: 'RECEIVE_OUTLYING_AREAS',
  RECEIVE_OUTLYING_AREAS_ERROR: 'RECEIVE_OUTLYING_AREAS_ERROR',

  REQUEST_MILITARY_STATES: 'REQUEST_MILITARY_STATES',
  RECEIVE_MILITARY_STATES: 'RECEIVE_MILITARY_STATES',
  RECEIVE_MILITARY_STATES_ERROR: 'RECEIVE_MILITARY_STATES_ERROR',

  REQUEST_MILITARY_CITIES: 'REQUEST_MILITARY_CITIES',
  RECEIVE_MILITARY_CITIES: 'RECEIVE_MILITARY_CITIES',
  RECEIVE_MILITARY_CITIES_ERROR: 'RECEIVE_MILITARY_CITIES_ERROR',

  REQUEST_MILITARY_COUNTRIES: 'REQUEST_MILITARY_COUNTRIES',
  RECEIVE_MILITARY_COUNTRIES: 'RECEIVE_MILITARY_COUNTRIES',
  RECEIVE_MILITARY_COUNTRIES_ERROR: 'RECEIVE_MILITARY_COUNTRIES_ERROR',

  REQUEST_DISTRICTS: 'REQUEST_DISTRICTS',
  RECEIVE_DISTRICTS: 'RECEIVE_DISTRICTS',
  RECEIVE_DISTRICTS_ERROR: 'RECEIVE_DISTRICTS_ERROR',

  REQUEST_ADMINISTRATIVE_TERRITORY: 'REQUEST_ADMINISTRATIVE_TERRITORY',
  RECEIVE_ADMINISTRATIVE_TERRITORY: 'RECEIVE_ADMINISTRATIVE_TERRITORY',
  RECEIVE_ADMINISTRATIVE_TERRITORY_ERROR: 'RECEIVE_ADMINISTRATIVE_TERRITORY_ERROR',
};

export default ACTIONS;
