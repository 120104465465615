// The purpose of this file is to have a place to cache the list of employer discounts
// This is necessary because storing these large lists in redux can cause the devtools to crash
//    when it tried to maintain a history of these data sets.
// We are still setting this data based on the actions and its status is stored in redux

let sortedEmployerDiscountList = [];

const getSortedEmployerDiscountList = () => sortedEmployerDiscountList;

const setSortedEmployerDiscountList = newSortedEmployerDiscountList => {
  sortedEmployerDiscountList = newSortedEmployerDiscountList;
};

let employerDiscountsGroupedAlphabetically = {};

const getEmployerDiscountsGroupedAlphabetically = () => employerDiscountsGroupedAlphabetically;

const setEmployerDiscountsGroupedAlphabetically = newEmployerDiscountsGroupedAlphabetically => {
  employerDiscountsGroupedAlphabetically = newEmployerDiscountsGroupedAlphabetically;
};

let employerDiscountMap = {};

const getEmployerDiscountMap = () => employerDiscountMap;

const setEmployerDiscountMap = newEmployerDiscountMap => {
  employerDiscountMap = newEmployerDiscountMap;
};

export {
  getSortedEmployerDiscountList,
  setSortedEmployerDiscountList,
  getEmployerDiscountsGroupedAlphabetically,
  setEmployerDiscountsGroupedAlphabetically,
  getEmployerDiscountMap,
  setEmployerDiscountMap,
};
