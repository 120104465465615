/* action types */
export const REQUEST_PERSON_MILITARY = 'REQUEST_PERSON_MILITARY';
export const RECEIVE_PERSON_MILITARY = 'RECEIVE_PERSON_MILITARY';
export const REQUEST_PERSON_MILITARY_ERROR = 'REQUEST_PERSON_MILITARY_ERROR';
export const REQUEST_POST_PERSON_MILITARY = 'REQUEST_POST_PERSON_MILITARY';
export const RECEIVE_POST_PERSON_MILITARY = 'RECEIVE_POST_PERSON_MILITARY';
export const REQUEST_POST_PERSON_MILITARY_ERROR = 'REQUEST_POST_PERSON_MILITARY_ERROR';
export const REQUEST_PUT_PERSON_MILITARY = 'REQUEST_PUT_PERSON_MILITARY';
export const RECEIVE_PUT_PERSON_MILITARY = 'RECEIVE_PUT_PERSON_MILITARY';
export const REQUEST_PUT_PERSON_MILITARY_ERROR = 'REQUEST_PUT_PERSON_MILITARY_ERROR';
export const REQUEST_DELETE_PERSON_MILITARY = 'REQUEST_DELETE_PERSON_MILITARY';
export const RECEIVE_DELETE_PERSON_MILITARY = 'RECEIVE_DELETE_PERSON_MILITARY';
export const REQUEST_DELETE_PERSON_MILITARY_ERROR = 'REQUEST_DELETE_PERSON_MILITARY_ERROR';
export const CLEAR_PERSON_MILITARY_MODIFY_STATUS = 'CLEAR_PERSON_MILITARY_MODIFY_STATUS';
