import retryCallIfNeeded from '../../../restCalls';

export default function militaryService(client) {
  const getMilitaryInstallationSuggest = async (input, dataSetter) => {
    if (input && input.length > 1) {
      const encodedInput = encodeURI(input);
      client.get(`/api/organizations/v1/military/suggestion/${encodedInput}`)
        .then(responseData => {
          if (responseData.data && Array.isArray(responseData.data)) {
            const data = responseData.data
              .map(suggestion => ({ label: suggestion.name, value: suggestion }));
            dataSetter(data);
          }
          // eslint-disable-next-line no-console
        }).catch(error => console.warn(`Attempted to fetch military installation suggestion but received ${error}`));
    }
  };

  const getVeteranOrgSuggest = (input, dataSetter) => {
    if (input && input.length > 1) {
      const encodedInput = encodeURI(input);
      client.get(`/api/organizations/v1/veteranorg/suggestion/${encodedInput}`)
        .then(responseData => {
          if (responseData.data && Array.isArray(responseData.data)) {
            const data = responseData.data
              .map(suggestion => ({ label: suggestion.name, value: suggestion }));
            dataSetter(data);
          }
          // eslint-disable-next-line no-console
        }).catch(error => console.warn(`Attempted to fetch veteran organization suggestion but received ${error}`));
    }
  };
  const getMilitaryByPersonIdAsync = async personId => retryCallIfNeeded(
    client,
    () => client.get(`/api/persons/v1/person/${personId}/military`),
  );
  const postPersonMilitaryAsync = async payload => retryCallIfNeeded(
    client,
    () => client.post('/api/persons/v1/person/military', payload),
  );
  const putPersonMilitaryAsync = async (militaryId, payload, etag) => retryCallIfNeeded(
    client,
    () => {
      const config = {
        headers: {
          'If-Match': etag || 'Default',
        },
      };
      return client.put(`/api/persons/v1/person/military/${militaryId}`, payload, config);
    },
  );
  const deletePersonMilitaryAsync = async (personId, militaryId) => retryCallIfNeeded(
    client,
    () => client.delete(`/api/persons/v1/person/${personId}/military/${militaryId}`),
  );

  return {
    getMilitaryByPersonIdAsync,
    postPersonMilitaryAsync,
    putPersonMilitaryAsync,
    deletePersonMilitaryAsync,
    getMilitaryInstallationSuggest,
    getVeteranOrgSuggest,
  };
}
