export const ACTIONS = {
  REQUEST_APP_EXPERIENCE: 'REQUEST_APP_EXPERIENCE',
  RECEIVE_APP_EXPERIENCE: 'RECEIVE_APP_EXPERIENCE',
  RECEIVE_APP_EXPERIENCE_ERROR: 'RECEIVE_APP_EXPERIENCE_ERROR',

  REQUEST_POST_APP_EXPERIENCE: 'REQUEST_POST_APP_EXPERIENCE',
  RECEIVE_POST_APP_EXPERIENCE: 'RECEIVE_POST_APP_EXPERIENCE',
  RECEIVE_POST_APP_EXPERIENCE_ERROR: 'RECEIVE_POST_APP_EXPERIENCE_ERROR',

  REQUEST_PUT_APP_EXPERIENCE: 'REQUEST_PUT_APP_EXPERIENCE',
  RECEIVE_PUT_APP_EXPERIENCE: 'RECEIVE_PUT_APP_EXPERIENCE',
  RECEIVE_PUT_APP_EXPERIENCE_ERROR: 'RECEIVE_PUT_APP_EXPERIENCE_ERROR',

  CLEAR_APP_EXPERIENCE_PATH_MODIFY_STATUS: 'CLEAR_APP_EXPERIENCE_PATH_MODIFY_STATUS',

  REQUEST_CHOOSE_PATH: 'REQUEST_CHOOSE_PATH',
  RECEIVE_CHOOSE_PATH: 'RECEIVE_CHOOSE_PATH',
  RECEIVE_CHOOSE_PATH_ERROR: 'RECEIVE_CHOOSE_PATH_ERROR',

  REQUEST_POST_CHOOSE_PATH: 'REQUEST_POST_CHOOSE_PATH',
  RECEIVE_POST_CHOOSE_PATH: 'RECEIVE_POST_CHOOSE_PATH',
  RECEIVE_POST_CHOOSE_PATH_ERROR: 'RECEIVE_POST_CHOOSE_PATH_ERROR',

  REQUEST_PUT_CHOOSE_PATH: 'REQUEST_PUT_CHOOSE_PATH',
  RECEIVE_PUT_CHOOSE_PATH: 'RECEIVE_PUT_CHOOSE_PATH',
  RECEIVE_PUT_CHOOSE_PATH_ERROR: 'RECEIVE_PUT_CHOOSE_PATH_ERROR',

  REQUEST_FEATURE_FLAGS: 'REQUEST_FEATURE_FLAGS',
  RECEIVE_FEATURE_FLAGS: 'RECEIVE_FEATURE_FLAGS',
  RECEIVE_FEATURE_FLAGS_ERROR: 'RECEIVE_FEATURE_FLAGS_ERROR',

  REQUEST_POST_FEATURE_FLAGS: 'REQUEST_POST_FEATURE_FLAGS',
  RECEIVE_POST_FEATURE_FLAGS: 'RECEIVE_POST_FEATURE_FLAGS',
  RECEIVE_POST_FEATURE_FLAGS_ERROR: 'RECEIVE_POST_FEATURE_FLAGS_ERROR',

  REQUEST_PUT_FEATURE_FLAGS: 'REQUEST_PUT_FEATURE_FLAGS',
  RECEIVE_PUT_FEATURE_FLAGS: 'RECEIVE_PUT_FEATURE_FLAGS',
  RECEIVE_PUT_FEATURE_FLAGS_ERROR: 'RECEIVE_PUT_FEATURE_FLAGS_ERROR',

  CLEAR_CHOOSE_PATH_MODIFY_STATUS: 'CLEAR_CHOOSE_PATH_MODIFY_STATUS',
};

export const CONSTANTS = {
  PREFERENCE_APP_EXPERIENCE: 'AppExperience',
  EXPERIENCE_NAE: 'NEW_ADMISSIONS',
  EXPERIENCE_APP_CENTER: 'APP_CENTER',
  PREFERENCE_CHOOSE_PATH: 'ChoosePath',
  PREFERENCE_FEATURE_FLAGS: 'FeatureFlags',
};
