export const ACTIONS = {
  REQUEST_POST_MILITARY_BENEFITS: 'REQUEST_POST_MILITARY_BENEFITS',
  RECEIVE_POST_MILITARY_BENEFITS: 'RECEIVE_POST_MILITARY_BENEFITS',
  REQUEST_POST_MILITARY_BENEFITS_ERROR: 'REQUEST_POST_MILITARY_BENEFITS_ERROR',
  REQUEST_POST_SECONDARY_MILITARY_BENEFITS: 'REQUEST_POST_SECONDARY_MILITARY_BENEFITS',
  RECEIVE_POST_SECONDARY_MILITARY_BENEFITS: 'RECEIVE_POST_SECONDARY_MILITARY_BENEFITS',
  REQUEST_POST_SECONDARY_MILITARY_BENEFITS_ERROR: 'REQUEST_POST_SECONDARY_MILITARY_BENEFITS_ERROR',
  CLEAR_SECONDARY_MILITARY_BENEFITS: 'CLEAR_SECONDARY_MILITARY_BENEFITS',
};

export default ACTIONS;
