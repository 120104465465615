import { createTheme } from '@material-ui/core/styles';

// reference this palette in your component within makeStyles with theme.palette
const base = {
  palette: {
    common: {
      black: '#000',
      white: '#FFF',
      darkBlue: '#114A61',
      lightGrey: '#D9D9D9',
      darkGrey: '#B2BBBF',
      blue: '#30718D',
      indigo: '#1A3D4D',
      glacier: '#74AEBF',
      divider: '#DADADA',
      red: '#b21f13',
      green: '#60A626',
    },
    background: {
      paper: '#FFF',
      dark: '#666666',
      default: '#FFF',
      mainBtn: '#DB3725',
      hoverBtn: '#DB3725',
      disabledBtn: '#8c8c8c',
      disabledInput: '#f5f5f5',
      disabledIcon: '#E3E6E7',
      adaGrey: '#e6e9ea',
      divider: '#5E7079',
      lightGrey: '#f2f3f3', // use with color text.adaGrey (for ligthest opt)
      darkGrey: '#97A3A9',
      border: '#BABABA',
      alert: {
        error: '',
        warning: '#FFF1E4',
        info: '#d6f3ff',
        success: '',
      },
      alertBorder: {
        error: '',
        warning: '#E06C00',
        info: '#30718d',
        success: '',
      },
      alertText: {
        error: '',
        warning: '#572A01',
        info: '#004F70',
        success: '',
      },
      lightBlue: '#F0F9FA',
    },
    primary: {
      main: '#DB3725',
      light: '#DD3826',
      contrastText: '#FFF',
      dark: '#8A1500',
      darker: '#58160f',
    },
    secondary: {
      main: '#009CAD',
      dark: '#018391',
      contrastText: '#FFF',
    },
    tertiary: {
      main: '#FFB5AD',
    },
    error: {
      light: '#DB3725',
      main: '#b21f13',
      contrastText: '#FFF',
    },
    text: {
      primary: '#001823',
      secondary: '#5E7079',
      disabled: '#5E7079',
      hint: '#5E7079',
      adaGrey: '#5E7079',
      greyL20: '#3a4f59',
      greyL40: '#7B8A92',
      almostBlack: '#000304',
      darkBlue: '#25576D',
    },
    line: {
      greyL70: '#d2d7d9',
    },
    alert: {
      main: '#d6f3ff',
      contrastText: '#FFF',
      hover: '#2a6279',
    },
    form: {
      label: '#001823',
      labelFocus: '#5e7079',
      error: '#b21f13',
    },
  },
  header: {
    height: '7.4rem',
  },
  spotlight: {
    mainColor: '#DB3725',
    alternateColor: '#97C5CF',
  },
  typography: {
    // this along with giving html font-size 62.5% in index.css allows
    // us to have 1rem == 10px for easier calculation
    // for example, 24px will be equal to 2.4rem, see: https://material-ui.com/customization/typography/#font-size
    htmlFontSize: 10,
    subtitle1: {
      fontsize: '1.6rem',
      fontWeight: 600,
      '@media (max-width:1440px)': {
        fontSize: '1.4rem',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 992,
      lg: 1440,
      xl: 1920,
    },
  },
  // werkt uses a 10px (1rem) grid in their design software so, best to align with them
  spacing: factor => `${1 * factor}rem`,
};

const overrides = {
  overrides: {
    MuiSelect: {
      outlined: {
        borderRadius: '0px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '0px',
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: '0px',
      },
    },
    MuiToggleButtonGroup: {
      grouped: {
        width: '100%',
        color: base.palette.text.primary,
        fontWeight: '700',
        margin: '0 0 20px',
        maxWidth: base.spacing(50),
        // minWidth: base.spacing(25),
        '&.Mui-selected': {
          backgroundColor: base.palette.common.black,
          color: base.palette.common.white,
          '&:hover': {
            backgroundColor: base.palette.common.black,
          },
        },
      },
    },
    MuiRadio: {
      root: {
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        borderRadius: '4px',
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        paddingLeft: '3rem',
        textTransform: 'uppercase',
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: '1.6rem 1.2rem 1.6rem',
      },
    },
  },
};

const theme = {
  ...base,
  ...overrides,
};

export default createTheme(theme);
