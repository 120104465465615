import { ACTIONS } from './types';
import STATUS from '../../globalStatuses';
import { errorToMessage } from '../../../errorMessaging';

function wallet(
  state = {
    wallet: {
      personId: '',
      ssn: '',
      creditBalance: {},
      isDone: false,
      reOpt: false,
      ssnStatus: {
        getStatus: STATUS.UNFETCHED,
        modifyStatus: STATUS.UNFETCHED,
      },
      creditBalanceStatus: {
        getStatus: STATUS.UNFETCHED,
        modifyStatus: STATUS.UNFETCHED,
      },
    },
  },
  action,
) {
  switch (action.type) {
    // GET Ssn
    case ACTIONS.REQUEST_SSN:
      return {
        ...state,
        ssnStatus: {
          ...state.ssnStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_SSN:
      return {
        ...state,
        ssn: action.ssn,
        isDone: action.isDone,
        ssnStatus: {
          ...state.ssnStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_SSN_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        ssnStatus: {
          ...state.ssnStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // POST/PUT/DELETE Ssn
    case ACTIONS.REQUEST_POST_SSN:
    case ACTIONS.REQUEST_DELETE_SSN:
    case ACTIONS.REQUEST_PUT_SSN:
      return {
        ...state,
        ssnStatus: {
          ...state.ssnStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };

    case ACTIONS.RECEIVE_POST_SSN:
    case ACTIONS.RECEIVE_PUT_SSN:
      return {
        ...state,
        personId: action.personId,
        ssn: action.ssn,
        ssnStatus: {
          ...state.ssnStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.RECEIVE_DELETE_SSN:
      return {
        ...state,
        personId: '',
        ssn: '',
        ssnStatus: {
          ...state.ssnStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };

    case ACTIONS.REQUEST_POST_SSN_ERROR:
    case ACTIONS.REQUEST_PUT_SSN_ERROR:
    case ACTIONS.REQUEST_DELETE_SSN_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        ssnStatus: {
          ...state.ssnStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.CLEAR_SSN_MODIFY_STATUS:
      return {
        ...state,
        ssnStatus: {
          ...state.ssnStatus,
          modifyStatus: STATUS.UNFETCHED,
        },
      };

    // RE OPT
    case ACTIONS.RE_OPT:
      return {
        ...state,
        reOpt: action.optIn,
      };

    // ######################
    // CREDIT BALANCE REDUCER
    // ######################
    // GET CREDIT BALANCE
    case ACTIONS.REQUEST_CREDIT_BALANCE:
      return {
        ...state,
        creditBalanceStatus: {
          ...state.creditBalanceStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_CREDIT_BALANCE:
      return {
        ...state,
        creditBalance: {
          refundOption: action.creditBalance.refundOption,
          accountNickname: action.creditBalance.accountNickname,
          routingNumber: action.creditBalance.routingNumber,
          accountNumber: action.creditBalance.accountNumber,
        },
        creditBalanceStatus: {
          ...state.creditBalanceStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_CREDIT_BALANCE_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        creditBalanceStatus: {
          ...state.creditBalanceStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // POST/PUT/DELETE CREDIT BALANCE
    case ACTIONS.REQUEST_POST_CREDIT_BALANCE:
    case ACTIONS.REQUEST_DELETE_CREDIT_BALANCE:
    case ACTIONS.REQUEST_PUT_CREDIT_BALANCE:
      return {
        ...state,
        creditBalanceStatus: {
          ...state.creditBalanceStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };

    case ACTIONS.RECEIVE_POST_CREDIT_BALANCE:
    case ACTIONS.RECEIVE_PUT_CREDIT_BALANCE:
      return {
        ...state,
        // CURRENT ENDPOINT DOES NOT RETURN DATA. IF CHANGES - UNCOMMENT BELOW
        // creditBalance: {
        //   refundOption: action.creditBalance.refundOption,
        //   accountNickname: action.creditBalance.accountNickname,
        //   routingNumber: action.creditBalance.routingNumber,
        //   accountNumber: action.creditBalance.accountNumber,
        // },
        creditBalanceStatus: {
          ...state.creditBalanceStatus,
          modifyStatus: STATUS.UPDATED,
          getStatus: STATUS.UNFETCHED,
        },
      };
    case ACTIONS.RECEIVE_DELETE_CREDIT_BALANCE:
      return {
        ...state,
        creditBalance: {},
        creditBalanceStatus: {
          ...state.creditBalanceStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };

    case ACTIONS.REQUEST_POST_CREDIT_BALANCE_ERROR:
    case ACTIONS.REQUEST_PUT_CREDIT_BALANCE_ERROR:
    case ACTIONS.REQUEST_DELETE_CREDIT_BALANCE_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        creditBalanceStatus: {
          ...state.creditBalanceStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.CLEAR_CREDIT_BALANCE_MODIFY_STATUS:
      return {
        ...state,
        creditBalanceStatus: {
          ...state.creditBalanceStatus,
          modifyStatus: STATUS.UNFETCHED,
        },
      };
    default:
      return state;
  }
}

export default wallet;
