import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ReactComponent as ERIcon } from '../../icons/erIcon/IRIcon.svg';
import styles from './styles';

export default function NameHelp({
  fullname,
  styleOverride,
}) {
  const classes = styles();

  return (
    <Grid
      container
      wrap="nowrap"
      className={clsx(classes.containerGrid, 'nameHelp')}
      style={styleOverride}
    >
      <Grid item>
        <ERIcon className={classes.erIcon} />
      </Grid>
      <Grid item>
        <div className={classes.fullname}>
          {fullname}
        </div>
      </Grid>
    </Grid>
  );
}

NameHelp.defaultProps = {
  fullname: '',
  styleOverride: {},
};

NameHelp.propTypes = {
  fullname: PropTypes.string,
  styleOverride: PropTypes.objectOf(
    PropTypes.object,
  ),
};
