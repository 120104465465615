import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, ClickAwayListener, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import styles from './styles';

const useStyles = styles;

function HelpTooltip({
  title,
  color,
  iconSize,
  arrow,
  theme,
  placement,
  id,
  tabIndex,
  popperProps,
  tooltipContextId,
  additionalClass,
  iconStyle,
}) {
  const classes = useStyles();

  const tooltipClasses = {
    tooltip: `${classes.tooltip} ${theme === 'light' ? classes.lightTooltip : classes.darkTooltip}`,
    arrow: (theme === 'light' ? classes.lightArrow : classes.darkArrow),
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  // Accessibility - ADA
  const targetMouseout = () => {
    const tooltipBtn = document.getElementById(`${id}-btn`);
    // eslint-disable-next-line no-use-before-define
    tooltipBtn.removeEventListener('click', keepOpen, false);
    setTimeout(() => {
      setOpen(true);
    }, 50);
    tooltipBtn.removeEventListener('mouseout', targetMouseout, false);
  };

  const keepOpen = () => {
    const tooltipBtn = document.getElementById(`${id}-btn`);
    tooltipBtn.addEventListener('mouseout', targetMouseout, false);
  };

  const handleOpen = () => {
    setOpen(true);
    const tooltipBtn = document.getElementById(`${id}-btn`);
    tooltipBtn.addEventListener('click', keepOpen, false);
  };

  const escADA = useCallback(event => {
    if (event.key === 'Escape') {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('keydown', escADA, false);
    return () => {
      document.body.removeEventListener('keydown', escADA, false);
    };
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Tooltip
        id={`${id}-text`}
        data-cy={`${id}-btn`}
        classes={tooltipClasses}
        title={title}
        arrow={arrow}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        placement={placement}
        tabIndex={tabIndex}
        PopperProps={popperProps}
        enterTouchDelay={0}
      >
        <IconButton
          id={`${id}-btn`}
          className={`tooltip-btn ${additionalClass}`}
          color={color}
          disableFocusRipple
          disableRipple
          // tooltipContextId is to bring context to the tooltip - ada
          aria-labelledby={tooltipContextId}
        >
          <InfoIcon
            aria-hidden="true"
            fontSize={iconSize}
            style={iconStyle}
          />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
}

HelpTooltip.defaultProps = {
  color: 'inherit',
  arrow: true,
  iconSize: 'inherit',
  theme: 'dark',
  placement: 'bottom',
  id: null,
  tabIndex: 0,
  popperProps: { disablePortal: true },
  tooltipContextId: '',
  additionalClass: '',
  iconStyle: {},
};

HelpTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
  id: PropTypes.string,
  color: PropTypes.oneOf(['inherit', 'primary', 'secondary']),
  arrow: PropTypes.bool,
  iconSize: PropTypes.oneOf(['inherit', 'default', 'small', 'large']),
  theme: PropTypes.oneOf(['light', 'dark']),
  placement: PropTypes.oneOf(['top', 'top-end', 'bottom', 'start', 'end']),
  popperProps: PropTypes.shape({}),
  tooltipContextId: PropTypes.string,
  additionalClass: PropTypes.string,
  iconStyle: PropTypes.shape({}),
};

export default React.memo(HelpTooltip);
