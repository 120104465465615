import { errorToMessage } from '../../../../errorMessaging';
import STATUS from '../../../globalStatuses';
import {
  RECEIVE_PERSON_CITIZENSHIP,
  RECEIVE_POST_PERSON_CITIZENSHIP,
  RECEIVE_PUT_PERSON_CITIZENSHIP,
  REQUEST_PERSON_CITIZENSHIP,
  REQUEST_PERSON_CITIZENSHIP_ERROR,
  REQUEST_POST_PERSON_CITIZENSHIP,
  REQUEST_POST_PERSON_CITIZENSHIP_ERROR,
  REQUEST_PUT_PERSON_CITIZENSHIP,
  REQUEST_PUT_PERSON_CITIZENSHIP_ERROR,
  REQUEST_DELETE_PERSON_CITIZENSHIP,
  RECEIVE_DELETE_PERSON_CITIZENSHIP,
  REQUEST_DELETE_PERSON_CITIZENSHIP_ERROR,
  CLEAR_PERSON_CITIZENSHIP_MODIFY_STATUS,
} from './types';

function citizenship(
  state = {
    citizenship: {},
  },
  action,
) {
  switch (action.type) {
    // GET Citizenship
    case REQUEST_PERSON_CITIZENSHIP:
      return {
        ...state,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_PERSON_CITIZENSHIP:
      return {
        ...state,
        citizenshipETag: action.etag,
        citizenship: action.citizenship,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_PERSON_CITIZENSHIP_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // POST Citizenship
    case REQUEST_POST_PERSON_CITIZENSHIP:
      return {
        ...state,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_POST_PERSON_CITIZENSHIP:
      return {
        ...state,
        citizenshipETag: action.etag,
        citizenship: action.citizenship,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case REQUEST_POST_PERSON_CITIZENSHIP_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // PUT Citizenship
    case REQUEST_PUT_PERSON_CITIZENSHIP:
      return {
        ...state,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_PUT_PERSON_CITIZENSHIP:
      return {
        ...state,
        citizenshipETag: action.etag,
        citizenship: action.citizenship,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case REQUEST_PUT_PERSON_CITIZENSHIP_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // DELETE Citizenship
    case REQUEST_DELETE_PERSON_CITIZENSHIP:
      return {
        ...state,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_DELETE_PERSON_CITIZENSHIP:
      return {
        ...state,
        citizenship: action.citizenship,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case REQUEST_DELETE_PERSON_CITIZENSHIP_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case CLEAR_PERSON_CITIZENSHIP_MODIFY_STATUS:
      return {
        ...state,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.UNFETCHED,
        },
      };
    default:
      return state;
  }
}

export default citizenship;
