export default [
  'Graduate',
  'Doctorate',
  'Graduate Certificate',
];

export const APPLICATION_ADMISSION_STATUS = {
  INPROGRESS: 'In progress',
  SUBMITTED: 'Submitted',
  UNDERREVIEW: 'Under Review',
  COMPLETED: 'Completed',
};
