// This is a patch to allow this code to be reused in the server.
// Can be refactored later, for not the functions calling the FS methods
// Are already not being used by the server.
const fullStoryUpdater = {
  sendCustomFullStoryEvent: () => { },
};
if ((typeof window !== 'undefined') && window) {
  const {
    sendCustomFullStoryEvent,
    // eslint-disable-next-line global-require
  } = require('./modules/vendor/fullstory/FullStoryUpdater');
  fullStoryUpdater.sendCustomFullStoryEvent = sendCustomFullStoryEvent;
}
const {
  REACT_APP_FORGE_ROCK_BASE_URL,
} = process.env;

const methodToCode = method => {
  switch (method) {
    case 'delete':
      return '01';
    case 'get':
      return '02';
    case 'post':
      return '03';
    case 'put':
      return '04';
    default:
      return '00';
  }
};

/**
 * Convert Domain and Subdomains to Code
 * @param {*} domain
 * @param {*} url
 */
// TODO: Ensure all calls in the app are covered. (I.E. Wallets, work, etc)
const domainToDigits = (domain, url) => {
  switch (domain) {
    case 'applications':
      if (url.indexOf('navigation') >= 0) {
        return '011';
      }
      return '010';
    case 'catalogs':
      return '020';
    case 'contacts':
      if (url.indexOf('validate') >= 0) {
        if (url.indexOf('zipcode') >= 0) {
          return '032';
        }
        if (url.indexOf('address') >= 0) {
          return '033';
        }
        return '031';
      }
      if (url.indexOf('suggest') >= 0) {
        if (url.indexOf('address') >= 0) {
          return '035';
        }
        return '034';
      }
      if (url.indexOf('address') >= 0) {
        return '036';
      }
      if (url.indexOf('email') >= 0) {
        return '037';
      }
      if (url.indexOf('phone') >= 0) {
        return '038';
      }
      if (url.indexOf('consent') >= 0) {
        return '039';
      }
      return '030';
    case 'courses':
      return '040';
    case 'iam':
      if (url.indexOf('email') >= 0) {
        return '051';
      }
      if (url.indexOf('username') >= 0) {
        return '052';
      }
      return '050';
    case 'learning':
      return '060';
    case 'organizations':
      if (url.indexOf('military') >= 0) {
        return '072';
      }
      if (url.indexOf('veranorg') >= 0) {
        return '072';
      }
      return '070';
    case 'persons':
      if (url.indexOf('names') >= 0) {
        return '081';
      }
      if (url.indexOf('name') >= 0) {
        return '082';
      }
      if (url.indexOf('information') >= 0) {
        return '083';
      }
      if (url.indexOf('education') >= 0) {
        if (url.indexOf('higher') >= 0) {
          return '085';
        }
        if (url.indexOf('secondary') >= 0) {
          return '086';
        }
        return '084';
      }
      if (url.indexOf('exams') >= 0) {
        return '087';
      }
      if (url.indexOf('military') >= 0) {
        return '088';
      }
      if (url.indexOf('employment') >= 0) {
        return '089';
      }
      return '080';
    case 'preferences':
      return '090';
    case 'programs':
      if (url.indexOf('programDetail') >= 0) {
        return '101';
      }
      if (url.indexOf('programOfferings') >= 0) {
        return '102';
      }
      return '100';
    case 'sagas':
      if (url.indexOf('humantasks') >= 0) {
        return '111';
      }
      if (url.indexOf('workflow/ADMISSION/person') >= 0) {
        return '112';
      }
      if (url.indexOf('workflow') >= 0) {
        return '113';
      }
      if (url.indexOf('/tasks') >= 0) {
        return '114';
      }
      return '110';
    case 'students':
      return '120';
    case 'wallets':
      return '130';
    case 'financialaid':
      return '140';
    case 'document':
      if (url.indexOf('task') >= 0) {
        return '151';
      }
      if (url.indexOf('sign') >= 0) {
        return '152';
      }
      if (url.indexOf('signDocument') >= 0) {
        return '153';
      }
      if (url.indexOf('cleanupForms') >= 0) {
        return '154';
      }
      if (url.indexOf('adobe') >= 0) {
        return '155';
      }
      if (url.indexOf('submitTasks') >= 0) {
        return '156';
      }
      if (url.indexOf('attachments') >= 0) {
        return '157';
      }
      return '150';
    case 'documents':
      return '160';
    default:
      return '000';
  }
};

const urlToCode = url => {
  const urlParts = url.split('/');
  if (url.indexOf('/application/') >= 0) { // PROXY Request 2XXXX
    switch (urlParts[2]) {
      case 'api':
        return `21${domainToDigits(urlParts[3], url)}`;
      case 'cas':
        return '22000';
      case 'csrf':
        return '23000';
      default:
        return '20000';
    }
  }
  if (url.indexOf('/api/') >= 0) { // API Request 10XXX
    return `10${domainToDigits(urlParts[2], url)}`;
  }
  if (url.indexOf(REACT_APP_FORGE_ROCK_BASE_URL) >= 0) { // FORGE ROCK REQUEST
    return '30000';
  }
  return '00000'; // OTHER
};

const jsErrorToCode = error => {
  const errorType = error.name;
  let errorTypeCode = '';
  let errorMessageCode = '';
  switch (errorType) {
    case 'EvalError':
      errorTypeCode = '001';
      break;
    case 'InternalError':
      errorTypeCode = '002';
      break;
    case 'RangeError':
      errorTypeCode = '003';
      break;
    case 'ReferenceError':
      errorTypeCode = '004';
      break;
    case 'SyntaxError':
      errorTypeCode = '005';
      break;
    case 'TypeError':
      errorTypeCode = '006';
      break;
    case 'URIError':
      errorTypeCode = '007';
      break;
    case 'Error':
      errorTypeCode = '008';
      break;
    case 'MissingData':
      errorTypeCode = '009';
      break;
    default:
      errorTypeCode = '000';
  }

  // TODO: Extend this with more conditons of expected JS errors
  if (error.message.includes('Cannot read propert') && error.message.includes('length')) {
    errorMessageCode = '001';
  } else if (error.message.includes('Cannot read propert')
    && error.message.includes('of undefined')) {
    errorMessageCode = '002';
  } else if (error.message.includes('\'undefined\' is not an object')) {
    errorMessageCode = '003';
  } else if (error.message.includes('\'null\' is not an object')) {
    errorMessageCode = '004';
  } else if (error.message.includes('Object doesn\'t support property or method')) {
    errorMessageCode = '005';
  } else if (error.message.includes('is not a function')) {
    errorMessageCode = '006';
  } else if (error.message.includes('Maximum call stack size exceeded')) {
    errorMessageCode = '007';
  } else if (error.message.includes('Cannot set propert')
    && error.message.includes('of undefined')) {
    errorMessageCode = '008';
  } else if (error.message.includes('is not defined')) {
    errorMessageCode = '009';
  } else if (error.message.includes('Network Error')) {
    errorMessageCode = '010';
  } else if (error.message.includes('No financial plan id')) {
    errorMessageCode = '011';
  } else if (error.message.includes('No apply web id')) {
    errorMessageCode = '012';
  } else {
    errorMessageCode = '000';
  }

  return `${errorTypeCode}-${errorMessageCode}`;
};

const PIIBlacklist = [
  'wallets',
  'iam',
];

const isPII = url => {
  let isBlocked = false;
  if (url) {
    PIIBlacklist.forEach(blacklistedUrl => {
      if (url.includes(blacklistedUrl)) {
        isBlocked = true;
      }
    });
  }

  return isBlocked;
};

const handleRequestError = error => {
  const { config = {}, response = {}, code = '' } = (error || {});
  let errorCode;
  if (error === 'No Email found') {
    errorCode = '02-21037-404';
  } else if (error === 'No Address found') {
    errorCode = '02-21036-404';
  } else if (error === 'No Phone found') {
    errorCode = '02-21038-404';
  } else if (error === 'No Phone Consent found') {
    errorCode = '02-21039-404';
  } else if (error === 'Navigation Error') {
    errorCode = '03-21011-000';
  } else if (error === 'triggerAlloyTrack error') {
    errorCode = '00-TAT-000';
  } else if (error === 'triggerAlloyPageView error') {
    errorCode = '00-TAPV-000';
  } else if (error === 'applicationSectionInfo Error') {
    errorCode = '00-ASI-000';
  } else if (error === 'postApplication analytics Error') {
    errorCode = '00-PAA-000';
  } else if (error === 'esiError') {
    errorCode = '00-ESI-000';
  } else {
    errorCode = code || `${methodToCode(config.method)}-${urlToCode(config.url ? config.url.toLowerCase() : '')}-${response.status || '000'}`;
  }
  const requestId = config.headers ? `\nREQUEST ID: ${config.headers['X-Request-Id']}` : '';
  const correlationId = `\nCORRELATION ID: ${config.headers ? config.headers['X-Correlation-Id'] : sessionStorage.getItem('X-Correlation-Id')}`;
  const shouldDataBeBlocked = isPII(config.url);

  // Object spread is not supported allowed in our current version of node.
  // eslint-disable-next-line prefer-object-spread
  const strippedResponse = Object.assign({}, response);

  if (strippedResponse.config
    && strippedResponse.config.headers
    && strippedResponse.config.headers.Authorization) {
    delete strippedResponse.config.headers.Authorization;
  }

  if (shouldDataBeBlocked
    && strippedResponse.config
    && strippedResponse.config.data) {
    delete strippedResponse.config.data;
  }

  fullStoryUpdater.sendCustomFullStoryEvent('REQUEST_ERROR', {
    requestId,
    correlationId,
    isPII: shouldDataBeBlocked,
    errorCode,
    requestUrl: config.url,
    requestMethod: config.method,
    requestBody: shouldDataBeBlocked || config.data ? {} : JSON.stringify(config.data),
    requestResponse: strippedResponse,
  });

  return errorCode;
};

const handleJsError = error => {
  console.log('Javascript error stack trace', error);
  const errorCode = jsErrorToCode(error);

  fullStoryUpdater.sendCustomFullStoryEvent('JAVASCRIPT_ERROR', {
    errorCode,
    errorMessage: error.message,
    errorStackTrace: error.stack,
  });

  return errorCode;
};

const errorToMessage = error => {
  let errorCode;
  try {
    if (error && error.message && !error.response) {
      errorCode = handleJsError(error);
    } else {
      errorCode = handleRequestError(error);
    }
  } catch {
    errorCode = '000-500';
  }
  return errorCode;
};

const errorsToMessages = errors => {
  const errorCodes = [];
  errors.forEach(error => {
    const dataError = error.errorData;
    try {
      if (dataError && dataError.message && !dataError.response) {
        errorCodes.push(handleJsError(dataError));
      } else {
        errorCodes.push(handleRequestError(dataError));
      }
    } catch {
      errorCodes.push('000-500');
    }
  });
  return errorCodes;
};

const errorToMessageNode = (method, url, status, headers) => {
  const requestId = headers ? `REQUEST ID: ${headers['x-request-id']}` : '';
  const correlationId = headers ? `CORRELATION ID: ${headers['x-correlation-id']}` : '';
  const errorMessage = `${methodToCode(method)}-${urlToCode(url ? url.toLowerCase() : '')}-${status || ''} ${requestId}  ${correlationId}`;
  return errorMessage;
};

module.exports = {
  errorToMessage, errorsToMessages, errorToMessageNode, urlToCode,
};
