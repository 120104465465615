export const ACTIONS = {
  CLEAR_CALCULATOR_INPUT_MODIFY_STATUS: 'CLEAR_CALCULATOR_INPUT_MODIFY_STATUS',
  REQUEST_POST_CALCULATOR_INPUT: 'REQUEST_POST_CALCULATOR_INPUT',
  RECEIVE_POST_CALCULATOR_INPUT: 'RECEIVE_POST_CALCULATOR_INPUT',
  REQUEST_POST_CALCULATOR_INPUT_ERROR: 'REQUEST_POST_CALCULATOR_INPUT_ERROR',
  REQUEST_CALCULATOR_INPUT: 'REQUEST_CALCULATOR_INPUT',
  RECEIVE_CALCULATOR_INPUT: 'RECEIVE_CALCULATOR_INPUT',
  REQUEST_CALCULATOR_INPUT_ERROR: 'REQUEST_CALCULATOR_INPUT_ERROR',
  REQUEST_PATCH_CALCULATOR_INPUT: 'REQUEST_PATCH_CALCULATOR_INPUT',
  RECEIVE_PATCH_CALCULATOR_INPUT: 'RECEIVE_PATCH_CALCULATOR_INPUT',
  REQUEST_PATCH_CALCULATOR_INPUT_ERROR: 'REQUEST_PATCH_CALCULATOR_INPUT_ERROR',
};

export default ACTIONS;
