import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  panelRoot: {
    borderColor: theme.palette.common.blue,
    borderWidth: theme.spacing(0.1),
    borderStyle: 'solid',
    boxShadow: 'none',
    marginBottom: theme.spacing(2),
  },
  iconItem: {
    '& svg': {
      width: theme.spacing(3.3),
      height: theme.spacing(3.3),
    },
  },
  headerItem: {
    minHeight: theme.spacing(5.4),
    padding: '1.3rem 0 1rem 0.8rem',
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(6),
      padding: '1.4rem 0 1rem 1.6rem',
    },
    '& h3': {
      fontSize: theme.spacing(1.6),
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.spacing(2),
      },
      margin: 0,
    },
  },
  buttonText: {
    fontWeight: 700,
  },
  checkbox: {
    fontWeight: '400',
    fontSize: theme.spacing(1.5),
  },
  diabledLink: {
    pointerEvents: 'none',
    backgroundColor: theme.palette.secondary.blue,
    cursor: 'default',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  taskContent: {
    marginBottom: theme.spacing(1.5),
    display: 'inline-block',
    '& ul': {
      paddingTop: '0.8rem!important',
      paddingBottom: '0.8rem!important',
    },
    '& li': {
      listStyleType: 'square',
      paddingBottom: '0.6rem!important',
    },
  },
  statusBlock: {
    marginBottom: theme.spacing(1),
  },
  descMsg: {
    marginBottom: theme.spacing(0.5),
  },
  statusMessage: {
    padding: '1.3rem 0.8rem 1rem 0.8rem',
    marginBottom: theme.spacing(0.4),
  },
  statusContent: {
    textAlign: 'center',
    marginTop: theme.spacing(0.4),
  },
  notificationStatusIcon: {
    position: 'absolute',
    marginLeft: '0.2rem',
  },
  statusLabel: {
    fontWeight: 700,
    marginLeft: '3rem',
  },
}));
