import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  link: {
    '& .MuiButton-label': {
      whiteSpace: 'nowrap',
    },
    '&.routerLink.linkArrow': {
      whiteSpace: 'nowrap',
    },
    '&.linkArrow': {
      position: 'relative',
      paddingRight: theme.spacing(6),
      color: theme.palette.primary.main,
      fontWeight: '400',
      '&:hover': {
        backgroundColor: 'transparent',
        '& .rightArrow': {
          marginLeft: theme.spacing(1.2),
        },
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '&.MuiButton-text': {
        paddingLeft: 0,
      },
      '& .MuiButton-endIcon': {
        marginLeft: 0,
      },
      '& .arrowWrapper': {
        width: '100%',
        display: 'inline-block',
      },
      '& .arrowWrapperLink': {
        width: '100%',
        display: 'inline',
        '& .rightArrow': {
          bottom: theme.spacing(1.5),
        },
      },
    },
  },
  rightArrow: {
    marginLeft: theme.spacing(0.8),
    bottom: theme.spacing(2),
    position: 'absolute',
    transition: 'margin .2s',
    '&:before': {
      borderColor: theme.palette.primary.main,
      borderWidth: theme.spacing(0.05),
      borderStyle: 'solid',
      height: 0,
      top: '50%',
      transform: 'translateY(0)',
      width: theme.spacing(3.9),
      content: '""',
      position: 'absolute',
    },
    '&:after': {
      borderColor: theme.palette.primary.main,
      borderRightStyle: 'solid',
      borderTopStyle: 'solid',
      borderRightWidth: theme.spacing(0.1),
      borderTopWidth: theme.spacing(0.1),
      display: 'inline-block',
      height: theme.spacing(1),
      right: theme.spacing(-3.4),
      top: '50%',
      transform: 'rotate(45deg) translateY(-65%)',
      width: theme.spacing(1),
      content: '""',
      position: 'absolute',
    },
  },
}));
